import { PercentageType } from '@intentic/yggdrasil-rotr';
import { Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { DataObjectViewProps } from '../../DataObjectView';

export const PercentageView = React.forwardRef<any, DataObjectViewProps>(
	({
		dataObject,
		variant = 'body1',
		...other
	}, ref) =>
	{
		const value = useMemo(() =>
		{
			return new Intl.NumberFormat().format(PercentageType.getRawPercentage(dataObject)!.toNumber());
		}, [dataObject]);

		return <Typography variant={variant} ref={ref} {...other}>{value}%</Typography>;
	});