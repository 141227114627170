import { Box, ButtonBase } from '@material-ui/core';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { Risicoklasse } from '../../../../../__generated__/ygg/model/modelClasses';
import { useComputed } from '../../../../../mobx/useComputed';
import { RisicobeoordelingItem } from '../../data/RiscoBeoordelingItem';
import {
	RISICO_KLASSE_HIGH_ID,
	RISICO_KLASSE_LOW_ID,
	RISICO_KLASSE_MIDDLE_ID,
	RISICO_KLASSE_VERY_HIGH_ID
} from '../../data/RisicoKlasseIds';
import { useBarStyles } from './useBarStyles';

export interface BarProps<T extends {readonly id: string | number}>
{
	item: T
	selectedItem?: T
	onSelectItem: (item: T | undefined) => void
	summary?: (item: T) => string
	referenceScore?: (item: T) => Risicoklasse
	actualScore?: (item: T) => Risicoklasse | undefined | null
	activated?: boolean
}

const defaultSummary = (item: RisicobeoordelingItem) => `R${item.id}`;
const defaultReferenceScore = ({beoordeling}: RisicobeoordelingItem) => beoordeling.automatischeInschattingRisicoklasse!
const defaultActualScore = ({beoordeling}: RisicobeoordelingItem) => beoordeling.brutoRisicoklasse

export const Bar = <T extends {readonly id: string | number}, >
	({
		item,
		selectedItem,
		onSelectItem,
		summary = defaultSummary as any,
		referenceScore = defaultReferenceScore as any,
		actualScore = defaultActualScore as any,
		activated
	}: BarProps<T>) =>
	{
		const classes = useBarStyles();

		const referenceRisicoKlasse = useComputed(() => referenceScore(item).id, [item, referenceScore]);

		const actualRisicoKlasse = useComputed(() => actualScore(item)?.id ?? referenceRisicoKlasse, [actualScore, item, referenceRisicoKlasse]);

		const rootClassName = clsx(
			classes.root,
			{
				[classes.low]: actualRisicoKlasse === RISICO_KLASSE_LOW_ID,
				[classes.middle]: actualRisicoKlasse === RISICO_KLASSE_MIDDLE_ID,
				[classes.high]: actualRisicoKlasse === RISICO_KLASSE_HIGH_ID,
				[classes.veryHigh]: actualRisicoKlasse === RISICO_KLASSE_VERY_HIGH_ID,
				[classes.selected]: item.id === selectedItem?.id,
			}
		);

		const outerClassName = clsx(
			classes.outer,
			{
				[classes.low]: actualRisicoKlasse === RISICO_KLASSE_LOW_ID || referenceRisicoKlasse === RISICO_KLASSE_LOW_ID,
				[classes.middle]: actualRisicoKlasse === RISICO_KLASSE_MIDDLE_ID || referenceRisicoKlasse === RISICO_KLASSE_MIDDLE_ID,
				[classes.high]: actualRisicoKlasse === RISICO_KLASSE_HIGH_ID || referenceRisicoKlasse === RISICO_KLASSE_HIGH_ID,
				[classes.veryHigh]: actualRisicoKlasse === RISICO_KLASSE_VERY_HIGH_ID || referenceRisicoKlasse === RISICO_KLASSE_VERY_HIGH_ID,
			}
		);
		const disabled = clsx({
				[classes.disabled]:!activated
			}
		)

		const handleClick = useCallback(() => 
		{
			onSelectItem(item);
		}, [item, onSelectItem]);
		return <ButtonBase
			classes={{disabled:disabled}}
			className={rootClassName}
			focusVisibleClassName={classes.selected}
			onClick={handleClick}
			disabled={!activated}
		>
			{summary(item)}
			<Box
				className={outerClassName}
			/>
			<Box
				className={classes.inner}
			/>
		</ButtonBase>;
	};