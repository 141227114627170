import { useEffect, useRef, useState } from 'react';
import { Observable, Subscription } from 'rxjs';

/**
 * Custom hook for usage of an {@link Observable}
 *
 * @param observable used {@link Observable}
 * @param catchError optional callback which catches the error
 * @return latest observable value
 */
export const useObservable = <T>(
	observable: Observable<T> | undefined,
	catchError?: (error: Error) => (void | T),
): T | undefined =>
{
	const [latestValue, setLatestValue] = useState<T>();
	const [subscription, setSubscription] = useState<Subscription | undefined>();

	const catchErrorRef = useRef(catchError);

	useEffect(() =>
	{
		if (observable)
		{
			if (catchErrorRef.current === undefined)
			{
				setSubscription(
					observable.subscribe(
						newValue => setLatestValue(newValue),
					)
				);
			}
			else
			{
				const errorHandler = (error: Error) =>
				{
					if (catchErrorRef.current !== undefined)
					{
						const result = catchErrorRef.current(error);

						if (result !== undefined)
							setLatestValue(result);
					}
					else
						throw error;
				}

				setSubscription(
					observable.subscribe(
						newValue => setLatestValue(newValue),
						errorHandler,
					)
				);
			}
		}
	}, [observable]);

	useEffect(() =>
	{
		return () => subscription?.unsubscribe();
	}, [subscription]);

	return latestValue;
};