/**
 * @param parameterString accepted forms:
 * <ul>
 *     <li>'?foo=bar&bee=cee'</li>
 *     <li>'#x=z&y=q'</li>
 *     <li>''</li>
 *     <li>'#'</li>
 *     <li>'?'</li>
 * </ul>
 */
export function parseParameterString(parameterString: string): { [key: string]: any }
{
	return parameterString
		.replace(/^#/, '')
		.replace(/^\?/, '')
		.split('&')
		.reduce(
			(accumulate, item) =>
			{
				const parts = item.split('=');
				const key = parts[0];
				const value = parts[1];
				const newAccumulate = {...accumulate};
				newAccumulate[key] = value;
				return newAccumulate;
			},
			{} as { [key: string]: any },
		);
}