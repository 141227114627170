import { SvgIconComponent } from '@material-ui/icons';
import React, {Dispatch, FC, SetStateAction, useContext, useMemo} from 'react';
import { useComputed } from '../../../mobx/useComputed';
import {
	BEDRIJFSPROFIEL_STEP,
	INVENTARISATIEVRAGEN_STEP,
	RAPPORTAGE_STEP,
	RISICO_ANALYSE_STEP,
	VERZEKERINGEN_STEP
} from '../../../pages/risico/dossier/data/DossierStep';
import { AppDrawerItem } from '../../AppDrawerItem';
import { DossierContextRef } from '../../data/DossierContext';

interface RisicoAdviesDossierAppDrawerItemProps
{
	item: string
	name: string
	selected: boolean
	Icon: SvgIconComponent,
	setConfirmDialogActions?: Dispatch<SetStateAction<{ onConfirm: () => void; onCancel: () => void }>>
	toggleConfirmDialog?: Dispatch<SetStateAction<boolean>>
}

export const RisicoAdviesDossierAppDrawerItem: FC<RisicoAdviesDossierAppDrawerItemProps> =
	({
		item,
		name: nameProp,
		...other
	}) =>
	{
		const dossier = useContext(DossierContextRef);

		const to = useMemo(() =>
		{
			return `/risico/${item}?d=${dossier?.id}`;
		}, [dossier, item]);

		const name = useComputed(() =>
		{
			if (item === 'dossier' && dossier !== undefined)
				return dossier.omschrijving;
			else
				return nameProp;
		}, [dossier, item, nameProp]);

		const [disabled, navigateWithConfirmation] = useMemo(() =>
		{
			switch (item)
			{
				case 'dossier':
					return [dossier === undefined, false];
				case 'organisatie/':
					switch (dossier?.huidigeStap!.id)
					{
						case BEDRIJFSPROFIEL_STEP:
							return [false, true]
						case undefined:
							return [true, false];
						default:
							return [false, false];
					}
				case 'bedrijfsprofiel':
				{
					switch (dossier?.huidigeStap!.id)
					{

						case BEDRIJFSPROFIEL_STEP:
						case RISICO_ANALYSE_STEP:
						case VERZEKERINGEN_STEP:
						case RAPPORTAGE_STEP:
							return [false, false];
						case INVENTARISATIEVRAGEN_STEP:
							return [false, true];
						default:
							return [true, false];
					}
				}
				case 'inventarisatievragen':
				{
					switch (dossier?.huidigeStap!.id)
					{
						case INVENTARISATIEVRAGEN_STEP:
						case RISICO_ANALYSE_STEP:
						case VERZEKERINGEN_STEP:
						case RAPPORTAGE_STEP:
							return [false, false];
						case BEDRIJFSPROFIEL_STEP:
						default:
							return [true, false];
					}
				}
				case 'risico-analyse':
				{
					switch (dossier?.huidigeStap!.id)
					{
						case RISICO_ANALYSE_STEP:
						case VERZEKERINGEN_STEP:
						case RAPPORTAGE_STEP:
							return [false, false];
						case BEDRIJFSPROFIEL_STEP:
						case INVENTARISATIEVRAGEN_STEP:
						default:
							return [true, false];
					}
				}
				case 'verzekeringen':
				{
					switch (dossier?.huidigeStap!.id)
					{
						case VERZEKERINGEN_STEP:
						case RAPPORTAGE_STEP:
							return [false, false];
						case BEDRIJFSPROFIEL_STEP:
						case INVENTARISATIEVRAGEN_STEP:
						case RISICO_ANALYSE_STEP:
						default:
							return [true, false];
					}
				}
				case 'rapportage':
				{
					switch (dossier?.huidigeStap!.id)
					{
						case RAPPORTAGE_STEP:
							return [false, false];
						case BEDRIJFSPROFIEL_STEP:
						case INVENTARISATIEVRAGEN_STEP:
						case RISICO_ANALYSE_STEP:
						case VERZEKERINGEN_STEP:
						default:
							return [true, false];
					}
				}
				default:
					return [false, false];
			}
		}, [dossier, item]);

		return <AppDrawerItem
			to={to}
			name={name}
			disabled={disabled}
			navigateWithConfirmation={navigateWithConfirmation}
			{...other}
		/>
	};