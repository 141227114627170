import { Branche, BusinessCanvas, EntityType, Risicoprofiel } from '../../../../__generated__/ygg/model/modelClasses';
import { getInheritors } from '../../../../query/util/getInheritors';

export function getCanvasItemBranches(
	risicoprofiel: Risicoprofiel,
	businessCanvasItem: BusinessCanvas,
	objectTypeDictionary: Record<string, EntityType>): Branche[]
{
	const branches = risicoprofiel.dekkingen!.flatMap(dekking => dekking.dekkingsentiteiten!.flatMap(dekkingsEntieit => dekkingsEntieit!.branches!));
	const relevantObjectTypeIds = new Set(
		businessCanvasItem.objectTypes!.flatMap(t => getInheritors(t)).map(objectType => objectType.id)
	);

	const relevantBrancheIds = new Set(
		risicoprofiel.objectBPTypes!
			.filter(objectType => relevantObjectTypeIds.has(objectType.id) && objectTypeDictionary[objectType.id] !== undefined)
			.map(objectType => objectTypeDictionary[objectType.id])
			.flatMap(objectType => objectType.branches!)
			.map(branche => branche.id)
	);

	const seen = new Set();
	return branches
		.filter(branche => !branche.inactief && branche.zakelijk && relevantBrancheIds.has(branche.id))
		.filter(branche => seen.has(branche.id) ? false : seen.add(branche.id));
}