import { Card, CardContent, CardHeader } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC, useMemo } from 'react';
import { Polis } from '../../../../../../__generated__/ygg/model/modelClasses';
import { PropPair } from '../../../../bedrijfsprofiel/components/object-bp/specialized/template/ObjectBPCardTemplate';
import { ObjectBPCardTemplatePropertyRow } from '../../../../bedrijfsprofiel/components/object-bp/specialized/template/ObjectBPCardTemplatePropertyRow';

const useStyles = makeStyles((theme: Theme) =>
	({
		header: {
			padding: theme.spacing(1),
			background: theme.palette.secondary.lightest,
			color: theme.palette.secondary.lightestContrastText,
		},
		content: {
			display: 'flex',
			flexDirection: 'column',
			rowGap: theme.spacing(1),
		},
	}),
);

interface PolicyCardProps
{
	policy: Polis
}

export const PolicyCard: FC<PolicyCardProps> =
	({
		policy,
	}) =>
	{
		const classes = useStyles();

		const props = useMemo<PropPair[]>(() =>
		{
			return [
				{
					prop: Polis.PROPS.registratienummer,
					value: policy._props.registratienummer,
				},
				{
					prop: Polis.PROPS.branche,
					value: policy._props.branche,
				},
				{
					prop: Polis.PROPS.omschrijving,
					value: policy._props.omschrijving,
				},
				{
					prop: Polis.PROPS.maatschappij,
					value: policy._props.maatschappij,
				},
				{
					prop: Polis.PROPS.maandbedrag,
					value: policy._props.maandbedrag,
				},
			];
		}, [policy]);

		return <Card>
			<CardHeader
				className={classes.header}
				title={policy.product}/>
			<CardContent className={classes.content}>
				{
					props.map((propPair, index) =>
						<ObjectBPCardTemplatePropertyRow
							key={index}
							prop={propPair.prop}
							value={propPair.value}
						/>
					)
				}
			</CardContent>
		</Card>;
	};