import { RichTextValidator } from "@intentic/yggdrasil-rotr";

export class NaiveQuillRichTextValidator implements RichTextValidator
{
	isValid(richText: string): boolean
	{
		const sanitized = richText
			.replace(/<br>/g, '')
			.replace(/<(strong|s|em|u|ul|ol|li|p|a)[^>]*>/g, '')
			.replace(/<\/(strong|s|em|u|ul|ol|a)>/g, '')
			.replace(/<\/(li|p)>/g, 'a');

		return !sanitized.includes('<') && !sanitized.includes('>');
	}

	length(richText: string): number
	{
		return (
			richText
				.replace(/<br>/g, '')
				.replace(/<(strong|s|em|u|ul|ol|li|p|a)[^>]*>/g, '')
				.replace(/<\/(strong|s|em|u|ul|ol|a)>/g, '')
				.replace(/<\/(li|p)>/g, 'a')
				.replace(/&gt;/g, '>')
				.replace(/&lt;/g, '<')
				.replace(/&amp;/g, '&').length - 1
		);
	}
}
