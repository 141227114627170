import {
	GoederenRisicobeoordeling,
	RisicoAdresRisicobeoordeling,
	Risicobeoordeling
} from '../../../../__generated__/ygg/model/modelClasses';

export function getName(beoordeling: Risicobeoordeling): string
{
	if (beoordeling instanceof RisicoAdresRisicobeoordeling)
	{
		return `${beoordeling.risicoType!.naam} "${beoordeling.risicoAdres!.adres}"`;
	}
	else if (beoordeling instanceof GoederenRisicobeoordeling)
	{
		return `${beoordeling.risicoType!.naam} "${beoordeling.goederen!.goederenType!.name}"`;
	}
	else
	{
		return beoordeling.risicoType!.naam;
	}
}