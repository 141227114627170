import { Collapse, lighten } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { FC, useContext } from 'react';
import { NetworkAvailabilityContextRef } from './NetworkAvailabilityContext';
import { INDICATOR_HEIGHT } from './NetworkAvailabilityIndicator';

const useStyles = makeStyles(theme => ({
	indicator: {
		backgroundColor: lighten(theme.palette.secondary.light, 0.5),
		borderBottom: `1px solid ${theme.palette.secondary.dark}`,
		height: INDICATOR_HEIGHT,
	},
}));

export const NetworkAvailabilityIndicatorPlaceholder: FC =
	() =>
	{
		const classes = useStyles();
		const {showIndicator} = useContext(NetworkAvailabilityContextRef);
		return <Collapse
			collapsedSize={0}
			in={showIndicator}
		>
			<div className={classes.indicator} />
		</Collapse>;
	};