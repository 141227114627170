import { NumberType } from '@intentic/yggdrasil-rotr';
import { Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { DataObjectViewProps } from '../../DataObjectView';

export const NumberView = React.forwardRef<any, DataObjectViewProps>(
	({
		dataObject,
		variant = 'body1',
		...other
	}, ref) =>
	{
		const value = useMemo(() =>
		{
			const value = NumberType.getRawNumber(dataObject)!;

			if (dataObject.specification.data.fractionalDigits === 0 && value.lessThan(10000))
				return value.toString();
			else
				return new Intl.NumberFormat().format(NumberType.getRawNumber(dataObject)!.toNumber());
		}, [dataObject]);

		return <Typography variant={variant} ref={ref} {...other}>{value}</Typography>;
	});