import { FieldProperty, Property, RelationshipProperty } from '@intentic/yggdrasil-rotr';
import {
	OptionalFieldProp, OptionalToManyRelationshipTypeProp, OptionalToOneRelationshipTypeProp,
	Prop,
	RequiredFieldProp, RequiredToManyRelationshipTypeProp,
	RequiredToOneRelationshipTypeProp
} from '@intentic/yggdrasil-rotr/build/querydsl/result/props/Props';
import { useContext, useMemo } from 'react';
import { YggdrasilContextRef } from '../../YggdrasilContext';




export function useProperty(prop: RequiredFieldProp | OptionalFieldProp): FieldProperty
export function useProperty(prop: RequiredToOneRelationshipTypeProp | OptionalToOneRelationshipTypeProp | RequiredToManyRelationshipTypeProp | OptionalToManyRelationshipTypeProp): RelationshipProperty
export function useProperty(prop: Prop): Property
export function useProperty(prop: undefined): undefined
export function useProperty(prop: RequiredFieldProp | OptionalFieldProp | undefined): FieldProperty | undefined
export function useProperty(prop: RequiredToOneRelationshipTypeProp | OptionalToOneRelationshipTypeProp | RequiredToManyRelationshipTypeProp | OptionalToManyRelationshipTypeProp | undefined): Property | undefined
export function useProperty(prop: Prop | undefined): RelationshipProperty | undefined
export function useProperty(prop: Prop | undefined): Property | undefined
{
	const {metadataService} = useContext(YggdrasilContextRef);
	
	return useMemo(() => 
	{
		if (prop === undefined)
			return undefined;
		if ('direction' in prop)
			return new RelationshipProperty(metadataService.getRelationshipType(prop.id), prop.direction === 'parent');
		else
			return new FieldProperty(metadataService.getEntityField(prop.id));
	}, [metadataService, prop]);
}