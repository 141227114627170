import { ENVIRONMENT_PACK_ID } from '@intentic/yggdrasil-rotr';
import React, { createContext, FC, useContext } from 'react';
import { EnvironmentPack } from '../__generated__/ygg/model/modelClasses';
import { useComputed } from '../mobx/useComputed';
import { useEntityTypes } from '../value/form/metadata/useEntityTypes';
import { UserContextRef } from './UserContext';

export const UserEnvironmentContextRef = createContext<EnvironmentPack | undefined>(undefined);

export const UserEnvironmentContextProvider: FC =
	({
		children
	}) =>
	{
		const me = useContext(UserContextRef);

		const [environmentPackType] = useEntityTypes(ENVIRONMENT_PACK_ID);
		const environmentPack = useComputed(() =>
		{
			if (me !== undefined)
			{
				const environmentPacks = me.canRead!.filter((pack): pack is EnvironmentPack => pack.entity.isInstanceOf(environmentPackType))
				
				if (environmentPacks.length === 1)
				{
					return environmentPacks[0];
				}
			}
		}, [environmentPackType, me]);
		
		return <UserEnvironmentContextRef.Provider value={environmentPack}>
			{children}
		</UserEnvironmentContextRef.Provider>;
	};