import { SvgIcon } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/styles/withStyles/withStyles';
import clsx from 'clsx';
import React, { FC } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		position: 'relative',
		display: 'flex',
		'&$checked $layer': {
			transform: 'scale(1)',
			transition: theme.transitions.create('transform', {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.shortest,
			}),
		},
	},
	layer: {
		left: 0,
		position: 'absolute',
		transform: 'scale(0)',
		transition: theme.transitions.create('transform', {
			easing: theme.transitions.easing.easeIn,
			duration: theme.transitions.duration.shortest,
		}),
	},
	checked: {},
}))

interface RadioIconProps
{
	checked?: true
	size?: 'small' | 'medium'
	className?: string
	classes?: Partial<ClassNameMap<'root' | 'icon'>>
}

export const RadioIcon: FC<RadioIconProps> =
	({
		checked,
		size = 'medium',
		className,
		classes: classesProp = {},
	}) =>
	{
		const classes = useStyles();

		return <div className={clsx(classes.root, {[classes.checked]: checked}, classesProp.root, className)}>
			<SvgIcon viewBox="0 0 24 24" fontSize={size} classes={{root: classesProp.icon}}>
				<path
					d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"/>
			</SvgIcon>
			<SvgIcon viewBox="0 0 24 24" fontSize={size} className={classes.layer} classes={{root: classesProp.icon}}>
				<ellipse fill="currentColor" ry="10" rx="10" cy="12" cx="12"/>
			</SvgIcon>
		</div>
	}