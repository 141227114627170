import { Exception } from '@intentic/yggdrasil-rotr';
import { init } from "@sentry/react";
import { Event, EventHint } from '@sentry/types';

export const EXTRA_EXCEPTION_KEY = 'Exception';
export const TAG_EXCEPTION_ID_KEY = 'exception.id';

export function setupSentry()
{
	if (process.env.REACT_APP_SENTRY_DSN)
	{
		init({
			dsn: process.env.REACT_APP_SENTRY_DSN,
			environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
			release: process.env.REACT_APP_SENTRY_RELEASE,
			beforeSend,
		});
	}
}

export function beforeSend(event: Event, hint?: EventHint): Event
{
	const exception = hint?.originalException;

	if (exception)
	{
		event.extra = {...event.extra, [EXTRA_EXCEPTION_KEY]: exception};

		if (exception instanceof Exception)
			event.tags = {...event.tags, [TAG_EXCEPTION_ID_KEY]: exception.id};
	}

	return event;
}