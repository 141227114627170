import { createTheme } from '@material-ui/core';
import { PaletteOptions } from '@material-ui/core/styles/createPalette';

const palette:PaletteOptions = {
    text: {
        primary: '#2E2E2E',
        secondary: '#515151',
        text3: '#919191',
        text4: '#BEBEBE',
        text5: '#D8D8D8',
    },
    primary: {
        main: '#33CC99',
        light: '#A4FBDE',
        dark: '#0C9D6C',
        darkest: '#024E35',
        contrastText: '#FFFFFF',
        lightContrastText: '#535353',
        darkContrastText: '#FFFFFF',
        darkestContrastText: '#FFFFFF',
    },
    secondary: {
        main: '#161689',
        light: '#9BC6F8',
        lightest: '#D6E9FF',
        contrastText: '#FFFFFF',
        lightestContrastText: '#2E2E2E',
    },
    background: {
        paper: '#FCFCFC',
        semiLight: '#E5F0EF',
        semiLight2: '#C7D6D5',
        semiLight3: '#E8FFF9',
        dark: '#395552',
    },
    success: {
        main: '#75CE93',
        light: '#F6FFF9',
        contrastText: '#FFFFFF',
        lightContrastText: '#515151',
    },
    error: {
        main: '#EB766D',
        light: '#FEECEB',
        contrastText: '#FFFFFF',
        lightContrastText: '#515151',
    },
    warning: {
        main: '#EFC666',
        light: '#FCF8EE',
        contrastText: '#FFFFFF',
        lightContrastText: '#515151',
    },
    info: {
        main: '#6FCCF5',
        light: '#EBF8FE',
        contrastText: '#FFFFFF',
        lightContrastText: '#515151',
    },
    riskVeryHigh: {
        main: '#DE2F22',
        contrastText: '#FFFFFF',
    },
    riskHigh: {
        main: '#FFB300',
        contrastText: '#000000',
    },
    riskMiddle: {
        main: '#f3e973',
        contrastText: '#000000',
    },
    riskLow: {
        main: '#75ce93',
        contrastText: '#000000',
    },
};

const sidePanelPalette:Partial<PaletteOptions> = {
    text: {
        ...palette.text,
        primary: '#FCFCFC',
        secondary: 'rgba(252, 252, 252, 0.7)',
    },
    background: {
        ...palette.background,
        paper: '#395552',
    },
};

export const theme = createTheme({
    palette,
    props: {
        MuiTypography: {
            variantMapping: {
                body1: 'span',
                body2: 'span',
            },
        },
    },
    overrides: {
        MuiInputLabel: {
            root: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '90%',
                height: '100%',
            },
            shrink: {
                // when shrunk the label is scaled 0.75%, so width is 100% / 0.75
                maxWidth: '133%',
            }
        },
        MuiDialog: {
            paper: {
                minHeight: '500px'
            },
        }
    },
});

export const sidePanelTheme = createTheme({
    palette: {
        type: 'dark',
        ...palette,
        ...sidePanelPalette
    },
    props: {
        MuiTypography: {
            variantMapping: {
                body1: 'span',
                body2: 'span',
            },
        },
    },
    overrides: {
        MuiInputLabel: {
            root: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '90%',
                height: '100%',
            },
            shrink: {
                maxWidth: '133%',
            }
        }
    },
});

export const pdfTheme = createTheme({
    palette,
    typography: {
        h2: {
            fontSize: '2.1667rem',
        },
        h3: {
            fontSize: '1.5rem',
        },
        h4: {
            fontSize: '1.1667rem',
        },
        h5: {
            fontSize: '1rem',
        },
        h6: {
            fontSize: '0.8333rem',
        },
        body1: {
            fontSize: '0.8333rem',
            lineHeight: 1.15,
        },
        button: {
            fontSize: '0.75rem',
        },
        caption: {
            fontSize: '0.6667rem',
        },
    },
    overrides: {
        MuiListItem: {
            root: {
                paddingTop: 0,
                paddingBottom: 0,
            }
        },
        MuiRadio: {
            root: {
                padding: 3,
            }
        },
    },
    props: {
        MuiTypography: {
            variantMapping: {
                body1: 'span',
                body2: 'span',
            },
        },
    },
});