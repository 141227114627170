import { Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC } from 'react';

const RADIUS = 63;
const INDICATOR_STROKE = 15
const OUTER_STROKE = 2.5
const CIRCUMFERENCE = RADIUS * Math.PI * 2
const SIZE = (RADIUS + INDICATOR_STROKE + OUTER_STROKE) * 2;

const useStyles = makeStyles((theme: Theme) =>
	({
		title: {
			...theme.typography.h4,
			fill: theme.palette.background.paper,
		},
		subtitle: {
			...theme.typography.caption,
			fill: theme.palette.background.paper,
		},
		innerCircle: {
			fill: theme.palette.background.dark,
		},
		circle: {
			stroke: '#DCFFB9',
			strokeWidth: INDICATOR_STROKE * 2,
			fill: 'transparent',
			transform: 'rotate(-90deg)',
			transformOrigin: '50% 50%',
			transition: theme.transitions.create('stroke-dashoffset', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.standard,
			}),
		},
		outerCircle: {
			stroke: theme.palette.background.semiLight2,
			strokeWidth: OUTER_STROKE * 2,
			fill: 'transparent',
		},
	}),
);

interface ProgressIndicatorProps
{
	value: number
}

export const ProgressIndicator: FC<ProgressIndicatorProps> =
	({
		value: valueProp,
	}) =>
	{
		const classes = useStyles();

		const value = valueProp < 0 ? 0 : valueProp > 1 ? 1 : valueProp;

		const offset = CIRCUMFERENCE - value * CIRCUMFERENCE;

		return <Box>
			<svg
				width={SIZE}
				height={SIZE}
			>
				<circle
					className={classes.innerCircle}
					r={RADIUS - INDICATOR_STROKE}
					cx="50%"
					cy="50%"
				/>
				<circle
					className={classes.circle}
					r={RADIUS}
					cx="50%"
					cy="50%"
					style={{strokeDasharray: `${CIRCUMFERENCE} ${CIRCUMFERENCE}`, strokeDashoffset: offset}}
				/>
				<circle
					className={classes.outerCircle}
					r={RADIUS + INDICATOR_STROKE}
					cx="50%"
					cy="50%"
				/>
				<text
					className={classes.title}
					x="50%"
					y="75"
					dominantBaseline="middle"
					textAnchor="middle">
					{`${Math.round(value * 100)}%`}
				</text>
				<text
					className={classes.subtitle}
					x="50%"
					y="95"
					dominantBaseline="middle"
					textAnchor="middle">
					voortgang
				</text>
			</svg>
		</Box>;
	};