import { makeStyles, Theme } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import { BOX_SIZE } from './GraphContainer';

export const useBarStyles = makeStyles((theme: Theme) =>
	({
		root: {
			position: 'relative',
			textAlign: 'center',
			display: 'flex',
			flexDirection: 'column',
			borderStartStartRadius: theme.spacing(1),
			borderStartEndRadius: theme.spacing(1),
			paddingBlockStart: theme.spacing(0.5),
			borderColor: 'transparent',
			borderStyle: 'solid',
			borderWidth: 2,
			borderBottomWidth: 0,
			...theme.typography.button,
		},
		selected: {},
		inner: {
			position: 'absolute',
			bottom: 0,
			left: 'auto',
			right: 'auto',
			width: theme.spacing(2),
			borderStartStartRadius: theme.spacing(1),
			borderStartEndRadius: theme.spacing(1),
			transition: theme.transitions.create('height', {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.standard,
			}),
		},
		outer: {
			background: theme.palette.text.text5,
			width: theme.spacing(2),
			marginInline: theme.spacing(1),
			borderStartStartRadius: theme.spacing(1),
			borderStartEndRadius: theme.spacing(1),
			transition: theme.transitions.create('height', {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.standard,
			}),
		},
		low: {
			'&$selected': {
				borderColor: theme.palette.riskLow.main,
				color: theme.palette.riskLow.main,
				background: alpha(theme.palette.riskLow.main, 0.2),
			},
			'&$outer': {
				height: BOX_SIZE * 0.5,
			},
			'& $inner': {
				background: theme.palette.riskLow.main,
				height: BOX_SIZE * 0.5,
			},
		},
		middle: {
			'&$selected': {
				borderColor: theme.palette.riskMiddle.main,
				color: theme.palette.riskMiddle.contrastText,
				background: alpha(theme.palette.riskMiddle.main, 0.2),
			},
			'&$outer': {
				height: BOX_SIZE * 1.5 + theme.spacing(0.5),
			},
			'& $inner': {
				background: theme.palette.riskMiddle.main,
				height: BOX_SIZE * 1.5 + theme.spacing(0.5),
			},
		},
		high: {
			'&$selected': {
				borderColor: theme.palette.riskHigh.main,
				color: theme.palette.riskHigh.main,
				background: alpha(theme.palette.riskHigh.main, 0.2),
			},
			'&$outer': {
				height: BOX_SIZE * 2.5 + theme.spacing(1),
			},
			'& $inner': {
				background: theme.palette.riskHigh.main,
				height: BOX_SIZE * 2.5 + theme.spacing(1),
			},
		},
		veryHigh: {
			'&$selected': {
				borderColor: theme.palette.riskVeryHigh.main,
				color: theme.palette.riskVeryHigh.main,
				background: alpha(theme.palette.riskVeryHigh.main, 0.2),
			},
			'&$outer': {
				height: BOX_SIZE * 3.5 + theme.spacing(1.5),
			},
			'& $inner': {
				background: theme.palette.riskVeryHigh.main,
				height: BOX_SIZE * 3.5 + theme.spacing(1.5),
			},
		},
		disabled:{
			opacity:'50%'
		}
	})
);