import { DataObject, Entity } from '@intentic/yggdrasil-rotr';
import { Prop } from '@intentic/yggdrasil-rotr/build/querydsl/result/props/Props';
import { Box, Typography, TypographyProps } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { FC } from 'react';
import { useProperty } from '../../../../../../../query/util/useProperty';
import { QueryDSLValueView } from '../../../../../../../query/view/QueryDSLValueView';

export const useStyles = makeStyles((theme: Theme) =>
	({
		contentRow: {
			display: 'flex',
		},
		contentRowTitle: {
			flexBasis: '40%',
			color: theme.palette.primary.darkest,
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			textAlign: 'end',
			'&:after': {
				content: '":"',
			}
		},
		contentRowValue: {
			paddingInlineStart: theme.spacing(1),
			flexBasis: '60%',
			wordBreak: 'break-word',
			hyphens: 'auto',
		}
	}),
);

export interface ObjectBPCardTemplatePropertyRowProps extends Omit<TypographyProps, 'classes'>
{
	prop: Prop | string
	value: Array<Entity> | Entity | DataObject | Array<DataObject> | null | undefined
	classes?: {row?: string, property?: string, value?: string, valueContainer?: string}
}

export const ObjectBPCardTemplatePropertyRow: FC<ObjectBPCardTemplatePropertyRowProps> =
	({
		prop,
		value,
		classes = {},
		...other
	}) =>
	{
		const styleClasses = useStyles();

		const property = useProperty(typeof prop === 'string' ? undefined : prop);

		const name = property?.getName() ?? (prop as string);

		const rowClassName = clsx(
			styleClasses.contentRow,
			classes.row
		);

		const propClassName = clsx(
			styleClasses.contentRowTitle,
			classes.property,
		);

		const containerValueClassName = clsx(
			styleClasses.contentRowValue,
			classes.valueContainer,
		);

		const valueClassName = clsx(
			classes.value
		);

		if (value === null)
		{
			return null;
		}
		else
		{
			return <Box className={rowClassName}>
				<Typography
					className={propClassName}
					title={name}
					{...other}
				>
					{name}
				</Typography>
				<QueryDSLValueView
					className={valueClassName}
					containerClassName={containerValueClassName}
					value={value}
					{...other}
				/>
			</Box>;
		}
	};