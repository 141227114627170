import { WebSocketStatus } from '@intentic/yggdrasil-rotr';
import { Collapse, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { Button } from '../generic/Button/Button';
import { RootContextRef } from '../RootContext';
import { NetworkAvailabilityContextRef } from './NetworkAvailabilityContext';

export const INDICATOR_HEIGHT = 30;

const useStyle = makeStyles((theme: Theme) => ({
	collapse: {
		zIndex: theme.zIndex.modal + 1,
		width: '100%',
		position: 'fixed',
	},
	root: {
		alignItems: 'center',
		backgroundColor: theme.palette.secondary.lightest,
		borderBottom: `1px solid ${theme.palette.secondary.dark}`,
		color: theme.palette.secondary.lightContrastText,
		display: 'flex',
		height: INDICATOR_HEIGHT,
		justifyContent: 'space-around',
		padding: theme.spacing(0.5),
	},
	textContainer: {
		display: 'flex',
		justifyContent: 'space-around',
		alignItems: 'center',
		overflow: 'hidden',
	},
	button: {
		flexShrink: 0,
	},
	text: {
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
	}
}));

export const NetworkAvailabilityIndicator: FC =
	() =>
	{
		const classes = useStyle();

		const {webSocketService} = useContext(RootContextRef);

		const [status, setStatus] = useState<WebSocketStatus>('DISCONNECTED');
		const [reconnectCountdown, setReconnectCountdown] = useState(0);

		const {setShowIndicator} = useContext(NetworkAvailabilityContextRef);

		useEffect(
			() =>
			{
				const statusSubscription = webSocketService.status.subscribe(setStatus);
				const countDownSubscription = webSocketService.reconnectCountdown.subscribe(value => setReconnectCountdown(Math.ceil(value / 1000)));

				return () =>
				{
					statusSubscription.unsubscribe();
					countDownSubscription.unsubscribe();
				};
			},
			[webSocketService],
		);

		const show = useMemo(() => status === 'RECONNECTING' || status === 'WAITING_TO_RECONNECT', [status]);
		
		useEffect(() => setShowIndicator(show), [setShowIndicator, show]);

		return <div>
			<Collapse
				collapsedSize={0}
				in={show}
				className={classes.collapse}
				mountOnEnter
				unmountOnExit
			>
				<div
					className={classes.root}
				>
					{
						status === 'WAITING_TO_RECONNECT'
							? <span className={classes.textContainer}>
								<Typography className={classes.text} variant="caption">
									{`Momenteel kan er geen verbinding gemaakt worden met de server. Een nieuwe poging vindt plaats over ${reconnectCountdown} seconde${reconnectCountdown === 1 ? '' : 'n'}.`}
								</Typography>
								<Button
									className={classes.button}
									color="inherit"
									onClick={() => webSocketService.connect()}
									size="small"
								>
									Probeer nu
								</Button>
							</span>
							: <Typography className={classes.text} variant="caption">
								Aan het verbinden met de server, even geduld alstublieft...
							</Typography>
					}
				</div>
			</Collapse>
		</div>;
	};