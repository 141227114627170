import { Collection, ValueType } from '@intentic/yggdrasil-rotr';
import { TypographyProps } from '@material-ui/core';
import React from 'react';
import { ValueView } from '../ValueView';

export interface CollectionViewProps extends TypographyProps
{
	collection: Collection<ValueType>
}

export const CollectionView = React.forwardRef<any, CollectionViewProps>(
	({
		collection,
		className,
		...other
	}, ref) =>
	{
		return <span className={className} ref={ref}>
			{
				collection.values.map((value, index) =>
					<React.Fragment key={index}>
						<ValueView value={value} {...other} />
						{' '}
					</React.Fragment>)
			}
		</span>;
	});