import { Box, Tab, Tabs } from '@material-ui/core';
import React, { ChangeEvent, FC, useCallback, useEffect, useRef, useState } from 'react';
import { BusinessCanvas, EntityType, Risicoklasse } from '../../../../../__generated__/ygg/model/modelClasses';
import { useCanvasTabsStyles } from '../../../risico-analyse/components/canvas/useCanvasTabsStyles';
import { CanvasItemBeoordelingItem } from '../../data/CanvasItemBeoordelingItem';
import { AggregateCanvasItem } from './AggregateCanvasItem';
import { CanvasItem } from './CanvasItem';

interface CanvasTabsProps
{
	businessCanvas: BusinessCanvas[]
	objectTypeDictionary: Record<string, EntityType>
	risicoklasses: Risicoklasse[]
	selectedItem?: CanvasItemBeoordelingItem
	onSelectItem: (item: CanvasItemBeoordelingItem | undefined) => void
	onPositionChange: (position: number) => void
	className?: string
}

export const CanvasTabs: FC<CanvasTabsProps> =
	({
		businessCanvas,
		objectTypeDictionary,
		risicoklasses,
		className,
		selectedItem: selectedCanvasItemBeoordelingItem,
		onSelectItem,
		onPositionChange,
	}) =>
	{
		const classes = useCanvasTabsStyles();

		const [selectedItem, setSelectedItem] = useState<BusinessCanvas | 'all'>('all');

		const handleChange = useCallback((event: ChangeEvent<{}>, value: string) =>
		{
			onSelectItem(undefined);
			onPositionChange(0);
			if (value === 'all')
				setSelectedItem('all');
			else
				setSelectedItem(businessCanvas.find(bc => bc.id === value)!);
		}, [businessCanvas, onPositionChange, onSelectItem]);

		const element = useRef<HTMLButtonElement>(null);
		
		const hasSelection = selectedCanvasItemBeoordelingItem !== undefined;

		useEffect(() =>
		{
			if (hasSelection)
			{
				const resizeListener = () =>
				{
					const rect = element.current!.getBoundingClientRect();
					onPositionChange(rect.top + (rect.height / 2) + window.pageYOffset);
				}

				resizeListener();

				window.addEventListener('resize', resizeListener);

				return () => window.removeEventListener('resize', resizeListener);
			}
		}, [hasSelection, onPositionChange, selectedCanvasItemBeoordelingItem, selectedItem]);

		return <Box
			className={className}
		>
			<Tabs
				ref={element}
				value={selectedItem === 'all' ? 'all' : selectedItem.id}
				onChange={handleChange}
				variant="scrollable"
				scrollButtons="on"
				textColor="primary"
				indicatorColor="primary"
				TabScrollButtonProps={{classes: {disabled: classes.disabled}}}
				classes={{root: classes.root, flexContainer: classes.container, scrollable: classes.scrollable, scrollButtons: classes.scrollButtons, indicator: classes.indicator}}
			>
				<Tab
					value="all"
					label="alle"
					className={classes.tab}
				/>
				{
					businessCanvas.map((canvasItem) =>
						<Tab
							key={canvasItem.id}
							value={canvasItem.id}
							label={canvasItem.name}
							className={classes.tab}
							classes={{selected: classes.selected}}
						/>
					)
				}
			</Tabs>
			{
				selectedItem === 'all' ?
					<AggregateCanvasItem
						businessCanvasItems={businessCanvas}
						objectTypeDictionary={objectTypeDictionary}
						risicoklasses={risicoklasses}
					/>
					:
					<CanvasItem
						key={selectedItem.id}
						businessCanvasItem={selectedItem}
						objectTypeDictionary={objectTypeDictionary}
						selectedItem={selectedCanvasItemBeoordelingItem}
						onSelectItem={onSelectItem}
					/>
			}
		</Box>;
	};