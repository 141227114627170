import { ApiService, WebSocketService } from '@intentic/yggdrasil-rotr';
import React from 'react';
import { BaseAuthenticationService } from './authentication/BaseAuthenticationService';

export interface RootContext<AS extends ApiService = ApiService, WS extends WebSocketService = WebSocketService>
{
	authenticationService: BaseAuthenticationService
	apiService: AS
	webSocketService: WS
}

export const RootContextRef = React.createContext<RootContext>(undefined!);