import { DialogActions, DialogContent as MUIDialogContent } from '@material-ui/core';
import clsx from 'clsx';
import React, { FC, useContext } from 'react';
import { Button } from '../Button/Button';
import { DialogAction } from './DialogAction';
import { DialogActionContextRef } from './DialogActionContext';
import { DialogContextRef } from './DialogContext';

export interface DialogProps
{
	className?: string
	classes?: Partial<{content: string, actionsContainer: string}>
	actions?: React.ReactElement[]
}

export const DialogContent: FC<DialogProps> =
	({
		className,
		children,
		classes = {},
		actions = [],
	}) =>
	{
		const handleClose = useContext(DialogActionContextRef);

		return <DialogContextRef.Provider value={true}>
			<MUIDialogContent className={clsx(className, classes.content)}>
				{children}
			</MUIDialogContent>
			<DialogActions className={classes.actionsContainer}>
				{
					actions.length
						?
						actions.map((action, index) =>
						{
							return <DialogAction
								key={index}
								action={action}
								handleClose={handleClose}
							/>;
						})
						:
						<Button
							onClick={handleClose}
							color="secondary"
							autoFocus
						>
							OK
						</Button>
				}
			</DialogActions>
		</DialogContextRef.Provider>;
	};