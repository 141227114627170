import { GraphqlApiService } from '@intentic/yggdrasil-rotr';
import React, { FC, useState } from 'react';
import { ImplicitOAuthAuthenticationService } from './authentication/ImplicitOAuthAuthenticationService';
import { RootContextRef } from './RootContext';

export const AppRootContext: FC = ({children}) =>
{
	const [authenticationService] = useState(() =>
	{
		const result = new ImplicitOAuthAuthenticationService(
			process.env.REACT_APP_API_ENDPOINT,
			process.env.REACT_APP_SSO_URL,
			process.env.REACT_APP_SSO_CLIENT_ID,
			process.env.REACT_APP_BASE_URL,
		);

		result.initialize();

		return result;
	});

	const [apiService] = useState(() => new GraphqlApiService(authenticationService, process.env.REACT_APP_API_ENDPOINT));

	// web socket service will be provided later
	return <RootContextRef.Provider
		value={{
			authenticationService,
			apiService,
			webSocketService: undefined!,
		}}
	>
		{
			children
		}
	</RootContextRef.Provider>;
};