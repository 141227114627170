import { ENTITY_ID_ID, ENTITY_TYPE_NAME_FIELD_ID, EntityField, EntityType, TextType } from '@intentic/yggdrasil-rotr';

export function getNameField(type: EntityType): EntityField
{
	const allFields = type.getAllFields();

	const nameFieldDataObject = type
		.entity
		.getDataObject(ENTITY_TYPE_NAME_FIELD_ID);
	const nameFieldId = TextType.getRawText(nameFieldDataObject);
	const specifiedNameField = allFields.find(field => field.id === nameFieldId);

	if (specifiedNameField !== undefined)
	{
		return specifiedNameField;
	}

	const defaultNames = ['name', 'naam'];

	const firstFieldCalledNameOrNaam = allFields.find(field => defaultNames.includes(field.getName().toLowerCase()));

	if (firstFieldCalledNameOrNaam !== undefined)
	{
		return firstFieldCalledNameOrNaam;
	}

	return type.getField(ENTITY_ID_ID);
}