import React, { FC, MouseEvent, ReactElement, useCallback } from 'react';

export interface DialogActionProps
{
	action: ReactElement
	handleClose: () => void
}

export const DialogAction: FC<DialogActionProps> =
	({
		action,
		handleClose,
	}) =>
	{
		const handleClick = useCallback((e: MouseEvent, ...args: any[]) =>
		{
			const result = action.props.onClick?.(e, ...args);
			e.persist();
			if (result instanceof Promise)
			{
				result.then(() =>
				{
					if (!e.defaultPrevented)
						handleClose();
				});
			}
			else
			{
				if (!e.defaultPrevented)
					handleClose();
			}
		}, [action.props, handleClose]);

		return React.cloneElement(action, {...action.props, onClick: handleClick});
	};