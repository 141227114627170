import {
    configure,
    GraphqlApiService,
    GraphqlMetadataService,
    GraphqlQueryService,
    GraphqlWebSocketService,
    hydrate,
    stub,
    YggdrasilContext
} from '@intentic/yggdrasil-rotr';
import * as React from 'react';
import { Context, createContext, FC, useContext, useEffect, useMemo, useState } from 'react';
import { RootContext, RootContextRef } from './RootContext';
import { useAsyncEffect } from './util/hooks/useAsyncEffect';
import { InstantComponentLoader } from './util/InstantComponentLoader';
import { NaiveQuillRichTextValidator } from './util/NaiveQuillRichTextValidator';

export const YggdrasilContextRef = createContext<YggdrasilContext>(undefined!);

export const YggdrasilContextProvider: FC =
    ({
         children,
     }) =>
    {
        const {
            apiService,
            webSocketService,
        } = useContext(RootContextRef as unknown as Context<RootContext<GraphqlApiService, GraphqlWebSocketService>>);

        const {context, metadataService} = useMemo(() => setupContext(apiService, webSocketService), [apiService, webSocketService]);
        
        const {fileRegistry} = context;

        useEffect(() =>
        {
            return () => fileRegistry.unregisterAll();
        }, [fileRegistry]);

        const [initialized, setInitialized] = useState(false);
        const [initializationError, setInitializationError] = useState<Error | undefined>(undefined);

        useAsyncEffect(() =>
        {
            return {
                promise: metadataService.whenInitialized,
                then: () =>
                {
                    setInitialized(true);
                },
                catch: setInitializationError
            };
        }, [metadataService.whenInitialized])

        if (initialized)
        {
            return <YggdrasilContextRef.Provider
                value={context}
            >
                {children}
            </YggdrasilContextRef.Provider>
        }
        else if (initializationError !== undefined)
        {
            return <>An error has occurred: {initializationError.message}</>
        }
        else
        {
            return <InstantComponentLoader/>;
        }
    };

function setupContext(apiService: GraphqlApiService, webSocketService: GraphqlWebSocketService): {context: YggdrasilContext<GraphqlMetadataService>, metadataService: GraphqlMetadataService}
{
    // stubbing because the the implementations require dependencies from yggdrasil-context
    const metadataServiceStub = stub(GraphqlMetadataService);
    const queryServiceStub = stub(GraphqlQueryService);

    const context = configure({
        metadataService: metadataServiceStub,
        entityQueryService: queryServiceStub,
        graphQueryService: queryServiceStub,
        userQueryService: queryServiceStub,
        mutationEngine: queryServiceStub,
        richTextValidator: new NaiveQuillRichTextValidator(),
    });

    const {
        functionConverterService,
        dataObjectSpecificationConverter,
        dataObjectValueConverter,
        textType,
    } = context;

    const metadataService = new GraphqlMetadataService(
        apiService,
        webSocketService,
        functionConverterService,
        dataObjectSpecificationConverter,
        dataObjectValueConverter,
        textType,
    );
    const queryService = new GraphqlQueryService(
        functionConverterService,
        dataObjectValueConverter,
        dataObjectSpecificationConverter,
        metadataService,
        apiService,
        webSocketService,
    );

    // resolve circular reference in Services
    metadataService.whenInitialized.then(() => hydrate(metadataServiceStub, metadataService));
    hydrate(queryServiceStub, queryService);

    return {context, metadataService};
}