import { makeStyles } from '@material-ui/core/styles';
import React, { FC, useContext, useMemo } from 'react';
import { QBalans } from '../../../../../../__generated__/ygg/dsl/queryClasses';
import { useDataPlural } from '../../../../../../query/useDataPlural';
import { LazyComponentLoader } from '../../../../../../util/LazyComponentLoader';
import { BalanceCard } from '../../../../bedrijfsprofiel/components/panel/balance/BalanceCard';
import { ClientContextRef } from '../../../../bedrijfsprofiel/data/ClientContext';

const useStyles = makeStyles(() =>
	({
		loader: {
			flexGrow: 1,
		},
	}),
);

export const BalanceList: FC =
	() =>
	{
		const classes = useStyles();

		const client = useContext(ClientContextRef);

		const balances = useDataPlural(queryFactory =>
		{
			return queryFactory.selectFrom(QBalans.balans)
				.where(QBalans.balans.organization.id.eq(client.organization!.id));
		}, [client]);

		const orderedBalances = useMemo(() =>
		{
			if (balances !== undefined)
				return balances.slice().sort((b1, b2) => b1.datum.getTime() - b2.datum.getTime());
		}, [balances]);

		if (orderedBalances === undefined)
		{
			return <LazyComponentLoader className={classes.loader}/>
		}
		else
		{
			return <>
				{
					orderedBalances.map(balance =>
						<BalanceCard
							key={balance.id}
							balance={balance}
						/>)
				}
			</>;
		}


	};