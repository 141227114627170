import {
	BooleanTypeID,
	ColorTypeID,
	CurrencyTypeID,
	DataObject,
	DateTimeTypeID,
	DateTypeID,
	EmailTypeID,
	FileTypeID,
	FunctionTypeID,
	JsonTypeID,
	NumberTypeID,
	PercentageTypeID,
	PeriodTypeID,
	PhoneTypeID,
	TextTypeID,
} from '@intentic/yggdrasil-rotr';
import { TypographyProps } from '@material-ui/core';
import React, { useMemo } from 'react';
import { DefaultView } from './DefaultView';
import { ColorView } from './type/color/ColorView';
import { FunctionView } from './type/complex/FunctionView';
import { FileView } from './type/file/FileView';
import { CurrencyView } from './type/number/CurrencyView';
import { NumberView } from './type/number/NumberView';
import { PercentageView } from './type/number/PercentageView';
import { TextView } from './type/text/TextView';

export interface DataObjectViewProps extends TypographyProps
{
	dataObject: DataObject
}

export const DataObjectView = React.forwardRef<any, DataObjectViewProps>(
	({
		dataObject,
		...other
	}, ref) =>
	{
		const typeId = dataObject.type().id;
		const Component = useMemo(() =>
		{
			switch (typeId)
			{
				case BooleanTypeID:
				case DateTimeTypeID:
				case DateTypeID:
				case PeriodTypeID:
				case EmailTypeID:
				case PhoneTypeID:
				case JsonTypeID:
					return DefaultView;
				case NumberTypeID:
					return NumberView;
				case CurrencyTypeID:
					return CurrencyView;
				case PercentageTypeID:
					return PercentageView;
				case FunctionTypeID:
					return FunctionView;
				case FileTypeID:
					return FileView;
				case TextTypeID:
					return TextView;
				case ColorTypeID:
					return ColorView;
				default:
					throw new Error(`No view found for type: ${typeId}`);
			}
		}, [typeId]);

		return <Component dataObject={dataObject} ref={ref} {...other} />;
	});