import { Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { Bar, BarProps } from './Bar';
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
	({
		root: {
			zIndex: 1,
			display: 'flex',
			overflowX: 'auto',
			paddingInline: theme.spacing(2),
			columnGap: theme.spacing(2),
			flexGrow: 1,
			alignItems: 'flex-end',
		},
	})
);

export interface GraphProps<T extends {readonly id: string | number}> extends Omit<BarProps<T>, 'item'>
{
	items: T[],
	activatedItem?:Set<string>
	classes?: { root?: string, graph: string }
}

export const Graph = <T extends {readonly id: string | number } >
	({
		items,
		activatedItem,
		classes,
		...other
	}: GraphProps<T>) =>
	{

		const cls = useStyles();

		const containerClass = clsx(cls.root, classes?.graph)

		return <Box
			className={containerClass}
		>
			{
				items.map((item) =>
					(
						<CheckedBar
							key={item.id}
							activatedItem={activatedItem}
							item={item}
							{...other}
						/>
					), [])
			}
		</Box>;
	};
interface CheckedBarProps<T extends {readonly id: string | number}> extends Omit<BarProps<T>, 'item'>
{
	item: T,
	activatedItem?:Set<string>
}

const CheckedBar = <T extends {readonly id: string | number}, >
({
	item,
	activatedItem,
	...other
}: CheckedBarProps<T>)=>{
	return <><Bar
		key={item.id}
		activated={activatedItem !==undefined ? activatedItem?.has(String(item.id)) : true}
		item={item}
		{...other}
	/></>
}