import React, { FC } from 'react';
import { QBusinessClient, QDossier } from '../../__generated__/ygg/dsl/queryClasses';
import { Dossier } from '../../__generated__/ygg/model/modelClasses';
import { useDataSingle } from '../../query/useDataSingle';
import { useQueryParams } from '../../util/router/useQueryParams';

export const DossierContextRef = React.createContext<Dossier | undefined>(undefined);
export const ClientIDContextRef = React.createContext<string | undefined>(undefined);

export const RequireDossier: FC =
	({
		children
	}) =>
	{
		const dossierID = useQueryParams().get('d');
		const clientID = useQueryParams().get('c');

		const dossier = useDataSingle(queryFactory =>
		{
			if (dossierID !== null)
			{
				return queryFactory.selectFrom(QDossier.dossier)
					.join(QDossier.dossier.huidigeStap)
					.join(QDossier.dossier.scope)
					.join(QDossier.dossier.client, QBusinessClient.businessClient)
					.for(dossierID)
			}
		}, [dossierID]);

		if (dossierID === null)
		{
			return <ClientIDContextRef.Provider value={clientID ?? undefined}>
				{children}
			</ClientIDContextRef.Provider>
		}
		else
		{
			return <DossierContextRef.Provider key={dossierID} value={dossier ?? undefined}>
				<ClientIDContextRef.Provider value={dossier?.client!.id}>
					{children}
				</ClientIDContextRef.Provider>
			</DossierContextRef.Provider>
		}
	}