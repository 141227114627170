import * as React from 'react';

export interface NetworkAvailabilityContext
{
	showIndicator: boolean
	setShowIndicator: (showIndicator: boolean) => void
}

export const NetworkAvailabilityContextRef = React.createContext<NetworkAvailabilityContext>({} as NetworkAvailabilityContext);

export const NetworkAvailabilityContextProvider = NetworkAvailabilityContextRef.Provider;