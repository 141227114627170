import { Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC } from 'react';
import { EntityType } from '../../../../../__generated__/ygg/model/modelClasses';
import { ItemSelection } from '../../DossierRoot';
import { BedrijfsprofielPanel } from './bedrijfsprofiel/BedrijfsprofielPanel';
import { OrganizationPanel } from './organization/OrganizationPanel';
import { RisicoAnalysePanel } from './risico-analyse/RisicoAnalysePanel';

const useStyles = makeStyles((theme: Theme) =>
	({
		container: {
			display: 'flex',
			flexDirection: 'column',
			rowGap: theme.spacing(2),
			padding: theme.spacing(1),
		},
		root: {
			display: 'flex',
			flexDirection: 'column',
		},
	}),
);

interface SelectionPanelProps
{
	objectTypeDictionary: Record<string, EntityType>
	selectedItem: ItemSelection
}

export const SelectionPanel: FC<SelectionPanelProps> =
	({
		objectTypeDictionary,
		selectedItem,
	}) =>
	{
		const classes = useStyles();

		return <Box
			className={classes.root}
		>
			<Box className={classes.container}>
				{
					selectedItem.type === 'organization' &&
					<OrganizationPanel
						selectedItem={selectedItem.item}
					/>
				}
				{
					selectedItem.type === 'bedrijfsprofiel' &&
					<BedrijfsprofielPanel
						selectedItem={selectedItem.item}
					/>
				}
				{
					selectedItem.type === 'risico analyse' &&
					<RisicoAnalysePanel
						objectTypeDictionary={objectTypeDictionary}
						selectedItem={selectedItem.item}
					/>
				}
			</Box>
		</Box>;
	};