import { ListItem, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC, useContext } from 'react';
import { EntityType } from '../../../../../../__generated__/ygg/model/modelClasses';
import { useComputed } from '../../../../../../mobx/useComputed';
import { getAncestors } from '../../../../../../query/util/getAncestors';
import { ValueView } from '../../../../../../value/view/ValueView';
import { ClientContextRef } from '../../../../bedrijfsprofiel/data/ClientContext';

const useStyles = makeStyles((theme: Theme) =>
	({
		root: {
			display: 'flex',
			justifyContent: 'space-between',
			background: theme.palette.background.semiLight2,
			borderRadius: theme.shape.borderRadius,
		},
	}),
);

interface ObjectBPTypeItemProps
{
	objectBPType: EntityType
}

export const ObjectBPTypeItem: FC<ObjectBPTypeItemProps> =
	({
		objectBPType,
	}) =>
	{
		const classes = useStyles();

		const client = useContext(ClientContextRef);

		const objects = useComputed(() =>
		{
			return client.organization!.objects!.filter(object => getAncestors(object.type).map(type => type.id).includes(objectBPType.id));
		}, [client, objectBPType.id]);

		if (objects.length > 0)
		{
			return <ListItem
				className={classes.root}
			>
				<ValueView color="textSecondary" value={objectBPType.entity}/>
				<Typography>
					{objects.length}
				</Typography>
			</ListItem>;
		}
		else
		{
			return null;
		}
	};