import { CurrencyType } from '@intentic/yggdrasil-rotr';
import { Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { DataObjectViewProps } from '../../DataObjectView';

export const CurrencyView = React.forwardRef<any, DataObjectViewProps>(
	({
		dataObject,
		variant = 'body1',
		...other
	}, ref) =>
	{
		const value = useMemo(() =>
		{
			return new Intl.NumberFormat().format(CurrencyType.getRawAmount(dataObject)!.toNumber());
		}, [dataObject]);
		
		const currency = useMemo(() => 
		{
			return CurrencyType.getRawCurrency(dataObject)!;
		}, [dataObject]);

		return <Typography variant={variant} ref={ref} {...other}>{currency}{value}</Typography>;
	});