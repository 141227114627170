import { createStyles, makeStyles, Typography, TypographyProps } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

import 'react-quill/dist/quill.core.css';

const useStyles = makeStyles(
	() =>
		createStyles({
			richViewer: {
				'&.ql-editor': {
					height: 'auto',
					padding: 0,
					// fontFamily: theme.typography.body1.fontFamily,
					// color: theme.typography.body1.color,
					// fontSize: theme.typography.body1.fontSize,
					// lineHeight: theme.typography.body1.lineHeight,
				},
			},
		}));

interface RichTextViewProps extends TypographyProps
{
	text: string
}

export const RichTextView = React.forwardRef<any, RichTextViewProps>(
	({
		text,
		className: classNameProp,
		...other
	}, ref) =>
	{
		const classes = useStyles();

		const className = clsx(
			classes.richViewer,
			'ql-editor',
			classNameProp,
		);

		return <Typography component="span" className={className} dangerouslySetInnerHTML={{__html: text}} ref={ref} {...other} />;
	});