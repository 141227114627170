import * as React from 'react';
import { FC, useContext, useEffect, useState } from 'react';
import { first } from 'rxjs/operators';
import { RootContextRef } from '../RootContext';
import { InstantComponentLoader } from '../util/InstantComponentLoader';
import { NetworkAvailabilityContextProvider } from './NetworkAvailabilityContext';
import { NetworkAvailabilityIndicator } from './NetworkAvailabilityIndicator';

export const RequireGraphqlWebSocket: FC =
	({
		 children,
	 }) =>
	{
		const {
			webSocketService,
		} = useContext(RootContextRef);
		const [webSocketMadeInitialConnection, setWebSocketMadeInitialConnection] = useState(false);
		const [showIndicator, setShowIndicator] = useState(false);

		useEffect(() =>
		{
			const subscriber = webSocketService.status.pipe(
				first(status => status === 'CONNECTED'),
			).subscribe(() => setWebSocketMadeInitialConnection(true));

			return () => {
				subscriber.unsubscribe();
			};
		}, [webSocketService]);

		return <NetworkAvailabilityContextProvider
			value={{
				showIndicator,
				setShowIndicator,
			}}
		>
			<NetworkAvailabilityIndicator />
			{
				webSocketMadeInitialConnection
					? children
					: <InstantComponentLoader/>
			}
		</NetworkAvailabilityContextProvider>;
	};