import { AuthenticationService, Credentials } from '@intentic/yggdrasil-rotr';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { LoginService } from './LoginService';

export abstract class BaseAuthenticationService implements AuthenticationService, LoginService
{
    /*---------------------------------------------------------------*
     *                         Dependencies                          *
     *---------------------------------------------------------------*/

    /*---------------------------------------------------------------*
     *                          Properties                           *
     *---------------------------------------------------------------*/

    /*---------------------------------------------------------------*
     *                          Constructors                         *
     *---------------------------------------------------------------*/

    protected constructor()
    {
    }

    /*---------------------------------------------------------------*
     *                     Business logic: public                    *
     *---------------------------------------------------------------*/

    public abstract get authenticated(): boolean;

    public abstract get initialized(): boolean;

    public abstract get credentials(): BehaviorSubject<Credentials | undefined>;

    public get authenticationToken(): string | undefined
    {
        return this.currentCredentials?.authenticationToken;
    }

    public get authorizationHeader(): string | undefined
    {
        return this.authenticationToken !== undefined
            ?
            `Bearer ${this.authenticationToken}`
            :
            undefined;
    }

    public get loading(): boolean
    {
        return this.currentCredentials === undefined;
    }

    public invalidateCurrentAndGetNewCredentials(authenticationTokenOfCredentialsToInvalidate: string): Promise<Credentials>
    {
        return new Promise<Credentials>(() =>
        {
            if (this.authenticationToken === authenticationTokenOfCredentialsToInvalidate)
                this.setCredentials(undefined);

            return this.obtainNewCredentials();
        });
    }

    public requireAuthentication(): Promise<void>
    {
        if (this.currentCredentials === undefined)
            return this.obtainNewCredentials().then();
        else
            return Promise.resolve();
    }

    public abstract attemptLogin(email: string, password: string): Promise<'success' | 'wrong_credentials'>;

    public abstract attemptSignUp(email: string, password: string): Promise<'success' | 'email_exists' | 'other_error'>;

    public abstract logout(): void;

    public getCredentials(): Promise<Credentials>
    {
        return this.credentials
            .pipe(first(
                (credentials): credentials is Credentials => credentials !== undefined
            ))
            .toPromise();
    }

    /*---------------------------------------------------------------*
     *                    Business logic: private                    *
     *---------------------------------------------------------------*/

    protected abstract obtainNewCredentials(): Promise<Credentials>;

    protected abstract setCredentials(credentials: Credentials | undefined): void;

    /*---------------------------------------------------------------*
     *                      Getters and setters                      *
     *---------------------------------------------------------------*/

    protected get currentCredentials(): Credentials | undefined
    {
        return this.credentials.getValue();
    }

    /*---------------------------------------------------------------*
     *                        Nested classes                         *
     *---------------------------------------------------------------*/
}