import { CssBaseline, ThemeProvider } from '@material-ui/core';
import React, { FC } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { AppRoot } from './AppRoot';
import { AppRootContext } from './AppRootContext';
import { AppWebSocketContextProvider } from './AppWebSocketContextProvider';
import { history } from './History';
import { RequireGraphqlWebSocket } from './network/RequireGraphqlWebSocket';
import { ReportWebSocketContextProvider } from './ReportWebSocketContextProvider';
import { RequireAuthentication } from './RequireAuthentication';
import { theme } from './Theme';
import { UserContextProvider } from './user/UserContext';
import { YggdrasilContextProvider } from './YggdrasilContext';

export const App: FC = () =>
{
    return <AppRootContext>
        <Router history={history}>
            <Switch>
                <Route path="/:placeholder*/report" exact>
                    <ReportWebSocketContextProvider>
                        <InnerApp/>
                    </ReportWebSocketContextProvider>
                </Route>
                <Route>
                    <AppWebSocketContextProvider>
                        <InnerApp/>
                    </AppWebSocketContextProvider>
                </Route>
            </Switch>
        </Router>
    </AppRootContext>;
};

const InnerApp: FC = () =>
{
    return <ThemeProvider theme={theme}>
        <CssBaseline/>
        <RequireAuthentication>
            <RequireGraphqlWebSocket>
                <YggdrasilContextProvider>
                    <UserContextProvider>
                        <AppRoot/>
                    </UserContextProvider>
                </YggdrasilContextProvider>
            </RequireGraphqlWebSocket>
        </RequireAuthentication>
    </ThemeProvider>;
}