import { Typography } from '@material-ui/core';
import React, { createContext, FC } from 'react';
import { QUser } from '../__generated__/ygg/dsl/queryClasses';
import { User } from '../__generated__/ygg/model/modelClasses';
import { useDataSingle } from '../query/useDataSingle';
import { UserEnvironmentContextProvider } from './UserEnvironmentContext';

export const UserContextRef = createContext<User | undefined>(undefined);

export const UserContextProvider: FC =
	({
		children
	}) =>
	{
		const me = useDataSingle(queryFactory =>
		{
			return queryFactory.selectFrom(QUser.user)
				.join(QUser.user.canRead)
				.me();
		}, []);

		if (me === null)
		{
			return <Typography
				color="error"
			>
				Gebruiker niet op kunnen halen
			</Typography>;
		}
		else
		{
			return <UserContextRef.Provider value={me}>
				<UserEnvironmentContextProvider>
					{children}
				</UserEnvironmentContextProvider>
			</UserContextRef.Provider>;
		}
	};