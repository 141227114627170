import { BusinessCanvas } from '../../../../__generated__/ygg/model/modelClasses';

export const BUSINESS_CANVAS_KANALEN_ID = '0620034e-f989-4484-8f96-9781e1f720a2';
export const BUSINESS_CANVAS_KLANTEN_ID = 'db69479d-699a-4bae-9576-ef087dac3443';
export const BUSINESS_CANVAS_MIDDELEN_ID = 'fad73ace-993d-4674-a30e-e8f2728af124';
export const BUSINESS_CANVAS_PARTNERS_ID = '5f097c79-09cf-4bb9-a4ba-2e44c9047f7c';
export const BUSINESS_CANVAS_PROCESSEN_ID = '9f87001c-2415-4f4f-a2bb-c098090194a3';
export const BUSINESS_CANVAS_PROPOSITIE_ID = '194f8d6e-8804-4fdf-8109-4f716b54b63c';
export const BUSINESS_CANVAS_RELATIE_ID = '030cee8b-9d06-481f-968e-ec459cad9b91';

const orderMapping: Record<string, number> =
	{
		[BUSINESS_CANVAS_PARTNERS_ID]: 0,
		[BUSINESS_CANVAS_PROCESSEN_ID]: 1,
		[BUSINESS_CANVAS_PROPOSITIE_ID]: 2,
		[BUSINESS_CANVAS_MIDDELEN_ID]: 3,
		[BUSINESS_CANVAS_RELATIE_ID]: 4,
		[BUSINESS_CANVAS_KANALEN_ID]: 5,
		[BUSINESS_CANVAS_KLANTEN_ID]: 6,
	}

export function BusinessCanvasComparator(bc1: BusinessCanvas, bc2: BusinessCanvas): number
{
	const order1 = orderMapping[bc1.id] ?? 10;
	const order2 = orderMapping[bc2.id] ?? 10;

	return order1 - order2;
}