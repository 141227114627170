import { ListItem, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ChevronRight } from '@material-ui/icons';
import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { OrganizationSelection } from '../../DossierRoot';

const useStyles = makeStyles((theme: Theme) =>
	({
		root: {
			borderBottom: `1px solid ${theme.palette.text.primary}`,
			'&$focusVisible': {
				color: theme.palette.primary.dark,
				fontWeight: theme.typography.fontWeightBold,
				boxShadow: theme.shadows[8],
				marginInline: theme.spacing(-1),
				paddingInline: theme.spacing(1),
				width: `calc(100% + ${theme.spacing(2)}px)`,
				borderBottom: 0,
			},
			'&$selected, &$selected:hover': {
				color: theme.palette.primary.dark,
				fontWeight: theme.typography.fontWeightBold,
				boxShadow: theme.shadows[8],
				marginInline: theme.spacing(-1),
				paddingInline: theme.spacing(1),
				width: `calc(100% + ${theme.spacing(2)}px)`,
				borderBottom: 0,
			},
		},
		focusVisible: {},
		selected: {},
		chevron: {
			marginInlineStart: 'auto',
		},
	}),
);

interface OrganizationDetailsItemProps
{
	detailsItem: OrganizationSelection['item']
	selectedItem?: OrganizationSelection['item']
	onSelectItem: (item: OrganizationSelection['item']) => void
	onPositionChange: (position: number) => void
	className?: string
}

export const OrganizationDetailsItem: FC<OrganizationDetailsItemProps> =
	({
		detailsItem,
		selectedItem,
		onSelectItem,
		onPositionChange,
		className,
	}) =>
	{
		const classes = useStyles();

		const listItemClasses = useMemo(() => ({
			root: classes.root,
			selected: classes.selected,
			focusVisible: classes.focusVisible
		}), [classes.focusVisible, classes.root, classes.selected]);

		const element = useRef<HTMLDivElement>(null);

		useEffect(() =>
		{
			if (detailsItem === selectedItem)
			{
				const resizeListener = () =>
				{
					const rect = element.current!.getBoundingClientRect();
					onPositionChange(rect.top + (rect.height / 2) + window.pageYOffset);
				}

				resizeListener();

				window.addEventListener('resize', resizeListener);

				return () => window.removeEventListener('resize', resizeListener);
			}
		}, [detailsItem, onPositionChange, selectedItem]);
		
		const handleClick = useCallback(() => 
		{
			onSelectItem(detailsItem);
		}, [detailsItem, onSelectItem]);

		return <ListItem
			ref={element}
			disableGutters
			button
			selected={detailsItem === selectedItem}
			onClick={handleClick}
			className={className}
			classes={listItemClasses}
		>
			<Typography color="textSecondary">
				{detailsItem}
			</Typography>
			<ChevronRight className={classes.chevron}/>
		</ListItem>;
	};