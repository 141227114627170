import { Box, Card, CardActionArea, CardHeader, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ChevronRight } from '@material-ui/icons';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Dossier } from '../../../../../__generated__/ygg/model/modelClasses';
import { OrganizationSelection } from '../../DossierRoot';
import { OrganizationCardContent } from './OrganizationCardContent';

const useStyles = makeStyles((theme: Theme) =>
	({
		root: {
			display: 'flex',
			flexDirection: 'column',
			flexBasis: '50%',
			background: theme.palette.background.semiLight,
		},
		header: {
			background: theme.palette.primary.main,
			color: theme.palette.primary.contrastText,
		},
		titleContainer: {
			display: 'flex',
		},
		title: {
			textTransform: 'uppercase',
			fontWeight: theme.typography.fontWeightMedium,
		},
		icon: {
			marginInlineStart: 'auto',
		},
		help: {
			marginInlineStart: theme.spacing(1),
			cursor: 'pointer',
		},
		content: {
			flexGrow: 1,
		},
	}),
);

interface OrganizationCardProps
{
	dossier: Dossier
	selectedItem?: OrganizationSelection['item']
	onSelectItem: (item: OrganizationSelection['item']) => void
	onPositionChange: (position: number) => void
}

export const OrganizationCard: FC<OrganizationCardProps> =
	({
		dossier,
		...other
	}) =>
	{
		const classes = useStyles();



		return <Card className={classes.root}>
			<CardActionArea
				component={Link}
				to={`./organisatie/?d=${dossier.id}`}
			>
				<CardHeader
					className={classes.header}
					disableTypography
					title={
						<Box className={classes.titleContainer}>
							<Typography
								className={classes.title}
							>
								Organisatie
							</Typography>
							<ChevronRight
								className={classes.icon}
							/>
						</Box>
					}
				/>
			</CardActionArea>
			<OrganizationCardContent
				className={classes.content}
				{...other}
			/>
		</Card>;
	};