import { GraphqlWebSocketService } from '@intentic/yggdrasil-rotr';
import React, { FC, useContext, useEffect, useState } from 'react';
import { RootContextRef } from './RootContext';

export const AppWebSocketContextProvider: FC = ({children}) =>
{
	const {authenticationService, apiService} = useContext(RootContextRef);

	const [webSocketService] = useState(() => new GraphqlWebSocketService(
		authenticationService,
		process.env.REACT_APP_WS_ENDPOINT,
		{logMessages: true, reconnectTimeouts: [5000, 10000, 15000, 30000, 60000, 120000, 300000]}),
	);

	useEffect(() =>
	{
		webSocketService.connect();

		return () => webSocketService.disconnect();
	}, [webSocketService]);
	
	return <RootContextRef.Provider
		value={{
			authenticationService,
			apiService,
			webSocketService,
		}}
	>
		{
			children
		}
	</RootContextRef.Provider>;
};