import { Credentials } from '@intentic/yggdrasil-rotr';
import { BehaviorSubject } from 'rxjs';
import URI from 'urijs';
import { history } from '../History';
import { parseParameterString } from '../util/parseParameterString';
import { BaseAuthenticationService } from './BaseAuthenticationService';
import { LoginService } from './LoginService';

export class ImplicitOAuthAuthenticationService extends BaseAuthenticationService implements LoginService
{
	private readonly apiEndpoint: string;
	private readonly ssoUrl: string;
	private readonly ssoClientId: string;
	private readonly baseUrl: string;

	public readonly credentials: BehaviorSubject<Credentials | undefined>;

	private _initialized: boolean;

	/**
	 * @param apiEndpoint
	 * @param ssoUrl
	 * @param ssoClientId
	 * @param baseUrl
	 */
	constructor(
		apiEndpoint: string,
		ssoUrl: string,
		ssoClientId: string,
		baseUrl: string,
	)
	{
		super();

		this.apiEndpoint = apiEndpoint;
		this.ssoUrl = ssoUrl;
		this.ssoClientId = ssoClientId;
		this.baseUrl = baseUrl;
		this._initialized = false;
		this.credentials = new BehaviorSubject<Credentials | undefined>(undefined);
	}

	public get initialized(): boolean
	{
		return this._initialized;
	}

	public get authenticated(): boolean
	{
		return this.initialized && this.currentCredentials !== undefined;
	}

	private static redirect(state: string | undefined): void
	{
		let url;
		if (state !== undefined)
		{
			try
			{
				url = decodeURIComponent(decodeURIComponent(state));
			}
			catch (e)
			{
				throw new Error(`Not a valid URL for this app: '${state}'`);
			}
		}
		else
		{
			url = window.location.pathname + window.location.search;
		}

		const specifiedBaseURL = process.env.REACT_APP_BASE_URL;

		if (new URL(url).origin !== new URL(specifiedBaseURL).origin)
		{
			throw new Error(`Not a valid URL for this app: '${url}'`);
		}

		history.replace(url.replace(specifiedBaseURL, ''));
	}

	public initialize(): void
	{
		if (this.currentCredentials === undefined)
		{
			const urlHash = parseParameterString(window.location.hash);
			const accessToken = urlHash.access_token as string | undefined;
			const error = urlHash.error as string | undefined;
			const state = urlHash.state as string | undefined;

			const token = error === undefined
				?
				accessToken
				:
				null;

			if (token)
			{
				this.setCredentials({
					authenticationToken: token,
				});

				ImplicitOAuthAuthenticationService.redirect(state);
				this.setInitialized();
			}
			else
			{
				this.setInitialized();
			}
		}
		else
		{
			this.setInitialized();
		}
	}

	attemptLogin(email: string, password: string): Promise<'success' | 'wrong_credentials'>
	{
		return this.obtainNewCredentials().then(() => 'success');
	}

	attemptSignUp(email: string, password: string): Promise<'success' | 'email_exists' | 'other_error'>
	{
		// the Garmr service does not support sign up atm.
		return Promise.resolve('other_error');
	}

	logout(): void
	{
		this.setCredentials(undefined);
		const form = document.createElement('form');
		form.setAttribute('method', 'POST');

		const uri = URI(`${this.ssoUrl}/logout`)
			.addQuery('redirect_uri', this.baseUrl)
			.toString();

		form.setAttribute('action', uri);

		document.body.append(form);

		form.submit();
	}

	public setInitialized(): void
	{
		this._initialized = true;
	}

	protected obtainNewCredentials(): Promise<Credentials>
	{
		return new Promise(() =>
		{
			const uri = URI(`${this.ssoUrl}/oauth/authorize`)
				.addQuery('response_type', 'token')
				.addQuery('client_id', this.ssoClientId)
				.addQuery('redirect_uri', this.baseUrl)
				// encode in case of query parameters / url hashes.
				.addQuery('state', encodeURIComponent(window.location.href)).toString();
			window.location.replace(uri);
			// never resolve; page will redirect
		});
	}

	protected setCredentials(credentials: Credentials | undefined): void
	{
		this.credentials.next(credentials);
	}
}