import { DialogProps as MUIDialogProps } from '@material-ui/core/Dialog/Dialog';
import { captureException } from '@sentry/react';
import React, { FC, useCallback, useState } from 'react';
import { Dialog } from '../Dialog';
import { DialogContent } from '../DialogContent';
import { ErrorDialogContextRef } from './ErrorDialogContext';

export const ErrorDialogProvider: FC =
	({
		 children,
	 }) =>
	{
		const [dialog, setDialog] = useState<{ title: React.ReactNode, error: Error } | undefined>(undefined);

		const showError = useCallback((error: Error, title: React.ReactNode) =>
		{
			captureException(error);

			setDialog({error, title})
		}, []);
		const clearError = useCallback(() => setDialog(undefined), []);

		return <ErrorDialogContextRef.Provider value={{showError, clearError}}>
			{children}
			{dialog &&
			<ErrorDialog {...dialog} onClose={clearError} />
			}
		</ErrorDialogContextRef.Provider>;
	};

export interface DialogProps extends Omit<MUIDialogProps, 'onExited' | 'open' | 'title' | 'onClose' | 'children'>
{
	title: React.ReactNode
	error: Error | undefined
	onClose: () => void
}

const ErrorDialog: FC<DialogProps> =
	({
		 error,
		 children,
		 ...other
	 }) =>
	{
		return <Dialog PaperProps={{style: {minHeight: 0}}} {...other} open={error !== undefined} text={error?.message ?? ''}>
			<DialogContent>

			</DialogContent>
		</Dialog>;
	};