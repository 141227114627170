import { Collection, DataObject, Entity, IllegalStateException, Value, ValueType } from '@intentic/yggdrasil-rotr';
import { Typography, TypographyProps } from '@material-ui/core';
import { TypographyTypeMap } from '@material-ui/core/Typography/Typography';
import React from 'react';
import { CollectionView } from './collection/CollectionView';
import { DataObjectView } from './dataobject/DataObjectView';
import { EntityView } from './entity/EntityView';

export type ValueViewProps<D extends React.ElementType = TypographyTypeMap['defaultComponent']> = TypographyProps<D> &
{
	value: Value<ValueType>
}

export const ValueView = React.forwardRef<any, ValueViewProps<any>>(
	({
		value,
		...other
	}, ref) =>
	{
		switch (value.type().getCategory())
		{
			case 'Primitive':
				return <DataObjectView dataObject={value as DataObject} ref={ref} {...other} />;
			case 'Complex':
				return <EntityView entity={value as Entity} ref={ref} {...other} />;
			case 'Collection':
				return <CollectionView collection={value as Collection<ValueType>} ref={ref} {...other} />;
			case 'Undefined':
				return <Typography variant={'body1'} ref={ref} {...other}>&#0;</Typography>;
			default:
				throw new IllegalStateException(`Unknown type ${value.type()}`);
		}
	});