import { CardContent, List } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC } from 'react';
import { OrganizationDetailsItems, OrganizationSelection } from '../../DossierRoot';
import { OrganizationDetailsItem } from './OrganizationDetailsItem';

const useStyles = makeStyles((theme: Theme) =>
	({
		list: {
			display: 'grid',
			gridTemplateAreas: `
			  "area-0 area-1"
			  "area-2 area-2"
			  "area-3 area-4"
			`,
			columnGap: theme.spacing(6),
			rowGap: theme.spacing(2),
		},
		area0: {
			gridArea: 'area-0',
		},
		area1: {
			gridArea: 'area-1',
		},
		area2: {
			gridArea: 'area-2',
		},
		area3: {
			gridArea: 'area-3',
		},
	}),
);

type AreaClassNames = `area${0|1|2|3}`;

interface OrganizationCardContentProps
{
	selectedItem?: OrganizationSelection['item']
	onSelectItem: (item: OrganizationSelection['item']) => void
	onPositionChange: (position: number) => void
	className?: string
}

export const OrganizationCardContent: FC<OrganizationCardContentProps> =
	({
		className,
		...other
	}) =>
	{
		const classes = useStyles();

		return <CardContent className={className}>
			<List className={classes.list}>
				{
					OrganizationDetailsItems.map((detailsItem, index) =>
						<OrganizationDetailsItem
							key={index}
							detailsItem={detailsItem}
							className={classes[`area${index}` as AreaClassNames]}
							{...other}
						/>
					)
				}
			</List>
		</CardContent>;
	};