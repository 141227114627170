import { DataObject } from '@intentic/yggdrasil-rotr';
import { getContrastRatio, useTheme } from '@material-ui/core';
import { CSSProperties, useMemo } from 'react';

export function useColorStyle(dataObject: DataObject | undefined | null): CSSProperties | undefined
{
	const theme = useTheme();

	const color = useMemo(() => dataObject?.toString(), [dataObject]);

	const textColor = useMemo(() =>
	{
		if (color !== undefined)
		{
			if (getContrastRatio(theme.palette.common.white, color) > (theme.palette.contrastThreshold ?? 3))
				return theme.palette.common.white;
			else
				return theme.palette.common.black;
		}
	}, [color, theme.palette.common.black, theme.palette.common.white, theme.palette.contrastThreshold]);

	return useMemo(() =>
	{
		if (color !== undefined)
			return {background: color, color: textColor};
	}, [color, textColor]);
}