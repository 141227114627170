import React, { FC } from 'react';
import { EntityType } from '../../../../../__generated__/ygg/model/modelClasses';
import { GenericSidePanel } from '../../../bedrijfsprofiel/components/panel/util/GenericSidePanel';
import { ItemSelection } from '../../DossierRoot';
import { SelectionPanel } from './SelectionPanel';

interface SidePanelProps
{
	objectTypeDictionary: Record<string, EntityType>
	selectedItem?: ItemSelection
	position: number
	className?: string
}

export const SidePanel: FC<SidePanelProps> =
	({
		objectTypeDictionary,
		selectedItem,
		position,
		className,
	}) =>
	{
		return <GenericSidePanel
			selectedItem={selectedItem}
			position={position}
			className={className}
		>
			{
				selectedItem &&
					<SelectionPanel
						objectTypeDictionary={objectTypeDictionary}
						selectedItem={selectedItem}
					/>
			}
		</GenericSidePanel>;
	};