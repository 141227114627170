import { CircularProgress, Theme, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';

export interface ButtonLoaderProps
{
	loading: boolean
	size: 'small' | 'medium' | 'large'
}

const useStyles = makeStyles(() => ({
	hiddenLabel: {
		visibility: 'hidden',
		width: '100%',
		display: 'inherit',
		alignItems: 'inherit',
		justifyContent: 'inherit',
	},
	progress: {
		position: 'absolute',
		left: 'auto',
		right: 'auto',
		top: 'auto',
		bottom: 'auto',
	},
}));

export const ButtonLoader: FC<ButtonLoaderProps> = (
	({
		 loading,
		 size,
		 children,
	 }) =>
	{
		const theme = useTheme();
		const classes = useStyles();

		return loading
			?
			<>
				<span className={classes.hiddenLabel}>{children}</span>
				<CircularProgress color="inherit" size={getCircularProgressSize(size, theme)} className={classes.progress} />
			</>
			:
			<>{children}</>;
	});

function getCircularProgressSize(size: 'small' | 'medium' | 'large', theme: Theme): string
{
	switch (size)
	{
		case 'small':
			return theme.typography.pxToRem(16);
		case 'medium':
			return theme.typography.pxToRem(20);
		case 'large':
			return theme.typography.pxToRem(24);
	}
}