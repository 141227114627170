import { Box, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React, { FC, useCallback } from 'react';
import { BusinessCanvas, Dossier } from '../../../../../__generated__/ygg/model/modelClasses';
import { useComputed } from '../../../../../mobx/useComputed';
import { ACCEPTATIE_STEP, ADVIES_STEP, UITVRAAG_STEP } from '../../../../product/dossier/data/DossierStep';
import {
	BEDRIJFSPROFIEL_STEP, INVENTARISATIEVRAGEN_STEP,
	ORGANISATIE_STEP,
	RAPPORTAGE_STEP,
	RISICO_ANALYSE_STEP,
	VERZEKERINGEN_STEP
} from '../../data/DossierStep';
import { BedrijfsprofielSelection, OrganizationSelection } from '../../DossierRoot';
import { BedrijfsprofielCard } from './BedrijfsprofielCard';
import { OrganizationCard } from './OrganizationCard';

const useStyles = makeStyles((theme: Theme) =>
	({
		root: {
			display: 'flex',
			gap: theme.spacing(2),
		},
		textSection: {
			display: 'flex',
			flexBasis: `calc(50% - ${theme.spacing(1)}px)`,
			justifyContent: 'flex-end',
			textAlign: 'end',
		},
		text: {
			color: theme.palette.primary.dark,
		},
		cardContainer: {
			flexBasis: `calc(50% - ${theme.spacing(1)}px)`,
		},
		checkCircle: {
			alignItems: 'end',
			paddingRight: `${theme.spacing(2)}px`
		},
		textRows: {
			display: 'flex',
			flexDirection: 'column',
			paddingLeft: '10px',
		},
		colorPrimary: {
			color: '#33CC99',
		},
		colorDisabled: {
			color: 'grey',
		},
	}),
);

interface BedrijfsprofielSectionProps
{
	dossier: Dossier
	categories: BusinessCanvas[]
	selectedItem?: OrganizationSelection | BedrijfsprofielSelection
	onSelectItem: (item: OrganizationSelection | BedrijfsprofielSelection) => void
	onPositionChange: (position: number) => void
}

export const BedrijfsprofielSection: FC<BedrijfsprofielSectionProps> =
	({
		dossier,
		categories,
		selectedItem,
		onSelectItem,
		...other
	}) =>
	{
		const classes = useStyles();

		const handleSelectBusinessCanvas = useCallback((item: BusinessCanvas) =>
		{
			onSelectItem({type: 'bedrijfsprofiel', item});
		}, [onSelectItem]);

		const handleSelectOrganizationDetails = useCallback((item: OrganizationSelection['item']) =>
		{
			onSelectItem({type: 'organization', item});
		}, [onSelectItem]);

		const disabled = useComputed(() =>
		{
			switch (dossier.huidigeStap!.id)
			{
				case RAPPORTAGE_STEP:
				case VERZEKERINGEN_STEP:
				case RISICO_ANALYSE_STEP:
				case INVENTARISATIEVRAGEN_STEP:
				case BEDRIJFSPROFIEL_STEP:
				case ACCEPTATIE_STEP:
				case ADVIES_STEP:
				case UITVRAAG_STEP:
					return false;
				case ORGANISATIE_STEP:
				default:
					return true;
			}
		}, [dossier]);

		return <>
			<Box className={classes.root}>
				<Box className={classes.textSection}>
					<Box className={classes.checkCircle}>
						<CheckCircleIcon className={classes.colorPrimary}/>
					</Box>
					<Box className={classes.textRows}>
						<Typography className={classes.text}>
							<li>Zijn alle geïmporteerde gegevens geverifieerd?</li>
						</Typography>
						<Typography variant="caption">
							Controleer of alle informatie over uw <br/> organisatie juist is voordat u verder gaat.
						</Typography>
					</Box>
				</Box>
				<Box className={classes.cardContainer}>
					<OrganizationCard
						dossier={dossier}
						onSelectItem={handleSelectOrganizationDetails}
						selectedItem={selectedItem?.type === 'organization' ? selectedItem.item : undefined}
						{...other}
					/>
				</Box>
			</Box>
			<Box className={classes.root}>
				<Box className={classes.textSection}>
					<Box className={classes.checkCircle}>
						<CheckCircleIcon className={disabled ? classes.colorDisabled : classes.colorPrimary}/>
					</Box>
					<Box className={classes.textRows}>
						<Typography className={classes.text}>
							<li>Is uw bedrijfsprofiel juist en volledig ingevuld?</li>
						</Typography>
						<Typography variant="caption">
							Controleer of alle relevante onderdelen <br/> van uw bedrijfsprofiel zijn aangemaakt en zijn ingevuld.
						</Typography>
					</Box>
				</Box>
				<Box className={classes.cardContainer}>
					<BedrijfsprofielCard
						dossier={dossier}
						categories={categories}
						onSelectItem={handleSelectBusinessCanvas}
						selectedItem={selectedItem?.type === 'bedrijfsprofiel' ? selectedItem.item : undefined}
						{...other}
					/>
				</Box>
			</Box>
		</>
	};