import { Box, Typography } from "@material-ui/core";
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ChevronRight } from '@material-ui/icons';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React, { FC, useContext } from "react";
import { Link } from 'react-router-dom';
import { Button } from "../../../../../generic/Button/Button";
import { useComputed } from '../../../../../mobx/useComputed';
import { DossierContextRef } from '../../data/DossierContext';
import {
	BEDRIJFSPROFIEL_STEP,
	INVENTARISATIEVRAGEN_STEP,
	ORGANISATIE_STEP,
	RAPPORTAGE_STEP,
	RISICO_ANALYSE_STEP,
	VERZEKERINGEN_STEP
} from '../../data/DossierStep';

const useStyles = makeStyles((theme: Theme) =>
	({
		root: {
			display: 'flex',
			justifyContent: 'flex-end',
			gap: theme.spacing(2),
		},
		textSection: {
			display: 'flex',
			flexBasis: `calc(50% - ${theme.spacing(1)}px)`,
			justifyContent: 'flex-end',
			textAlign: 'end',
		},
		text: {
			color: theme.palette.primary.dark,
		},
		buttonContainer: {
			flexBasis: `calc(50% - ${theme.spacing(1)}px)`,
		},
		button: {
			width: '100%',
			padding: theme.spacing(1, 2),
			...theme.typography.body1,
		},
		icon: {
			marginInlineStart: 'auto',
		},
		checkCircle: {
			alignItems: 'end',
			paddingRight: `${theme.spacing(2)}px`
		},
		textRows: {
			display: 'flex',
			flexDirection: 'column',
			paddingLeft: '10px',
		},
		colorPrimary: {
			color: '#33CC99',
		},
		colorDisabled: {
			color: 'grey',
		},
	}),
);

export const AcceptatievragenSection: FC =
	() =>
	{
		const classes = useStyles();

		const dossier = useContext(DossierContextRef);

		const disabled = useComputed(() =>
		{
			switch (dossier.huidigeStap!.id)
			{
				case RAPPORTAGE_STEP:
				case VERZEKERINGEN_STEP:
				case RISICO_ANALYSE_STEP:
				case INVENTARISATIEVRAGEN_STEP:
					return false;
				case BEDRIJFSPROFIEL_STEP:
				case ORGANISATIE_STEP:
				default:
					return true;
			}
		}, [dossier]);


		return <Box className={classes.root}>
			<Box className={classes.textSection}>
				<Box className={classes.checkCircle}>
					<CheckCircleIcon className={disabled ? classes.colorDisabled : classes.colorPrimary}/>
				</Box>
				<Box className={classes.textRows}>
					<Typography className={classes.text}>
						<li>Zijn alle aanvullende vragen over uw <br/> organisatie beantwoord?</li>
					</Typography>
					<Typography variant="caption">
						Beantwoord de aanvullende vragen ten aanzien <br/> van uw bedrijfsprofiel.
					</Typography>
				</Box>
			</Box>
			<Box className={classes.buttonContainer}>
				<Button
					disabled={disabled}
					variant="contained"
					color="primary"
					className={classes.button}
					component={Link}
					to={`./inventarisatievragen?d=${dossier.id}`}
				>
					Inventarisatievragen
					<ChevronRight
						className={classes.icon}
					/>
				</Button>
			</Box>
		</Box>;
	}