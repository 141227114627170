import { IllegalStateException } from '@intentic/yggdrasil-rotr';
import { Radio } from '@material-ui/core';
import { RadioProps } from '@material-ui/core/Radio/Radio';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/styles/withStyles/withStyles';
import clsx from 'clsx';
import React from 'react';
import {
	BEOORDELING_KLASSE_HIGH_ID,
	BEOORDELING_KLASSE_LOW_ID,
	BEOORDELING_KLASSE_MIDDLE_ID,
	BEOORDELING_KLASSE_VERY_LOW_ID
} from '../../data/BeoordelingKlasseIds';
import { RadioIcon } from '../risicobeoordeling/RadioIcon';

const useStyles = makeStyles((theme: Theme) =>
	({
		root: {
			display: 'flex',
			flexWrap: 'nowrap',
			flexDirection: 'row',
		},
		veryHigh: {
			color: theme.palette.riskVeryHigh.main,
			'&$checked': {
				color: theme.palette.riskVeryHigh.main,
			},
			'&$disabled': {
				color: theme.palette.riskVeryHigh.main,
			},
		},
		high: {
			color: theme.palette.riskHigh.main,
			'&$checked': {
				color: theme.palette.riskHigh.main,
			},
			'&$disabled': {
				color: theme.palette.riskHigh.main,
			},
		},
		middle: {
			color: theme.palette.riskMiddle.main,
			'&$checked': {
				color: theme.palette.riskMiddle.main,
			},
			'&$disabled': {
				color: theme.palette.riskMiddle.main,
			},
		},
		low: {
			color: theme.palette.riskLow.main,
			'&$checked': {
				color: theme.palette.riskLow.main,
			},
			'&$disabled': {
				color: theme.palette.riskLow.main,
			},
		},
		checked: {},
		disabled: {},
	})
);

export type ValueOptions =
	typeof BEOORDELING_KLASSE_VERY_LOW_ID
	| typeof BEOORDELING_KLASSE_LOW_ID
	| typeof BEOORDELING_KLASSE_MIDDLE_ID
	| typeof BEOORDELING_KLASSE_HIGH_ID;

interface ControlebeoordelingRadioProps extends Omit<RadioProps, 'value' | 'classes'>
{
	value: ValueOptions
	classes?: Partial<ClassNameMap<'radio' | 'icon'>>
}

export const ControlebeoordelingRadio = React.forwardRef<any, ControlebeoordelingRadioProps>(
	({
		value,
		size,
		classes: classesProp = {},
		...other
	}, ref) =>
	{
		const classes = useStyles();

		const rootClassName = clsx(
			{
				[classes.low]: value === BEOORDELING_KLASSE_HIGH_ID,
				[classes.middle]: value === BEOORDELING_KLASSE_MIDDLE_ID,
				[classes.high]: value === BEOORDELING_KLASSE_LOW_ID,
				[classes.veryHigh]: value === BEOORDELING_KLASSE_VERY_LOW_ID,
			},
			classesProp.radio
		);

		let label;
		switch (value)
		{
			case BEOORDELING_KLASSE_HIGH_ID:
				label = 'hoog';
				break;
			case BEOORDELING_KLASSE_MIDDLE_ID:
				label = 'middel';
				break;
			case BEOORDELING_KLASSE_LOW_ID:
				label = 'laag';
				break;
			case BEOORDELING_KLASSE_VERY_LOW_ID:
				label = 'zeer laag';
				break;
			default:
				throw new IllegalStateException(`Unknown value option ${value}`);
		}

		return <Radio
			ref={ref}
			value={value}
			name="beoordelingklasse"
			size={size}
			inputProps={{'aria-label': label}}
			classes={{root: rootClassName, checked: classes.checked, disabled: classes.disabled}}
			icon={<RadioIcon classes={{icon: classesProp.icon} } size={size}/>}
			checkedIcon={<RadioIcon classes={{icon: classesProp.icon}} checked size={size}/>}
			{...other}
		/>;
	});