import { Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { DataObjectViewProps } from '../../DataObjectView';
import { useColorStyle } from './useColorStyle';

export const ColorView = React.forwardRef<any, DataObjectViewProps>(
	({
		dataObject,
		variant,
		style,
		...other
	}, ref) =>
	{
		const colorStyle = useColorStyle(dataObject);

		const combinedStyle = useMemo(() =>
		{
			if (style === undefined)
				return colorStyle;
			else
				return {...style, ...colorStyle};
		}, [colorStyle, style]);

		return <Typography variant={variant ?? 'body1'} ref={ref} {...other} style={combinedStyle}>{dataObject.toString()}</Typography>;
	});