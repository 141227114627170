import { createStyles, makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { DataObjectViewProps } from '../../DataObjectView';

const useStyles = makeStyles(() =>
	createStyles({
		content: {
			whiteSpace: 'pre-wrap',
		},
	}));

export const FunctionView = React.forwardRef<any, DataObjectViewProps>(
	({
		dataObject,
		variant,
		className,
		...other
	}, ref) =>
	{
		const classes = useStyles();
		const value = dataObject.toString();

		const valueClassName = clsx(
			classes.content,
			className,
		);

		return <Typography variant={variant ?? 'body1'} className={valueClassName} ref={ref} {...other}>{value.indexOf('(') === 0 ? value.substring(1, value.length - 1) : value}</Typography>;
	});