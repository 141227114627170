import { FileType, FileTypeObject, IMAGE_MIME_TYPES, NamedBlob } from '@intentic/yggdrasil-rotr';
import { Button, CircularProgress, createStyles, Fade, makeStyles, PropTypes, Theme, Typography } from '@material-ui/core';
import { SaveAlt } from '@material-ui/icons';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { YggdrasilContextRef } from '../../../../../YggdrasilContext';
import { DataObjectViewProps } from '../../DataObjectView';

const useStyles = makeStyles(
	(theme: Theme) =>
		createStyles({
			rightIcon: {
				marginLeft: theme.spacing(),
			},
			error: {
				color: theme.palette.error.main,
			},
		}));

function isButtonColor(input?: PropTypes.Color | 'initial' | 'inherit' | 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error'): input is PropTypes.Color
{
	return ['inherit', 'primary', 'secondary'].includes(input ?? '');
}

export const FileView = React.forwardRef<any, DataObjectViewProps>(
	({
		dataObject,
		variant,
		color,
		...other
	}, ref) =>
	{
		const buttonColor = isButtonColor(color) ? color : 'default';
		const classes = useStyles();
		const data = useMemo(() => dataObject.data, [dataObject]) as Partial<FileTypeObject>;
		const [type, setType] = useState(data.type);
		const {fileRegistry} = useContext(YggdrasilContextRef);
		const [file, setFileInternal] = useState<NamedBlob | undefined | null>(undefined);
		const [loadingFile, setLoadingFile] = useState(true);

		const fileLoaded = useCallback((file: NamedBlob | undefined) =>
		{
			if (file)
			{
				setFileInternal(file);
				setType(file.type);
			}

			setLoadingFile(false);
		}, []);

		// component unmount
		useEffect(() =>
		{
			if (data.url !== undefined)
			{
				if (type === undefined)
				{
					FileType.loadFile(data.url).then(blob => fileLoaded(blob === null ? undefined : blob));
				}
			}
			else if (data.localFileReference !== undefined)
			{
				fileLoaded(fileRegistry.get(data.localFileReference));
			}

			return () =>
			{
				if (data.localFileReference !== undefined)
					fileRegistry.release(data.localFileReference);
			};
		}, [data, type, fileLoaded, fileRegistry]);

		useEffect(() =>
		{
			if (type && IMAGE_MIME_TYPES.includes(type))
			{
				setLoadingFile(false);

				if (data.url !== undefined)
					setFileInternal(null);
			}
		}, [data.url, type]);

		return data.name === undefined
			?
			<Typography variant={variant ?? 'body1'} color={color} ref={ref} {...other}>undefined</Typography>
			:
			(
				type && IMAGE_MIME_TYPES.includes(type)
					?
					(
						loadingFile
							?
							<div ref={ref}>
								<Fade in={loadingFile} timeout={{enter: 800, exit: 0}} unmountOnExit>
									<CircularProgress size={32} />
								</Fade>
								<Typography variant={variant ?? 'body1'} color={color} {...other}>
									<Button variant="contained" color={buttonColor} href={data.url!} download={data.name} target="_blank">
										{data.name}
										<SaveAlt className={classes.rightIcon} />
									</Button>
								</Typography>
							</div>
							:
							(
								file === undefined
									?
									<div ref={ref}>
										<Typography variant={variant ?? 'body1'} {...other} className={classes.error}>
											Bestand niet kunnen laden
										</Typography>
										<Button variant="contained" color={buttonColor} href={data.url!} download={data.name} target="_blank">
											{data.name}
											<SaveAlt className={classes.rightIcon} />
										</Button>
									</div>
									:
									<img src={data.url ? data.url : file!.preview!} style={{pointerEvents: 'none'}}
										 alt={data.name} />
							)
					)
					:
					<div ref={ref}>
						<Button variant="contained" color={buttonColor} href={data.url!} download={data.name} target="_blank">
							{data.name}
							<SaveAlt className={classes.rightIcon} />
						</Button>
					</div>
			);
	});