import DateFnsUtils from '@date-io/date-fns';
import { Grow, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TransitionProps } from '@material-ui/core/transitions';
import { Close } from '@material-ui/icons';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import nlLocale from 'date-fns/locale/nl';
import { SnackbarProvider } from 'notistack';
import React, { FC, lazy, Suspense, useCallback } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AppDrawer } from './drawer/AppDrawer';
import { ErrorDialogProvider } from './generic/Dialog/Error/ErrorDialogProvider';
import { NetworkAvailabilityIndicatorPlaceholder } from './network/NetworkAvailabilityIndicatorPlaceholder';
import ErrorBoundary from './util/error/ErrorBoundary';
import { LazyComponentLoader } from './util/LazyComponentLoader';

const useStyles = makeStyles(() =>
	({
		root: {
			display: 'flex',
		},
		content: {
			minWidth: 0,
			flexGrow: 1,
			display: 'flex',
			minHeight: '100vh',
		},
		loader: {
			flexGrow: 1,
		}
	}),
);

const Clients = lazy(() => import('./pages/clients/index'));

const RisicoReport = lazy(() => import('./pages/risico/report/index'));
const RisicoDossier = lazy(() => import('./pages/risico/dossier/index'));
const RisicoBedrijfsprofiel = lazy(() => import('./pages/risico/bedrijfsprofiel/index'));
const RisicoAcceptatievragen = lazy(() => import('./pages/risico/acceptatievragen/index'));
const RisicoRisicoAnalyse = lazy(() => import('./pages/risico/risico-analyse/index'));
const RisicoVerzekeringen = lazy(() => import('./pages/risico/verzekeringen/index'));
const RisicoRapportage = lazy(() => import('./pages/risico/rapportage/index'));

const ProductReport = lazy(() => import('./pages/product/report/index'));
const ProductDossier = lazy(() => import('./pages/product/dossier/index'));
const ProductBedrijfsprofiel = lazy(() => import('./pages/product/bedrijfsprofiel/index'));
const ProductAcceptatie = lazy(() => import('./pages/product/acceptatie/index'));
const ProductUitvraag = lazy(() => import('./pages/product/uitvraag/index'));
const ProductAdvies = lazy(() => import('./pages/product/advies/index'));
const ProductRapportage = lazy(() => import('./pages/product/rapportage/index'));

const Organisatie = lazy(() => import('./pages/organisatie/index'));
const Home = lazy(() => import('./pages/Home'));
const Help = lazy(() => import('./pages/help/index'));

export const AppRoot: FC = () =>
{
	const classes = useStyles();

	// add action to all snackbars
	const notistackRef = React.createRef<SnackbarProvider>();
	const onClickDismiss = useCallback((key: string | number) => () =>
	{
		notistackRef.current!.closeSnackbar(key);
	}, [notistackRef]);

	return <MuiPickersUtilsProvider utils={DateFnsUtils} locale={nlLocale}>
		<SnackbarProvider
			ref={notistackRef}
			action={key =>
				<IconButton size="small" aria-label="close" color="inherit" onClick={onClickDismiss(key)}>
					<Close fontSize="small"/>
				</IconButton>
			}
			TransitionComponent={Grow as React.ComponentType<TransitionProps>}
		>
			<ErrorDialogProvider>
				<NetworkAvailabilityIndicatorPlaceholder />
				<div className={classes.root}>
					<Suspense fallback={<LazyComponentLoader className={classes.loader}/>}>
						<Switch>
							<Route path="/risico/report" exact component={RisicoReport}/>
							<Route path="/product/report" exact component={ProductReport}/>
							<Route>
								<AppDrawer/>
								<main className={classes.content}>
									<ErrorBoundary>
										<Suspense fallback={<LazyComponentLoader className={classes.loader}/>}>
											<Switch>
												<Route path="/" exact component={Clients}/>

												<Route path="/risico/dossier" exact component={RisicoDossier}/>
												<Route path="/risico/bedrijfsprofiel" exact component={RisicoBedrijfsprofiel}/>
												<Route path="/risico/inventarisatievragen" exact component={RisicoAcceptatievragen}/>
												<Route path="/risico/risico-analyse" exact component={RisicoRisicoAnalyse}/>
												<Route path="/risico/verzekeringen" exact component={RisicoVerzekeringen}/>
												<Route path="/risico/rapportage" exact component={RisicoRapportage}/>
												<Route path="/risico/organisatie" component={Organisatie}/>

												<Route path="/product/dossier" exact component={ProductDossier}/>
												<Route path="/product/bedrijfsprofiel" exact component={ProductBedrijfsprofiel}/>
												<Route path="/product/acceptatie" exact component={ProductAcceptatie}/>
												<Route path="/product/uitvraag" exact component={ProductUitvraag}/>
												<Route path="/product/advies" exact component={ProductAdvies}/>
												<Route path="/product/rapportage" exact component={ProductRapportage}/>
												<Route path="/product/organisatie" component={Organisatie}/>

												<Route path="/organisatie/home" component={Home} />
												<Route path="/help" component={Help}/>

												<Route><Redirect to="/"/></Route>
											</Switch>
										</Suspense>
									</ErrorBoundary>
								</main>
							</Route>
						</Switch>
					</Suspense>
				</div>
			</ErrorDialogProvider>
		</SnackbarProvider>
	</MuiPickersUtilsProvider>;
};
