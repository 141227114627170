import { Box, Card, CardContent, CardHeader } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC, useMemo } from 'react';
import { Balans } from '../../../../../../__generated__/ygg/model/modelClasses';
import { PropPair } from '../../object-bp/specialized/template/ObjectBPCardTemplate';
import { ObjectBPCardTemplatePropertyRow } from '../../object-bp/specialized/template/ObjectBPCardTemplatePropertyRow';

const useStyles = makeStyles((theme: Theme) =>
	({
		header: {
			padding: theme.spacing(1),
			background: theme.palette.secondary.lightest,
			color: theme.palette.secondary.lightestContrastText,
		},
		content: {
			display: 'flex',
			flexDirection: 'column',
			rowGap: theme.spacing(4),
		},
		section: {
			display: 'flex',
			flexDirection: 'column',
			rowGap: theme.spacing(1),
		},
		total: {
			fontWeight: theme.typography.fontWeightBold,
		}
	}),
);

interface BalanceCardProps
{
	balance: Balans
}

export const BalanceCard: FC<BalanceCardProps> =
	({
		balance
	}) =>
	{
		const classes = useStyles();

		const activaProps = useMemo<PropPair[]>(() =>
		{
			return [
				{
					prop: Balans.PROPS.immaterieleVasteActiva,
					value: balance._props.immaterieleVasteActiva,
				},
				{
					prop: Balans.PROPS.materieleVasteActiva,
					value: balance._props.materieleVasteActiva,
				},
				{
					prop: Balans.PROPS.financieleVasteActiva,
					value: balance._props.financieleVasteActiva,
				},
				{
					prop: Balans.PROPS.overigeVasteActiva,
					value: balance._props.overigeVasteActiva,
				},
				{
					prop: Balans.PROPS.voorraden,
					value: balance._props.voorraden,
				},
				{
					prop: Balans.PROPS.vorderingenEnOverlopendeActiva,
					value: balance._props.vorderingenEnOverlopendeActiva,
				},
				{
					prop: Balans.PROPS.liquideMiddelen,
					value: balance._props.liquideMiddelen,
				},
				{
					prop: Balans.PROPS.overigeVlottendeActiva,
					value: balance._props.overigeVlottendeActiva,
				},
			];
		}, [
			balance._props.financieleVasteActiva,
			balance._props.immaterieleVasteActiva,
			balance._props.liquideMiddelen,
			balance._props.materieleVasteActiva,
			balance._props.overigeVasteActiva,
			balance._props.overigeVlottendeActiva,
			balance._props.voorraden,
			balance._props.vorderingenEnOverlopendeActiva
		]);

		const passivaProps = useMemo<PropPair[]>(() =>
		{
			return [
				{
					prop: Balans.PROPS.eigenVermogen,
					value: balance._props.eigenVermogen,
				},
				{
					prop: Balans.PROPS.langlopendeSchulden,
					value: balance._props.langlopendeSchulden,
				},
				{
					prop: Balans.PROPS.kortlopendeSchulden,
					value: balance._props.kortlopendeSchulden,
				},
				{
					prop: Balans.PROPS.overigePassiva,
					value: balance._props.overigePassiva,
				},
			];
		}, [
			balance._props.eigenVermogen,
			balance._props.kortlopendeSchulden,
			balance._props.langlopendeSchulden,
			balance._props.overigePassiva
		]);

		return <Card>
			<CardHeader
				className={classes.header}
				title={`Balans (${balance.datum.getFullYear()})`}/>
			<CardContent className={classes.content}>
				<Box className={classes.section}>
					{
						activaProps.map((propPair, index) =>
							<ObjectBPCardTemplatePropertyRow
								key={index}
								prop={propPair.prop}
								value={propPair.value}
							/>
						)
					}
					{
						<ObjectBPCardTemplatePropertyRow
							prop={Balans.PROPS.totaalActiva}
							value={balance._props.totaalActiva}
							classes={{
								property: classes.total,
								value: classes.total,
							}}
						/>
					}
				</Box>
				<Box className={classes.section}>
					{
						passivaProps.map((propPair, index) =>
							<ObjectBPCardTemplatePropertyRow
								key={index}
								prop={propPair.prop}
								value={propPair.value}
							/>
						)
					}
					{
						<ObjectBPCardTemplatePropertyRow
							prop={Balans.PROPS.totaalPassiva}
							value={balance._props.totaalPassiva}
							classes={{
								property: classes.total,
								value: classes.total,
							}}
						/>
					}
				</Box>
			</CardContent>
		</Card>;
	};