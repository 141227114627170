import { makeStyles } from '@material-ui/core/styles';
import React, { FC, useContext, useMemo } from 'react';
import { QPolis } from '../../../../../../__generated__/ygg/dsl/queryClasses';
import { useDataPlural } from '../../../../../../query/useDataPlural';
import { LazyComponentLoader } from '../../../../../../util/LazyComponentLoader';
import { ClientContextRef } from '../../../../bedrijfsprofiel/data/ClientContext';
import { PolicyCard } from './PolicyCard';

const useStyles = makeStyles(() =>
	({
		loader: {
			flexGrow: 1,
		},
	}),
);

export const PolicyList: FC =
	() =>
	{
		const classes = useStyles();

		const client = useContext(ClientContextRef);

		const policies = useDataPlural(queryFactory =>
		{
			return queryFactory.selectFrom(QPolis.polis)
				.join(QPolis.polis.branche)
				.where(QPolis.polis.client.id.eq(client.id));
		}, [client]);

		const orderedPolicies = useMemo(() =>
		{
			if (policies !== undefined)
				return policies.slice().sort((p1, p2) => p1.product.localeCompare(p2.product));
		}, [policies]);

		if (orderedPolicies === undefined)
		{
			return <LazyComponentLoader className={classes.loader}/>
		}
		else
		{
			return <>
				{
					orderedPolicies.map(policy =>
						<PolicyCard
							key={policy.id}
							policy={policy}
						/>)
				}
			</>;
		}
	};