import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC, useCallback, useContext, useMemo, useState } from 'react';
import { QAcceptatievragenDefinitie, QBranche, QEntityType } from '../../../__generated__/ygg/dsl/queryClasses';
import { BusinessCanvas, EntityType, Risicoklasse } from '../../../__generated__/ygg/model/modelClasses';
import { useDataPlural } from '../../../query/useDataPlural';
import { getAncestors } from '../../../query/util/getAncestors';
import { LazyComponentLoader } from '../../../util/LazyComponentLoader';
import { ClientContextRef } from '../bedrijfsprofiel/data/ClientContext';
import { AcceptatievragenSection } from './components/acceptatievragen/AcceptatievragenSection';
import { BedrijfsprofielSection } from './components/bedrijfsprofiel/BedrijfsprofielSection';
import { Introduction } from './components/introduction/Introduction';
import { SidePanel } from './components/panel/SidePanel';
import { RapportageSection } from './components/rapportage/RapportageSection';
import { RisicoAnalyseSection } from './components/risico-analyse/RisicoAnalyseSection';
import { VerzekeringenSection } from './components/verzekeringen/VerzekeringenSection';
import { CanvasItemBeoordelingItem } from './data/CanvasItemBeoordelingItem';
import { DossierContextRef } from './data/DossierContext';

export const BASIS_SCOPE_ID = 'fbe6a56d-e802-4b0d-a012-f8370d49a498';
export const LIGHT_SCOPE_ID = '583b39f3-e41d-4345-8eee-9af041dc7431';

const useStyles = makeStyles((theme: Theme) =>
	({
		main: {
			flexGrow: 1,
			minWidth: 0,
			padding: theme.spacing(2),
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing(6),
		},
		loader: {
			flexGrow: 1,
		},
	}),
);

interface DossierRootProps
{
	categories: BusinessCanvas[]
	objectTypeDictionary: Record<string, EntityType>
	risicoklasses: Risicoklasse[]
}

export const OrganizationDetailsItems = ['Details', 'Financiën', 'Risico-Adressen', 'Polissen'];

export interface OrganizationSelection
{
	type: 'organization'
	item: typeof OrganizationDetailsItems[number]
}

export interface BedrijfsprofielSelection
{
	type: 'bedrijfsprofiel'
	item: BusinessCanvas
}

export interface RisicoAnalyseSelection
{
	type: 'risico analyse'
	item: CanvasItemBeoordelingItem
}

export type ItemSelection = OrganizationSelection | BedrijfsprofielSelection | RisicoAnalyseSelection

export const DossierRoot: FC<DossierRootProps> =
	({
		categories,
		objectTypeDictionary,
		risicoklasses,
	}) =>
	{
		const classes = useStyles();
		const dossier = useContext(DossierContextRef);
		const client = useContext(ClientContextRef);

		const [selectedItem, setSelectedItem] = useState<ItemSelection | undefined>();
		const [position, setPosition] = useState<number>(0);

		const handleSelectedItem = useCallback((item: ItemSelection | undefined) =>
		{
			setSelectedItem(prev =>
			{
				if (item === undefined)
				{
					return item;
				}
				else if (item.type === prev?.type)
				{
					if (item.type === 'organization')
						return item.item === prev?.item ? undefined : item;
					else if (item.type === 'bedrijfsprofiel')
						return item.item.id === (prev?.item as BusinessCanvas).id ? undefined : item;
					else if (item.type === 'risico analyse')
						return item.item.risicobeoordelingItem.id === (prev?.item as CanvasItemBeoordelingItem).risicobeoordelingItem.id ? undefined : item;
				}
				else
				{
					return item;
				}
			});
		}, []);

		const objectBPTypeIds = useMemo(() => new Set(client.organization!.objects!.flatMap(object => getAncestors(object.type)).map(type => type.id)), [client.organization]);

		const objectTypes = useDataPlural(queryFactory =>
		{
			if (objectBPTypeIds.size > 0)
			{
				return queryFactory.selectFrom(QEntityType.entityType)
					.join(QEntityType.entityType.branches, QBranche.branche)
					.join(QBranche.branche.accceptatievragen, QAcceptatievragenDefinitie.acceptatievragenDefinitie)
					.join(QAcceptatievragenDefinitie.acceptatievragenDefinitie.maatregel)
					.join(QAcceptatievragenDefinitie.acceptatievragenDefinitie.risico)
					.for(...objectBPTypeIds);
			}
		}, [objectBPTypeIds]);

		const objectTypeDictionaryWithAcceptatievragen = useMemo(() =>
		{
			if (objectBPTypeIds.size === 0)
				return {};
			else
				return objectTypes?.reduce<Record<string, EntityType>>((result, current) =>
				{
					result[current.id] = current;
					return result;
				}, {});
		}, [objectBPTypeIds.size, objectTypes]);

		if (objectTypeDictionaryWithAcceptatievragen === undefined)
		{
			return <LazyComponentLoader className={classes.loader}/>;
		}
		else
		{
			return <>
				<ol
					className={classes.main}
				>
					<Introduction/>
					<BedrijfsprofielSection
						dossier={dossier}
						categories={categories}
						selectedItem={selectedItem?.type === 'bedrijfsprofiel' || selectedItem?.type === 'organization'
							? selectedItem
							: undefined}
						onSelectItem={handleSelectedItem}
						onPositionChange={setPosition}
					/>
					{
						dossier.scope!.id === BASIS_SCOPE_ID && <AcceptatievragenSection/>
					}
					{
						dossier.scope!.id === BASIS_SCOPE_ID && <RisicoAnalyseSection
							categories={categories}
							objectTypeDictionary={objectTypeDictionary}
							risicoklasses={risicoklasses}
							selectedItem={selectedItem?.type === 'risico analyse' ? selectedItem : undefined}
							onSelectItem={handleSelectedItem}
							onPositionChange={setPosition}
						/>
					}
					<VerzekeringenSection/>
					<RapportageSection/>
				</ol>
				<SidePanel
					objectTypeDictionary={objectTypeDictionaryWithAcceptatievragen}
					selectedItem={selectedItem}
					position={position}
				/>
			</>;
		}
	};