import { Box, Paper, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import { Graph, GraphProps } from './Graph';

export const BOX_SIZE = 60;

const useStyles = makeStyles((theme: Theme) =>
	({
		root: {
			display: 'flex',
			flexGrow: 1,
			position: 'relative',
			zIndex: 2,
		},
		legend: {
			display: 'flex',
			paddingInline: theme.spacing(2),
			justifyContent: 'space-between',
			writingMode: 'vertical-rl',
			transform: 'rotate(180deg)',
			// remove top and bottom shadow
			clipPath: 'inset(0px -5px)',
		},
		primary: {
			background: theme.palette.primary.darkest,
			color: theme.palette.primary.darkestContrastText,
		},
		secondary: {
			background: theme.palette.text.secondary,
			color: theme.palette.primary.darkestContrastText,
		},
		backgroundStack: {
			position: 'absolute',
			left: 0,
			right: 0,
			flexGrow: 1,
			display: 'flex',
			flexDirection: 'column',
			rowGap: theme.spacing(0.5),
		},
		background: {
			height: BOX_SIZE,
			// remove left shadow
			clipPath: 'inset(-5px -5px -5px 0px)',
		},
		legendStack: {
			zIndex: 1,
			display: 'flex',
			flexDirection: 'column',
			rowGap: theme.spacing(0.5),
		},
		legendBox: {
			height: BOX_SIZE,
			width: 36,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			textAlign: 'center',
			...theme.typography.caption,
			textTransform: 'uppercase',
			fontSize: '0.5rem',
		},
		veryHigh: {
			background: theme.palette.riskVeryHigh.main,
			color: theme.palette.riskVeryHigh.contrastText,
		},
		high: {
			background: theme.palette.riskHigh.main,
			color: theme.palette.riskHigh.contrastText,
		},
		middle: {
			background: theme.palette.riskMiddle.main,
			color: theme.palette.riskMiddle.contrastText,
		},
		low: {
			background: theme.palette.riskLow.main,
			color: theme.palette.riskLow.contrastText,
		},
	})
);

export interface GraphContainerProps<T extends {readonly id: string | number}> extends GraphProps<T>
{
	color?: 'primary' | 'secondary'
	topScaleName?: string
	bottomScaleName?: string
	activatedItem?: Set<string>
}

export const GraphContainer = <T extends {readonly id: string | number}, >
	({
		color = 'primary',
		topScaleName= 'bruto',
		bottomScaleName= 'netto',
		classes,
		...other
	}: GraphContainerProps<T>) =>
	{
		const cls = useStyles();

		return <Box
			className={clsx(cls.root, classes?.root)}
		>
			<Box className={cls.backgroundStack}>
				<Paper
					square
					className={cls.background}
				/>
				<Paper
					square
					className={cls.background}
				/>
				<Paper
					square
					className={cls.background}
				/>
				<Paper
					square
					className={cls.background}
				/>
			</Box>
			<Paper square className={clsx(
				cls.legend,
				cls[color],
			)}>
				<Typography>{bottomScaleName}</Typography>
				<Typography>{topScaleName}</Typography>
			</Paper>
			<Box className={cls.legendStack}>
				<Box
					className={clsx(cls.legendBox, cls.veryHigh)}
				>
					zeer hoog
				</Box>
				<Box
					className={clsx(cls.legendBox, cls.high)}
				>
					hoog
				</Box>
				<Box
					className={clsx(cls.legendBox, cls.middle)}
				>
					middel
				</Box>
				<Box
					className={clsx(cls.legendBox, cls.low)}
				>
					laag
				</Box>
			</Box>
			<Graph
				classes={classes}
				{...other}
			/>
		</Box>;
	};