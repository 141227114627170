import {ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import { makeStyles, Theme } from '@material-ui/core/styles';
import { SvgIconComponent } from '@material-ui/icons';
import React, {Dispatch, FC, SetStateAction, useMemo} from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";

export const useAppDrawerItemStyles = makeStyles((theme: Theme) =>
	({
		root: {
			color: theme.palette.primary.darkest,
			'&$focusVisible': {
				backgroundColor: theme.palette.primary.darkest,
				color: theme.palette.primary.darkestContrastText,
				'& $icon': {
					color: theme.palette.primary.darkestContrastText,
				}
			},
			'&$selected, &$selected:hover': {
				backgroundColor: theme.palette.primary.darkest,
				color: theme.palette.primary.darkestContrastText,
				'& $icon': {
					color: theme.palette.primary.darkestContrastText,
				}
			},
		},
		focusVisible: {},
		selected: {},
		icon: {
			color: theme.palette.primary.darkest,
		},
		text: {
			textOverflow: 'ellipsis',
			overflow: 'hidden',
		},
	}),
);

interface AppDrawerItemProps
{
	to: string
	name: string
	selected: boolean
	disabled?: boolean
	navigateWithConfirmation?: boolean
	Icon: SvgIconComponent
	setConfirmDialogActions?: Dispatch<SetStateAction<{ onConfirm: () => void; onCancel: () => void }>>
	toggleConfirmDialog?: Dispatch<SetStateAction<boolean>>
}

export const AppDrawerItem: FC<AppDrawerItemProps> =
	({
		to,
		name,
		selected,
		disabled = false,
		navigateWithConfirmation = false,
		Icon,
		setConfirmDialogActions,
		toggleConfirmDialog
	}) =>
	{
		const history = useHistory();

		const classes = useAppDrawerItemStyles();

		const listItemClasses = useMemo(() => ({
			root: classes.root,
			selected: classes.selected,
			focusVisible: classes.focusVisible
		}), [classes.focusVisible, classes.root, classes.selected]);

		return <li>
			{
				setConfirmDialogActions && navigateWithConfirmation ?
					<ListItem
						classes={listItemClasses}
						button
						disabled={disabled}
						selected={selected}
						onClick={() => {
							if (navigateWithConfirmation && toggleConfirmDialog) {
								toggleConfirmDialog(true)
								setConfirmDialogActions({
									onConfirm() {
										history.push(to)
										toggleConfirmDialog(false)
									},
									onCancel() {
										toggleConfirmDialog(false)
									}
								})
							}
						}}
					>
						<ListItemIcon className={classes.icon}><Icon/></ListItemIcon>
						<ListItemText classes={{primary: classes.text}} primary={name}/>
					</ListItem>
					:
					<ListItem
						classes={listItemClasses}
						button
						disabled={disabled}
						selected={selected}
						component={Link}
						to={to}
					>
						<ListItemIcon className={classes.icon}><Icon/></ListItemIcon>
						<ListItemText classes={{primary: classes.text}} primary={name}/>
					</ListItem>
			}

		</li>;
	};