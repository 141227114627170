import { Entity } from '@intentic/yggdrasil-rotr';
import { TypographyProps } from '@material-ui/core';
import React from 'react';
import { getNameField } from '../../../entity/getNameField';
import { ValueView } from '../ValueView';

export interface EntityViewProps extends TypographyProps
{
	entity: Entity
}

export const EntityView = React.forwardRef<any, EntityViewProps>(
	({
		entity,
		...other
	}, ref) =>
	{
		return <ValueView
			value={entity.getFieldProperty(getNameField(entity.type()))}
			ref={ref}
			{...other}
		/>;
	});