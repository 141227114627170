import React, { FC, useMemo } from 'react';
import { DataObjectViewProps } from '../../DataObjectView';
import { TextTypeSpecification } from '@intentic/yggdrasil-rotr';
import { RichTextView } from './util/RichTextView';
import { DefaultView } from '../../DefaultView';

export const TextView: FC<DataObjectViewProps> = React.forwardRef<any, DataObjectViewProps>(
	({
		dataObject,
		...other
	}, ref) =>
	{
		const specificationData = useMemo(() => dataObject.specification.data as TextTypeSpecification, [dataObject.specification.data]);

		if (specificationData.isRichText)
		{
			return <RichTextView text={(dataObject.data.text as string | undefined) ?? ''} ref={ref} {...other} />;
		}
		else
		{
			return <DefaultView dataObject={dataObject} ref={ref} {...other} />;
		}
	});