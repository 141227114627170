import { MetadataService, ModelMemoizer } from '@intentic/yggdrasil-rotr';
import { EntityType } from '../../__generated__/ygg/model/modelClasses';

export function getAncestors(entityType: EntityType): EntityType[]
{
	const metadataService = (entityType as any)._metadataService as MetadataService;

	return getAncestorsInternal(
		new EntityType(
			metadataService,
			metadataService.getByMetaEntity(entityType.entity).entity,
			'metadata',
			new ModelMemoizer()
		)
	);
}

function getAncestorsInternal(entityType: EntityType): EntityType[]
{
	if (entityType.extends != null)
		return [entityType, ...getAncestorsInternal(entityType.extends)];
	else
		return [entityType];
}