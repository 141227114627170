import { makeStyles, Theme } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';

export const useCanvasTabsStyles = makeStyles((theme: Theme) =>
	({
		root: {
			overflow: 'initial',
		},
		container: {
			gap: theme.spacing(1),
			paddingInline: theme.spacing(1),
		},
		scrollable: {
			flexGrow: 0,
		},
		scrollButtons: {
			background: theme.palette.background.paper,
			opacity: 1,
			zIndex: 5,
			boxShadow: theme.shadows[1],
			// remove bottom shadow
			clipPath: 'inset(-5px -5px 0px -5px)',
			'&$disabled': {
				opacity: 1,
				color: alpha(theme.palette.primary.darkest, 0.5),
			},
			borderStartStartRadius: theme.shape.borderRadius,
			borderStartEndRadius: theme.shape.borderRadius,
		},
		disabled: {},
		tab: {
			position: 'relative',
			zIndex: 3,
			boxShadow: theme.shadows[1],
			minWidth: 'auto !important',
			background: theme.palette.background.paper,
			color: theme.palette.primary.darkest,
			'&$selected': {
				color: theme.palette.primary.dark,
			},
			borderStartStartRadius: theme.shape.borderRadius,
			borderStartEndRadius: theme.shape.borderRadius,
		},
		selected: {},
		indicator: {
			zIndex: 6,
		},
	})
);