import { BusinessCanvas, EntityType } from '../../../../__generated__/ygg/model/modelClasses';
import { useComputed } from '../../../../mobx/useComputed';
import { getInheritors } from '../../../../query/util/getInheritors';

export function useRisicoprofielenIDs(businessCanvasItem: BusinessCanvas, objectTypeDictionary: Record<string, EntityType>): Set<string>
export function useRisicoprofielenIDs(businessCanvasItem: BusinessCanvas | undefined, objectTypeDictionary: Record<string, EntityType>): Set<string> | undefined
export function useRisicoprofielenIDs(businessCanvasItem: BusinessCanvas | undefined, objectTypeDictionary: Record<string, EntityType>): Set<string> | undefined
{
	return useComputed(() => 
	{
		return getRisicoprofielenIDs(businessCanvasItem, objectTypeDictionary);
	}, [businessCanvasItem, objectTypeDictionary]);
}

export function getRisicoprofielenIDs(businessCanvasItem: BusinessCanvas, objectTypeDictionary: Record<string, EntityType>): Set<string>
export function getRisicoprofielenIDs(businessCanvasItem: BusinessCanvas | undefined, objectTypeDictionary: Record<string, EntityType>): Set<string> | undefined
export function getRisicoprofielenIDs(businessCanvasItem: BusinessCanvas | undefined, objectTypeDictionary: Record<string, EntityType>): Set<string> | undefined
{
	if (businessCanvasItem)
	{
		return new Set(businessCanvasItem.objectTypes!.flatMap(t => getInheritors(t))
			.map(type => objectTypeDictionary[type.id])
			.flatMap(objectType => objectType.risicoprofielen!.map(risicoprofiel => risicoprofiel.id)));
	}
}