/* eslint-disable @typescript-eslint/no-unused-vars */
import { MetadataService, BooleanType, ColorType, ColorTypeObject, CurrencyType, DateType, DateTimeType, EmailType, Entity, FileType, FunctionType, FunctionTypeObject, GraphNode, Json, JsonType, NamedBlob, NumberType, PercentageType, Period, PeriodType, PeriodTypeObject, PhoneType, SignatureType, SignatureTypeObject, TextType, ZonedDateTime, getFetchTreeOfRelation, isGraphNode, getFieldValue, getRelatedPlural, getRelatedSingular, ConcreteType, createProps, QueryFactory, toTypedEntity, ModelFetchContext, ModelMemoizer, AbstractObjEntity } from "@intentic/yggdrasil-rotr";
import { Decimal } from "decimal.js";
import { computed, makeObservable, observable, override } from "mobx";
export type ObjEntityProps = ConcreteType<typeof ObjEntity.PROPS>;
export type EntityTypeProps = ConcreteType<typeof EntityType.PROPS>;
export type EntityFieldProps = ConcreteType<typeof EntityField.PROPS>;
export type RelationshipTypeProps = ConcreteType<typeof RelationshipType.PROPS>;
export type UserProps = ConcreteType<typeof User.PROPS>;
export type DistributiekanalenProps = ConcreteType<typeof Distributiekanalen.PROPS>;
export type KlantenProps = ConcreteType<typeof Klanten.PROPS>;
export type ElectronicaProps = ConcreteType<typeof Electronica.PROPS>;
export type InventarisProps = ConcreteType<typeof Inventaris.PROPS>;
export type AanhangerProps = ConcreteType<typeof Aanhanger.PROPS>;
export type TegoedenOpBankgirorekeningenProps = ConcreteType<typeof TegoedenOpBankgirorekeningen.PROPS>;
export type AutomobielenMetBestuurderProps = ConcreteType<typeof AutomobielenMetBestuurder.PROPS>;
export type VliegtuigenProps = ConcreteType<typeof Vliegtuigen.PROPS>;
export type AutoProps = ConcreteType<typeof Auto.PROPS>;
export type BouwclaimsProps = ConcreteType<typeof Bouwclaims.PROPS>;
export type AbstractGebouwenProps = ConcreteType<typeof AbstractGebouwen.PROPS>;
export type HuurdersInvesteringenProps = ConcreteType<typeof HuurdersInvesteringen.PROPS>;
export type VastgoedBeleggingenProps = ConcreteType<typeof VastgoedBeleggingen.PROPS>;
export type ContractenProps = ConcreteType<typeof Contracten.PROPS>;
export type WerkmaterieelProps = ConcreteType<typeof Werkmaterieel.PROPS>;
export type BusProps = ConcreteType<typeof Bus.PROPS>;
export type EffectenProps = ConcreteType<typeof Effecten.PROPS>;
export type GebouwenProps = ConcreteType<typeof Gebouwen.PROPS>;
export type VrachtwagenProps = ConcreteType<typeof Vrachtwagen.PROPS>;
export type MotorProps = ConcreteType<typeof Motor.PROPS>;
export type SchepenProps = ConcreteType<typeof Schepen.PROPS>;
export type KasmiddelenProps = ConcreteType<typeof Kasmiddelen.PROPS>;
export type BromfietsProps = ConcreteType<typeof Bromfiets.PROPS>;
export type FietsProps = ConcreteType<typeof Fiets.PROPS>;
export type TractorProps = ConcreteType<typeof Tractor.PROPS>;
export type PerceelProps = ConcreteType<typeof Perceel.PROPS>;
export type GroepsvermogenProps = ConcreteType<typeof Groepsvermogen.PROPS>;
export type SoortElectronischApparatuurProps = ConcreteType<typeof SoortElectronischApparatuur.PROPS>;
export type SoortFietsProps = ConcreteType<typeof SoortFiets.PROPS>;
export type GebruikWerkmaterieelProps = ConcreteType<typeof GebruikWerkmaterieel.PROPS>;
export type DeelnemingenProps = ConcreteType<typeof Deelnemingen.PROPS>;
export type DGAProps = ConcreteType<typeof DGA.PROPS>;
export type ManagementProps = ConcreteType<typeof Management.PROPS>;
export type KredietenProps = ConcreteType<typeof Kredieten.PROPS>;
export type LeveranciersProps = ConcreteType<typeof Leveranciers.PROPS>;
export type PersoneelProps = ConcreteType<typeof Personeel.PROPS>;
export type VoorraadMeerjarenPlantopstandProps = ConcreteType<typeof VoorraadMeerjarenPlantopstand.PROPS>;
export type VoorradenProps = ConcreteType<typeof Voorraden.PROPS>;
export type VoorraadEmballageProps = ConcreteType<typeof VoorraadEmballage.PROPS>;
export type OndersteunendeProcessenProps = ConcreteType<typeof OndersteunendeProcessen.PROPS>;
export type KernprocessenProps = ConcreteType<typeof Kernprocessen.PROPS>;
export type VoorraadLevendeHaveProps = ConcreteType<typeof VoorraadLevendeHave.PROPS>;
export type VoorraadVastgoedProps = ConcreteType<typeof VoorraadVastgoed.PROPS>;
export type GoederenProps = ConcreteType<typeof Goederen.PROPS>;
export type SoortenOogstproductenProps = ConcreteType<typeof SoortenOogstproducten.PROPS>;
export type OndersteunendeProcessenMasterdataProps = ConcreteType<typeof OndersteunendeProcessenMasterdata.PROPS>;
export type KernprocessenMasterdataProps = ConcreteType<typeof KernprocessenMasterdata.PROPS>;
export type SoortenLevendeHaveProps = ConcreteType<typeof SoortenLevendeHave.PROPS>;
export type PropositieProps = ConcreteType<typeof Propositie.PROPS>;
export type RelatiebeheerProps = ConcreteType<typeof Relatiebeheer.PROPS>;
export type OfferteProps = ConcreteType<typeof Offerte.PROPS>;
export type OfferteBeoordelingProps = ConcreteType<typeof OfferteBeoordeling.PROPS>;
export type BetaaltermijnProps = ConcreteType<typeof Betaaltermijn.PROPS>;
export type MaatschappijProps = ConcreteType<typeof Maatschappij.PROPS>;
export type ProductBemiddelingDossierProps = ConcreteType<typeof ProductBemiddelingDossier.PROPS>;
export type AcceptatiedeelvragenProps = ConcreteType<typeof Acceptatiedeelvragen.PROPS>;
export type AcceptatievragenDefinitieProps = ConcreteType<typeof AcceptatievragenDefinitie.PROPS>;
export type AcceptatiedeelvraagRelevantieProps = ConcreteType<typeof AcceptatiedeelvraagRelevantie.PROPS>;
export type BrancheBeoordelingProps = ConcreteType<typeof BrancheBeoordeling.PROPS>;
export type BrancheProps = ConcreteType<typeof Branche.PROPS>;
export type DekkingsEntiteitProps = ConcreteType<typeof DekkingsEntiteit.PROPS>;
export type DekkingsTypeProps = ConcreteType<typeof DekkingsType.PROPS>;
export type ClausuleBeoordelingProps = ConcreteType<typeof ClausuleBeoordeling.PROPS>;
export type ClausuleProps = ConcreteType<typeof Clausule.PROPS>;
export type ClausuleRelevantieProps = ConcreteType<typeof ClausuleRelevantie.PROPS>;
export type ClausuleRelevantieAcceptatiedeelvraagProps = ConcreteType<typeof ClausuleRelevantieAcceptatiedeelvraag.PROPS>;
export type ClausuleRelevantieBrancheProps = ConcreteType<typeof ClausuleRelevantieBranche.PROPS>;
export type ClausuleRelevantieRBHProps = ConcreteType<typeof ClausuleRelevantieRBH.PROPS>;
export type ControleBeoordelingProps = ConcreteType<typeof ControleBeoordeling.PROPS>;
export type BeoordelingsklasseProps = ConcreteType<typeof Beoordelingsklasse.PROPS>;
export type ActiepuntProps = ConcreteType<typeof Actiepunt.PROPS>;
export type MaatregelBeoordelingProps = ConcreteType<typeof MaatregelBeoordeling.PROPS>;
export type MaatregelCategorieProps = ConcreteType<typeof MaatregelCategorie.PROPS>;
export type MaatregelCategorieVKRIProps = ConcreteType<typeof MaatregelCategorieVKRI.PROPS>;
export type MaatregelSubcategorieProps = ConcreteType<typeof MaatregelSubcategorie.PROPS>;
export type MaatregelProps = ConcreteType<typeof Maatregel.PROPS>;
export type MaatregelIBBProps = ConcreteType<typeof MaatregelIBB.PROPS>;
export type MaatregelVKRIProps = ConcreteType<typeof MaatregelVKRI.PROPS>;
export type VKRIMaatregelRelevantieProps = ConcreteType<typeof VKRIMaatregelRelevantie.PROPS>;
export type VKRIMaatregelsetProps = ConcreteType<typeof VKRIMaatregelset.PROPS>;
export type RisicoAdviesDossierProps = ConcreteType<typeof RisicoAdviesDossier.PROPS>;
export type RisicocategorieProps = ConcreteType<typeof Risicocategorie.PROPS>;
export type RisicoprofielProps = ConcreteType<typeof Risicoprofiel.PROPS>;
export type RisicobeoordelingProps = ConcreteType<typeof Risicobeoordeling.PROPS>;
export type RisicoAdresRisicobeoordelingProps = ConcreteType<typeof RisicoAdresRisicobeoordeling.PROPS>;
export type GoederenRisicobeoordelingProps = ConcreteType<typeof GoederenRisicobeoordeling.PROPS>;
export type RisicoklasseProps = ConcreteType<typeof Risicoklasse.PROPS>;
export type RisicoklasseIBBProps = ConcreteType<typeof RisicoklasseIBB.PROPS>;
export type VKRIGoederenTypeProps = ConcreteType<typeof VKRIGoederenType.PROPS>;
export type RisicostrategieProps = ConcreteType<typeof Risicostrategie.PROPS>;
export type TriggerProps = ConcreteType<typeof Trigger.PROPS>;
export type AutomationProps = ConcreteType<typeof Automation.PROPS>;
export type AutomationResultProps = ConcreteType<typeof AutomationResult.PROPS>;
export type PackProps = ConcreteType<typeof Pack.PROPS>;
export type EnvironmentPackProps = ConcreteType<typeof EnvironmentPack.PROPS>;
export type ModulePackProps = ConcreteType<typeof ModulePack.PROPS>;
export type SystemPackProps = ConcreteType<typeof SystemPack.PROPS>;
export type SBIProps = ConcreteType<typeof SBI.PROPS>;
export type RBHProps = ConcreteType<typeof RBH.PROPS>;
export type RisicoAdresProps = ConcreteType<typeof RisicoAdres.PROPS>;
export type IBBImpactSchadeProps = ConcreteType<typeof IBBImpactSchade.PROPS>;
export type IBBBrandontwikkelsnelheidProps = ConcreteType<typeof IBBBrandontwikkelsnelheid.PROPS>;
export type IBBGebruikskarakteristiekGebouw1Props = ConcreteType<typeof IBBGebruikskarakteristiekGebouw1.PROPS>;
export type IBBGebruikskarakteristiekGebouw2Props = ConcreteType<typeof IBBGebruikskarakteristiekGebouw2.PROPS>;
export type IBBGebruikskarakteristiekPersoonlijkRuimtesProps = ConcreteType<typeof IBBGebruikskarakteristiekPersoonlijkRuimtes.PROPS>;
export type BusinessCanvasProps = ConcreteType<typeof BusinessCanvas.PROPS>;
export type ObjectBPProps = ConcreteType<typeof ObjectBP.PROPS>;
export type BusinessClientProps = ConcreteType<typeof BusinessClient.PROPS>;
export type KVKRelationProps = ConcreteType<typeof KVKRelation.PROPS>;
export type OrganizationProps = ConcreteType<typeof Organization.PROPS>;
export type ConcernrelatiesProps = ConcreteType<typeof Concernrelaties.PROPS>;
export type GroupMemberProps = ConcreteType<typeof GroupMember.PROPS>;
export type PersonProps = ConcreteType<typeof Person.PROPS>;
export type DossierProps = ConcreteType<typeof Dossier.PROPS>;
export type ScopeProps = ConcreteType<typeof Scope.PROPS>;
export type AdviesStappenProps = ConcreteType<typeof AdviesStappen.PROPS>;
export type DossierStatusProps = ConcreteType<typeof DossierStatus.PROPS>;
export type PrijsAfspraakProps = ConcreteType<typeof PrijsAfspraak.PROPS>;
export type NacalculatieProps = ConcreteType<typeof Nacalculatie.PROPS>;
export type NietFactureerbaarProps = ConcreteType<typeof NietFactureerbaar.PROPS>;
export type VastePrijsProps = ConcreteType<typeof VastePrijs.PROPS>;
export type DatastoreProps = ConcreteType<typeof Datastore.PROPS>;
export type PolisProps = ConcreteType<typeof Polis.PROPS>;
export type PakketProps = ConcreteType<typeof Pakket.PROPS>;
export type DeponeringenProps = ConcreteType<typeof Deponeringen.PROPS>;
export type BalansProps = ConcreteType<typeof Balans.PROPS>;
export type VerliesEnWinstrekeningProps = ConcreteType<typeof VerliesEnWinstrekening.PROPS>;
export type KengetallenProps = ConcreteType<typeof Kengetallen.PROPS>;
export abstract class ObjEntity extends AbstractObjEntity {
    public static ID = "ec451672-48a2-4022-b1b1-221688ed7b47";
    public static PROPS = createProps({
        id: {
            type: "Field",
            id: "23949128-6f6c-4940-b266-e4e2b13e9438",
            required: true
        },
        pack: {
            type: "RelationshipType",
            id: "8f42b5db-6e5c-4775-9a06-e647d4d45771",
            direction: "child",
            multiplicity: "One",
            required: true
        }
    });
    public readonly _props: ObjEntityProps;
    constructor(protected metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(entity, metadataService, modelMemoizer, fetchContext);
        makeObservable<ObjEntity>(this, {
            _props: observable,
            id: computed,
            pack: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get type(): EntityType {
        return toTypedEntity(this._metadataService.getById(this.entity.type().id).entity, this._metadataService, "metadata", this._modelMemoizer);
    }
    get id(): string {
        return TextType.getRawText(this._props.id)!;
    }
    get pack(): Pack | undefined {
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        if (packFetchContext !== undefined) {
            return toTypedEntity(this._props.pack, this._metadataService, packFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class AcceptatiedeelvraagRelevantie extends ObjEntity {
    public static ID = "547a5e69-630b-44b7-b1bf-316d5ecb7275";
    public static PROPS = createProps({
        ...ObjEntity.PROPS,
        vraag: {
            type: "Field",
            id: "c4e817d2-94f4-4079-b745-959dfdfdfdaf",
            required: true
        },
        acceptatiedeelvraag: {
            type: "RelationshipType",
            id: "7b2ce394-4e48-4e38-9cf9-305ac6666142",
            direction: "child",
            multiplicity: "One",
            required: true
        },
        rbh: {
            type: "RelationshipType",
            id: "7873b14a-5128-4035-b9e6-33d92987a434",
            direction: "child",
            multiplicity: "One",
            required: false
        },
        branche: {
            type: "RelationshipType",
            id: "19187c6f-9e77-4302-bea0-95af9cbc7192",
            direction: "child",
            multiplicity: "One",
            required: true
        }
    });
    public readonly _props: AcceptatiedeelvraagRelevantieProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<AcceptatiedeelvraagRelevantie>(this, {
            _props: override,
            vraag: computed,
            acceptatiedeelvraag: computed,
            rbh: computed,
            branche: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const acceptatiedeelvraagFetchContext = getFetchTreeOfRelation(this._fetchContext, AcceptatiedeelvraagRelevantie.PROPS.acceptatiedeelvraag.id, AcceptatiedeelvraagRelevantie.PROPS.acceptatiedeelvraag.direction);
        const rbhFetchContext = getFetchTreeOfRelation(this._fetchContext, AcceptatiedeelvraagRelevantie.PROPS.rbh.id, AcceptatiedeelvraagRelevantie.PROPS.rbh.direction);
        const brancheFetchContext = getFetchTreeOfRelation(this._fetchContext, AcceptatiedeelvraagRelevantie.PROPS.branche.id, AcceptatiedeelvraagRelevantie.PROPS.branche.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatiedeelvraag() {
                try {
                    return getRelatedSingular(entity, acceptatiedeelvraagFetchContext, AcceptatiedeelvraagRelevantie.PROPS.acceptatiedeelvraag.id, AcceptatiedeelvraagRelevantie.PROPS.acceptatiedeelvraag.direction, AcceptatiedeelvraagRelevantie.PROPS.acceptatiedeelvraag.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get rbh() {
                try {
                    return getRelatedSingular(entity, rbhFetchContext, AcceptatiedeelvraagRelevantie.PROPS.rbh.id, AcceptatiedeelvraagRelevantie.PROPS.rbh.direction, AcceptatiedeelvraagRelevantie.PROPS.rbh.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get branche() {
                try {
                    return getRelatedSingular(entity, brancheFetchContext, AcceptatiedeelvraagRelevantie.PROPS.branche.id, AcceptatiedeelvraagRelevantie.PROPS.branche.direction, AcceptatiedeelvraagRelevantie.PROPS.branche.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get vraag() {
                try {
                    return getFieldValue(metadataService, entity, AcceptatiedeelvraagRelevantie.PROPS.vraag.id, AcceptatiedeelvraagRelevantie.PROPS.vraag.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get vraag(): Json {
        return JsonType.getRawJson(this._props.vraag)!;
    }
    get acceptatiedeelvraag(): EntityType | undefined {
        const acceptatiedeelvraagFetchContext = getFetchTreeOfRelation(this._fetchContext, AcceptatiedeelvraagRelevantie.PROPS.acceptatiedeelvraag.id, AcceptatiedeelvraagRelevantie.PROPS.acceptatiedeelvraag.direction);
        if (acceptatiedeelvraagFetchContext !== undefined) {
            return toTypedEntity(this._props.acceptatiedeelvraag, this._metadataService, acceptatiedeelvraagFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get rbh(): (RBH | null) | undefined {
        const rbhFetchContext = getFetchTreeOfRelation(this._fetchContext, AcceptatiedeelvraagRelevantie.PROPS.rbh.id, AcceptatiedeelvraagRelevantie.PROPS.rbh.direction);
        if (rbhFetchContext !== undefined) {
            return toTypedEntity(this._props.rbh, this._metadataService, rbhFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get branche(): Branche | undefined {
        const brancheFetchContext = getFetchTreeOfRelation(this._fetchContext, AcceptatiedeelvraagRelevantie.PROPS.branche.id, AcceptatiedeelvraagRelevantie.PROPS.branche.direction);
        if (brancheFetchContext !== undefined) {
            return toTypedEntity(this._props.branche, this._metadataService, brancheFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export abstract class Acceptatiedeelvragen extends ObjEntity {
    public static ID = "14c5edc4-db94-4819-9cf8-801a8027942c";
    public static PROPS = createProps({
        ...ObjEntity.PROPS,
        risicoAdviesDossier: {
            type: "RelationshipType",
            id: "845e7f5d-ac21-4a73-8ace-6a86791edce3",
            direction: "parent",
            multiplicity: "One",
            required: true
        },
        objectBP: {
            type: "RelationshipType",
            id: "36ee8e41-1c11-413f-b1d3-102dd5483dbd",
            direction: "parent",
            multiplicity: "One",
            required: false
        }
    });
    public readonly _props: AcceptatiedeelvragenProps;
    constructor(protected metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Acceptatiedeelvragen>(this, {
            _props: override,
            risicoAdviesDossier: computed,
            objectBP: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const risicoAdviesDossierFetchContext = getFetchTreeOfRelation(this._fetchContext, Acceptatiedeelvragen.PROPS.risicoAdviesDossier.id, Acceptatiedeelvragen.PROPS.risicoAdviesDossier.direction);
        const objectBPFetchContext = getFetchTreeOfRelation(this._fetchContext, Acceptatiedeelvragen.PROPS.objectBP.id, Acceptatiedeelvragen.PROPS.objectBP.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get risicoAdviesDossier() {
                try {
                    return getRelatedSingular(entity, risicoAdviesDossierFetchContext, Acceptatiedeelvragen.PROPS.risicoAdviesDossier.id, Acceptatiedeelvragen.PROPS.risicoAdviesDossier.direction, Acceptatiedeelvragen.PROPS.risicoAdviesDossier.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectBP() {
                try {
                    return getRelatedSingular(entity, objectBPFetchContext, Acceptatiedeelvragen.PROPS.objectBP.id, Acceptatiedeelvragen.PROPS.objectBP.direction, Acceptatiedeelvragen.PROPS.objectBP.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get risicoAdviesDossier(): RisicoAdviesDossier | undefined {
        const risicoAdviesDossierFetchContext = getFetchTreeOfRelation(this._fetchContext, Acceptatiedeelvragen.PROPS.risicoAdviesDossier.id, Acceptatiedeelvragen.PROPS.risicoAdviesDossier.direction);
        if (risicoAdviesDossierFetchContext !== undefined) {
            return toTypedEntity(this._props.risicoAdviesDossier, this._metadataService, risicoAdviesDossierFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get objectBP(): (ObjectBP | null) | undefined {
        const objectBPFetchContext = getFetchTreeOfRelation(this._fetchContext, Acceptatiedeelvragen.PROPS.objectBP.id, Acceptatiedeelvragen.PROPS.objectBP.direction);
        if (objectBPFetchContext !== undefined) {
            return toTypedEntity(this._props.objectBP, this._metadataService, objectBPFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class AcceptatievragenDefinitie extends ObjEntity {
    public static ID = "d1198a3d-d2cf-41d1-9076-d0999690250f";
    public static PROPS = createProps({
        ...ObjEntity.PROPS,
        naam: {
            type: "Field",
            id: "042578bd-0ff9-47b7-aa18-ef5994242b5a",
            required: true
        },
        risico: {
            type: "RelationshipType",
            id: "d369562b-5ce1-4e2e-a869-7a402b9d9251",
            direction: "child",
            multiplicity: "One",
            required: false
        },
        maatregel: {
            type: "RelationshipType",
            id: "b7684012-c1f5-4952-92fb-b8968d5dd8a6",
            direction: "child",
            multiplicity: "One",
            required: false
        },
        product: {
            type: "RelationshipType",
            id: "2715ca91-2af9-4635-a43c-6e0d5f30e283",
            direction: "child",
            multiplicity: "One",
            required: false
        },
        inventarisatie: {
            type: "RelationshipType",
            id: "ac324f88-2cf8-4524-bc30-f7cec7cb6ec7",
            direction: "child",
            multiplicity: "One",
            required: false
        },
        ObjectBPType: {
            type: "RelationshipType",
            id: "dbaa3e31-ebbd-4bf5-87fb-ba8f19be7d13",
            direction: "child",
            multiplicity: "Many",
            required: false
        },
        branches: {
            type: "RelationshipType",
            id: "d667664e-a2ed-4687-8733-717976b40e26",
            direction: "parent",
            multiplicity: "Many",
            required: false
        }
    });
    public readonly _props: AcceptatievragenDefinitieProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<AcceptatievragenDefinitie>(this, {
            _props: override,
            naam: computed,
            risico: computed,
            maatregel: computed,
            product: computed,
            inventarisatie: computed,
            ObjectBPType: computed,
            branches: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const risicoFetchContext = getFetchTreeOfRelation(this._fetchContext, AcceptatievragenDefinitie.PROPS.risico.id, AcceptatievragenDefinitie.PROPS.risico.direction);
        const maatregelFetchContext = getFetchTreeOfRelation(this._fetchContext, AcceptatievragenDefinitie.PROPS.maatregel.id, AcceptatievragenDefinitie.PROPS.maatregel.direction);
        const productFetchContext = getFetchTreeOfRelation(this._fetchContext, AcceptatievragenDefinitie.PROPS.product.id, AcceptatievragenDefinitie.PROPS.product.direction);
        const inventarisatieFetchContext = getFetchTreeOfRelation(this._fetchContext, AcceptatievragenDefinitie.PROPS.inventarisatie.id, AcceptatievragenDefinitie.PROPS.inventarisatie.direction);
        const ObjectBPTypeFetchContext = getFetchTreeOfRelation(this._fetchContext, AcceptatievragenDefinitie.PROPS.ObjectBPType.id, AcceptatievragenDefinitie.PROPS.ObjectBPType.direction);
        const branchesFetchContext = getFetchTreeOfRelation(this._fetchContext, AcceptatievragenDefinitie.PROPS.branches.id, AcceptatievragenDefinitie.PROPS.branches.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get risico() {
                try {
                    return getRelatedSingular(entity, risicoFetchContext, AcceptatievragenDefinitie.PROPS.risico.id, AcceptatievragenDefinitie.PROPS.risico.direction, AcceptatievragenDefinitie.PROPS.risico.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get maatregel() {
                try {
                    return getRelatedSingular(entity, maatregelFetchContext, AcceptatievragenDefinitie.PROPS.maatregel.id, AcceptatievragenDefinitie.PROPS.maatregel.direction, AcceptatievragenDefinitie.PROPS.maatregel.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get product() {
                try {
                    return getRelatedSingular(entity, productFetchContext, AcceptatievragenDefinitie.PROPS.product.id, AcceptatievragenDefinitie.PROPS.product.direction, AcceptatievragenDefinitie.PROPS.product.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get inventarisatie() {
                try {
                    return getRelatedSingular(entity, inventarisatieFetchContext, AcceptatievragenDefinitie.PROPS.inventarisatie.id, AcceptatievragenDefinitie.PROPS.inventarisatie.direction, AcceptatievragenDefinitie.PROPS.inventarisatie.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get ObjectBPType() {
                try {
                    return getRelatedPlural(entity, ObjectBPTypeFetchContext, AcceptatievragenDefinitie.PROPS.ObjectBPType.id, AcceptatievragenDefinitie.PROPS.ObjectBPType.direction, AcceptatievragenDefinitie.PROPS.ObjectBPType.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get branches() {
                try {
                    return getRelatedPlural(entity, branchesFetchContext, AcceptatievragenDefinitie.PROPS.branches.id, AcceptatievragenDefinitie.PROPS.branches.direction, AcceptatievragenDefinitie.PROPS.branches.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get naam() {
                try {
                    return getFieldValue(metadataService, entity, AcceptatievragenDefinitie.PROPS.naam.id, AcceptatievragenDefinitie.PROPS.naam.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get naam(): string {
        return TextType.getRawText(this._props.naam)!;
    }
    get risico(): (EntityType | null) | undefined {
        const risicoFetchContext = getFetchTreeOfRelation(this._fetchContext, AcceptatievragenDefinitie.PROPS.risico.id, AcceptatievragenDefinitie.PROPS.risico.direction);
        if (risicoFetchContext !== undefined) {
            return toTypedEntity(this._props.risico, this._metadataService, risicoFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get maatregel(): (EntityType | null) | undefined {
        const maatregelFetchContext = getFetchTreeOfRelation(this._fetchContext, AcceptatievragenDefinitie.PROPS.maatregel.id, AcceptatievragenDefinitie.PROPS.maatregel.direction);
        if (maatregelFetchContext !== undefined) {
            return toTypedEntity(this._props.maatregel, this._metadataService, maatregelFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get product(): (EntityType | null) | undefined {
        const productFetchContext = getFetchTreeOfRelation(this._fetchContext, AcceptatievragenDefinitie.PROPS.product.id, AcceptatievragenDefinitie.PROPS.product.direction);
        if (productFetchContext !== undefined) {
            return toTypedEntity(this._props.product, this._metadataService, productFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get inventarisatie(): (EntityType | null) | undefined {
        const inventarisatieFetchContext = getFetchTreeOfRelation(this._fetchContext, AcceptatievragenDefinitie.PROPS.inventarisatie.id, AcceptatievragenDefinitie.PROPS.inventarisatie.direction);
        if (inventarisatieFetchContext !== undefined) {
            return toTypedEntity(this._props.inventarisatie, this._metadataService, inventarisatieFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get ObjectBPType(): EntityType[] | undefined {
        const ObjectBPTypeFetchContext = getFetchTreeOfRelation(this._fetchContext, AcceptatievragenDefinitie.PROPS.ObjectBPType.id, AcceptatievragenDefinitie.PROPS.ObjectBPType.direction);
        if (ObjectBPTypeFetchContext !== undefined) {
            return toTypedEntity(this._props.ObjectBPType, this._metadataService, ObjectBPTypeFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get branches(): Branche[] | undefined {
        const branchesFetchContext = getFetchTreeOfRelation(this._fetchContext, AcceptatievragenDefinitie.PROPS.branches.id, AcceptatievragenDefinitie.PROPS.branches.direction);
        if (branchesFetchContext !== undefined) {
            return toTypedEntity(this._props.branches, this._metadataService, branchesFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class Actiepunt extends ObjEntity {
    public static ID = "ac226441-13f7-45d3-a57d-d318d28d5ed8";
    public static PROPS = createProps({
        ...ObjEntity.PROPS,
        beoordeling: {
            type: "RelationshipType",
            id: "21496ff1-b12c-434e-8b7f-48fdf6e6c25b",
            direction: "parent",
            multiplicity: "One",
            required: false
        }
    });
    public readonly _props: ActiepuntProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Actiepunt>(this, {
            _props: override,
            beoordeling: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const beoordelingFetchContext = getFetchTreeOfRelation(this._fetchContext, Actiepunt.PROPS.beoordeling.id, Actiepunt.PROPS.beoordeling.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get beoordeling() {
                try {
                    return getRelatedSingular(entity, beoordelingFetchContext, Actiepunt.PROPS.beoordeling.id, Actiepunt.PROPS.beoordeling.direction, Actiepunt.PROPS.beoordeling.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get beoordeling(): (ControleBeoordeling | null) | undefined {
        const beoordelingFetchContext = getFetchTreeOfRelation(this._fetchContext, Actiepunt.PROPS.beoordeling.id, Actiepunt.PROPS.beoordeling.direction);
        if (beoordelingFetchContext !== undefined) {
            return toTypedEntity(this._props.beoordeling, this._metadataService, beoordelingFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class Automation extends ObjEntity {
    public static ID = "678bcf76-36b8-4848-8b2c-842b9633ada2";
    public static PROPS = createProps({
        ...ObjEntity.PROPS,
        actief: {
            type: "Field",
            id: "f10e387c-ce3a-406c-bf0b-c38a97413c79",
            required: true
        },
        trigger: {
            type: "RelationshipType",
            id: "a7b219ab-dd2f-4fb7-bac3-9721583ac145",
            direction: "child",
            multiplicity: "One",
            required: true
        },
        resultedIn: {
            type: "RelationshipType",
            id: "678bcf76-36b8-4848-8b2c-842b9633ada2",
            direction: "parent",
            multiplicity: "Many",
            required: false
        }
    });
    public readonly _props: AutomationProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Automation>(this, {
            _props: override,
            actief: computed,
            trigger: computed,
            resultedIn: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const triggerFetchContext = getFetchTreeOfRelation(this._fetchContext, Automation.PROPS.trigger.id, Automation.PROPS.trigger.direction);
        const resultedInFetchContext = getFetchTreeOfRelation(this._fetchContext, Automation.PROPS.resultedIn.id, Automation.PROPS.resultedIn.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get trigger() {
                try {
                    return getRelatedSingular(entity, triggerFetchContext, Automation.PROPS.trigger.id, Automation.PROPS.trigger.direction, Automation.PROPS.trigger.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get resultedIn() {
                try {
                    return getRelatedPlural(entity, resultedInFetchContext, Automation.PROPS.resultedIn.id, Automation.PROPS.resultedIn.direction, Automation.PROPS.resultedIn.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get actief() {
                try {
                    return getFieldValue(metadataService, entity, Automation.PROPS.actief.id, Automation.PROPS.actief.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get actief(): boolean {
        return BooleanType.getRawBoolean(this._props.actief)!;
    }
    get trigger(): Trigger | undefined {
        const triggerFetchContext = getFetchTreeOfRelation(this._fetchContext, Automation.PROPS.trigger.id, Automation.PROPS.trigger.direction);
        if (triggerFetchContext !== undefined) {
            return toTypedEntity(this._props.trigger, this._metadataService, triggerFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get resultedIn(): AutomationResult[] | undefined {
        const resultedInFetchContext = getFetchTreeOfRelation(this._fetchContext, Automation.PROPS.resultedIn.id, Automation.PROPS.resultedIn.direction);
        if (resultedInFetchContext !== undefined) {
            return toTypedEntity(this._props.resultedIn, this._metadataService, resultedInFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class AutomationResult extends ObjEntity {
    public static ID = "897a6ba5-592f-420f-a775-cbc1e20fb3b2";
    public static PROPS = createProps({
        ...ObjEntity.PROPS,
        invokingCommit: {
            type: "Field",
            id: "731174ae-84f6-4db0-9498-b1fe8e3b4751",
            required: true
        },
        isResultOf: {
            type: "RelationshipType",
            id: "678bcf76-36b8-4848-8b2c-842b9633ada2",
            direction: "child",
            multiplicity: "One",
            required: true
        }
    });
    public readonly _props: AutomationResultProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<AutomationResult>(this, {
            _props: override,
            invokingCommit: computed,
            isResultOf: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const isResultOfFetchContext = getFetchTreeOfRelation(this._fetchContext, AutomationResult.PROPS.isResultOf.id, AutomationResult.PROPS.isResultOf.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get isResultOf() {
                try {
                    return getRelatedSingular(entity, isResultOfFetchContext, AutomationResult.PROPS.isResultOf.id, AutomationResult.PROPS.isResultOf.direction, AutomationResult.PROPS.isResultOf.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get invokingCommit() {
                try {
                    return getFieldValue(metadataService, entity, AutomationResult.PROPS.invokingCommit.id, AutomationResult.PROPS.invokingCommit.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get invokingCommit(): string {
        return TextType.getRawText(this._props.invokingCommit)!;
    }
    get isResultOf(): Automation | undefined {
        const isResultOfFetchContext = getFetchTreeOfRelation(this._fetchContext, AutomationResult.PROPS.isResultOf.id, AutomationResult.PROPS.isResultOf.direction);
        if (isResultOfFetchContext !== undefined) {
            return toTypedEntity(this._props.isResultOf, this._metadataService, isResultOfFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class Branche extends ObjEntity {
    public static ID = "c4636b3b-2680-42d6-9d53-ce4dbf1c5070";
    public static PROPS = createProps({
        ...ObjEntity.PROPS,
        name: {
            type: "Field",
            id: "1ad5c1f8-9bfc-4fcc-87dd-9de67754a72e",
            required: true
        },
        inactief: {
            type: "Field",
            id: "fed77bed-59dc-411b-b176-ff9cc032f0f9",
            required: true
        },
        objectSpecifiek: {
            type: "Field",
            id: "7a504b90-05b6-4e54-8d5d-68b16c6f5f8e",
            required: true
        },
        zakelijk: {
            type: "Field",
            id: "06575262-32d2-4e28-b4f2-d0084899b68f",
            required: true
        },
        particulier: {
            type: "Field",
            id: "4aa1c2ac-35df-4a93-8d71-23d2d1ea1e01",
            required: true
        },
        accceptatievragen: {
            type: "RelationshipType",
            id: "d667664e-a2ed-4687-8733-717976b40e26",
            direction: "child",
            multiplicity: "Many",
            required: false
        },
        dekkingsentiteiten: {
            type: "RelationshipType",
            id: "b3d2e453-3bbe-4f05-80f1-b262a51b3f51",
            direction: "child",
            multiplicity: "Many",
            required: false
        },
        objectBPTypes: {
            type: "RelationshipType",
            id: "f81a3cd6-9eef-4430-aa0a-492ef0f1b3c6",
            direction: "child",
            multiplicity: "Many",
            required: false
        },
        polissen: {
            type: "RelationshipType",
            id: "349d1ffc-a286-4d13-a6cd-225b282acb1e",
            direction: "child",
            multiplicity: "Many",
            required: false
        },
        offertes: {
            type: "RelationshipType",
            id: "e79f5e2b-2a03-4c7f-919c-f942149d0db0",
            direction: "parent",
            multiplicity: "Many",
            required: false
        },
        offerteBeoordelingen: {
            type: "RelationshipType",
            id: "a4105de0-133e-4561-ad0b-554f6dcda96a",
            direction: "parent",
            multiplicity: "Many",
            required: false
        },
        productbemiddlingsdossiers: {
            type: "RelationshipType",
            id: "d34f401a-d75f-4bac-84d3-f653a695f6cc",
            direction: "parent",
            multiplicity: "Many",
            required: false
        },
        acceptiedeelvragenRelevanties: {
            type: "RelationshipType",
            id: "19187c6f-9e77-4302-bea0-95af9cbc7192",
            direction: "parent",
            multiplicity: "Many",
            required: false
        },
        beoordelingen: {
            type: "RelationshipType",
            id: "a7b04e3e-f932-4c39-a6e6-787e4248da47",
            direction: "parent",
            multiplicity: "Many",
            required: false
        },
        clausuleRelevanties: {
            type: "RelationshipType",
            id: "40d96ea7-49c7-4dea-9b8a-8fe93f4bacce",
            direction: "parent",
            multiplicity: "Many",
            required: false
        }
    });
    public readonly _props: BrancheProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Branche>(this, {
            _props: override,
            name: computed,
            inactief: computed,
            objectSpecifiek: computed,
            zakelijk: computed,
            particulier: computed,
            accceptatievragen: computed,
            dekkingsentiteiten: computed,
            objectBPTypes: computed,
            polissen: computed,
            offertes: computed,
            offerteBeoordelingen: computed,
            productbemiddlingsdossiers: computed,
            acceptiedeelvragenRelevanties: computed,
            beoordelingen: computed,
            clausuleRelevanties: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const accceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, Branche.PROPS.accceptatievragen.id, Branche.PROPS.accceptatievragen.direction);
        const dekkingsentiteitenFetchContext = getFetchTreeOfRelation(this._fetchContext, Branche.PROPS.dekkingsentiteiten.id, Branche.PROPS.dekkingsentiteiten.direction);
        const objectBPTypesFetchContext = getFetchTreeOfRelation(this._fetchContext, Branche.PROPS.objectBPTypes.id, Branche.PROPS.objectBPTypes.direction);
        const polissenFetchContext = getFetchTreeOfRelation(this._fetchContext, Branche.PROPS.polissen.id, Branche.PROPS.polissen.direction);
        const offertesFetchContext = getFetchTreeOfRelation(this._fetchContext, Branche.PROPS.offertes.id, Branche.PROPS.offertes.direction);
        const offerteBeoordelingenFetchContext = getFetchTreeOfRelation(this._fetchContext, Branche.PROPS.offerteBeoordelingen.id, Branche.PROPS.offerteBeoordelingen.direction);
        const productbemiddlingsdossiersFetchContext = getFetchTreeOfRelation(this._fetchContext, Branche.PROPS.productbemiddlingsdossiers.id, Branche.PROPS.productbemiddlingsdossiers.direction);
        const acceptiedeelvragenRelevantiesFetchContext = getFetchTreeOfRelation(this._fetchContext, Branche.PROPS.acceptiedeelvragenRelevanties.id, Branche.PROPS.acceptiedeelvragenRelevanties.direction);
        const beoordelingenFetchContext = getFetchTreeOfRelation(this._fetchContext, Branche.PROPS.beoordelingen.id, Branche.PROPS.beoordelingen.direction);
        const clausuleRelevantiesFetchContext = getFetchTreeOfRelation(this._fetchContext, Branche.PROPS.clausuleRelevanties.id, Branche.PROPS.clausuleRelevanties.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get accceptatievragen() {
                try {
                    return getRelatedPlural(entity, accceptatievragenFetchContext, Branche.PROPS.accceptatievragen.id, Branche.PROPS.accceptatievragen.direction, Branche.PROPS.accceptatievragen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get dekkingsentiteiten() {
                try {
                    return getRelatedPlural(entity, dekkingsentiteitenFetchContext, Branche.PROPS.dekkingsentiteiten.id, Branche.PROPS.dekkingsentiteiten.direction, Branche.PROPS.dekkingsentiteiten.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectBPTypes() {
                try {
                    return getRelatedPlural(entity, objectBPTypesFetchContext, Branche.PROPS.objectBPTypes.id, Branche.PROPS.objectBPTypes.direction, Branche.PROPS.objectBPTypes.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get polissen() {
                try {
                    return getRelatedPlural(entity, polissenFetchContext, Branche.PROPS.polissen.id, Branche.PROPS.polissen.direction, Branche.PROPS.polissen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get offertes() {
                try {
                    return getRelatedPlural(entity, offertesFetchContext, Branche.PROPS.offertes.id, Branche.PROPS.offertes.direction, Branche.PROPS.offertes.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get offerteBeoordelingen() {
                try {
                    return getRelatedPlural(entity, offerteBeoordelingenFetchContext, Branche.PROPS.offerteBeoordelingen.id, Branche.PROPS.offerteBeoordelingen.direction, Branche.PROPS.offerteBeoordelingen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get productbemiddlingsdossiers() {
                try {
                    return getRelatedPlural(entity, productbemiddlingsdossiersFetchContext, Branche.PROPS.productbemiddlingsdossiers.id, Branche.PROPS.productbemiddlingsdossiers.direction, Branche.PROPS.productbemiddlingsdossiers.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptiedeelvragenRelevanties() {
                try {
                    return getRelatedPlural(entity, acceptiedeelvragenRelevantiesFetchContext, Branche.PROPS.acceptiedeelvragenRelevanties.id, Branche.PROPS.acceptiedeelvragenRelevanties.direction, Branche.PROPS.acceptiedeelvragenRelevanties.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get beoordelingen() {
                try {
                    return getRelatedPlural(entity, beoordelingenFetchContext, Branche.PROPS.beoordelingen.id, Branche.PROPS.beoordelingen.direction, Branche.PROPS.beoordelingen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get clausuleRelevanties() {
                try {
                    return getRelatedPlural(entity, clausuleRelevantiesFetchContext, Branche.PROPS.clausuleRelevanties.id, Branche.PROPS.clausuleRelevanties.direction, Branche.PROPS.clausuleRelevanties.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get name() {
                try {
                    return getFieldValue(metadataService, entity, Branche.PROPS.name.id, Branche.PROPS.name.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get inactief() {
                try {
                    return getFieldValue(metadataService, entity, Branche.PROPS.inactief.id, Branche.PROPS.inactief.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectSpecifiek() {
                try {
                    return getFieldValue(metadataService, entity, Branche.PROPS.objectSpecifiek.id, Branche.PROPS.objectSpecifiek.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get zakelijk() {
                try {
                    return getFieldValue(metadataService, entity, Branche.PROPS.zakelijk.id, Branche.PROPS.zakelijk.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get particulier() {
                try {
                    return getFieldValue(metadataService, entity, Branche.PROPS.particulier.id, Branche.PROPS.particulier.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get name(): string {
        return TextType.getRawText(this._props.name)!;
    }
    get inactief(): boolean {
        return BooleanType.getRawBoolean(this._props.inactief)!;
    }
    get objectSpecifiek(): boolean {
        return BooleanType.getRawBoolean(this._props.objectSpecifiek)!;
    }
    get zakelijk(): boolean {
        return BooleanType.getRawBoolean(this._props.zakelijk)!;
    }
    get particulier(): boolean {
        return BooleanType.getRawBoolean(this._props.particulier)!;
    }
    get accceptatievragen(): AcceptatievragenDefinitie[] | undefined {
        const accceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, Branche.PROPS.accceptatievragen.id, Branche.PROPS.accceptatievragen.direction);
        if (accceptatievragenFetchContext !== undefined) {
            return toTypedEntity(this._props.accceptatievragen, this._metadataService, accceptatievragenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get dekkingsentiteiten(): DekkingsEntiteit[] | undefined {
        const dekkingsentiteitenFetchContext = getFetchTreeOfRelation(this._fetchContext, Branche.PROPS.dekkingsentiteiten.id, Branche.PROPS.dekkingsentiteiten.direction);
        if (dekkingsentiteitenFetchContext !== undefined) {
            return toTypedEntity(this._props.dekkingsentiteiten, this._metadataService, dekkingsentiteitenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get objectBPTypes(): EntityType[] | undefined {
        const objectBPTypesFetchContext = getFetchTreeOfRelation(this._fetchContext, Branche.PROPS.objectBPTypes.id, Branche.PROPS.objectBPTypes.direction);
        if (objectBPTypesFetchContext !== undefined) {
            return toTypedEntity(this._props.objectBPTypes, this._metadataService, objectBPTypesFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get polissen(): Polis[] | undefined {
        const polissenFetchContext = getFetchTreeOfRelation(this._fetchContext, Branche.PROPS.polissen.id, Branche.PROPS.polissen.direction);
        if (polissenFetchContext !== undefined) {
            return toTypedEntity(this._props.polissen, this._metadataService, polissenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get offertes(): Offerte[] | undefined {
        const offertesFetchContext = getFetchTreeOfRelation(this._fetchContext, Branche.PROPS.offertes.id, Branche.PROPS.offertes.direction);
        if (offertesFetchContext !== undefined) {
            return toTypedEntity(this._props.offertes, this._metadataService, offertesFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get offerteBeoordelingen(): OfferteBeoordeling[] | undefined {
        const offerteBeoordelingenFetchContext = getFetchTreeOfRelation(this._fetchContext, Branche.PROPS.offerteBeoordelingen.id, Branche.PROPS.offerteBeoordelingen.direction);
        if (offerteBeoordelingenFetchContext !== undefined) {
            return toTypedEntity(this._props.offerteBeoordelingen, this._metadataService, offerteBeoordelingenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get productbemiddlingsdossiers(): ProductBemiddelingDossier[] | undefined {
        const productbemiddlingsdossiersFetchContext = getFetchTreeOfRelation(this._fetchContext, Branche.PROPS.productbemiddlingsdossiers.id, Branche.PROPS.productbemiddlingsdossiers.direction);
        if (productbemiddlingsdossiersFetchContext !== undefined) {
            return toTypedEntity(this._props.productbemiddlingsdossiers, this._metadataService, productbemiddlingsdossiersFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get acceptiedeelvragenRelevanties(): AcceptatiedeelvraagRelevantie[] | undefined {
        const acceptiedeelvragenRelevantiesFetchContext = getFetchTreeOfRelation(this._fetchContext, Branche.PROPS.acceptiedeelvragenRelevanties.id, Branche.PROPS.acceptiedeelvragenRelevanties.direction);
        if (acceptiedeelvragenRelevantiesFetchContext !== undefined) {
            return toTypedEntity(this._props.acceptiedeelvragenRelevanties, this._metadataService, acceptiedeelvragenRelevantiesFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get beoordelingen(): BrancheBeoordeling[] | undefined {
        const beoordelingenFetchContext = getFetchTreeOfRelation(this._fetchContext, Branche.PROPS.beoordelingen.id, Branche.PROPS.beoordelingen.direction);
        if (beoordelingenFetchContext !== undefined) {
            return toTypedEntity(this._props.beoordelingen, this._metadataService, beoordelingenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get clausuleRelevanties(): ClausuleRelevantieBranche[] | undefined {
        const clausuleRelevantiesFetchContext = getFetchTreeOfRelation(this._fetchContext, Branche.PROPS.clausuleRelevanties.id, Branche.PROPS.clausuleRelevanties.direction);
        if (clausuleRelevantiesFetchContext !== undefined) {
            return toTypedEntity(this._props.clausuleRelevanties, this._metadataService, clausuleRelevantiesFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class BusinessCanvas extends ObjEntity {
    public static ID = "2ded18a4-5976-4b5b-a8e5-52147051acd0";
    public static PROPS = createProps({
        ...ObjEntity.PROPS,
        name: {
            type: "Field",
            id: "bb4051a7-bc9a-4f57-b31e-5606754d627a",
            required: true
        },
        objectTypes: {
            type: "RelationshipType",
            id: "ff931078-acda-4c8f-82ec-b00e2c54450b",
            direction: "child",
            multiplicity: "Many",
            required: false
        }
    });
    public readonly _props: BusinessCanvasProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<BusinessCanvas>(this, {
            _props: override,
            name: computed,
            objectTypes: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const objectTypesFetchContext = getFetchTreeOfRelation(this._fetchContext, BusinessCanvas.PROPS.objectTypes.id, BusinessCanvas.PROPS.objectTypes.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectTypes() {
                try {
                    return getRelatedPlural(entity, objectTypesFetchContext, BusinessCanvas.PROPS.objectTypes.id, BusinessCanvas.PROPS.objectTypes.direction, BusinessCanvas.PROPS.objectTypes.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get name() {
                try {
                    return getFieldValue(metadataService, entity, BusinessCanvas.PROPS.name.id, BusinessCanvas.PROPS.name.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get name(): string {
        return TextType.getRawText(this._props.name)!;
    }
    get objectTypes(): EntityType[] | undefined {
        const objectTypesFetchContext = getFetchTreeOfRelation(this._fetchContext, BusinessCanvas.PROPS.objectTypes.id, BusinessCanvas.PROPS.objectTypes.direction);
        if (objectTypesFetchContext !== undefined) {
            return toTypedEntity(this._props.objectTypes, this._metadataService, objectTypesFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class BusinessClient extends ObjEntity {
    public static ID = "5a14491a-d926-45d3-8115-c434390729af";
    public static PROPS = createProps({
        ...ObjEntity.PROPS,
        clientId: {
            type: "Field",
            id: "16310a81-e8fb-4c80-8223-6b9543ceb092",
            required: true
        },
        organization: {
            type: "RelationshipType",
            id: "1096f8a2-91f8-45a5-8ab6-6d8de865fd4a",
            direction: "child",
            multiplicity: "One",
            required: true
        },
        person: {
            type: "RelationshipType",
            id: "0d640fa2-67be-45db-9291-47f7ab1b89dc",
            direction: "child",
            multiplicity: "One",
            required: true
        },
        dossiers: {
            type: "RelationshipType",
            id: "5ca8ff55-1df0-4642-bbc3-6ec3f22a4759",
            direction: "child",
            multiplicity: "Many",
            required: false
        },
        polissen: {
            type: "RelationshipType",
            id: "d1b6a84f-5eec-4438-830c-94cb1706d3f4",
            direction: "child",
            multiplicity: "Many",
            required: false
        }
    });
    public readonly _props: BusinessClientProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<BusinessClient>(this, {
            _props: override,
            clientId: computed,
            organization: computed,
            person: computed,
            dossiers: computed,
            polissen: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, BusinessClient.PROPS.organization.id, BusinessClient.PROPS.organization.direction);
        const personFetchContext = getFetchTreeOfRelation(this._fetchContext, BusinessClient.PROPS.person.id, BusinessClient.PROPS.person.direction);
        const dossiersFetchContext = getFetchTreeOfRelation(this._fetchContext, BusinessClient.PROPS.dossiers.id, BusinessClient.PROPS.dossiers.direction);
        const polissenFetchContext = getFetchTreeOfRelation(this._fetchContext, BusinessClient.PROPS.polissen.id, BusinessClient.PROPS.polissen.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, BusinessClient.PROPS.organization.id, BusinessClient.PROPS.organization.direction, BusinessClient.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get person() {
                try {
                    return getRelatedSingular(entity, personFetchContext, BusinessClient.PROPS.person.id, BusinessClient.PROPS.person.direction, BusinessClient.PROPS.person.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get dossiers() {
                try {
                    return getRelatedPlural(entity, dossiersFetchContext, BusinessClient.PROPS.dossiers.id, BusinessClient.PROPS.dossiers.direction, BusinessClient.PROPS.dossiers.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get polissen() {
                try {
                    return getRelatedPlural(entity, polissenFetchContext, BusinessClient.PROPS.polissen.id, BusinessClient.PROPS.polissen.direction, BusinessClient.PROPS.polissen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get clientId() {
                try {
                    return getFieldValue(metadataService, entity, BusinessClient.PROPS.clientId.id, BusinessClient.PROPS.clientId.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get clientId(): string {
        return TextType.getRawText(this._props.clientId)!;
    }
    get organization(): Organization | undefined {
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, BusinessClient.PROPS.organization.id, BusinessClient.PROPS.organization.direction);
        if (organizationFetchContext !== undefined) {
            return toTypedEntity(this._props.organization, this._metadataService, organizationFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get person(): Person | undefined {
        const personFetchContext = getFetchTreeOfRelation(this._fetchContext, BusinessClient.PROPS.person.id, BusinessClient.PROPS.person.direction);
        if (personFetchContext !== undefined) {
            return toTypedEntity(this._props.person, this._metadataService, personFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get dossiers(): Dossier[] | undefined {
        const dossiersFetchContext = getFetchTreeOfRelation(this._fetchContext, BusinessClient.PROPS.dossiers.id, BusinessClient.PROPS.dossiers.direction);
        if (dossiersFetchContext !== undefined) {
            return toTypedEntity(this._props.dossiers, this._metadataService, dossiersFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get polissen(): Polis[] | undefined {
        const polissenFetchContext = getFetchTreeOfRelation(this._fetchContext, BusinessClient.PROPS.polissen.id, BusinessClient.PROPS.polissen.direction);
        if (polissenFetchContext !== undefined) {
            return toTypedEntity(this._props.polissen, this._metadataService, polissenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export abstract class ClausuleRelevantie extends ObjEntity {
    public static ID = "fa127b1a-084e-46a0-94e1-0a8bb891e19a";
    public static PROPS = createProps({
        ...ObjEntity.PROPS,
        clausules: {
            type: "RelationshipType",
            id: "2d28859b-37e0-414e-b75f-a34802422baa",
            direction: "child",
            multiplicity: "Many",
            required: true
        }
    });
    public readonly _props: ClausuleRelevantieProps;
    constructor(protected metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<ClausuleRelevantie>(this, {
            _props: override,
            clausules: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const clausulesFetchContext = getFetchTreeOfRelation(this._fetchContext, ClausuleRelevantie.PROPS.clausules.id, ClausuleRelevantie.PROPS.clausules.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get clausules() {
                try {
                    return getRelatedPlural(entity, clausulesFetchContext, ClausuleRelevantie.PROPS.clausules.id, ClausuleRelevantie.PROPS.clausules.direction, ClausuleRelevantie.PROPS.clausules.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get clausules(): Clausule[] | undefined {
        const clausulesFetchContext = getFetchTreeOfRelation(this._fetchContext, ClausuleRelevantie.PROPS.clausules.id, ClausuleRelevantie.PROPS.clausules.direction);
        if (clausulesFetchContext !== undefined) {
            return toTypedEntity(this._props.clausules, this._metadataService, clausulesFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class ClausuleRelevantieAcceptatiedeelvraag extends ClausuleRelevantie {
    public static ID = "ee845ec3-3fe9-4884-914d-c11460d6c37d";
    public static PROPS = createProps({
        ...ClausuleRelevantie.PROPS,
        vraag: {
            type: "Field",
            id: "210b4052-75a2-487c-9177-521908cefe96",
            required: true
        },
        antwoord: {
            type: "Field",
            id: "bed73666-ddd3-4480-8a42-910dff971edd",
            required: true
        },
        acceptatiedeelvraag: {
            type: "RelationshipType",
            id: "47fcf915-386e-4fac-9efc-c2d34b9bfac9",
            direction: "child",
            multiplicity: "One",
            required: true
        }
    });
    public readonly _props: ClausuleRelevantieAcceptatiedeelvraagProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<ClausuleRelevantieAcceptatiedeelvraag>(this, {
            _props: override,
            vraag: computed,
            antwoord: computed,
            acceptatiedeelvraag: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const clausulesFetchContext = getFetchTreeOfRelation(this._fetchContext, ClausuleRelevantie.PROPS.clausules.id, ClausuleRelevantie.PROPS.clausules.direction);
        const acceptatiedeelvraagFetchContext = getFetchTreeOfRelation(this._fetchContext, ClausuleRelevantieAcceptatiedeelvraag.PROPS.acceptatiedeelvraag.id, ClausuleRelevantieAcceptatiedeelvraag.PROPS.acceptatiedeelvraag.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get clausules() {
                try {
                    return getRelatedPlural(entity, clausulesFetchContext, ClausuleRelevantie.PROPS.clausules.id, ClausuleRelevantie.PROPS.clausules.direction, ClausuleRelevantie.PROPS.clausules.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatiedeelvraag() {
                try {
                    return getRelatedSingular(entity, acceptatiedeelvraagFetchContext, ClausuleRelevantieAcceptatiedeelvraag.PROPS.acceptatiedeelvraag.id, ClausuleRelevantieAcceptatiedeelvraag.PROPS.acceptatiedeelvraag.direction, ClausuleRelevantieAcceptatiedeelvraag.PROPS.acceptatiedeelvraag.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get vraag() {
                try {
                    return getFieldValue(metadataService, entity, ClausuleRelevantieAcceptatiedeelvraag.PROPS.vraag.id, ClausuleRelevantieAcceptatiedeelvraag.PROPS.vraag.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get antwoord() {
                try {
                    return getFieldValue(metadataService, entity, ClausuleRelevantieAcceptatiedeelvraag.PROPS.antwoord.id, ClausuleRelevantieAcceptatiedeelvraag.PROPS.antwoord.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get vraag(): Json {
        return JsonType.getRawJson(this._props.vraag)!;
    }
    get antwoord(): Json {
        return JsonType.getRawJson(this._props.antwoord)!;
    }
    get acceptatiedeelvraag(): EntityType | undefined {
        const acceptatiedeelvraagFetchContext = getFetchTreeOfRelation(this._fetchContext, ClausuleRelevantieAcceptatiedeelvraag.PROPS.acceptatiedeelvraag.id, ClausuleRelevantieAcceptatiedeelvraag.PROPS.acceptatiedeelvraag.direction);
        if (acceptatiedeelvraagFetchContext !== undefined) {
            return toTypedEntity(this._props.acceptatiedeelvraag, this._metadataService, acceptatiedeelvraagFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class ClausuleRelevantieBranche extends ClausuleRelevantie {
    public static ID = "3a067679-190b-4daa-9eaa-97db5d5bcaca";
    public static PROPS = createProps({
        ...ClausuleRelevantie.PROPS,
        branche: {
            type: "RelationshipType",
            id: "40d96ea7-49c7-4dea-9b8a-8fe93f4bacce",
            direction: "child",
            multiplicity: "One",
            required: true
        }
    });
    public readonly _props: ClausuleRelevantieBrancheProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<ClausuleRelevantieBranche>(this, {
            _props: override,
            branche: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const clausulesFetchContext = getFetchTreeOfRelation(this._fetchContext, ClausuleRelevantie.PROPS.clausules.id, ClausuleRelevantie.PROPS.clausules.direction);
        const brancheFetchContext = getFetchTreeOfRelation(this._fetchContext, ClausuleRelevantieBranche.PROPS.branche.id, ClausuleRelevantieBranche.PROPS.branche.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get clausules() {
                try {
                    return getRelatedPlural(entity, clausulesFetchContext, ClausuleRelevantie.PROPS.clausules.id, ClausuleRelevantie.PROPS.clausules.direction, ClausuleRelevantie.PROPS.clausules.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get branche() {
                try {
                    return getRelatedSingular(entity, brancheFetchContext, ClausuleRelevantieBranche.PROPS.branche.id, ClausuleRelevantieBranche.PROPS.branche.direction, ClausuleRelevantieBranche.PROPS.branche.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get branche(): Branche | undefined {
        const brancheFetchContext = getFetchTreeOfRelation(this._fetchContext, ClausuleRelevantieBranche.PROPS.branche.id, ClausuleRelevantieBranche.PROPS.branche.direction);
        if (brancheFetchContext !== undefined) {
            return toTypedEntity(this._props.branche, this._metadataService, brancheFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class ClausuleRelevantieRBH extends ClausuleRelevantie {
    public static ID = "192c7219-564a-4401-8d5d-ac55d93ddbd5";
    public static PROPS = createProps({
        ...ClausuleRelevantie.PROPS,
        rbhCode: {
            type: "RelationshipType",
            id: "4d692cb1-c929-484e-8589-5999fdef77f3",
            direction: "child",
            multiplicity: "One",
            required: true
        }
    });
    public readonly _props: ClausuleRelevantieRBHProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<ClausuleRelevantieRBH>(this, {
            _props: override,
            rbhCode: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const clausulesFetchContext = getFetchTreeOfRelation(this._fetchContext, ClausuleRelevantie.PROPS.clausules.id, ClausuleRelevantie.PROPS.clausules.direction);
        const rbhCodeFetchContext = getFetchTreeOfRelation(this._fetchContext, ClausuleRelevantieRBH.PROPS.rbhCode.id, ClausuleRelevantieRBH.PROPS.rbhCode.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get clausules() {
                try {
                    return getRelatedPlural(entity, clausulesFetchContext, ClausuleRelevantie.PROPS.clausules.id, ClausuleRelevantie.PROPS.clausules.direction, ClausuleRelevantie.PROPS.clausules.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get rbhCode() {
                try {
                    return getRelatedSingular(entity, rbhCodeFetchContext, ClausuleRelevantieRBH.PROPS.rbhCode.id, ClausuleRelevantieRBH.PROPS.rbhCode.direction, ClausuleRelevantieRBH.PROPS.rbhCode.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get rbhCode(): RBH | undefined {
        const rbhCodeFetchContext = getFetchTreeOfRelation(this._fetchContext, ClausuleRelevantieRBH.PROPS.rbhCode.id, ClausuleRelevantieRBH.PROPS.rbhCode.direction);
        if (rbhCodeFetchContext !== undefined) {
            return toTypedEntity(this._props.rbhCode, this._metadataService, rbhCodeFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class Concernrelaties extends ObjEntity {
    public static ID = "3bafc895-2b1e-4f95-a4b0-5d8705576eaf";
    public static PROPS = createProps({
        ...ObjEntity.PROPS,
        belang: {
            type: "Field",
            id: "b777cee0-82fb-4e16-ac10-aa750960ece9",
            required: true
        },
        name: {
            type: "Field",
            id: "2b93e8f4-52ea-404e-a9b4-b788e04d4666",
            required: false
        },
        dochterOrganisatie: {
            type: "RelationshipType",
            id: "103cfd7f-12aa-4def-bf07-385e61b85022",
            direction: "child",
            multiplicity: "One",
            required: true
        },
        moederOrganisatie: {
            type: "RelationshipType",
            id: "670868eb-79c1-48b0-9cf6-43a4ed00681e",
            direction: "parent",
            multiplicity: "One",
            required: true
        }
    });
    public readonly _props: ConcernrelatiesProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Concernrelaties>(this, {
            _props: override,
            belang: computed,
            name: computed,
            dochterOrganisatie: computed,
            moederOrganisatie: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const dochterOrganisatieFetchContext = getFetchTreeOfRelation(this._fetchContext, Concernrelaties.PROPS.dochterOrganisatie.id, Concernrelaties.PROPS.dochterOrganisatie.direction);
        const moederOrganisatieFetchContext = getFetchTreeOfRelation(this._fetchContext, Concernrelaties.PROPS.moederOrganisatie.id, Concernrelaties.PROPS.moederOrganisatie.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get dochterOrganisatie() {
                try {
                    return getRelatedSingular(entity, dochterOrganisatieFetchContext, Concernrelaties.PROPS.dochterOrganisatie.id, Concernrelaties.PROPS.dochterOrganisatie.direction, Concernrelaties.PROPS.dochterOrganisatie.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get moederOrganisatie() {
                try {
                    return getRelatedSingular(entity, moederOrganisatieFetchContext, Concernrelaties.PROPS.moederOrganisatie.id, Concernrelaties.PROPS.moederOrganisatie.direction, Concernrelaties.PROPS.moederOrganisatie.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get belang() {
                try {
                    return getFieldValue(metadataService, entity, Concernrelaties.PROPS.belang.id, Concernrelaties.PROPS.belang.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get name() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Concernrelaties.PROPS.name.id, Concernrelaties.PROPS.name.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get belang(): Decimal {
        return PercentageType.getRawPercentage(this._props.belang)!;
    }
    get name(): string | null {
        return this._props.name !== null ? TextType.getRawText(this._props.name)! : null;
    }
    get dochterOrganisatie(): Organization | undefined {
        const dochterOrganisatieFetchContext = getFetchTreeOfRelation(this._fetchContext, Concernrelaties.PROPS.dochterOrganisatie.id, Concernrelaties.PROPS.dochterOrganisatie.direction);
        if (dochterOrganisatieFetchContext !== undefined) {
            return toTypedEntity(this._props.dochterOrganisatie, this._metadataService, dochterOrganisatieFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get moederOrganisatie(): Organization | undefined {
        const moederOrganisatieFetchContext = getFetchTreeOfRelation(this._fetchContext, Concernrelaties.PROPS.moederOrganisatie.id, Concernrelaties.PROPS.moederOrganisatie.direction);
        if (moederOrganisatieFetchContext !== undefined) {
            return toTypedEntity(this._props.moederOrganisatie, this._metadataService, moederOrganisatieFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export abstract class ControleBeoordeling extends ObjEntity {
    public static ID = "8bdac0f8-307b-4515-848a-15393de36b6b";
    public static PROPS = createProps({
        ...ObjEntity.PROPS,
        toelichting: {
            type: "Field",
            id: "d73618e2-76f2-4ce9-8db2-6424c04b69b0",
            required: false
        },
        risicoprofielen: {
            type: "RelationshipType",
            id: "4e709117-1010-4985-a251-f45eedbf55f6",
            direction: "child",
            multiplicity: "Many",
            required: true
        },
        beoordelingsklasse: {
            type: "RelationshipType",
            id: "d516946c-960b-4415-9689-eb5a26a2e490",
            direction: "child",
            multiplicity: "One",
            required: true
        },
        actiepunten: {
            type: "RelationshipType",
            id: "21496ff1-b12c-434e-8b7f-48fdf6e6c25b",
            direction: "child",
            multiplicity: "Many",
            required: false
        },
        dossier: {
            type: "RelationshipType",
            id: "29bb066f-71e3-48b4-9f67-f31083ced5c2",
            direction: "parent",
            multiplicity: "One",
            required: true
        }
    });
    public readonly _props: ControleBeoordelingProps;
    constructor(protected metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<ControleBeoordeling>(this, {
            _props: override,
            toelichting: computed,
            risicoprofielen: computed,
            beoordelingsklasse: computed,
            actiepunten: computed,
            dossier: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const risicoprofielenFetchContext = getFetchTreeOfRelation(this._fetchContext, ControleBeoordeling.PROPS.risicoprofielen.id, ControleBeoordeling.PROPS.risicoprofielen.direction);
        const beoordelingsklasseFetchContext = getFetchTreeOfRelation(this._fetchContext, ControleBeoordeling.PROPS.beoordelingsklasse.id, ControleBeoordeling.PROPS.beoordelingsklasse.direction);
        const actiepuntenFetchContext = getFetchTreeOfRelation(this._fetchContext, ControleBeoordeling.PROPS.actiepunten.id, ControleBeoordeling.PROPS.actiepunten.direction);
        const dossierFetchContext = getFetchTreeOfRelation(this._fetchContext, ControleBeoordeling.PROPS.dossier.id, ControleBeoordeling.PROPS.dossier.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get risicoprofielen() {
                try {
                    return getRelatedPlural(entity, risicoprofielenFetchContext, ControleBeoordeling.PROPS.risicoprofielen.id, ControleBeoordeling.PROPS.risicoprofielen.direction, ControleBeoordeling.PROPS.risicoprofielen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get beoordelingsklasse() {
                try {
                    return getRelatedSingular(entity, beoordelingsklasseFetchContext, ControleBeoordeling.PROPS.beoordelingsklasse.id, ControleBeoordeling.PROPS.beoordelingsklasse.direction, ControleBeoordeling.PROPS.beoordelingsklasse.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get actiepunten() {
                try {
                    return getRelatedPlural(entity, actiepuntenFetchContext, ControleBeoordeling.PROPS.actiepunten.id, ControleBeoordeling.PROPS.actiepunten.direction, ControleBeoordeling.PROPS.actiepunten.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get dossier() {
                try {
                    return getRelatedSingular(entity, dossierFetchContext, ControleBeoordeling.PROPS.dossier.id, ControleBeoordeling.PROPS.dossier.direction, ControleBeoordeling.PROPS.dossier.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get toelichting() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ControleBeoordeling.PROPS.toelichting.id, ControleBeoordeling.PROPS.toelichting.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get toelichting(): string | null {
        return this._props.toelichting !== null ? TextType.getRawText(this._props.toelichting)! : null;
    }
    get risicoprofielen(): Risicoprofiel[] | undefined {
        const risicoprofielenFetchContext = getFetchTreeOfRelation(this._fetchContext, ControleBeoordeling.PROPS.risicoprofielen.id, ControleBeoordeling.PROPS.risicoprofielen.direction);
        if (risicoprofielenFetchContext !== undefined) {
            return toTypedEntity(this._props.risicoprofielen, this._metadataService, risicoprofielenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get beoordelingsklasse(): Beoordelingsklasse | undefined {
        const beoordelingsklasseFetchContext = getFetchTreeOfRelation(this._fetchContext, ControleBeoordeling.PROPS.beoordelingsklasse.id, ControleBeoordeling.PROPS.beoordelingsklasse.direction);
        if (beoordelingsklasseFetchContext !== undefined) {
            return toTypedEntity(this._props.beoordelingsklasse, this._metadataService, beoordelingsklasseFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get actiepunten(): Actiepunt[] | undefined {
        const actiepuntenFetchContext = getFetchTreeOfRelation(this._fetchContext, ControleBeoordeling.PROPS.actiepunten.id, ControleBeoordeling.PROPS.actiepunten.direction);
        if (actiepuntenFetchContext !== undefined) {
            return toTypedEntity(this._props.actiepunten, this._metadataService, actiepuntenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get dossier(): RisicoAdviesDossier | undefined {
        const dossierFetchContext = getFetchTreeOfRelation(this._fetchContext, ControleBeoordeling.PROPS.dossier.id, ControleBeoordeling.PROPS.dossier.direction);
        if (dossierFetchContext !== undefined) {
            return toTypedEntity(this._props.dossier, this._metadataService, dossierFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class BrancheBeoordeling extends ControleBeoordeling {
    public static ID = "a4d8bc75-256b-4e9e-bf87-bf219a191478";
    public static PROPS = createProps({
        ...ControleBeoordeling.PROPS,
        branche: {
            type: "RelationshipType",
            id: "a7b04e3e-f932-4c39-a6e6-787e4248da47",
            direction: "child",
            multiplicity: "One",
            required: true
        },
        missendeDekkingen: {
            type: "RelationshipType",
            id: "346cad39-987c-43c0-ab6a-1fec0416cae9",
            direction: "child",
            multiplicity: "Many",
            required: false
        },
        aanwezigeDekkingen: {
            type: "RelationshipType",
            id: "dda7c31c-476f-4879-8b49-85f4290acb03",
            direction: "child",
            multiplicity: "Many",
            required: false
        }
    });
    public readonly _props: BrancheBeoordelingProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<BrancheBeoordeling>(this, {
            _props: override,
            branche: computed,
            missendeDekkingen: computed,
            aanwezigeDekkingen: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const risicoprofielenFetchContext = getFetchTreeOfRelation(this._fetchContext, ControleBeoordeling.PROPS.risicoprofielen.id, ControleBeoordeling.PROPS.risicoprofielen.direction);
        const beoordelingsklasseFetchContext = getFetchTreeOfRelation(this._fetchContext, ControleBeoordeling.PROPS.beoordelingsklasse.id, ControleBeoordeling.PROPS.beoordelingsklasse.direction);
        const actiepuntenFetchContext = getFetchTreeOfRelation(this._fetchContext, ControleBeoordeling.PROPS.actiepunten.id, ControleBeoordeling.PROPS.actiepunten.direction);
        const dossierFetchContext = getFetchTreeOfRelation(this._fetchContext, ControleBeoordeling.PROPS.dossier.id, ControleBeoordeling.PROPS.dossier.direction);
        const brancheFetchContext = getFetchTreeOfRelation(this._fetchContext, BrancheBeoordeling.PROPS.branche.id, BrancheBeoordeling.PROPS.branche.direction);
        const missendeDekkingenFetchContext = getFetchTreeOfRelation(this._fetchContext, BrancheBeoordeling.PROPS.missendeDekkingen.id, BrancheBeoordeling.PROPS.missendeDekkingen.direction);
        const aanwezigeDekkingenFetchContext = getFetchTreeOfRelation(this._fetchContext, BrancheBeoordeling.PROPS.aanwezigeDekkingen.id, BrancheBeoordeling.PROPS.aanwezigeDekkingen.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get risicoprofielen() {
                try {
                    return getRelatedPlural(entity, risicoprofielenFetchContext, ControleBeoordeling.PROPS.risicoprofielen.id, ControleBeoordeling.PROPS.risicoprofielen.direction, ControleBeoordeling.PROPS.risicoprofielen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get beoordelingsklasse() {
                try {
                    return getRelatedSingular(entity, beoordelingsklasseFetchContext, ControleBeoordeling.PROPS.beoordelingsklasse.id, ControleBeoordeling.PROPS.beoordelingsklasse.direction, ControleBeoordeling.PROPS.beoordelingsklasse.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get actiepunten() {
                try {
                    return getRelatedPlural(entity, actiepuntenFetchContext, ControleBeoordeling.PROPS.actiepunten.id, ControleBeoordeling.PROPS.actiepunten.direction, ControleBeoordeling.PROPS.actiepunten.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get dossier() {
                try {
                    return getRelatedSingular(entity, dossierFetchContext, ControleBeoordeling.PROPS.dossier.id, ControleBeoordeling.PROPS.dossier.direction, ControleBeoordeling.PROPS.dossier.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get toelichting() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ControleBeoordeling.PROPS.toelichting.id, ControleBeoordeling.PROPS.toelichting.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get branche() {
                try {
                    return getRelatedSingular(entity, brancheFetchContext, BrancheBeoordeling.PROPS.branche.id, BrancheBeoordeling.PROPS.branche.direction, BrancheBeoordeling.PROPS.branche.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get missendeDekkingen() {
                try {
                    return getRelatedPlural(entity, missendeDekkingenFetchContext, BrancheBeoordeling.PROPS.missendeDekkingen.id, BrancheBeoordeling.PROPS.missendeDekkingen.direction, BrancheBeoordeling.PROPS.missendeDekkingen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get aanwezigeDekkingen() {
                try {
                    return getRelatedPlural(entity, aanwezigeDekkingenFetchContext, BrancheBeoordeling.PROPS.aanwezigeDekkingen.id, BrancheBeoordeling.PROPS.aanwezigeDekkingen.direction, BrancheBeoordeling.PROPS.aanwezigeDekkingen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get branche(): Branche | undefined {
        const brancheFetchContext = getFetchTreeOfRelation(this._fetchContext, BrancheBeoordeling.PROPS.branche.id, BrancheBeoordeling.PROPS.branche.direction);
        if (brancheFetchContext !== undefined) {
            return toTypedEntity(this._props.branche, this._metadataService, brancheFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get missendeDekkingen(): DekkingsEntiteit[] | undefined {
        const missendeDekkingenFetchContext = getFetchTreeOfRelation(this._fetchContext, BrancheBeoordeling.PROPS.missendeDekkingen.id, BrancheBeoordeling.PROPS.missendeDekkingen.direction);
        if (missendeDekkingenFetchContext !== undefined) {
            return toTypedEntity(this._props.missendeDekkingen, this._metadataService, missendeDekkingenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get aanwezigeDekkingen(): DekkingsEntiteit[] | undefined {
        const aanwezigeDekkingenFetchContext = getFetchTreeOfRelation(this._fetchContext, BrancheBeoordeling.PROPS.aanwezigeDekkingen.id, BrancheBeoordeling.PROPS.aanwezigeDekkingen.direction);
        if (aanwezigeDekkingenFetchContext !== undefined) {
            return toTypedEntity(this._props.aanwezigeDekkingen, this._metadataService, aanwezigeDekkingenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class ClausuleBeoordeling extends ControleBeoordeling {
    public static ID = "2a42481d-6f55-40c1-b64f-7596cf94b456";
    public static PROPS = createProps({
        ...ControleBeoordeling.PROPS,
        clausule: {
            type: "RelationshipType",
            id: "d2783fd4-1246-4e15-ad1d-49a714a2a64f",
            direction: "child",
            multiplicity: "One",
            required: true
        }
    });
    public readonly _props: ClausuleBeoordelingProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<ClausuleBeoordeling>(this, {
            _props: override,
            clausule: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const risicoprofielenFetchContext = getFetchTreeOfRelation(this._fetchContext, ControleBeoordeling.PROPS.risicoprofielen.id, ControleBeoordeling.PROPS.risicoprofielen.direction);
        const beoordelingsklasseFetchContext = getFetchTreeOfRelation(this._fetchContext, ControleBeoordeling.PROPS.beoordelingsklasse.id, ControleBeoordeling.PROPS.beoordelingsklasse.direction);
        const actiepuntenFetchContext = getFetchTreeOfRelation(this._fetchContext, ControleBeoordeling.PROPS.actiepunten.id, ControleBeoordeling.PROPS.actiepunten.direction);
        const dossierFetchContext = getFetchTreeOfRelation(this._fetchContext, ControleBeoordeling.PROPS.dossier.id, ControleBeoordeling.PROPS.dossier.direction);
        const clausuleFetchContext = getFetchTreeOfRelation(this._fetchContext, ClausuleBeoordeling.PROPS.clausule.id, ClausuleBeoordeling.PROPS.clausule.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get risicoprofielen() {
                try {
                    return getRelatedPlural(entity, risicoprofielenFetchContext, ControleBeoordeling.PROPS.risicoprofielen.id, ControleBeoordeling.PROPS.risicoprofielen.direction, ControleBeoordeling.PROPS.risicoprofielen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get beoordelingsklasse() {
                try {
                    return getRelatedSingular(entity, beoordelingsklasseFetchContext, ControleBeoordeling.PROPS.beoordelingsklasse.id, ControleBeoordeling.PROPS.beoordelingsklasse.direction, ControleBeoordeling.PROPS.beoordelingsklasse.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get actiepunten() {
                try {
                    return getRelatedPlural(entity, actiepuntenFetchContext, ControleBeoordeling.PROPS.actiepunten.id, ControleBeoordeling.PROPS.actiepunten.direction, ControleBeoordeling.PROPS.actiepunten.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get dossier() {
                try {
                    return getRelatedSingular(entity, dossierFetchContext, ControleBeoordeling.PROPS.dossier.id, ControleBeoordeling.PROPS.dossier.direction, ControleBeoordeling.PROPS.dossier.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get toelichting() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ControleBeoordeling.PROPS.toelichting.id, ControleBeoordeling.PROPS.toelichting.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get clausule() {
                try {
                    return getRelatedSingular(entity, clausuleFetchContext, ClausuleBeoordeling.PROPS.clausule.id, ClausuleBeoordeling.PROPS.clausule.direction, ClausuleBeoordeling.PROPS.clausule.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get clausule(): Clausule | undefined {
        const clausuleFetchContext = getFetchTreeOfRelation(this._fetchContext, ClausuleBeoordeling.PROPS.clausule.id, ClausuleBeoordeling.PROPS.clausule.direction);
        if (clausuleFetchContext !== undefined) {
            return toTypedEntity(this._props.clausule, this._metadataService, clausuleFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class MaatregelBeoordeling extends ControleBeoordeling {
    public static ID = "a33f174b-8d1d-4483-bc12-b4cadad9bd4a";
    public static PROPS = createProps({
        ...ControleBeoordeling.PROPS,
        maatregelCategorie: {
            type: "RelationshipType",
            id: "52ecf7b9-b495-492e-915c-0d6ac221db1f",
            direction: "child",
            multiplicity: "One",
            required: true
        },
        maatregelen: {
            type: "RelationshipType",
            id: "b7003eda-0670-48af-9b07-8006c174863d",
            direction: "child",
            multiplicity: "Many",
            required: true
        }
    });
    public readonly _props: MaatregelBeoordelingProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<MaatregelBeoordeling>(this, {
            _props: override,
            maatregelCategorie: computed,
            maatregelen: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const risicoprofielenFetchContext = getFetchTreeOfRelation(this._fetchContext, ControleBeoordeling.PROPS.risicoprofielen.id, ControleBeoordeling.PROPS.risicoprofielen.direction);
        const beoordelingsklasseFetchContext = getFetchTreeOfRelation(this._fetchContext, ControleBeoordeling.PROPS.beoordelingsklasse.id, ControleBeoordeling.PROPS.beoordelingsklasse.direction);
        const actiepuntenFetchContext = getFetchTreeOfRelation(this._fetchContext, ControleBeoordeling.PROPS.actiepunten.id, ControleBeoordeling.PROPS.actiepunten.direction);
        const dossierFetchContext = getFetchTreeOfRelation(this._fetchContext, ControleBeoordeling.PROPS.dossier.id, ControleBeoordeling.PROPS.dossier.direction);
        const maatregelCategorieFetchContext = getFetchTreeOfRelation(this._fetchContext, MaatregelBeoordeling.PROPS.maatregelCategorie.id, MaatregelBeoordeling.PROPS.maatregelCategorie.direction);
        const maatregelenFetchContext = getFetchTreeOfRelation(this._fetchContext, MaatregelBeoordeling.PROPS.maatregelen.id, MaatregelBeoordeling.PROPS.maatregelen.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get risicoprofielen() {
                try {
                    return getRelatedPlural(entity, risicoprofielenFetchContext, ControleBeoordeling.PROPS.risicoprofielen.id, ControleBeoordeling.PROPS.risicoprofielen.direction, ControleBeoordeling.PROPS.risicoprofielen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get beoordelingsklasse() {
                try {
                    return getRelatedSingular(entity, beoordelingsklasseFetchContext, ControleBeoordeling.PROPS.beoordelingsklasse.id, ControleBeoordeling.PROPS.beoordelingsklasse.direction, ControleBeoordeling.PROPS.beoordelingsklasse.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get actiepunten() {
                try {
                    return getRelatedPlural(entity, actiepuntenFetchContext, ControleBeoordeling.PROPS.actiepunten.id, ControleBeoordeling.PROPS.actiepunten.direction, ControleBeoordeling.PROPS.actiepunten.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get dossier() {
                try {
                    return getRelatedSingular(entity, dossierFetchContext, ControleBeoordeling.PROPS.dossier.id, ControleBeoordeling.PROPS.dossier.direction, ControleBeoordeling.PROPS.dossier.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get toelichting() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ControleBeoordeling.PROPS.toelichting.id, ControleBeoordeling.PROPS.toelichting.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get maatregelCategorie() {
                try {
                    return getRelatedSingular(entity, maatregelCategorieFetchContext, MaatregelBeoordeling.PROPS.maatregelCategorie.id, MaatregelBeoordeling.PROPS.maatregelCategorie.direction, MaatregelBeoordeling.PROPS.maatregelCategorie.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get maatregelen() {
                try {
                    return getRelatedPlural(entity, maatregelenFetchContext, MaatregelBeoordeling.PROPS.maatregelen.id, MaatregelBeoordeling.PROPS.maatregelen.direction, MaatregelBeoordeling.PROPS.maatregelen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get maatregelCategorie(): MaatregelSubcategorie | undefined {
        const maatregelCategorieFetchContext = getFetchTreeOfRelation(this._fetchContext, MaatregelBeoordeling.PROPS.maatregelCategorie.id, MaatregelBeoordeling.PROPS.maatregelCategorie.direction);
        if (maatregelCategorieFetchContext !== undefined) {
            return toTypedEntity(this._props.maatregelCategorie, this._metadataService, maatregelCategorieFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get maatregelen(): Maatregel[] | undefined {
        const maatregelenFetchContext = getFetchTreeOfRelation(this._fetchContext, MaatregelBeoordeling.PROPS.maatregelen.id, MaatregelBeoordeling.PROPS.maatregelen.direction);
        if (maatregelenFetchContext !== undefined) {
            return toTypedEntity(this._props.maatregelen, this._metadataService, maatregelenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class Datastore extends ObjEntity {
    public static ID = "ab2b30c7-ed73-4e71-b541-3890b13f432e";
    public static PROPS = createProps({
        ...ObjEntity.PROPS,
        name: {
            type: "Field",
            id: "e9dc85f3-0337-40b0-9d6b-978ea4d20fee",
            required: true
        },
        value: {
            type: "Field",
            id: "82572602-0d38-4a67-8375-7d9df7d0212b",
            required: false
        }
    });
    public readonly _props: DatastoreProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Datastore>(this, {
            _props: override,
            name: computed,
            value: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get name() {
                try {
                    return getFieldValue(metadataService, entity, Datastore.PROPS.name.id, Datastore.PROPS.name.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Datastore.PROPS.value.id, Datastore.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get name(): string {
        return TextType.getRawText(this._props.name)!;
    }
    get value(): string | null {
        return this._props.value !== null ? TextType.getRawText(this._props.value)! : null;
    }
}
export class AdviesStappen extends Datastore {
    public static ID = "7942e9f1-686a-437c-a280-b5c854f85492";
    public static PROPS = createProps({
        ...Datastore.PROPS,
        dossiers: {
            type: "RelationshipType",
            id: "9b64bca3-60e8-4071-8173-8ff194967ac8",
            direction: "parent",
            multiplicity: "Many",
            required: false
        }
    });
    public readonly _props: AdviesStappenProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<AdviesStappen>(this, {
            _props: override,
            dossiers: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const dossiersFetchContext = getFetchTreeOfRelation(this._fetchContext, AdviesStappen.PROPS.dossiers.id, AdviesStappen.PROPS.dossiers.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get name() {
                try {
                    return getFieldValue(metadataService, entity, Datastore.PROPS.name.id, Datastore.PROPS.name.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Datastore.PROPS.value.id, Datastore.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get dossiers() {
                try {
                    return getRelatedPlural(entity, dossiersFetchContext, AdviesStappen.PROPS.dossiers.id, AdviesStappen.PROPS.dossiers.direction, AdviesStappen.PROPS.dossiers.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get dossiers(): Dossier[] | undefined {
        const dossiersFetchContext = getFetchTreeOfRelation(this._fetchContext, AdviesStappen.PROPS.dossiers.id, AdviesStappen.PROPS.dossiers.direction);
        if (dossiersFetchContext !== undefined) {
            return toTypedEntity(this._props.dossiers, this._metadataService, dossiersFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class Beoordelingsklasse extends Datastore {
    public static ID = "f753eab5-37b1-48de-808b-a6adb1028113";
    public static PROPS = createProps({
        ...Datastore.PROPS,
        beoordelingen: {
            type: "RelationshipType",
            id: "d516946c-960b-4415-9689-eb5a26a2e490",
            direction: "parent",
            multiplicity: "Many",
            required: false
        }
    });
    public readonly _props: BeoordelingsklasseProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Beoordelingsklasse>(this, {
            _props: override,
            beoordelingen: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const beoordelingenFetchContext = getFetchTreeOfRelation(this._fetchContext, Beoordelingsklasse.PROPS.beoordelingen.id, Beoordelingsklasse.PROPS.beoordelingen.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get name() {
                try {
                    return getFieldValue(metadataService, entity, Datastore.PROPS.name.id, Datastore.PROPS.name.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Datastore.PROPS.value.id, Datastore.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get beoordelingen() {
                try {
                    return getRelatedPlural(entity, beoordelingenFetchContext, Beoordelingsklasse.PROPS.beoordelingen.id, Beoordelingsklasse.PROPS.beoordelingen.direction, Beoordelingsklasse.PROPS.beoordelingen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get beoordelingen(): ControleBeoordeling[] | undefined {
        const beoordelingenFetchContext = getFetchTreeOfRelation(this._fetchContext, Beoordelingsklasse.PROPS.beoordelingen.id, Beoordelingsklasse.PROPS.beoordelingen.direction);
        if (beoordelingenFetchContext !== undefined) {
            return toTypedEntity(this._props.beoordelingen, this._metadataService, beoordelingenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class Betaaltermijn extends Datastore {
    public static ID = "f461e3d5-d7da-400a-bf28-463bb46252d2";
    public static PROPS = createProps({
        ...Datastore.PROPS,
        offertes: {
            type: "RelationshipType",
            id: "84ea679a-267a-4bbb-a4cb-de69a9971b74",
            direction: "parent",
            multiplicity: "Many",
            required: false
        }
    });
    public readonly _props: BetaaltermijnProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Betaaltermijn>(this, {
            _props: override,
            offertes: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const offertesFetchContext = getFetchTreeOfRelation(this._fetchContext, Betaaltermijn.PROPS.offertes.id, Betaaltermijn.PROPS.offertes.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get name() {
                try {
                    return getFieldValue(metadataService, entity, Datastore.PROPS.name.id, Datastore.PROPS.name.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Datastore.PROPS.value.id, Datastore.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get offertes() {
                try {
                    return getRelatedPlural(entity, offertesFetchContext, Betaaltermijn.PROPS.offertes.id, Betaaltermijn.PROPS.offertes.direction, Betaaltermijn.PROPS.offertes.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get offertes(): Offerte[] | undefined {
        const offertesFetchContext = getFetchTreeOfRelation(this._fetchContext, Betaaltermijn.PROPS.offertes.id, Betaaltermijn.PROPS.offertes.direction);
        if (offertesFetchContext !== undefined) {
            return toTypedEntity(this._props.offertes, this._metadataService, offertesFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class Clausule extends Datastore {
    public static ID = "79616caf-055a-4dff-8467-ea69a444c54e";
    public static PROPS = createProps({
        ...Datastore.PROPS,
        actief: {
            type: "Field",
            id: "b11840af-481b-420d-ba1c-43be2f401c15",
            required: true
        },
        bijzonderheden: {
            type: "Field",
            id: "4392a193-c6e0-46e5-8b35-0db7dec4f50e",
            required: false
        },
        beoordelingen: {
            type: "RelationshipType",
            id: "d2783fd4-1246-4e15-ad1d-49a714a2a64f",
            direction: "parent",
            multiplicity: "Many",
            required: false
        },
        clausuleRelevanties: {
            type: "RelationshipType",
            id: "2d28859b-37e0-414e-b75f-a34802422baa",
            direction: "parent",
            multiplicity: "Many",
            required: false
        }
    });
    public readonly _props: ClausuleProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Clausule>(this, {
            _props: override,
            actief: computed,
            bijzonderheden: computed,
            beoordelingen: computed,
            clausuleRelevanties: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const beoordelingenFetchContext = getFetchTreeOfRelation(this._fetchContext, Clausule.PROPS.beoordelingen.id, Clausule.PROPS.beoordelingen.direction);
        const clausuleRelevantiesFetchContext = getFetchTreeOfRelation(this._fetchContext, Clausule.PROPS.clausuleRelevanties.id, Clausule.PROPS.clausuleRelevanties.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get name() {
                try {
                    return getFieldValue(metadataService, entity, Datastore.PROPS.name.id, Datastore.PROPS.name.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Datastore.PROPS.value.id, Datastore.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get beoordelingen() {
                try {
                    return getRelatedPlural(entity, beoordelingenFetchContext, Clausule.PROPS.beoordelingen.id, Clausule.PROPS.beoordelingen.direction, Clausule.PROPS.beoordelingen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get clausuleRelevanties() {
                try {
                    return getRelatedPlural(entity, clausuleRelevantiesFetchContext, Clausule.PROPS.clausuleRelevanties.id, Clausule.PROPS.clausuleRelevanties.direction, Clausule.PROPS.clausuleRelevanties.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get actief() {
                try {
                    return getFieldValue(metadataService, entity, Clausule.PROPS.actief.id, Clausule.PROPS.actief.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get bijzonderheden() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Clausule.PROPS.bijzonderheden.id, Clausule.PROPS.bijzonderheden.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get actief(): boolean {
        return BooleanType.getRawBoolean(this._props.actief)!;
    }
    get bijzonderheden(): string | null {
        return this._props.bijzonderheden !== null ? TextType.getRawText(this._props.bijzonderheden)! : null;
    }
    get beoordelingen(): ClausuleBeoordeling[] | undefined {
        const beoordelingenFetchContext = getFetchTreeOfRelation(this._fetchContext, Clausule.PROPS.beoordelingen.id, Clausule.PROPS.beoordelingen.direction);
        if (beoordelingenFetchContext !== undefined) {
            return toTypedEntity(this._props.beoordelingen, this._metadataService, beoordelingenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get clausuleRelevanties(): ClausuleRelevantie[] | undefined {
        const clausuleRelevantiesFetchContext = getFetchTreeOfRelation(this._fetchContext, Clausule.PROPS.clausuleRelevanties.id, Clausule.PROPS.clausuleRelevanties.direction);
        if (clausuleRelevantiesFetchContext !== undefined) {
            return toTypedEntity(this._props.clausuleRelevanties, this._metadataService, clausuleRelevantiesFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class DossierStatus extends Datastore {
    public static ID = "92d5e118-aba7-41ff-aece-45cd1e1019b5";
    public static PROPS = createProps({
        ...Datastore.PROPS,
        dossiers: {
            type: "RelationshipType",
            id: "5364805f-c833-418a-99dd-7e5fb66e5281",
            direction: "parent",
            multiplicity: "Many",
            required: false
        }
    });
    public readonly _props: DossierStatusProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<DossierStatus>(this, {
            _props: override,
            dossiers: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const dossiersFetchContext = getFetchTreeOfRelation(this._fetchContext, DossierStatus.PROPS.dossiers.id, DossierStatus.PROPS.dossiers.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get name() {
                try {
                    return getFieldValue(metadataService, entity, Datastore.PROPS.name.id, Datastore.PROPS.name.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Datastore.PROPS.value.id, Datastore.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get dossiers() {
                try {
                    return getRelatedPlural(entity, dossiersFetchContext, DossierStatus.PROPS.dossiers.id, DossierStatus.PROPS.dossiers.direction, DossierStatus.PROPS.dossiers.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get dossiers(): Dossier[] | undefined {
        const dossiersFetchContext = getFetchTreeOfRelation(this._fetchContext, DossierStatus.PROPS.dossiers.id, DossierStatus.PROPS.dossiers.direction);
        if (dossiersFetchContext !== undefined) {
            return toTypedEntity(this._props.dossiers, this._metadataService, dossiersFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class GebruikWerkmaterieel extends Datastore {
    public static ID = "5a945cbd-89fb-4b4a-ba0e-bf3b1ec9bfff";
    public static PROPS = createProps({
        ...Datastore.PROPS,
        naam: {
            type: "Field",
            id: "e9dc85f3-0337-40b0-9d6b-978ea4d20fee",
            required: true
        },
        werkmaterieel: {
            type: "RelationshipType",
            id: "ddb42641-4a49-48b3-8893-7516045a5797",
            direction: "parent",
            multiplicity: "Many",
            required: false
        }
    });
    public readonly _props: GebruikWerkmaterieelProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<GebruikWerkmaterieel>(this, {
            _props: override,
            naam: computed,
            werkmaterieel: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const werkmaterieelFetchContext = getFetchTreeOfRelation(this._fetchContext, GebruikWerkmaterieel.PROPS.werkmaterieel.id, GebruikWerkmaterieel.PROPS.werkmaterieel.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get name() {
                try {
                    return getFieldValue(metadataService, entity, Datastore.PROPS.name.id, Datastore.PROPS.name.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Datastore.PROPS.value.id, Datastore.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get werkmaterieel() {
                try {
                    return getRelatedPlural(entity, werkmaterieelFetchContext, GebruikWerkmaterieel.PROPS.werkmaterieel.id, GebruikWerkmaterieel.PROPS.werkmaterieel.direction, GebruikWerkmaterieel.PROPS.werkmaterieel.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get naam() {
                try {
                    return getFieldValue(metadataService, entity, GebruikWerkmaterieel.PROPS.naam.id, GebruikWerkmaterieel.PROPS.naam.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get naam(): string {
        return TextType.getRawText(this._props.naam)!;
    }
    get werkmaterieel(): Werkmaterieel[] | undefined {
        const werkmaterieelFetchContext = getFetchTreeOfRelation(this._fetchContext, GebruikWerkmaterieel.PROPS.werkmaterieel.id, GebruikWerkmaterieel.PROPS.werkmaterieel.direction);
        if (werkmaterieelFetchContext !== undefined) {
            return toTypedEntity(this._props.werkmaterieel, this._metadataService, werkmaterieelFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class IBBBrandontwikkelsnelheid extends Datastore {
    public static ID = "58fc2dcd-486b-461e-b0c4-a85159a22951";
    public static PROPS = createProps({
        ...Datastore.PROPS,
        risicoAdressen: {
            type: "RelationshipType",
            id: "e04599de-7c0e-4287-9353-ff558b26d995",
            direction: "parent",
            multiplicity: "Many",
            required: false
        }
    });
    public readonly _props: IBBBrandontwikkelsnelheidProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<IBBBrandontwikkelsnelheid>(this, {
            _props: override,
            risicoAdressen: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const risicoAdressenFetchContext = getFetchTreeOfRelation(this._fetchContext, IBBBrandontwikkelsnelheid.PROPS.risicoAdressen.id, IBBBrandontwikkelsnelheid.PROPS.risicoAdressen.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get name() {
                try {
                    return getFieldValue(metadataService, entity, Datastore.PROPS.name.id, Datastore.PROPS.name.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Datastore.PROPS.value.id, Datastore.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get risicoAdressen() {
                try {
                    return getRelatedPlural(entity, risicoAdressenFetchContext, IBBBrandontwikkelsnelheid.PROPS.risicoAdressen.id, IBBBrandontwikkelsnelheid.PROPS.risicoAdressen.direction, IBBBrandontwikkelsnelheid.PROPS.risicoAdressen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get risicoAdressen(): RisicoAdres[] | undefined {
        const risicoAdressenFetchContext = getFetchTreeOfRelation(this._fetchContext, IBBBrandontwikkelsnelheid.PROPS.risicoAdressen.id, IBBBrandontwikkelsnelheid.PROPS.risicoAdressen.direction);
        if (risicoAdressenFetchContext !== undefined) {
            return toTypedEntity(this._props.risicoAdressen, this._metadataService, risicoAdressenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class IBBGebruikskarakteristiekGebouw1 extends Datastore {
    public static ID = "cae4cb41-d904-47a5-b104-3193f4513176";
    public static PROPS = createProps({
        ...Datastore.PROPS,
        risicoAdressen: {
            type: "RelationshipType",
            id: "0619f180-9035-44ed-b60d-ff5d88680494",
            direction: "parent",
            multiplicity: "Many",
            required: false
        }
    });
    public readonly _props: IBBGebruikskarakteristiekGebouw1Props;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<IBBGebruikskarakteristiekGebouw1>(this, {
            _props: override,
            risicoAdressen: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const risicoAdressenFetchContext = getFetchTreeOfRelation(this._fetchContext, IBBGebruikskarakteristiekGebouw1.PROPS.risicoAdressen.id, IBBGebruikskarakteristiekGebouw1.PROPS.risicoAdressen.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get name() {
                try {
                    return getFieldValue(metadataService, entity, Datastore.PROPS.name.id, Datastore.PROPS.name.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Datastore.PROPS.value.id, Datastore.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get risicoAdressen() {
                try {
                    return getRelatedPlural(entity, risicoAdressenFetchContext, IBBGebruikskarakteristiekGebouw1.PROPS.risicoAdressen.id, IBBGebruikskarakteristiekGebouw1.PROPS.risicoAdressen.direction, IBBGebruikskarakteristiekGebouw1.PROPS.risicoAdressen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get risicoAdressen(): RisicoAdres[] | undefined {
        const risicoAdressenFetchContext = getFetchTreeOfRelation(this._fetchContext, IBBGebruikskarakteristiekGebouw1.PROPS.risicoAdressen.id, IBBGebruikskarakteristiekGebouw1.PROPS.risicoAdressen.direction);
        if (risicoAdressenFetchContext !== undefined) {
            return toTypedEntity(this._props.risicoAdressen, this._metadataService, risicoAdressenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class IBBGebruikskarakteristiekGebouw2 extends Datastore {
    public static ID = "637df7d1-7324-4edf-9c35-34b6a6f5bce0";
    public static PROPS = createProps({
        ...Datastore.PROPS,
        risicoAdressen: {
            type: "RelationshipType",
            id: "b69bd698-b4ba-4bb1-91c3-2ba703ac94e8",
            direction: "parent",
            multiplicity: "Many",
            required: false
        }
    });
    public readonly _props: IBBGebruikskarakteristiekGebouw2Props;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<IBBGebruikskarakteristiekGebouw2>(this, {
            _props: override,
            risicoAdressen: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const risicoAdressenFetchContext = getFetchTreeOfRelation(this._fetchContext, IBBGebruikskarakteristiekGebouw2.PROPS.risicoAdressen.id, IBBGebruikskarakteristiekGebouw2.PROPS.risicoAdressen.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get name() {
                try {
                    return getFieldValue(metadataService, entity, Datastore.PROPS.name.id, Datastore.PROPS.name.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Datastore.PROPS.value.id, Datastore.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get risicoAdressen() {
                try {
                    return getRelatedPlural(entity, risicoAdressenFetchContext, IBBGebruikskarakteristiekGebouw2.PROPS.risicoAdressen.id, IBBGebruikskarakteristiekGebouw2.PROPS.risicoAdressen.direction, IBBGebruikskarakteristiekGebouw2.PROPS.risicoAdressen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get risicoAdressen(): RisicoAdres[] | undefined {
        const risicoAdressenFetchContext = getFetchTreeOfRelation(this._fetchContext, IBBGebruikskarakteristiekGebouw2.PROPS.risicoAdressen.id, IBBGebruikskarakteristiekGebouw2.PROPS.risicoAdressen.direction);
        if (risicoAdressenFetchContext !== undefined) {
            return toTypedEntity(this._props.risicoAdressen, this._metadataService, risicoAdressenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class IBBGebruikskarakteristiekPersoonlijkRuimtes extends Datastore {
    public static ID = "d3322dbe-3491-4c4e-baa9-c108cec3eb4e";
    public static PROPS = createProps({
        ...Datastore.PROPS,
        risicoAdressen: {
            type: "RelationshipType",
            id: "5ccbafe8-ba0f-49a7-af2b-1a250cf46acc",
            direction: "parent",
            multiplicity: "Many",
            required: false
        }
    });
    public readonly _props: IBBGebruikskarakteristiekPersoonlijkRuimtesProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<IBBGebruikskarakteristiekPersoonlijkRuimtes>(this, {
            _props: override,
            risicoAdressen: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const risicoAdressenFetchContext = getFetchTreeOfRelation(this._fetchContext, IBBGebruikskarakteristiekPersoonlijkRuimtes.PROPS.risicoAdressen.id, IBBGebruikskarakteristiekPersoonlijkRuimtes.PROPS.risicoAdressen.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get name() {
                try {
                    return getFieldValue(metadataService, entity, Datastore.PROPS.name.id, Datastore.PROPS.name.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Datastore.PROPS.value.id, Datastore.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get risicoAdressen() {
                try {
                    return getRelatedPlural(entity, risicoAdressenFetchContext, IBBGebruikskarakteristiekPersoonlijkRuimtes.PROPS.risicoAdressen.id, IBBGebruikskarakteristiekPersoonlijkRuimtes.PROPS.risicoAdressen.direction, IBBGebruikskarakteristiekPersoonlijkRuimtes.PROPS.risicoAdressen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get risicoAdressen(): RisicoAdres[] | undefined {
        const risicoAdressenFetchContext = getFetchTreeOfRelation(this._fetchContext, IBBGebruikskarakteristiekPersoonlijkRuimtes.PROPS.risicoAdressen.id, IBBGebruikskarakteristiekPersoonlijkRuimtes.PROPS.risicoAdressen.direction);
        if (risicoAdressenFetchContext !== undefined) {
            return toTypedEntity(this._props.risicoAdressen, this._metadataService, risicoAdressenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class IBBImpactSchade extends Datastore {
    public static ID = "b4b06c68-a0a7-48d0-b4da-a6b328109d54";
    public static PROPS = createProps({
        ...Datastore.PROPS,
        risicoAdressen: {
            type: "RelationshipType",
            id: "58754998-c308-4c42-b1f4-8fa5843b7cb1",
            direction: "parent",
            multiplicity: "Many",
            required: false
        }
    });
    public readonly _props: IBBImpactSchadeProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<IBBImpactSchade>(this, {
            _props: override,
            risicoAdressen: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const risicoAdressenFetchContext = getFetchTreeOfRelation(this._fetchContext, IBBImpactSchade.PROPS.risicoAdressen.id, IBBImpactSchade.PROPS.risicoAdressen.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get name() {
                try {
                    return getFieldValue(metadataService, entity, Datastore.PROPS.name.id, Datastore.PROPS.name.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Datastore.PROPS.value.id, Datastore.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get risicoAdressen() {
                try {
                    return getRelatedPlural(entity, risicoAdressenFetchContext, IBBImpactSchade.PROPS.risicoAdressen.id, IBBImpactSchade.PROPS.risicoAdressen.direction, IBBImpactSchade.PROPS.risicoAdressen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get risicoAdressen(): RisicoAdres[] | undefined {
        const risicoAdressenFetchContext = getFetchTreeOfRelation(this._fetchContext, IBBImpactSchade.PROPS.risicoAdressen.id, IBBImpactSchade.PROPS.risicoAdressen.direction);
        if (risicoAdressenFetchContext !== undefined) {
            return toTypedEntity(this._props.risicoAdressen, this._metadataService, risicoAdressenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class KernprocessenMasterdata extends Datastore {
    public static ID = "8540dbca-bda5-4b22-9fce-3b081801cc47";
    public static PROPS = createProps({
        ...Datastore.PROPS,
        kernprocessen: {
            type: "RelationshipType",
            id: "dbf200fc-b40d-4b20-a355-824bf48a957f",
            direction: "parent",
            multiplicity: "Many",
            required: false
        }
    });
    public readonly _props: KernprocessenMasterdataProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<KernprocessenMasterdata>(this, {
            _props: override,
            kernprocessen: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const kernprocessenFetchContext = getFetchTreeOfRelation(this._fetchContext, KernprocessenMasterdata.PROPS.kernprocessen.id, KernprocessenMasterdata.PROPS.kernprocessen.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get name() {
                try {
                    return getFieldValue(metadataService, entity, Datastore.PROPS.name.id, Datastore.PROPS.name.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Datastore.PROPS.value.id, Datastore.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get kernprocessen() {
                try {
                    return getRelatedPlural(entity, kernprocessenFetchContext, KernprocessenMasterdata.PROPS.kernprocessen.id, KernprocessenMasterdata.PROPS.kernprocessen.direction, KernprocessenMasterdata.PROPS.kernprocessen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get kernprocessen(): Kernprocessen[] | undefined {
        const kernprocessenFetchContext = getFetchTreeOfRelation(this._fetchContext, KernprocessenMasterdata.PROPS.kernprocessen.id, KernprocessenMasterdata.PROPS.kernprocessen.direction);
        if (kernprocessenFetchContext !== undefined) {
            return toTypedEntity(this._props.kernprocessen, this._metadataService, kernprocessenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export abstract class Maatregel extends Datastore {
    public static ID = "93233b84-64cd-4310-983f-1c6c3a1ee9c8";
    public static PROPS = createProps({
        ...Datastore.PROPS,
        normering: {
            type: "Field",
            id: "f2c4dc49-4d38-4bd5-a851-412841567266",
            required: false
        },
        beoordelingen: {
            type: "RelationshipType",
            id: "b7003eda-0670-48af-9b07-8006c174863d",
            direction: "parent",
            multiplicity: "Many",
            required: false
        },
        categorie: {
            type: "RelationshipType",
            id: "d0886f3d-611c-4829-8020-ad32e5ab22dd",
            direction: "parent",
            multiplicity: "One",
            required: true
        }
    });
    public readonly _props: MaatregelProps;
    constructor(protected metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Maatregel>(this, {
            _props: override,
            normering: computed,
            beoordelingen: computed,
            categorie: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const beoordelingenFetchContext = getFetchTreeOfRelation(this._fetchContext, Maatregel.PROPS.beoordelingen.id, Maatregel.PROPS.beoordelingen.direction);
        const categorieFetchContext = getFetchTreeOfRelation(this._fetchContext, Maatregel.PROPS.categorie.id, Maatregel.PROPS.categorie.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get name() {
                try {
                    return getFieldValue(metadataService, entity, Datastore.PROPS.name.id, Datastore.PROPS.name.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Datastore.PROPS.value.id, Datastore.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get beoordelingen() {
                try {
                    return getRelatedPlural(entity, beoordelingenFetchContext, Maatregel.PROPS.beoordelingen.id, Maatregel.PROPS.beoordelingen.direction, Maatregel.PROPS.beoordelingen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get categorie() {
                try {
                    return getRelatedSingular(entity, categorieFetchContext, Maatregel.PROPS.categorie.id, Maatregel.PROPS.categorie.direction, Maatregel.PROPS.categorie.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get normering() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Maatregel.PROPS.normering.id, Maatregel.PROPS.normering.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get normering(): string | null {
        return this._props.normering !== null ? TextType.getRawText(this._props.normering)! : null;
    }
    get beoordelingen(): MaatregelBeoordeling[] | undefined {
        const beoordelingenFetchContext = getFetchTreeOfRelation(this._fetchContext, Maatregel.PROPS.beoordelingen.id, Maatregel.PROPS.beoordelingen.direction);
        if (beoordelingenFetchContext !== undefined) {
            return toTypedEntity(this._props.beoordelingen, this._metadataService, beoordelingenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get categorie(): MaatregelSubcategorie | undefined {
        const categorieFetchContext = getFetchTreeOfRelation(this._fetchContext, Maatregel.PROPS.categorie.id, Maatregel.PROPS.categorie.direction);
        if (categorieFetchContext !== undefined) {
            return toTypedEntity(this._props.categorie, this._metadataService, categorieFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export abstract class MaatregelCategorie extends Datastore {
    public static ID = "fc77c9e0-8c2f-4157-8c70-7170a6509b24";
    public static PROPS = createProps({
        ...Datastore.PROPS,
        subcategorieen: {
            type: "RelationshipType",
            id: "77484392-f152-4075-b81f-33be6a15b09a",
            direction: "child",
            multiplicity: "Many",
            required: false
        }
    });
    public readonly _props: MaatregelCategorieProps;
    constructor(protected metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<MaatregelCategorie>(this, {
            _props: override,
            subcategorieen: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const subcategorieenFetchContext = getFetchTreeOfRelation(this._fetchContext, MaatregelCategorie.PROPS.subcategorieen.id, MaatregelCategorie.PROPS.subcategorieen.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get name() {
                try {
                    return getFieldValue(metadataService, entity, Datastore.PROPS.name.id, Datastore.PROPS.name.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Datastore.PROPS.value.id, Datastore.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get subcategorieen() {
                try {
                    return getRelatedPlural(entity, subcategorieenFetchContext, MaatregelCategorie.PROPS.subcategorieen.id, MaatregelCategorie.PROPS.subcategorieen.direction, MaatregelCategorie.PROPS.subcategorieen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get subcategorieen(): MaatregelSubcategorie[] | undefined {
        const subcategorieenFetchContext = getFetchTreeOfRelation(this._fetchContext, MaatregelCategorie.PROPS.subcategorieen.id, MaatregelCategorie.PROPS.subcategorieen.direction);
        if (subcategorieenFetchContext !== undefined) {
            return toTypedEntity(this._props.subcategorieen, this._metadataService, subcategorieenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class MaatregelCategorieVKRI extends MaatregelCategorie {
    public static ID = "cddbfc2f-5b5c-47e9-8016-a3e3b4235b43";
    public static PROPS = createProps({
        ...MaatregelCategorie.PROPS,
        maatregelsets: {
            type: "RelationshipType",
            id: "c31bd451-a55e-4a60-a0bb-eaee77dde020",
            direction: "child",
            multiplicity: "Many",
            required: false
        }
    });
    public readonly _props: MaatregelCategorieVKRIProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<MaatregelCategorieVKRI>(this, {
            _props: override,
            maatregelsets: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const subcategorieenFetchContext = getFetchTreeOfRelation(this._fetchContext, MaatregelCategorie.PROPS.subcategorieen.id, MaatregelCategorie.PROPS.subcategorieen.direction);
        const maatregelsetsFetchContext = getFetchTreeOfRelation(this._fetchContext, MaatregelCategorieVKRI.PROPS.maatregelsets.id, MaatregelCategorieVKRI.PROPS.maatregelsets.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get name() {
                try {
                    return getFieldValue(metadataService, entity, Datastore.PROPS.name.id, Datastore.PROPS.name.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Datastore.PROPS.value.id, Datastore.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get subcategorieen() {
                try {
                    return getRelatedPlural(entity, subcategorieenFetchContext, MaatregelCategorie.PROPS.subcategorieen.id, MaatregelCategorie.PROPS.subcategorieen.direction, MaatregelCategorie.PROPS.subcategorieen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get maatregelsets() {
                try {
                    return getRelatedPlural(entity, maatregelsetsFetchContext, MaatregelCategorieVKRI.PROPS.maatregelsets.id, MaatregelCategorieVKRI.PROPS.maatregelsets.direction, MaatregelCategorieVKRI.PROPS.maatregelsets.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get maatregelsets(): VKRIMaatregelset[] | undefined {
        const maatregelsetsFetchContext = getFetchTreeOfRelation(this._fetchContext, MaatregelCategorieVKRI.PROPS.maatregelsets.id, MaatregelCategorieVKRI.PROPS.maatregelsets.direction);
        if (maatregelsetsFetchContext !== undefined) {
            return toTypedEntity(this._props.maatregelsets, this._metadataService, maatregelsetsFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class MaatregelIBB extends Maatregel {
    public static ID = "6680cb0a-a9b8-455a-98b7-3c738974f729";
    public static PROPS = createProps({
        ...Maatregel.PROPS,
        risicoklasse: {
            type: "RelationshipType",
            id: "07476212-0836-4347-a045-c7c7775a0785",
            direction: "parent",
            multiplicity: "Many",
            required: true
        }
    });
    public readonly _props: MaatregelIBBProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<MaatregelIBB>(this, {
            _props: override,
            risicoklasse: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const beoordelingenFetchContext = getFetchTreeOfRelation(this._fetchContext, Maatregel.PROPS.beoordelingen.id, Maatregel.PROPS.beoordelingen.direction);
        const categorieFetchContext = getFetchTreeOfRelation(this._fetchContext, Maatregel.PROPS.categorie.id, Maatregel.PROPS.categorie.direction);
        const risicoklasseFetchContext = getFetchTreeOfRelation(this._fetchContext, MaatregelIBB.PROPS.risicoklasse.id, MaatregelIBB.PROPS.risicoklasse.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get name() {
                try {
                    return getFieldValue(metadataService, entity, Datastore.PROPS.name.id, Datastore.PROPS.name.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Datastore.PROPS.value.id, Datastore.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get beoordelingen() {
                try {
                    return getRelatedPlural(entity, beoordelingenFetchContext, Maatregel.PROPS.beoordelingen.id, Maatregel.PROPS.beoordelingen.direction, Maatregel.PROPS.beoordelingen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get categorie() {
                try {
                    return getRelatedSingular(entity, categorieFetchContext, Maatregel.PROPS.categorie.id, Maatregel.PROPS.categorie.direction, Maatregel.PROPS.categorie.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get normering() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Maatregel.PROPS.normering.id, Maatregel.PROPS.normering.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get risicoklasse() {
                try {
                    return getRelatedPlural(entity, risicoklasseFetchContext, MaatregelIBB.PROPS.risicoklasse.id, MaatregelIBB.PROPS.risicoklasse.direction, MaatregelIBB.PROPS.risicoklasse.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get risicoklasse(): RisicoklasseIBB[] | undefined {
        const risicoklasseFetchContext = getFetchTreeOfRelation(this._fetchContext, MaatregelIBB.PROPS.risicoklasse.id, MaatregelIBB.PROPS.risicoklasse.direction);
        if (risicoklasseFetchContext !== undefined) {
            return toTypedEntity(this._props.risicoklasse, this._metadataService, risicoklasseFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class MaatregelVKRI extends Maatregel {
    public static ID = "a6933749-722a-434a-b34b-d5921258b248";
    public static PROPS = createProps({
        ...Maatregel.PROPS
    });
    public readonly _props: MaatregelVKRIProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<MaatregelVKRI>(this, {
            _props: override
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const beoordelingenFetchContext = getFetchTreeOfRelation(this._fetchContext, Maatregel.PROPS.beoordelingen.id, Maatregel.PROPS.beoordelingen.direction);
        const categorieFetchContext = getFetchTreeOfRelation(this._fetchContext, Maatregel.PROPS.categorie.id, Maatregel.PROPS.categorie.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get name() {
                try {
                    return getFieldValue(metadataService, entity, Datastore.PROPS.name.id, Datastore.PROPS.name.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Datastore.PROPS.value.id, Datastore.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get beoordelingen() {
                try {
                    return getRelatedPlural(entity, beoordelingenFetchContext, Maatregel.PROPS.beoordelingen.id, Maatregel.PROPS.beoordelingen.direction, Maatregel.PROPS.beoordelingen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get categorie() {
                try {
                    return getRelatedSingular(entity, categorieFetchContext, Maatregel.PROPS.categorie.id, Maatregel.PROPS.categorie.direction, Maatregel.PROPS.categorie.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get normering() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Maatregel.PROPS.normering.id, Maatregel.PROPS.normering.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
}
export abstract class MaatregelSubcategorie extends Datastore {
    public static ID = "ae6662d0-c961-4661-b2d3-b655842ece0c";
    public static PROPS = createProps({
        ...Datastore.PROPS,
        maatregelen: {
            type: "RelationshipType",
            id: "d0886f3d-611c-4829-8020-ad32e5ab22dd",
            direction: "child",
            multiplicity: "Many",
            required: false
        },
        beoordelingen: {
            type: "RelationshipType",
            id: "52ecf7b9-b495-492e-915c-0d6ac221db1f",
            direction: "parent",
            multiplicity: "Many",
            required: false
        },
        hoofdcategorie: {
            type: "RelationshipType",
            id: "77484392-f152-4075-b81f-33be6a15b09a",
            direction: "parent",
            multiplicity: "One",
            required: true
        }
    });
    public readonly _props: MaatregelSubcategorieProps;
    constructor(protected metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<MaatregelSubcategorie>(this, {
            _props: override,
            maatregelen: computed,
            beoordelingen: computed,
            hoofdcategorie: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const maatregelenFetchContext = getFetchTreeOfRelation(this._fetchContext, MaatregelSubcategorie.PROPS.maatregelen.id, MaatregelSubcategorie.PROPS.maatregelen.direction);
        const beoordelingenFetchContext = getFetchTreeOfRelation(this._fetchContext, MaatregelSubcategorie.PROPS.beoordelingen.id, MaatregelSubcategorie.PROPS.beoordelingen.direction);
        const hoofdcategorieFetchContext = getFetchTreeOfRelation(this._fetchContext, MaatregelSubcategorie.PROPS.hoofdcategorie.id, MaatregelSubcategorie.PROPS.hoofdcategorie.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get name() {
                try {
                    return getFieldValue(metadataService, entity, Datastore.PROPS.name.id, Datastore.PROPS.name.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Datastore.PROPS.value.id, Datastore.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get maatregelen() {
                try {
                    return getRelatedPlural(entity, maatregelenFetchContext, MaatregelSubcategorie.PROPS.maatregelen.id, MaatregelSubcategorie.PROPS.maatregelen.direction, MaatregelSubcategorie.PROPS.maatregelen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get beoordelingen() {
                try {
                    return getRelatedPlural(entity, beoordelingenFetchContext, MaatregelSubcategorie.PROPS.beoordelingen.id, MaatregelSubcategorie.PROPS.beoordelingen.direction, MaatregelSubcategorie.PROPS.beoordelingen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get hoofdcategorie() {
                try {
                    return getRelatedSingular(entity, hoofdcategorieFetchContext, MaatregelSubcategorie.PROPS.hoofdcategorie.id, MaatregelSubcategorie.PROPS.hoofdcategorie.direction, MaatregelSubcategorie.PROPS.hoofdcategorie.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get maatregelen(): Maatregel[] | undefined {
        const maatregelenFetchContext = getFetchTreeOfRelation(this._fetchContext, MaatregelSubcategorie.PROPS.maatregelen.id, MaatregelSubcategorie.PROPS.maatregelen.direction);
        if (maatregelenFetchContext !== undefined) {
            return toTypedEntity(this._props.maatregelen, this._metadataService, maatregelenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get beoordelingen(): MaatregelBeoordeling[] | undefined {
        const beoordelingenFetchContext = getFetchTreeOfRelation(this._fetchContext, MaatregelSubcategorie.PROPS.beoordelingen.id, MaatregelSubcategorie.PROPS.beoordelingen.direction);
        if (beoordelingenFetchContext !== undefined) {
            return toTypedEntity(this._props.beoordelingen, this._metadataService, beoordelingenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get hoofdcategorie(): MaatregelCategorie | undefined {
        const hoofdcategorieFetchContext = getFetchTreeOfRelation(this._fetchContext, MaatregelSubcategorie.PROPS.hoofdcategorie.id, MaatregelSubcategorie.PROPS.hoofdcategorie.direction);
        if (hoofdcategorieFetchContext !== undefined) {
            return toTypedEntity(this._props.hoofdcategorie, this._metadataService, hoofdcategorieFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class Maatschappij extends Datastore {
    public static ID = "07fe40de-f7c7-4030-80e6-3d2b9d67ed79";
    public static PROPS = createProps({
        ...Datastore.PROPS,
        offertes: {
            type: "RelationshipType",
            id: "2f411354-77e6-41ab-bc59-5e21a0d123b6",
            direction: "parent",
            multiplicity: "Many",
            required: false
        }
    });
    public readonly _props: MaatschappijProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Maatschappij>(this, {
            _props: override,
            offertes: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const offertesFetchContext = getFetchTreeOfRelation(this._fetchContext, Maatschappij.PROPS.offertes.id, Maatschappij.PROPS.offertes.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get name() {
                try {
                    return getFieldValue(metadataService, entity, Datastore.PROPS.name.id, Datastore.PROPS.name.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Datastore.PROPS.value.id, Datastore.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get offertes() {
                try {
                    return getRelatedPlural(entity, offertesFetchContext, Maatschappij.PROPS.offertes.id, Maatschappij.PROPS.offertes.direction, Maatschappij.PROPS.offertes.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get offertes(): Offerte[] | undefined {
        const offertesFetchContext = getFetchTreeOfRelation(this._fetchContext, Maatschappij.PROPS.offertes.id, Maatschappij.PROPS.offertes.direction);
        if (offertesFetchContext !== undefined) {
            return toTypedEntity(this._props.offertes, this._metadataService, offertesFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class OndersteunendeProcessenMasterdata extends Datastore {
    public static ID = "584ce35c-5bb0-478a-807e-8e0c4c95e4cc";
    public static PROPS = createProps({
        ...Datastore.PROPS,
        ondersteunendeProcessen: {
            type: "RelationshipType",
            id: "1bf2b0ae-581b-4abe-9c6c-9a09ce1fbd0a",
            direction: "parent",
            multiplicity: "Many",
            required: false
        }
    });
    public readonly _props: OndersteunendeProcessenMasterdataProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<OndersteunendeProcessenMasterdata>(this, {
            _props: override,
            ondersteunendeProcessen: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const ondersteunendeProcessenFetchContext = getFetchTreeOfRelation(this._fetchContext, OndersteunendeProcessenMasterdata.PROPS.ondersteunendeProcessen.id, OndersteunendeProcessenMasterdata.PROPS.ondersteunendeProcessen.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get name() {
                try {
                    return getFieldValue(metadataService, entity, Datastore.PROPS.name.id, Datastore.PROPS.name.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Datastore.PROPS.value.id, Datastore.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get ondersteunendeProcessen() {
                try {
                    return getRelatedPlural(entity, ondersteunendeProcessenFetchContext, OndersteunendeProcessenMasterdata.PROPS.ondersteunendeProcessen.id, OndersteunendeProcessenMasterdata.PROPS.ondersteunendeProcessen.direction, OndersteunendeProcessenMasterdata.PROPS.ondersteunendeProcessen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get ondersteunendeProcessen(): OndersteunendeProcessen[] | undefined {
        const ondersteunendeProcessenFetchContext = getFetchTreeOfRelation(this._fetchContext, OndersteunendeProcessenMasterdata.PROPS.ondersteunendeProcessen.id, OndersteunendeProcessenMasterdata.PROPS.ondersteunendeProcessen.direction);
        if (ondersteunendeProcessenFetchContext !== undefined) {
            return toTypedEntity(this._props.ondersteunendeProcessen, this._metadataService, ondersteunendeProcessenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class RBH extends Datastore {
    public static ID = "4d972eea-d077-4571-bea0-1f29a49a979d";
    public static PROPS = createProps({
        ...Datastore.PROPS,
        acceptiedeelvragenRelevanties: {
            type: "RelationshipType",
            id: "7873b14a-5128-4035-b9e6-33d92987a434",
            direction: "parent",
            multiplicity: "Many",
            required: false
        },
        clausuleRelevanties: {
            type: "RelationshipType",
            id: "4d692cb1-c929-484e-8589-5999fdef77f3",
            direction: "parent",
            multiplicity: "Many",
            required: false
        },
        sbi: {
            type: "RelationshipType",
            id: "d2d8f20d-8a5b-4535-8b75-b8ee374d0c67",
            direction: "parent",
            multiplicity: "One",
            required: true
        }
    });
    public readonly _props: RBHProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<RBH>(this, {
            _props: override,
            acceptiedeelvragenRelevanties: computed,
            clausuleRelevanties: computed,
            sbi: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const acceptiedeelvragenRelevantiesFetchContext = getFetchTreeOfRelation(this._fetchContext, RBH.PROPS.acceptiedeelvragenRelevanties.id, RBH.PROPS.acceptiedeelvragenRelevanties.direction);
        const clausuleRelevantiesFetchContext = getFetchTreeOfRelation(this._fetchContext, RBH.PROPS.clausuleRelevanties.id, RBH.PROPS.clausuleRelevanties.direction);
        const sbiFetchContext = getFetchTreeOfRelation(this._fetchContext, RBH.PROPS.sbi.id, RBH.PROPS.sbi.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get name() {
                try {
                    return getFieldValue(metadataService, entity, Datastore.PROPS.name.id, Datastore.PROPS.name.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Datastore.PROPS.value.id, Datastore.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptiedeelvragenRelevanties() {
                try {
                    return getRelatedPlural(entity, acceptiedeelvragenRelevantiesFetchContext, RBH.PROPS.acceptiedeelvragenRelevanties.id, RBH.PROPS.acceptiedeelvragenRelevanties.direction, RBH.PROPS.acceptiedeelvragenRelevanties.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get clausuleRelevanties() {
                try {
                    return getRelatedPlural(entity, clausuleRelevantiesFetchContext, RBH.PROPS.clausuleRelevanties.id, RBH.PROPS.clausuleRelevanties.direction, RBH.PROPS.clausuleRelevanties.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get sbi() {
                try {
                    return getRelatedSingular(entity, sbiFetchContext, RBH.PROPS.sbi.id, RBH.PROPS.sbi.direction, RBH.PROPS.sbi.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get acceptiedeelvragenRelevanties(): AcceptatiedeelvraagRelevantie[] | undefined {
        const acceptiedeelvragenRelevantiesFetchContext = getFetchTreeOfRelation(this._fetchContext, RBH.PROPS.acceptiedeelvragenRelevanties.id, RBH.PROPS.acceptiedeelvragenRelevanties.direction);
        if (acceptiedeelvragenRelevantiesFetchContext !== undefined) {
            return toTypedEntity(this._props.acceptiedeelvragenRelevanties, this._metadataService, acceptiedeelvragenRelevantiesFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get clausuleRelevanties(): ClausuleRelevantieRBH[] | undefined {
        const clausuleRelevantiesFetchContext = getFetchTreeOfRelation(this._fetchContext, RBH.PROPS.clausuleRelevanties.id, RBH.PROPS.clausuleRelevanties.direction);
        if (clausuleRelevantiesFetchContext !== undefined) {
            return toTypedEntity(this._props.clausuleRelevanties, this._metadataService, clausuleRelevantiesFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get sbi(): SBI | undefined {
        const sbiFetchContext = getFetchTreeOfRelation(this._fetchContext, RBH.PROPS.sbi.id, RBH.PROPS.sbi.direction);
        if (sbiFetchContext !== undefined) {
            return toTypedEntity(this._props.sbi, this._metadataService, sbiFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class Risicoklasse extends Datastore {
    public static ID = "cb2bf9e7-7570-4f02-9553-f00298153f05";
    public static PROPS = createProps({
        ...Datastore.PROPS,
        maatregelenRelevanties: {
            type: "RelationshipType",
            id: "821c9d69-918d-482d-9123-840ee867028c",
            direction: "parent",
            multiplicity: "Many",
            required: false
        },
        automatischeBeoordelingen: {
            type: "RelationshipType",
            id: "977077af-f599-4e84-80ba-ae39535baa85",
            direction: "parent",
            multiplicity: "Many",
            required: false
        },
        brutoBeoordelingen: {
            type: "RelationshipType",
            id: "9f32ad5d-90c7-480c-9b4d-b86f90b2732e",
            direction: "parent",
            multiplicity: "Many",
            required: false
        },
        nettoBeoordelingen: {
            type: "RelationshipType",
            id: "9308aeb4-e851-4483-9080-b19533524889",
            direction: "parent",
            multiplicity: "Many",
            required: false
        }
    });
    public readonly _props: RisicoklasseProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Risicoklasse>(this, {
            _props: override,
            maatregelenRelevanties: computed,
            automatischeBeoordelingen: computed,
            brutoBeoordelingen: computed,
            nettoBeoordelingen: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const maatregelenRelevantiesFetchContext = getFetchTreeOfRelation(this._fetchContext, Risicoklasse.PROPS.maatregelenRelevanties.id, Risicoklasse.PROPS.maatregelenRelevanties.direction);
        const automatischeBeoordelingenFetchContext = getFetchTreeOfRelation(this._fetchContext, Risicoklasse.PROPS.automatischeBeoordelingen.id, Risicoklasse.PROPS.automatischeBeoordelingen.direction);
        const brutoBeoordelingenFetchContext = getFetchTreeOfRelation(this._fetchContext, Risicoklasse.PROPS.brutoBeoordelingen.id, Risicoklasse.PROPS.brutoBeoordelingen.direction);
        const nettoBeoordelingenFetchContext = getFetchTreeOfRelation(this._fetchContext, Risicoklasse.PROPS.nettoBeoordelingen.id, Risicoklasse.PROPS.nettoBeoordelingen.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get name() {
                try {
                    return getFieldValue(metadataService, entity, Datastore.PROPS.name.id, Datastore.PROPS.name.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Datastore.PROPS.value.id, Datastore.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get maatregelenRelevanties() {
                try {
                    return getRelatedPlural(entity, maatregelenRelevantiesFetchContext, Risicoklasse.PROPS.maatregelenRelevanties.id, Risicoklasse.PROPS.maatregelenRelevanties.direction, Risicoklasse.PROPS.maatregelenRelevanties.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get automatischeBeoordelingen() {
                try {
                    return getRelatedPlural(entity, automatischeBeoordelingenFetchContext, Risicoklasse.PROPS.automatischeBeoordelingen.id, Risicoklasse.PROPS.automatischeBeoordelingen.direction, Risicoklasse.PROPS.automatischeBeoordelingen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get brutoBeoordelingen() {
                try {
                    return getRelatedPlural(entity, brutoBeoordelingenFetchContext, Risicoklasse.PROPS.brutoBeoordelingen.id, Risicoklasse.PROPS.brutoBeoordelingen.direction, Risicoklasse.PROPS.brutoBeoordelingen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get nettoBeoordelingen() {
                try {
                    return getRelatedPlural(entity, nettoBeoordelingenFetchContext, Risicoklasse.PROPS.nettoBeoordelingen.id, Risicoklasse.PROPS.nettoBeoordelingen.direction, Risicoklasse.PROPS.nettoBeoordelingen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get maatregelenRelevanties(): VKRIMaatregelRelevantie[] | undefined {
        const maatregelenRelevantiesFetchContext = getFetchTreeOfRelation(this._fetchContext, Risicoklasse.PROPS.maatregelenRelevanties.id, Risicoklasse.PROPS.maatregelenRelevanties.direction);
        if (maatregelenRelevantiesFetchContext !== undefined) {
            return toTypedEntity(this._props.maatregelenRelevanties, this._metadataService, maatregelenRelevantiesFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get automatischeBeoordelingen(): Risicobeoordeling[] | undefined {
        const automatischeBeoordelingenFetchContext = getFetchTreeOfRelation(this._fetchContext, Risicoklasse.PROPS.automatischeBeoordelingen.id, Risicoklasse.PROPS.automatischeBeoordelingen.direction);
        if (automatischeBeoordelingenFetchContext !== undefined) {
            return toTypedEntity(this._props.automatischeBeoordelingen, this._metadataService, automatischeBeoordelingenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get brutoBeoordelingen(): Risicobeoordeling[] | undefined {
        const brutoBeoordelingenFetchContext = getFetchTreeOfRelation(this._fetchContext, Risicoklasse.PROPS.brutoBeoordelingen.id, Risicoklasse.PROPS.brutoBeoordelingen.direction);
        if (brutoBeoordelingenFetchContext !== undefined) {
            return toTypedEntity(this._props.brutoBeoordelingen, this._metadataService, brutoBeoordelingenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get nettoBeoordelingen(): Risicobeoordeling[] | undefined {
        const nettoBeoordelingenFetchContext = getFetchTreeOfRelation(this._fetchContext, Risicoklasse.PROPS.nettoBeoordelingen.id, Risicoklasse.PROPS.nettoBeoordelingen.direction);
        if (nettoBeoordelingenFetchContext !== undefined) {
            return toTypedEntity(this._props.nettoBeoordelingen, this._metadataService, nettoBeoordelingenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class RisicoklasseIBB extends Datastore {
    public static ID = "6b682033-e556-4481-8855-5441aac08ab9";
    public static PROPS = createProps({
        ...Datastore.PROPS,
        maatregelen: {
            type: "RelationshipType",
            id: "07476212-0836-4347-a045-c7c7775a0785",
            direction: "child",
            multiplicity: "Many",
            required: false
        },
        risicobeoordelingen: {
            type: "RelationshipType",
            id: "0e4f5ed0-325c-4a41-b6a6-23fb12c6add4",
            direction: "parent",
            multiplicity: "Many",
            required: false
        }
    });
    public readonly _props: RisicoklasseIBBProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<RisicoklasseIBB>(this, {
            _props: override,
            maatregelen: computed,
            risicobeoordelingen: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const maatregelenFetchContext = getFetchTreeOfRelation(this._fetchContext, RisicoklasseIBB.PROPS.maatregelen.id, RisicoklasseIBB.PROPS.maatregelen.direction);
        const risicobeoordelingenFetchContext = getFetchTreeOfRelation(this._fetchContext, RisicoklasseIBB.PROPS.risicobeoordelingen.id, RisicoklasseIBB.PROPS.risicobeoordelingen.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get name() {
                try {
                    return getFieldValue(metadataService, entity, Datastore.PROPS.name.id, Datastore.PROPS.name.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Datastore.PROPS.value.id, Datastore.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get maatregelen() {
                try {
                    return getRelatedPlural(entity, maatregelenFetchContext, RisicoklasseIBB.PROPS.maatregelen.id, RisicoklasseIBB.PROPS.maatregelen.direction, RisicoklasseIBB.PROPS.maatregelen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get risicobeoordelingen() {
                try {
                    return getRelatedPlural(entity, risicobeoordelingenFetchContext, RisicoklasseIBB.PROPS.risicobeoordelingen.id, RisicoklasseIBB.PROPS.risicobeoordelingen.direction, RisicoklasseIBB.PROPS.risicobeoordelingen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get maatregelen(): MaatregelIBB[] | undefined {
        const maatregelenFetchContext = getFetchTreeOfRelation(this._fetchContext, RisicoklasseIBB.PROPS.maatregelen.id, RisicoklasseIBB.PROPS.maatregelen.direction);
        if (maatregelenFetchContext !== undefined) {
            return toTypedEntity(this._props.maatregelen, this._metadataService, maatregelenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get risicobeoordelingen(): RisicoAdresRisicobeoordeling[] | undefined {
        const risicobeoordelingenFetchContext = getFetchTreeOfRelation(this._fetchContext, RisicoklasseIBB.PROPS.risicobeoordelingen.id, RisicoklasseIBB.PROPS.risicobeoordelingen.direction);
        if (risicobeoordelingenFetchContext !== undefined) {
            return toTypedEntity(this._props.risicobeoordelingen, this._metadataService, risicobeoordelingenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class Risicostrategie extends Datastore {
    public static ID = "5ac21db0-6dc0-43d1-92ea-7cf2ad3c45af";
    public static PROPS = createProps({
        ...Datastore.PROPS,
        beoordelingen: {
            type: "RelationshipType",
            id: "12313fef-1cac-42eb-af90-85d54fb0e9ff",
            direction: "parent",
            multiplicity: "Many",
            required: false
        }
    });
    public readonly _props: RisicostrategieProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Risicostrategie>(this, {
            _props: override,
            beoordelingen: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const beoordelingenFetchContext = getFetchTreeOfRelation(this._fetchContext, Risicostrategie.PROPS.beoordelingen.id, Risicostrategie.PROPS.beoordelingen.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get name() {
                try {
                    return getFieldValue(metadataService, entity, Datastore.PROPS.name.id, Datastore.PROPS.name.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Datastore.PROPS.value.id, Datastore.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get beoordelingen() {
                try {
                    return getRelatedPlural(entity, beoordelingenFetchContext, Risicostrategie.PROPS.beoordelingen.id, Risicostrategie.PROPS.beoordelingen.direction, Risicostrategie.PROPS.beoordelingen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get beoordelingen(): Risicobeoordeling[] | undefined {
        const beoordelingenFetchContext = getFetchTreeOfRelation(this._fetchContext, Risicostrategie.PROPS.beoordelingen.id, Risicostrategie.PROPS.beoordelingen.direction);
        if (beoordelingenFetchContext !== undefined) {
            return toTypedEntity(this._props.beoordelingen, this._metadataService, beoordelingenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class SBI extends Datastore {
    public static ID = "10bad5f4-61c3-4b70-a84e-5fba55028843";
    public static PROPS = createProps({
        ...Datastore.PROPS,
        rbhs: {
            type: "RelationshipType",
            id: "d2d8f20d-8a5b-4535-8b75-b8ee374d0c67",
            direction: "child",
            multiplicity: "Many",
            required: false
        },
        organisatiesPrimair: {
            type: "RelationshipType",
            id: "40c7ca03-c9cd-4a6a-b284-fef45afd02f7",
            direction: "parent",
            multiplicity: "Many",
            required: false
        },
        organisaties: {
            type: "RelationshipType",
            id: "673f8545-5456-4d2e-a585-d067a8e0f2ed",
            direction: "parent",
            multiplicity: "Many",
            required: false
        }
    });
    public readonly _props: SBIProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<SBI>(this, {
            _props: override,
            rbhs: computed,
            organisatiesPrimair: computed,
            organisaties: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const rbhsFetchContext = getFetchTreeOfRelation(this._fetchContext, SBI.PROPS.rbhs.id, SBI.PROPS.rbhs.direction);
        const organisatiesPrimairFetchContext = getFetchTreeOfRelation(this._fetchContext, SBI.PROPS.organisatiesPrimair.id, SBI.PROPS.organisatiesPrimair.direction);
        const organisatiesFetchContext = getFetchTreeOfRelation(this._fetchContext, SBI.PROPS.organisaties.id, SBI.PROPS.organisaties.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get name() {
                try {
                    return getFieldValue(metadataService, entity, Datastore.PROPS.name.id, Datastore.PROPS.name.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Datastore.PROPS.value.id, Datastore.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get rbhs() {
                try {
                    return getRelatedPlural(entity, rbhsFetchContext, SBI.PROPS.rbhs.id, SBI.PROPS.rbhs.direction, SBI.PROPS.rbhs.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organisatiesPrimair() {
                try {
                    return getRelatedPlural(entity, organisatiesPrimairFetchContext, SBI.PROPS.organisatiesPrimair.id, SBI.PROPS.organisatiesPrimair.direction, SBI.PROPS.organisatiesPrimair.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organisaties() {
                try {
                    return getRelatedPlural(entity, organisatiesFetchContext, SBI.PROPS.organisaties.id, SBI.PROPS.organisaties.direction, SBI.PROPS.organisaties.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get rbhs(): RBH[] | undefined {
        const rbhsFetchContext = getFetchTreeOfRelation(this._fetchContext, SBI.PROPS.rbhs.id, SBI.PROPS.rbhs.direction);
        if (rbhsFetchContext !== undefined) {
            return toTypedEntity(this._props.rbhs, this._metadataService, rbhsFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get organisatiesPrimair(): Organization[] | undefined {
        const organisatiesPrimairFetchContext = getFetchTreeOfRelation(this._fetchContext, SBI.PROPS.organisatiesPrimair.id, SBI.PROPS.organisatiesPrimair.direction);
        if (organisatiesPrimairFetchContext !== undefined) {
            return toTypedEntity(this._props.organisatiesPrimair, this._metadataService, organisatiesPrimairFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get organisaties(): Organization[] | undefined {
        const organisatiesFetchContext = getFetchTreeOfRelation(this._fetchContext, SBI.PROPS.organisaties.id, SBI.PROPS.organisaties.direction);
        if (organisatiesFetchContext !== undefined) {
            return toTypedEntity(this._props.organisaties, this._metadataService, organisatiesFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class Scope extends Datastore {
    public static ID = "5304cda9-57dc-4503-be54-e86b6d24df77";
    public static PROPS = createProps({
        ...Datastore.PROPS,
        dossiers: {
            type: "RelationshipType",
            id: "cc4af1f9-b6b4-43b3-ba92-302dbebe6449",
            direction: "parent",
            multiplicity: "Many",
            required: false
        }
    });
    public readonly _props: ScopeProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Scope>(this, {
            _props: override,
            dossiers: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const dossiersFetchContext = getFetchTreeOfRelation(this._fetchContext, Scope.PROPS.dossiers.id, Scope.PROPS.dossiers.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get name() {
                try {
                    return getFieldValue(metadataService, entity, Datastore.PROPS.name.id, Datastore.PROPS.name.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Datastore.PROPS.value.id, Datastore.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get dossiers() {
                try {
                    return getRelatedPlural(entity, dossiersFetchContext, Scope.PROPS.dossiers.id, Scope.PROPS.dossiers.direction, Scope.PROPS.dossiers.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get dossiers(): Dossier[] | undefined {
        const dossiersFetchContext = getFetchTreeOfRelation(this._fetchContext, Scope.PROPS.dossiers.id, Scope.PROPS.dossiers.direction);
        if (dossiersFetchContext !== undefined) {
            return toTypedEntity(this._props.dossiers, this._metadataService, dossiersFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class SoortElectronischApparatuur extends Datastore {
    public static ID = "19fd71a9-8aaf-4b8e-b358-34783bc3f479";
    public static PROPS = createProps({
        ...Datastore.PROPS,
        electronica: {
            type: "RelationshipType",
            id: "b7b00e0f-130d-4f66-ab44-2d276a06f860",
            direction: "parent",
            multiplicity: "Many",
            required: false
        }
    });
    public readonly _props: SoortElectronischApparatuurProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<SoortElectronischApparatuur>(this, {
            _props: override,
            electronica: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const electronicaFetchContext = getFetchTreeOfRelation(this._fetchContext, SoortElectronischApparatuur.PROPS.electronica.id, SoortElectronischApparatuur.PROPS.electronica.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get name() {
                try {
                    return getFieldValue(metadataService, entity, Datastore.PROPS.name.id, Datastore.PROPS.name.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Datastore.PROPS.value.id, Datastore.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get electronica() {
                try {
                    return getRelatedPlural(entity, electronicaFetchContext, SoortElectronischApparatuur.PROPS.electronica.id, SoortElectronischApparatuur.PROPS.electronica.direction, SoortElectronischApparatuur.PROPS.electronica.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get electronica(): Electronica[] | undefined {
        const electronicaFetchContext = getFetchTreeOfRelation(this._fetchContext, SoortElectronischApparatuur.PROPS.electronica.id, SoortElectronischApparatuur.PROPS.electronica.direction);
        if (electronicaFetchContext !== undefined) {
            return toTypedEntity(this._props.electronica, this._metadataService, electronicaFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class SoortenLevendeHave extends Datastore {
    public static ID = "6c897705-362a-47d5-a976-cb7eea33b9d0";
    public static PROPS = createProps({
        ...Datastore.PROPS,
        voorraadLevendeHave: {
            type: "RelationshipType",
            id: "9e29f9d4-a348-4b82-8e92-a5c108cd0efe",
            direction: "parent",
            multiplicity: "Many",
            required: false
        }
    });
    public readonly _props: SoortenLevendeHaveProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<SoortenLevendeHave>(this, {
            _props: override,
            voorraadLevendeHave: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const voorraadLevendeHaveFetchContext = getFetchTreeOfRelation(this._fetchContext, SoortenLevendeHave.PROPS.voorraadLevendeHave.id, SoortenLevendeHave.PROPS.voorraadLevendeHave.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get name() {
                try {
                    return getFieldValue(metadataService, entity, Datastore.PROPS.name.id, Datastore.PROPS.name.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Datastore.PROPS.value.id, Datastore.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get voorraadLevendeHave() {
                try {
                    return getRelatedPlural(entity, voorraadLevendeHaveFetchContext, SoortenLevendeHave.PROPS.voorraadLevendeHave.id, SoortenLevendeHave.PROPS.voorraadLevendeHave.direction, SoortenLevendeHave.PROPS.voorraadLevendeHave.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get voorraadLevendeHave(): VoorraadLevendeHave[] | undefined {
        const voorraadLevendeHaveFetchContext = getFetchTreeOfRelation(this._fetchContext, SoortenLevendeHave.PROPS.voorraadLevendeHave.id, SoortenLevendeHave.PROPS.voorraadLevendeHave.direction);
        if (voorraadLevendeHaveFetchContext !== undefined) {
            return toTypedEntity(this._props.voorraadLevendeHave, this._metadataService, voorraadLevendeHaveFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class SoortenOogstproducten extends Datastore {
    public static ID = "a9ecc75b-de8c-4075-8a55-cfeeb0fc09da";
    public static PROPS = createProps({
        ...Datastore.PROPS,
        VoorraadMeerjarenPlantopstand: {
            type: "RelationshipType",
            id: "6e7bc55a-4858-4158-a50d-4137bbfef1de",
            direction: "parent",
            multiplicity: "Many",
            required: false
        }
    });
    public readonly _props: SoortenOogstproductenProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<SoortenOogstproducten>(this, {
            _props: override,
            VoorraadMeerjarenPlantopstand: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const VoorraadMeerjarenPlantopstandFetchContext = getFetchTreeOfRelation(this._fetchContext, SoortenOogstproducten.PROPS.VoorraadMeerjarenPlantopstand.id, SoortenOogstproducten.PROPS.VoorraadMeerjarenPlantopstand.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get name() {
                try {
                    return getFieldValue(metadataService, entity, Datastore.PROPS.name.id, Datastore.PROPS.name.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Datastore.PROPS.value.id, Datastore.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get VoorraadMeerjarenPlantopstand() {
                try {
                    return getRelatedPlural(entity, VoorraadMeerjarenPlantopstandFetchContext, SoortenOogstproducten.PROPS.VoorraadMeerjarenPlantopstand.id, SoortenOogstproducten.PROPS.VoorraadMeerjarenPlantopstand.direction, SoortenOogstproducten.PROPS.VoorraadMeerjarenPlantopstand.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get VoorraadMeerjarenPlantopstand(): VoorraadMeerjarenPlantopstand[] | undefined {
        const VoorraadMeerjarenPlantopstandFetchContext = getFetchTreeOfRelation(this._fetchContext, SoortenOogstproducten.PROPS.VoorraadMeerjarenPlantopstand.id, SoortenOogstproducten.PROPS.VoorraadMeerjarenPlantopstand.direction);
        if (VoorraadMeerjarenPlantopstandFetchContext !== undefined) {
            return toTypedEntity(this._props.VoorraadMeerjarenPlantopstand, this._metadataService, VoorraadMeerjarenPlantopstandFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class SoortFiets extends Datastore {
    public static ID = "c6f4b3ad-818d-4e53-a24b-2e5a8eb51d6b";
    public static PROPS = createProps({
        ...Datastore.PROPS,
        naam: {
            type: "Field",
            id: "e9dc85f3-0337-40b0-9d6b-978ea4d20fee",
            required: true
        },
        fiets: {
            type: "RelationshipType",
            id: "2bcaca2f-6df9-4745-9d50-049d2c29adaa",
            direction: "parent",
            multiplicity: "One",
            required: true
        }
    });
    public readonly _props: SoortFietsProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<SoortFiets>(this, {
            _props: override,
            naam: computed,
            fiets: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const fietsFetchContext = getFetchTreeOfRelation(this._fetchContext, SoortFiets.PROPS.fiets.id, SoortFiets.PROPS.fiets.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get name() {
                try {
                    return getFieldValue(metadataService, entity, Datastore.PROPS.name.id, Datastore.PROPS.name.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Datastore.PROPS.value.id, Datastore.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get fiets() {
                try {
                    return getRelatedSingular(entity, fietsFetchContext, SoortFiets.PROPS.fiets.id, SoortFiets.PROPS.fiets.direction, SoortFiets.PROPS.fiets.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get naam() {
                try {
                    return getFieldValue(metadataService, entity, SoortFiets.PROPS.naam.id, SoortFiets.PROPS.naam.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get naam(): string {
        return TextType.getRawText(this._props.naam)!;
    }
    get fiets(): Fiets | undefined {
        const fietsFetchContext = getFetchTreeOfRelation(this._fetchContext, SoortFiets.PROPS.fiets.id, SoortFiets.PROPS.fiets.direction);
        if (fietsFetchContext !== undefined) {
            return toTypedEntity(this._props.fiets, this._metadataService, fietsFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class VKRIGoederenType extends Datastore {
    public static ID = "c1d56353-3b30-4db4-a0a0-a71c366a42ee";
    public static PROPS = createProps({
        ...Datastore.PROPS,
        goederen: {
            type: "RelationshipType",
            id: "e6fcddc2-b639-4e44-9bda-e557458197ab",
            direction: "parent",
            multiplicity: "Many",
            required: false
        }
    });
    public readonly _props: VKRIGoederenTypeProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<VKRIGoederenType>(this, {
            _props: override,
            goederen: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const goederenFetchContext = getFetchTreeOfRelation(this._fetchContext, VKRIGoederenType.PROPS.goederen.id, VKRIGoederenType.PROPS.goederen.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get name() {
                try {
                    return getFieldValue(metadataService, entity, Datastore.PROPS.name.id, Datastore.PROPS.name.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Datastore.PROPS.value.id, Datastore.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get goederen() {
                try {
                    return getRelatedPlural(entity, goederenFetchContext, VKRIGoederenType.PROPS.goederen.id, VKRIGoederenType.PROPS.goederen.direction, VKRIGoederenType.PROPS.goederen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get goederen(): Goederen[] | undefined {
        const goederenFetchContext = getFetchTreeOfRelation(this._fetchContext, VKRIGoederenType.PROPS.goederen.id, VKRIGoederenType.PROPS.goederen.direction);
        if (goederenFetchContext !== undefined) {
            return toTypedEntity(this._props.goederen, this._metadataService, goederenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class VKRIMaatregelset extends Datastore {
    public static ID = "1fa81dbf-4bd2-4945-b6fc-02c282aef402";
    public static PROPS = createProps({
        ...Datastore.PROPS,
        relevanties: {
            type: "RelationshipType",
            id: "526728f9-20a1-4ef4-a765-f439faeb4f7e",
            direction: "parent",
            multiplicity: "Many",
            required: false
        },
        categorie: {
            type: "RelationshipType",
            id: "c31bd451-a55e-4a60-a0bb-eaee77dde020",
            direction: "parent",
            multiplicity: "One",
            required: true
        }
    });
    public readonly _props: VKRIMaatregelsetProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<VKRIMaatregelset>(this, {
            _props: override,
            relevanties: computed,
            categorie: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const relevantiesFetchContext = getFetchTreeOfRelation(this._fetchContext, VKRIMaatregelset.PROPS.relevanties.id, VKRIMaatregelset.PROPS.relevanties.direction);
        const categorieFetchContext = getFetchTreeOfRelation(this._fetchContext, VKRIMaatregelset.PROPS.categorie.id, VKRIMaatregelset.PROPS.categorie.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get name() {
                try {
                    return getFieldValue(metadataService, entity, Datastore.PROPS.name.id, Datastore.PROPS.name.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Datastore.PROPS.value.id, Datastore.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get relevanties() {
                try {
                    return getRelatedPlural(entity, relevantiesFetchContext, VKRIMaatregelset.PROPS.relevanties.id, VKRIMaatregelset.PROPS.relevanties.direction, VKRIMaatregelset.PROPS.relevanties.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get categorie() {
                try {
                    return getRelatedSingular(entity, categorieFetchContext, VKRIMaatregelset.PROPS.categorie.id, VKRIMaatregelset.PROPS.categorie.direction, VKRIMaatregelset.PROPS.categorie.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get relevanties(): VKRIMaatregelRelevantie[] | undefined {
        const relevantiesFetchContext = getFetchTreeOfRelation(this._fetchContext, VKRIMaatregelset.PROPS.relevanties.id, VKRIMaatregelset.PROPS.relevanties.direction);
        if (relevantiesFetchContext !== undefined) {
            return toTypedEntity(this._props.relevanties, this._metadataService, relevantiesFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get categorie(): MaatregelCategorieVKRI | undefined {
        const categorieFetchContext = getFetchTreeOfRelation(this._fetchContext, VKRIMaatregelset.PROPS.categorie.id, VKRIMaatregelset.PROPS.categorie.direction);
        if (categorieFetchContext !== undefined) {
            return toTypedEntity(this._props.categorie, this._metadataService, categorieFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class DekkingsEntiteit extends ObjEntity {
    public static ID = "1bdd9dd4-b3cd-4707-ac46-6722a6a32aeb";
    public static PROPS = createProps({
        ...ObjEntity.PROPS,
        dekkingen: {
            type: "RelationshipType",
            id: "677704a0-7fdb-4640-8b52-425597122077",
            direction: "child",
            multiplicity: "Many",
            required: false
        },
        offertes: {
            type: "RelationshipType",
            id: "cb53fb3a-d5d4-4fdd-8b38-2c8da6a41963",
            direction: "parent",
            multiplicity: "Many",
            required: false
        },
        beoordelingenMissend: {
            type: "RelationshipType",
            id: "346cad39-987c-43c0-ab6a-1fec0416cae9",
            direction: "parent",
            multiplicity: "Many",
            required: false
        },
        beoordelingenAanwezig: {
            type: "RelationshipType",
            id: "dda7c31c-476f-4879-8b49-85f4290acb03",
            direction: "parent",
            multiplicity: "Many",
            required: false
        },
        branches: {
            type: "RelationshipType",
            id: "b3d2e453-3bbe-4f05-80f1-b262a51b3f51",
            direction: "parent",
            multiplicity: "Many",
            required: false
        }
    });
    public readonly _props: DekkingsEntiteitProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<DekkingsEntiteit>(this, {
            _props: override,
            dekkingen: computed,
            offertes: computed,
            beoordelingenMissend: computed,
            beoordelingenAanwezig: computed,
            branches: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const dekkingenFetchContext = getFetchTreeOfRelation(this._fetchContext, DekkingsEntiteit.PROPS.dekkingen.id, DekkingsEntiteit.PROPS.dekkingen.direction);
        const offertesFetchContext = getFetchTreeOfRelation(this._fetchContext, DekkingsEntiteit.PROPS.offertes.id, DekkingsEntiteit.PROPS.offertes.direction);
        const beoordelingenMissendFetchContext = getFetchTreeOfRelation(this._fetchContext, DekkingsEntiteit.PROPS.beoordelingenMissend.id, DekkingsEntiteit.PROPS.beoordelingenMissend.direction);
        const beoordelingenAanwezigFetchContext = getFetchTreeOfRelation(this._fetchContext, DekkingsEntiteit.PROPS.beoordelingenAanwezig.id, DekkingsEntiteit.PROPS.beoordelingenAanwezig.direction);
        const branchesFetchContext = getFetchTreeOfRelation(this._fetchContext, DekkingsEntiteit.PROPS.branches.id, DekkingsEntiteit.PROPS.branches.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get dekkingen() {
                try {
                    return getRelatedPlural(entity, dekkingenFetchContext, DekkingsEntiteit.PROPS.dekkingen.id, DekkingsEntiteit.PROPS.dekkingen.direction, DekkingsEntiteit.PROPS.dekkingen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get offertes() {
                try {
                    return getRelatedPlural(entity, offertesFetchContext, DekkingsEntiteit.PROPS.offertes.id, DekkingsEntiteit.PROPS.offertes.direction, DekkingsEntiteit.PROPS.offertes.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get beoordelingenMissend() {
                try {
                    return getRelatedPlural(entity, beoordelingenMissendFetchContext, DekkingsEntiteit.PROPS.beoordelingenMissend.id, DekkingsEntiteit.PROPS.beoordelingenMissend.direction, DekkingsEntiteit.PROPS.beoordelingenMissend.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get beoordelingenAanwezig() {
                try {
                    return getRelatedPlural(entity, beoordelingenAanwezigFetchContext, DekkingsEntiteit.PROPS.beoordelingenAanwezig.id, DekkingsEntiteit.PROPS.beoordelingenAanwezig.direction, DekkingsEntiteit.PROPS.beoordelingenAanwezig.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get branches() {
                try {
                    return getRelatedPlural(entity, branchesFetchContext, DekkingsEntiteit.PROPS.branches.id, DekkingsEntiteit.PROPS.branches.direction, DekkingsEntiteit.PROPS.branches.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get dekkingen(): DekkingsType[] | undefined {
        const dekkingenFetchContext = getFetchTreeOfRelation(this._fetchContext, DekkingsEntiteit.PROPS.dekkingen.id, DekkingsEntiteit.PROPS.dekkingen.direction);
        if (dekkingenFetchContext !== undefined) {
            return toTypedEntity(this._props.dekkingen, this._metadataService, dekkingenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get offertes(): Offerte[] | undefined {
        const offertesFetchContext = getFetchTreeOfRelation(this._fetchContext, DekkingsEntiteit.PROPS.offertes.id, DekkingsEntiteit.PROPS.offertes.direction);
        if (offertesFetchContext !== undefined) {
            return toTypedEntity(this._props.offertes, this._metadataService, offertesFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get beoordelingenMissend(): BrancheBeoordeling[] | undefined {
        const beoordelingenMissendFetchContext = getFetchTreeOfRelation(this._fetchContext, DekkingsEntiteit.PROPS.beoordelingenMissend.id, DekkingsEntiteit.PROPS.beoordelingenMissend.direction);
        if (beoordelingenMissendFetchContext !== undefined) {
            return toTypedEntity(this._props.beoordelingenMissend, this._metadataService, beoordelingenMissendFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get beoordelingenAanwezig(): BrancheBeoordeling[] | undefined {
        const beoordelingenAanwezigFetchContext = getFetchTreeOfRelation(this._fetchContext, DekkingsEntiteit.PROPS.beoordelingenAanwezig.id, DekkingsEntiteit.PROPS.beoordelingenAanwezig.direction);
        if (beoordelingenAanwezigFetchContext !== undefined) {
            return toTypedEntity(this._props.beoordelingenAanwezig, this._metadataService, beoordelingenAanwezigFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get branches(): Branche[] | undefined {
        const branchesFetchContext = getFetchTreeOfRelation(this._fetchContext, DekkingsEntiteit.PROPS.branches.id, DekkingsEntiteit.PROPS.branches.direction);
        if (branchesFetchContext !== undefined) {
            return toTypedEntity(this._props.branches, this._metadataService, branchesFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class DekkingsType extends ObjEntity {
    public static ID = "b702f1ed-f4dd-4805-a1a7-8acb7ac605a0";
    public static PROPS = createProps({
        ...ObjEntity.PROPS,
        dekkingsentiteiten: {
            type: "RelationshipType",
            id: "677704a0-7fdb-4640-8b52-425597122077",
            direction: "parent",
            multiplicity: "Many",
            required: false
        },
        risicoprofielen: {
            type: "RelationshipType",
            id: "95e5d8ac-1a8c-4a68-9617-8c8d58dd3154",
            direction: "parent",
            multiplicity: "Many",
            required: false
        }
    });
    public readonly _props: DekkingsTypeProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<DekkingsType>(this, {
            _props: override,
            dekkingsentiteiten: computed,
            risicoprofielen: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const dekkingsentiteitenFetchContext = getFetchTreeOfRelation(this._fetchContext, DekkingsType.PROPS.dekkingsentiteiten.id, DekkingsType.PROPS.dekkingsentiteiten.direction);
        const risicoprofielenFetchContext = getFetchTreeOfRelation(this._fetchContext, DekkingsType.PROPS.risicoprofielen.id, DekkingsType.PROPS.risicoprofielen.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get dekkingsentiteiten() {
                try {
                    return getRelatedPlural(entity, dekkingsentiteitenFetchContext, DekkingsType.PROPS.dekkingsentiteiten.id, DekkingsType.PROPS.dekkingsentiteiten.direction, DekkingsType.PROPS.dekkingsentiteiten.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get risicoprofielen() {
                try {
                    return getRelatedPlural(entity, risicoprofielenFetchContext, DekkingsType.PROPS.risicoprofielen.id, DekkingsType.PROPS.risicoprofielen.direction, DekkingsType.PROPS.risicoprofielen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get dekkingsentiteiten(): DekkingsEntiteit[] | undefined {
        const dekkingsentiteitenFetchContext = getFetchTreeOfRelation(this._fetchContext, DekkingsType.PROPS.dekkingsentiteiten.id, DekkingsType.PROPS.dekkingsentiteiten.direction);
        if (dekkingsentiteitenFetchContext !== undefined) {
            return toTypedEntity(this._props.dekkingsentiteiten, this._metadataService, dekkingsentiteitenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get risicoprofielen(): Risicoprofiel[] | undefined {
        const risicoprofielenFetchContext = getFetchTreeOfRelation(this._fetchContext, DekkingsType.PROPS.risicoprofielen.id, DekkingsType.PROPS.risicoprofielen.direction);
        if (risicoprofielenFetchContext !== undefined) {
            return toTypedEntity(this._props.risicoprofielen, this._metadataService, risicoprofielenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class Deponeringen extends ObjEntity {
    public static ID = "6dab63d0-4376-4dfd-bae3-cc9a7579018d";
    public static PROPS = createProps({
        ...ObjEntity.PROPS,
        datum: {
            type: "Field",
            id: "d4f3c6e1-7921-4498-9a1c-27be543be890",
            required: true
        },
        organization: {
            type: "RelationshipType",
            id: "27f45c01-ebef-4a73-b7fc-4a1e411d86f4",
            direction: "parent",
            multiplicity: "One",
            required: true
        }
    });
    public readonly _props: DeponeringenProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Deponeringen>(this, {
            _props: override,
            datum: computed,
            organization: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, Deponeringen.PROPS.organization.id, Deponeringen.PROPS.organization.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, Deponeringen.PROPS.organization.id, Deponeringen.PROPS.organization.direction, Deponeringen.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get datum() {
                try {
                    return getFieldValue(metadataService, entity, Deponeringen.PROPS.datum.id, Deponeringen.PROPS.datum.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get datum(): Date {
        return DateType.getRawDate(this._props.datum)!;
    }
    get organization(): Organization | undefined {
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, Deponeringen.PROPS.organization.id, Deponeringen.PROPS.organization.direction);
        if (organizationFetchContext !== undefined) {
            return toTypedEntity(this._props.organization, this._metadataService, organizationFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class Balans extends Deponeringen {
    public static ID = "d3276f46-5db0-4e31-8862-64648f056b65";
    public static PROPS = createProps({
        ...Deponeringen.PROPS,
        immaterieleVasteActiva: {
            type: "Field",
            id: "ac691027-7499-4ca0-9b29-d0453f21cf5d",
            required: false
        },
        materieleVasteActiva: {
            type: "Field",
            id: "82eab9e8-023a-4c22-8737-1bb6c37d0ca2",
            required: false
        },
        financieleVasteActiva: {
            type: "Field",
            id: "db75be6e-ceca-4c3a-ae9b-ca27dddf1395",
            required: false
        },
        overigeVasteActiva: {
            type: "Field",
            id: "d1126a6c-4bc0-486c-ab8d-47e70de7ec02",
            required: false
        },
        voorraden: {
            type: "Field",
            id: "f22de59f-60ca-41f7-a3ea-253330e38d2e",
            required: false
        },
        vorderingenEnOverlopendeActiva: {
            type: "Field",
            id: "731b50af-cb15-48f6-bc15-74f1ae833615",
            required: false
        },
        liquideMiddelen: {
            type: "Field",
            id: "a0a54dce-da4a-4f60-b3dd-0fe1f1909bd3",
            required: false
        },
        overigeVlottendeActiva: {
            type: "Field",
            id: "48566ae2-3e9d-4dff-ae5d-cef2f7737415",
            required: false
        },
        eigenVermogen: {
            type: "Field",
            id: "1ca16f0a-ab01-42bc-8a01-08c6aee0edb3",
            required: false
        },
        langlopendeSchulden: {
            type: "Field",
            id: "16127471-79b5-48e2-a541-c7d159685f05",
            required: false
        },
        kortlopendeSchulden: {
            type: "Field",
            id: "28957c43-85a5-4f28-b678-4e1ea0311f50",
            required: false
        },
        overigePassiva: {
            type: "Field",
            id: "327eb9cb-014f-463b-86b1-057694ca6222",
            required: false
        },
        totaalActiva: {
            type: "Field",
            id: "ec46ed2f-7d47-4c75-9f55-dd3994274e4f",
            required: false
        },
        totaalPassiva: {
            type: "Field",
            id: "b2762048-c4c6-4df4-95d5-534edb52fdbe",
            required: false
        }
    });
    public readonly _props: BalansProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Balans>(this, {
            _props: override,
            immaterieleVasteActiva: computed,
            materieleVasteActiva: computed,
            financieleVasteActiva: computed,
            overigeVasteActiva: computed,
            voorraden: computed,
            vorderingenEnOverlopendeActiva: computed,
            liquideMiddelen: computed,
            overigeVlottendeActiva: computed,
            eigenVermogen: computed,
            langlopendeSchulden: computed,
            kortlopendeSchulden: computed,
            overigePassiva: computed,
            totaalActiva: computed,
            totaalPassiva: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, Deponeringen.PROPS.organization.id, Deponeringen.PROPS.organization.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, Deponeringen.PROPS.organization.id, Deponeringen.PROPS.organization.direction, Deponeringen.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get datum() {
                try {
                    return getFieldValue(metadataService, entity, Deponeringen.PROPS.datum.id, Deponeringen.PROPS.datum.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get immaterieleVasteActiva() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Balans.PROPS.immaterieleVasteActiva.id, Balans.PROPS.immaterieleVasteActiva.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get materieleVasteActiva() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Balans.PROPS.materieleVasteActiva.id, Balans.PROPS.materieleVasteActiva.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get financieleVasteActiva() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Balans.PROPS.financieleVasteActiva.id, Balans.PROPS.financieleVasteActiva.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get overigeVasteActiva() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Balans.PROPS.overigeVasteActiva.id, Balans.PROPS.overigeVasteActiva.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get voorraden() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Balans.PROPS.voorraden.id, Balans.PROPS.voorraden.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get vorderingenEnOverlopendeActiva() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Balans.PROPS.vorderingenEnOverlopendeActiva.id, Balans.PROPS.vorderingenEnOverlopendeActiva.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get liquideMiddelen() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Balans.PROPS.liquideMiddelen.id, Balans.PROPS.liquideMiddelen.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get overigeVlottendeActiva() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Balans.PROPS.overigeVlottendeActiva.id, Balans.PROPS.overigeVlottendeActiva.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get eigenVermogen() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Balans.PROPS.eigenVermogen.id, Balans.PROPS.eigenVermogen.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get langlopendeSchulden() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Balans.PROPS.langlopendeSchulden.id, Balans.PROPS.langlopendeSchulden.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get kortlopendeSchulden() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Balans.PROPS.kortlopendeSchulden.id, Balans.PROPS.kortlopendeSchulden.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get overigePassiva() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Balans.PROPS.overigePassiva.id, Balans.PROPS.overigePassiva.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get totaalActiva() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Balans.PROPS.totaalActiva.id, Balans.PROPS.totaalActiva.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get totaalPassiva() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Balans.PROPS.totaalPassiva.id, Balans.PROPS.totaalPassiva.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get immaterieleVasteActiva(): Decimal | null {
        return this._props.immaterieleVasteActiva !== null ? CurrencyType.getRawAmount(this._props.immaterieleVasteActiva)! : null;
    }
    get materieleVasteActiva(): Decimal | null {
        return this._props.materieleVasteActiva !== null ? CurrencyType.getRawAmount(this._props.materieleVasteActiva)! : null;
    }
    get financieleVasteActiva(): Decimal | null {
        return this._props.financieleVasteActiva !== null ? CurrencyType.getRawAmount(this._props.financieleVasteActiva)! : null;
    }
    get overigeVasteActiva(): Decimal | null {
        return this._props.overigeVasteActiva !== null ? CurrencyType.getRawAmount(this._props.overigeVasteActiva)! : null;
    }
    get voorraden(): Decimal | null {
        return this._props.voorraden !== null ? CurrencyType.getRawAmount(this._props.voorraden)! : null;
    }
    get vorderingenEnOverlopendeActiva(): Decimal | null {
        return this._props.vorderingenEnOverlopendeActiva !== null ? CurrencyType.getRawAmount(this._props.vorderingenEnOverlopendeActiva)! : null;
    }
    get liquideMiddelen(): Decimal | null {
        return this._props.liquideMiddelen !== null ? CurrencyType.getRawAmount(this._props.liquideMiddelen)! : null;
    }
    get overigeVlottendeActiva(): Decimal | null {
        return this._props.overigeVlottendeActiva !== null ? CurrencyType.getRawAmount(this._props.overigeVlottendeActiva)! : null;
    }
    get eigenVermogen(): Decimal | null {
        return this._props.eigenVermogen !== null ? CurrencyType.getRawAmount(this._props.eigenVermogen)! : null;
    }
    get langlopendeSchulden(): Decimal | null {
        return this._props.langlopendeSchulden !== null ? CurrencyType.getRawAmount(this._props.langlopendeSchulden)! : null;
    }
    get kortlopendeSchulden(): Decimal | null {
        return this._props.kortlopendeSchulden !== null ? CurrencyType.getRawAmount(this._props.kortlopendeSchulden)! : null;
    }
    get overigePassiva(): Decimal | null {
        return this._props.overigePassiva !== null ? CurrencyType.getRawAmount(this._props.overigePassiva)! : null;
    }
    get totaalActiva(): Decimal | null {
        return this._props.totaalActiva !== null ? CurrencyType.getRawAmount(this._props.totaalActiva)! : null;
    }
    get totaalPassiva(): Decimal | null {
        return this._props.totaalPassiva !== null ? CurrencyType.getRawAmount(this._props.totaalPassiva)! : null;
    }
}
export class Kengetallen extends Deponeringen {
    public static ID = "f55e6de2-7680-46a1-af16-59b730ca98a3";
    public static PROPS = createProps({
        ...Deponeringen.PROPS,
        currentRatio: {
            type: "Field",
            id: "f01566e0-4de4-4152-a90e-b07a78988a66",
            required: false
        },
        quickRatio: {
            type: "Field",
            id: "af22b83e-08a0-4b56-a728-1c0aa4689597",
            required: false
        },
        goudenBalans: {
            type: "Field",
            id: "e4130a4f-f31d-40fb-80e3-0973731c8651",
            required: false
        },
        balanstotaalVreemdVermogen: {
            type: "Field",
            id: "12130cf7-0649-4b3d-b6a0-64d3b03c9a25",
            required: false
        },
        eigenVermogenBalanstotaal: {
            type: "Field",
            id: "d751af47-8ecb-4796-b505-96683e7f1294",
            required: false
        },
        bedrijfsresultaatBalanstotaal: {
            type: "Field",
            id: "bd8f7442-5d10-4156-b0c1-c3875309c75b",
            required: false
        },
        nettoResultaatEigenVermogen: {
            type: "Field",
            id: "ba586422-4846-42a4-91dd-bb858b3c9309",
            required: false
        },
        brutoWinstmarge: {
            type: "Field",
            id: "2358789e-ea2b-4dc3-8295-ce6ba25098b7",
            required: false
        },
        hefboomEffect: {
            type: "Field",
            id: "163a81eb-06d7-412e-ae88-34518f5ba2c5",
            required: false
        },
        werkkapitaal: {
            type: "Field",
            id: "63fc99e4-f778-4a89-9f7c-281f42af035e",
            required: false
        }
    });
    public readonly _props: KengetallenProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Kengetallen>(this, {
            _props: override,
            currentRatio: computed,
            quickRatio: computed,
            goudenBalans: computed,
            balanstotaalVreemdVermogen: computed,
            eigenVermogenBalanstotaal: computed,
            bedrijfsresultaatBalanstotaal: computed,
            nettoResultaatEigenVermogen: computed,
            brutoWinstmarge: computed,
            hefboomEffect: computed,
            werkkapitaal: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, Deponeringen.PROPS.organization.id, Deponeringen.PROPS.organization.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, Deponeringen.PROPS.organization.id, Deponeringen.PROPS.organization.direction, Deponeringen.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get datum() {
                try {
                    return getFieldValue(metadataService, entity, Deponeringen.PROPS.datum.id, Deponeringen.PROPS.datum.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get currentRatio() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Kengetallen.PROPS.currentRatio.id, Kengetallen.PROPS.currentRatio.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get quickRatio() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Kengetallen.PROPS.quickRatio.id, Kengetallen.PROPS.quickRatio.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get goudenBalans() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Kengetallen.PROPS.goudenBalans.id, Kengetallen.PROPS.goudenBalans.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get balanstotaalVreemdVermogen() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Kengetallen.PROPS.balanstotaalVreemdVermogen.id, Kengetallen.PROPS.balanstotaalVreemdVermogen.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get eigenVermogenBalanstotaal() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Kengetallen.PROPS.eigenVermogenBalanstotaal.id, Kengetallen.PROPS.eigenVermogenBalanstotaal.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get bedrijfsresultaatBalanstotaal() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Kengetallen.PROPS.bedrijfsresultaatBalanstotaal.id, Kengetallen.PROPS.bedrijfsresultaatBalanstotaal.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get nettoResultaatEigenVermogen() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Kengetallen.PROPS.nettoResultaatEigenVermogen.id, Kengetallen.PROPS.nettoResultaatEigenVermogen.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get brutoWinstmarge() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Kengetallen.PROPS.brutoWinstmarge.id, Kengetallen.PROPS.brutoWinstmarge.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get hefboomEffect() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Kengetallen.PROPS.hefboomEffect.id, Kengetallen.PROPS.hefboomEffect.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get werkkapitaal() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Kengetallen.PROPS.werkkapitaal.id, Kengetallen.PROPS.werkkapitaal.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get currentRatio(): Decimal | null {
        return this._props.currentRatio !== null ? NumberType.getRawNumber(this._props.currentRatio)! : null;
    }
    get quickRatio(): Decimal | null {
        return this._props.quickRatio !== null ? NumberType.getRawNumber(this._props.quickRatio)! : null;
    }
    get goudenBalans(): Decimal | null {
        return this._props.goudenBalans !== null ? NumberType.getRawNumber(this._props.goudenBalans)! : null;
    }
    get balanstotaalVreemdVermogen(): Decimal | null {
        return this._props.balanstotaalVreemdVermogen !== null ? NumberType.getRawNumber(this._props.balanstotaalVreemdVermogen)! : null;
    }
    get eigenVermogenBalanstotaal(): Decimal | null {
        return this._props.eigenVermogenBalanstotaal !== null ? NumberType.getRawNumber(this._props.eigenVermogenBalanstotaal)! : null;
    }
    get bedrijfsresultaatBalanstotaal(): Decimal | null {
        return this._props.bedrijfsresultaatBalanstotaal !== null ? NumberType.getRawNumber(this._props.bedrijfsresultaatBalanstotaal)! : null;
    }
    get nettoResultaatEigenVermogen(): Decimal | null {
        return this._props.nettoResultaatEigenVermogen !== null ? NumberType.getRawNumber(this._props.nettoResultaatEigenVermogen)! : null;
    }
    get brutoWinstmarge(): Decimal | null {
        return this._props.brutoWinstmarge !== null ? NumberType.getRawNumber(this._props.brutoWinstmarge)! : null;
    }
    get hefboomEffect(): Decimal | null {
        return this._props.hefboomEffect !== null ? NumberType.getRawNumber(this._props.hefboomEffect)! : null;
    }
    get werkkapitaal(): Decimal | null {
        return this._props.werkkapitaal !== null ? NumberType.getRawNumber(this._props.werkkapitaal)! : null;
    }
}
export class VerliesEnWinstrekening extends Deponeringen {
    public static ID = "545c6758-9c65-4dc7-9a06-b08e9fd130b2";
    public static PROPS = createProps({
        ...Deponeringen.PROPS,
        nettoOmzet: {
            type: "Field",
            id: "84362a14-4136-40ef-8f2d-b1dab2ef338a",
            required: false
        },
        kostprijsOmzet: {
            type: "Field",
            id: "ed78b430-a70a-4569-aaa6-c983592a4c9b",
            required: false
        },
        brutoOmzetresultaat: {
            type: "Field",
            id: "69474381-0884-48bc-8182-8f36a02339ca",
            required: false
        },
        overigeBedrijfskosten: {
            type: "Field",
            id: "518d5554-6179-4b3c-89f7-1e0f58a52a28",
            required: false
        },
        overigeBedrijfsopbrengsten: {
            type: "Field",
            id: "8c0b6ca3-ec44-4be6-9387-47e4762d73bb",
            required: false
        },
        bedrijfsresultaat: {
            type: "Field",
            id: "d3ed2c1b-eef3-4eee-8e87-bd92583bb642",
            required: false
        },
        andereRentebatenEnSoortgelijkeOpbrengsten: {
            type: "Field",
            id: "78f83c8a-fb41-4953-952a-ed56fc98c3f1",
            required: false
        },
        rentelastenEnSoortgelijkeKosten: {
            type: "Field",
            id: "25d33d9b-0e8d-4d54-a317-b5d30c57c027",
            required: false
        },
        saldoFinancieleBatenLasten: {
            type: "Field",
            id: "1b591586-f58c-46b3-a66b-5c7744cb4ce1",
            required: false
        },
        resultaatVoorBelastingen: {
            type: "Field",
            id: "791a2866-275b-438f-9746-b9c280f354a5",
            required: false
        },
        belastingen: {
            type: "Field",
            id: "04a47a4d-10fb-4a24-a1a8-f3fc5443301e",
            required: false
        },
        aandeelInWvDeelnemingen: {
            type: "Field",
            id: "8789102d-c220-4dba-b5ab-86632e2b9b6e",
            required: false
        },
        resultaatNaBelastingen: {
            type: "Field",
            id: "0dce4777-9298-485f-8ebd-ff745a78ed47",
            required: false
        },
        resultaatuitDeelnemingenNaBelasting: {
            type: "Field",
            id: "2309f9dd-570c-4395-b92f-e17e4b71f3e9",
            required: false
        },
        nettoResultaat: {
            type: "Field",
            id: "0d6ed4a8-c110-4a3d-9d1f-c8bd18b23130",
            required: false
        }
    });
    public readonly _props: VerliesEnWinstrekeningProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<VerliesEnWinstrekening>(this, {
            _props: override,
            nettoOmzet: computed,
            kostprijsOmzet: computed,
            brutoOmzetresultaat: computed,
            overigeBedrijfskosten: computed,
            overigeBedrijfsopbrengsten: computed,
            bedrijfsresultaat: computed,
            andereRentebatenEnSoortgelijkeOpbrengsten: computed,
            rentelastenEnSoortgelijkeKosten: computed,
            saldoFinancieleBatenLasten: computed,
            resultaatVoorBelastingen: computed,
            belastingen: computed,
            aandeelInWvDeelnemingen: computed,
            resultaatNaBelastingen: computed,
            resultaatuitDeelnemingenNaBelasting: computed,
            nettoResultaat: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, Deponeringen.PROPS.organization.id, Deponeringen.PROPS.organization.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, Deponeringen.PROPS.organization.id, Deponeringen.PROPS.organization.direction, Deponeringen.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get datum() {
                try {
                    return getFieldValue(metadataService, entity, Deponeringen.PROPS.datum.id, Deponeringen.PROPS.datum.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get nettoOmzet() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, VerliesEnWinstrekening.PROPS.nettoOmzet.id, VerliesEnWinstrekening.PROPS.nettoOmzet.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get kostprijsOmzet() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, VerliesEnWinstrekening.PROPS.kostprijsOmzet.id, VerliesEnWinstrekening.PROPS.kostprijsOmzet.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get brutoOmzetresultaat() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, VerliesEnWinstrekening.PROPS.brutoOmzetresultaat.id, VerliesEnWinstrekening.PROPS.brutoOmzetresultaat.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get overigeBedrijfskosten() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, VerliesEnWinstrekening.PROPS.overigeBedrijfskosten.id, VerliesEnWinstrekening.PROPS.overigeBedrijfskosten.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get overigeBedrijfsopbrengsten() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, VerliesEnWinstrekening.PROPS.overigeBedrijfsopbrengsten.id, VerliesEnWinstrekening.PROPS.overigeBedrijfsopbrengsten.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get bedrijfsresultaat() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, VerliesEnWinstrekening.PROPS.bedrijfsresultaat.id, VerliesEnWinstrekening.PROPS.bedrijfsresultaat.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get andereRentebatenEnSoortgelijkeOpbrengsten() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, VerliesEnWinstrekening.PROPS.andereRentebatenEnSoortgelijkeOpbrengsten.id, VerliesEnWinstrekening.PROPS.andereRentebatenEnSoortgelijkeOpbrengsten.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get rentelastenEnSoortgelijkeKosten() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, VerliesEnWinstrekening.PROPS.rentelastenEnSoortgelijkeKosten.id, VerliesEnWinstrekening.PROPS.rentelastenEnSoortgelijkeKosten.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get saldoFinancieleBatenLasten() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, VerliesEnWinstrekening.PROPS.saldoFinancieleBatenLasten.id, VerliesEnWinstrekening.PROPS.saldoFinancieleBatenLasten.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get resultaatVoorBelastingen() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, VerliesEnWinstrekening.PROPS.resultaatVoorBelastingen.id, VerliesEnWinstrekening.PROPS.resultaatVoorBelastingen.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get belastingen() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, VerliesEnWinstrekening.PROPS.belastingen.id, VerliesEnWinstrekening.PROPS.belastingen.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get aandeelInWvDeelnemingen() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, VerliesEnWinstrekening.PROPS.aandeelInWvDeelnemingen.id, VerliesEnWinstrekening.PROPS.aandeelInWvDeelnemingen.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get resultaatNaBelastingen() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, VerliesEnWinstrekening.PROPS.resultaatNaBelastingen.id, VerliesEnWinstrekening.PROPS.resultaatNaBelastingen.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get resultaatuitDeelnemingenNaBelasting() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, VerliesEnWinstrekening.PROPS.resultaatuitDeelnemingenNaBelasting.id, VerliesEnWinstrekening.PROPS.resultaatuitDeelnemingenNaBelasting.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get nettoResultaat() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, VerliesEnWinstrekening.PROPS.nettoResultaat.id, VerliesEnWinstrekening.PROPS.nettoResultaat.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get nettoOmzet(): Decimal | null {
        return this._props.nettoOmzet !== null ? CurrencyType.getRawAmount(this._props.nettoOmzet)! : null;
    }
    get kostprijsOmzet(): Decimal | null {
        return this._props.kostprijsOmzet !== null ? CurrencyType.getRawAmount(this._props.kostprijsOmzet)! : null;
    }
    get brutoOmzetresultaat(): Decimal | null {
        return this._props.brutoOmzetresultaat !== null ? CurrencyType.getRawAmount(this._props.brutoOmzetresultaat)! : null;
    }
    get overigeBedrijfskosten(): Decimal | null {
        return this._props.overigeBedrijfskosten !== null ? CurrencyType.getRawAmount(this._props.overigeBedrijfskosten)! : null;
    }
    get overigeBedrijfsopbrengsten(): Decimal | null {
        return this._props.overigeBedrijfsopbrengsten !== null ? CurrencyType.getRawAmount(this._props.overigeBedrijfsopbrengsten)! : null;
    }
    get bedrijfsresultaat(): Decimal | null {
        return this._props.bedrijfsresultaat !== null ? CurrencyType.getRawAmount(this._props.bedrijfsresultaat)! : null;
    }
    get andereRentebatenEnSoortgelijkeOpbrengsten(): Decimal | null {
        return this._props.andereRentebatenEnSoortgelijkeOpbrengsten !== null ? CurrencyType.getRawAmount(this._props.andereRentebatenEnSoortgelijkeOpbrengsten)! : null;
    }
    get rentelastenEnSoortgelijkeKosten(): Decimal | null {
        return this._props.rentelastenEnSoortgelijkeKosten !== null ? CurrencyType.getRawAmount(this._props.rentelastenEnSoortgelijkeKosten)! : null;
    }
    get saldoFinancieleBatenLasten(): Decimal | null {
        return this._props.saldoFinancieleBatenLasten !== null ? CurrencyType.getRawAmount(this._props.saldoFinancieleBatenLasten)! : null;
    }
    get resultaatVoorBelastingen(): Decimal | null {
        return this._props.resultaatVoorBelastingen !== null ? CurrencyType.getRawAmount(this._props.resultaatVoorBelastingen)! : null;
    }
    get belastingen(): Decimal | null {
        return this._props.belastingen !== null ? CurrencyType.getRawAmount(this._props.belastingen)! : null;
    }
    get aandeelInWvDeelnemingen(): Decimal | null {
        return this._props.aandeelInWvDeelnemingen !== null ? CurrencyType.getRawAmount(this._props.aandeelInWvDeelnemingen)! : null;
    }
    get resultaatNaBelastingen(): Decimal | null {
        return this._props.resultaatNaBelastingen !== null ? CurrencyType.getRawAmount(this._props.resultaatNaBelastingen)! : null;
    }
    get resultaatuitDeelnemingenNaBelasting(): Decimal | null {
        return this._props.resultaatuitDeelnemingenNaBelasting !== null ? CurrencyType.getRawAmount(this._props.resultaatuitDeelnemingenNaBelasting)! : null;
    }
    get nettoResultaat(): Decimal | null {
        return this._props.nettoResultaat !== null ? CurrencyType.getRawAmount(this._props.nettoResultaat)! : null;
    }
}
export abstract class Dossier extends ObjEntity {
    public static ID = "2e396497-5e8f-408a-aefe-63c08320581d";
    public static PROPS = createProps({
        ...ObjEntity.PROPS,
        omschrijving: {
            type: "Field",
            id: "243edea7-740c-4473-a6fd-28fd5e71545f",
            required: true
        },
        rapport: {
            type: "Field",
            id: "b53297c8-ed38-43c7-90bd-fffbae13635c",
            required: false
        },
        deelnemingen: {
            type: "Field",
            id: "ee16950d-f0b6-4235-9e65-a45995ed0923",
            required: false
        },
        kredieten: {
            type: "Field",
            id: "58aae349-a78a-4ea7-a91c-fb8b0539990c",
            required: false
        },
        toeleveranciers: {
            type: "Field",
            id: "590bba34-b103-48b6-b887-92cdd80ca12a",
            required: false
        },
        voorraden: {
            type: "Field",
            id: "acbee963-c8ab-4495-a23e-6e1b157b303f",
            required: false
        },
        gebouwenEnTerreinen: {
            type: "Field",
            id: "6dea3730-1b0a-4e31-afbc-c05e426b63cf",
            required: false
        },
        vervoersmiddelen: {
            type: "Field",
            id: "b8641d15-a8e3-4b16-b0a9-878fd186d69e",
            required: false
        },
        inventaris: {
            type: "Field",
            id: "059e10f9-643b-475d-bb9c-c5fe9f1ca615",
            required: false
        },
        groepsvermogen: {
            type: "Field",
            id: "653c013d-4466-4ca3-9ba0-1eb6681681e8",
            required: false
        },
        machinesAndInstallaties: {
            type: "Field",
            id: "6f7451e4-40e6-4ec0-a472-3d0cfdf0ecbd",
            required: false
        },
        rechtenAndVergunningen: {
            type: "Field",
            id: "b213fd64-745c-4b20-be58-6114583b1d23",
            required: false
        },
        liquideMiddelen: {
            type: "Field",
            id: "aac99fbc-2ba2-4ba0-8f98-25c725d66946",
            required: false
        },
        effecten: {
            type: "Field",
            id: "905fc936-9668-416f-8e8f-069f7851d963",
            required: false
        },
        contactpersoon: {
            type: "RelationshipType",
            id: "69b64f6f-1634-4176-9396-51d476fccb11",
            direction: "child",
            multiplicity: "One",
            required: true
        },
        scope: {
            type: "RelationshipType",
            id: "cc4af1f9-b6b4-43b3-ba92-302dbebe6449",
            direction: "child",
            multiplicity: "One",
            required: true
        },
        status: {
            type: "RelationshipType",
            id: "5364805f-c833-418a-99dd-7e5fb66e5281",
            direction: "child",
            multiplicity: "One",
            required: true
        },
        huidigeStap: {
            type: "RelationshipType",
            id: "9b64bca3-60e8-4071-8173-8ff194967ac8",
            direction: "child",
            multiplicity: "One",
            required: true
        },
        prijs: {
            type: "RelationshipType",
            id: "fa6534e6-3c87-4522-b79a-167936c798b9",
            direction: "child",
            multiplicity: "One",
            required: true
        },
        client: {
            type: "RelationshipType",
            id: "5ca8ff55-1df0-4642-bbc3-6ec3f22a4759",
            direction: "parent",
            multiplicity: "One",
            required: true
        }
    });
    public readonly _props: DossierProps;
    constructor(protected metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Dossier>(this, {
            _props: override,
            omschrijving: computed,
            rapport: computed,
            deelnemingen: computed,
            kredieten: computed,
            toeleveranciers: computed,
            voorraden: computed,
            gebouwenEnTerreinen: computed,
            vervoersmiddelen: computed,
            inventaris: computed,
            groepsvermogen: computed,
            machinesAndInstallaties: computed,
            rechtenAndVergunningen: computed,
            liquideMiddelen: computed,
            effecten: computed,
            contactpersoon: computed,
            scope: computed,
            status: computed,
            huidigeStap: computed,
            prijs: computed,
            client: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const contactpersoonFetchContext = getFetchTreeOfRelation(this._fetchContext, Dossier.PROPS.contactpersoon.id, Dossier.PROPS.contactpersoon.direction);
        const scopeFetchContext = getFetchTreeOfRelation(this._fetchContext, Dossier.PROPS.scope.id, Dossier.PROPS.scope.direction);
        const statusFetchContext = getFetchTreeOfRelation(this._fetchContext, Dossier.PROPS.status.id, Dossier.PROPS.status.direction);
        const huidigeStapFetchContext = getFetchTreeOfRelation(this._fetchContext, Dossier.PROPS.huidigeStap.id, Dossier.PROPS.huidigeStap.direction);
        const prijsFetchContext = getFetchTreeOfRelation(this._fetchContext, Dossier.PROPS.prijs.id, Dossier.PROPS.prijs.direction);
        const clientFetchContext = getFetchTreeOfRelation(this._fetchContext, Dossier.PROPS.client.id, Dossier.PROPS.client.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get contactpersoon() {
                try {
                    return getRelatedSingular(entity, contactpersoonFetchContext, Dossier.PROPS.contactpersoon.id, Dossier.PROPS.contactpersoon.direction, Dossier.PROPS.contactpersoon.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get scope() {
                try {
                    return getRelatedSingular(entity, scopeFetchContext, Dossier.PROPS.scope.id, Dossier.PROPS.scope.direction, Dossier.PROPS.scope.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get status() {
                try {
                    return getRelatedSingular(entity, statusFetchContext, Dossier.PROPS.status.id, Dossier.PROPS.status.direction, Dossier.PROPS.status.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get huidigeStap() {
                try {
                    return getRelatedSingular(entity, huidigeStapFetchContext, Dossier.PROPS.huidigeStap.id, Dossier.PROPS.huidigeStap.direction, Dossier.PROPS.huidigeStap.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get prijs() {
                try {
                    return getRelatedSingular(entity, prijsFetchContext, Dossier.PROPS.prijs.id, Dossier.PROPS.prijs.direction, Dossier.PROPS.prijs.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get client() {
                try {
                    return getRelatedSingular(entity, clientFetchContext, Dossier.PROPS.client.id, Dossier.PROPS.client.direction, Dossier.PROPS.client.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get omschrijving() {
                try {
                    return getFieldValue(metadataService, entity, Dossier.PROPS.omschrijving.id, Dossier.PROPS.omschrijving.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get rapport() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Dossier.PROPS.rapport.id, Dossier.PROPS.rapport.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get deelnemingen() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Dossier.PROPS.deelnemingen.id, Dossier.PROPS.deelnemingen.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get kredieten() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Dossier.PROPS.kredieten.id, Dossier.PROPS.kredieten.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get toeleveranciers() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Dossier.PROPS.toeleveranciers.id, Dossier.PROPS.toeleveranciers.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get voorraden() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Dossier.PROPS.voorraden.id, Dossier.PROPS.voorraden.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get gebouwenEnTerreinen() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Dossier.PROPS.gebouwenEnTerreinen.id, Dossier.PROPS.gebouwenEnTerreinen.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get vervoersmiddelen() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Dossier.PROPS.vervoersmiddelen.id, Dossier.PROPS.vervoersmiddelen.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get inventaris() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Dossier.PROPS.inventaris.id, Dossier.PROPS.inventaris.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get groepsvermogen() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Dossier.PROPS.groepsvermogen.id, Dossier.PROPS.groepsvermogen.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get machinesAndInstallaties() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Dossier.PROPS.machinesAndInstallaties.id, Dossier.PROPS.machinesAndInstallaties.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get rechtenAndVergunningen() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Dossier.PROPS.rechtenAndVergunningen.id, Dossier.PROPS.rechtenAndVergunningen.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get liquideMiddelen() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Dossier.PROPS.liquideMiddelen.id, Dossier.PROPS.liquideMiddelen.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get effecten() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Dossier.PROPS.effecten.id, Dossier.PROPS.effecten.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get omschrijving(): string {
        return TextType.getRawText(this._props.omschrijving)!;
    }
    get rapport(): Promise<NamedBlob | null> | null {
        return this._props.rapport !== null ? FileType.loadFile(FileType.getRawURL(this._props.rapport)!)! : null;
    }
    get deelnemingen(): boolean | null {
        return this._props.deelnemingen !== null ? BooleanType.getRawBoolean(this._props.deelnemingen)! : null;
    }
    get kredieten(): boolean | null {
        return this._props.kredieten !== null ? BooleanType.getRawBoolean(this._props.kredieten)! : null;
    }
    get toeleveranciers(): boolean | null {
        return this._props.toeleveranciers !== null ? BooleanType.getRawBoolean(this._props.toeleveranciers)! : null;
    }
    get voorraden(): boolean | null {
        return this._props.voorraden !== null ? BooleanType.getRawBoolean(this._props.voorraden)! : null;
    }
    get gebouwenEnTerreinen(): boolean | null {
        return this._props.gebouwenEnTerreinen !== null ? BooleanType.getRawBoolean(this._props.gebouwenEnTerreinen)! : null;
    }
    get vervoersmiddelen(): boolean | null {
        return this._props.vervoersmiddelen !== null ? BooleanType.getRawBoolean(this._props.vervoersmiddelen)! : null;
    }
    get inventaris(): boolean | null {
        return this._props.inventaris !== null ? BooleanType.getRawBoolean(this._props.inventaris)! : null;
    }
    get groepsvermogen(): boolean | null {
        return this._props.groepsvermogen !== null ? BooleanType.getRawBoolean(this._props.groepsvermogen)! : null;
    }
    get machinesAndInstallaties(): boolean | null {
        return this._props.machinesAndInstallaties !== null ? BooleanType.getRawBoolean(this._props.machinesAndInstallaties)! : null;
    }
    get rechtenAndVergunningen(): boolean | null {
        return this._props.rechtenAndVergunningen !== null ? BooleanType.getRawBoolean(this._props.rechtenAndVergunningen)! : null;
    }
    get liquideMiddelen(): boolean | null {
        return this._props.liquideMiddelen !== null ? BooleanType.getRawBoolean(this._props.liquideMiddelen)! : null;
    }
    get effecten(): boolean | null {
        return this._props.effecten !== null ? BooleanType.getRawBoolean(this._props.effecten)! : null;
    }
    get contactpersoon(): Person | undefined {
        const contactpersoonFetchContext = getFetchTreeOfRelation(this._fetchContext, Dossier.PROPS.contactpersoon.id, Dossier.PROPS.contactpersoon.direction);
        if (contactpersoonFetchContext !== undefined) {
            return toTypedEntity(this._props.contactpersoon, this._metadataService, contactpersoonFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get scope(): Scope | undefined {
        const scopeFetchContext = getFetchTreeOfRelation(this._fetchContext, Dossier.PROPS.scope.id, Dossier.PROPS.scope.direction);
        if (scopeFetchContext !== undefined) {
            return toTypedEntity(this._props.scope, this._metadataService, scopeFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get status(): DossierStatus | undefined {
        const statusFetchContext = getFetchTreeOfRelation(this._fetchContext, Dossier.PROPS.status.id, Dossier.PROPS.status.direction);
        if (statusFetchContext !== undefined) {
            return toTypedEntity(this._props.status, this._metadataService, statusFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get huidigeStap(): AdviesStappen | undefined {
        const huidigeStapFetchContext = getFetchTreeOfRelation(this._fetchContext, Dossier.PROPS.huidigeStap.id, Dossier.PROPS.huidigeStap.direction);
        if (huidigeStapFetchContext !== undefined) {
            return toTypedEntity(this._props.huidigeStap, this._metadataService, huidigeStapFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get prijs(): PrijsAfspraak | undefined {
        const prijsFetchContext = getFetchTreeOfRelation(this._fetchContext, Dossier.PROPS.prijs.id, Dossier.PROPS.prijs.direction);
        if (prijsFetchContext !== undefined) {
            return toTypedEntity(this._props.prijs, this._metadataService, prijsFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get client(): BusinessClient | undefined {
        const clientFetchContext = getFetchTreeOfRelation(this._fetchContext, Dossier.PROPS.client.id, Dossier.PROPS.client.direction);
        if (clientFetchContext !== undefined) {
            return toTypedEntity(this._props.client, this._metadataService, clientFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class ProductBemiddelingDossier extends Dossier {
    public static ID = "61254af0-93cc-4811-85da-3efc64902b81";
    public static PROPS = createProps({
        ...Dossier.PROPS,
        conclusie: {
            type: "Field",
            id: "11f4205f-3fb4-4851-b1de-caed85b2f738",
            required: false
        },
        offertes: {
            type: "RelationshipType",
            id: "f08ee548-ea48-46f8-88a4-392b900866c2",
            direction: "child",
            multiplicity: "Many",
            required: false
        },
        offerteBeoordelingen: {
            type: "RelationshipType",
            id: "11a3dbbe-6cf0-4502-a1e5-1051a2e909c3",
            direction: "child",
            multiplicity: "Many",
            required: false
        },
        branches: {
            type: "RelationshipType",
            id: "d34f401a-d75f-4bac-84d3-f653a695f6cc",
            direction: "child",
            multiplicity: "Many",
            required: false
        },
        risicoAdviesdossier: {
            type: "RelationshipType",
            id: "bb70dfa4-8ecb-46c2-ab44-029b28d169f4",
            direction: "parent",
            multiplicity: "One",
            required: true
        }
    });
    public readonly _props: ProductBemiddelingDossierProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<ProductBemiddelingDossier>(this, {
            _props: override,
            conclusie: computed,
            offertes: computed,
            offerteBeoordelingen: computed,
            branches: computed,
            risicoAdviesdossier: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const contactpersoonFetchContext = getFetchTreeOfRelation(this._fetchContext, Dossier.PROPS.contactpersoon.id, Dossier.PROPS.contactpersoon.direction);
        const scopeFetchContext = getFetchTreeOfRelation(this._fetchContext, Dossier.PROPS.scope.id, Dossier.PROPS.scope.direction);
        const statusFetchContext = getFetchTreeOfRelation(this._fetchContext, Dossier.PROPS.status.id, Dossier.PROPS.status.direction);
        const huidigeStapFetchContext = getFetchTreeOfRelation(this._fetchContext, Dossier.PROPS.huidigeStap.id, Dossier.PROPS.huidigeStap.direction);
        const prijsFetchContext = getFetchTreeOfRelation(this._fetchContext, Dossier.PROPS.prijs.id, Dossier.PROPS.prijs.direction);
        const clientFetchContext = getFetchTreeOfRelation(this._fetchContext, Dossier.PROPS.client.id, Dossier.PROPS.client.direction);
        const offertesFetchContext = getFetchTreeOfRelation(this._fetchContext, ProductBemiddelingDossier.PROPS.offertes.id, ProductBemiddelingDossier.PROPS.offertes.direction);
        const offerteBeoordelingenFetchContext = getFetchTreeOfRelation(this._fetchContext, ProductBemiddelingDossier.PROPS.offerteBeoordelingen.id, ProductBemiddelingDossier.PROPS.offerteBeoordelingen.direction);
        const branchesFetchContext = getFetchTreeOfRelation(this._fetchContext, ProductBemiddelingDossier.PROPS.branches.id, ProductBemiddelingDossier.PROPS.branches.direction);
        const risicoAdviesdossierFetchContext = getFetchTreeOfRelation(this._fetchContext, ProductBemiddelingDossier.PROPS.risicoAdviesdossier.id, ProductBemiddelingDossier.PROPS.risicoAdviesdossier.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get contactpersoon() {
                try {
                    return getRelatedSingular(entity, contactpersoonFetchContext, Dossier.PROPS.contactpersoon.id, Dossier.PROPS.contactpersoon.direction, Dossier.PROPS.contactpersoon.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get scope() {
                try {
                    return getRelatedSingular(entity, scopeFetchContext, Dossier.PROPS.scope.id, Dossier.PROPS.scope.direction, Dossier.PROPS.scope.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get status() {
                try {
                    return getRelatedSingular(entity, statusFetchContext, Dossier.PROPS.status.id, Dossier.PROPS.status.direction, Dossier.PROPS.status.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get huidigeStap() {
                try {
                    return getRelatedSingular(entity, huidigeStapFetchContext, Dossier.PROPS.huidigeStap.id, Dossier.PROPS.huidigeStap.direction, Dossier.PROPS.huidigeStap.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get prijs() {
                try {
                    return getRelatedSingular(entity, prijsFetchContext, Dossier.PROPS.prijs.id, Dossier.PROPS.prijs.direction, Dossier.PROPS.prijs.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get client() {
                try {
                    return getRelatedSingular(entity, clientFetchContext, Dossier.PROPS.client.id, Dossier.PROPS.client.direction, Dossier.PROPS.client.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get omschrijving() {
                try {
                    return getFieldValue(metadataService, entity, Dossier.PROPS.omschrijving.id, Dossier.PROPS.omschrijving.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get rapport() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Dossier.PROPS.rapport.id, Dossier.PROPS.rapport.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get deelnemingen() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Dossier.PROPS.deelnemingen.id, Dossier.PROPS.deelnemingen.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get kredieten() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Dossier.PROPS.kredieten.id, Dossier.PROPS.kredieten.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get toeleveranciers() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Dossier.PROPS.toeleveranciers.id, Dossier.PROPS.toeleveranciers.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get voorraden() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Dossier.PROPS.voorraden.id, Dossier.PROPS.voorraden.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get gebouwenEnTerreinen() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Dossier.PROPS.gebouwenEnTerreinen.id, Dossier.PROPS.gebouwenEnTerreinen.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get vervoersmiddelen() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Dossier.PROPS.vervoersmiddelen.id, Dossier.PROPS.vervoersmiddelen.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get inventaris() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Dossier.PROPS.inventaris.id, Dossier.PROPS.inventaris.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get groepsvermogen() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Dossier.PROPS.groepsvermogen.id, Dossier.PROPS.groepsvermogen.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get machinesAndInstallaties() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Dossier.PROPS.machinesAndInstallaties.id, Dossier.PROPS.machinesAndInstallaties.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get rechtenAndVergunningen() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Dossier.PROPS.rechtenAndVergunningen.id, Dossier.PROPS.rechtenAndVergunningen.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get liquideMiddelen() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Dossier.PROPS.liquideMiddelen.id, Dossier.PROPS.liquideMiddelen.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get effecten() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Dossier.PROPS.effecten.id, Dossier.PROPS.effecten.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get offertes() {
                try {
                    return getRelatedPlural(entity, offertesFetchContext, ProductBemiddelingDossier.PROPS.offertes.id, ProductBemiddelingDossier.PROPS.offertes.direction, ProductBemiddelingDossier.PROPS.offertes.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get offerteBeoordelingen() {
                try {
                    return getRelatedPlural(entity, offerteBeoordelingenFetchContext, ProductBemiddelingDossier.PROPS.offerteBeoordelingen.id, ProductBemiddelingDossier.PROPS.offerteBeoordelingen.direction, ProductBemiddelingDossier.PROPS.offerteBeoordelingen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get branches() {
                try {
                    return getRelatedPlural(entity, branchesFetchContext, ProductBemiddelingDossier.PROPS.branches.id, ProductBemiddelingDossier.PROPS.branches.direction, ProductBemiddelingDossier.PROPS.branches.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get risicoAdviesdossier() {
                try {
                    return getRelatedSingular(entity, risicoAdviesdossierFetchContext, ProductBemiddelingDossier.PROPS.risicoAdviesdossier.id, ProductBemiddelingDossier.PROPS.risicoAdviesdossier.direction, ProductBemiddelingDossier.PROPS.risicoAdviesdossier.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get conclusie() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ProductBemiddelingDossier.PROPS.conclusie.id, ProductBemiddelingDossier.PROPS.conclusie.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get conclusie(): string | null {
        return this._props.conclusie !== null ? TextType.getRawText(this._props.conclusie)! : null;
    }
    get offertes(): Offerte[] | undefined {
        const offertesFetchContext = getFetchTreeOfRelation(this._fetchContext, ProductBemiddelingDossier.PROPS.offertes.id, ProductBemiddelingDossier.PROPS.offertes.direction);
        if (offertesFetchContext !== undefined) {
            return toTypedEntity(this._props.offertes, this._metadataService, offertesFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get offerteBeoordelingen(): OfferteBeoordeling[] | undefined {
        const offerteBeoordelingenFetchContext = getFetchTreeOfRelation(this._fetchContext, ProductBemiddelingDossier.PROPS.offerteBeoordelingen.id, ProductBemiddelingDossier.PROPS.offerteBeoordelingen.direction);
        if (offerteBeoordelingenFetchContext !== undefined) {
            return toTypedEntity(this._props.offerteBeoordelingen, this._metadataService, offerteBeoordelingenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get branches(): Branche[] | undefined {
        const branchesFetchContext = getFetchTreeOfRelation(this._fetchContext, ProductBemiddelingDossier.PROPS.branches.id, ProductBemiddelingDossier.PROPS.branches.direction);
        if (branchesFetchContext !== undefined) {
            return toTypedEntity(this._props.branches, this._metadataService, branchesFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get risicoAdviesdossier(): RisicoAdviesDossier | undefined {
        const risicoAdviesdossierFetchContext = getFetchTreeOfRelation(this._fetchContext, ProductBemiddelingDossier.PROPS.risicoAdviesdossier.id, ProductBemiddelingDossier.PROPS.risicoAdviesdossier.direction);
        if (risicoAdviesdossierFetchContext !== undefined) {
            return toTypedEntity(this._props.risicoAdviesdossier, this._metadataService, risicoAdviesdossierFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class RisicoAdviesDossier extends Dossier {
    public static ID = "ee7c1bb4-85d6-4b1c-a8eb-0a7e2e267bac";
    public static PROPS = createProps({
        ...Dossier.PROPS,
        conclusie: {
            type: "Field",
            id: "9cb3afc5-4c7b-41d8-a26d-7c6883f26f74",
            required: false
        },
        productbemiddlingsdossiers: {
            type: "RelationshipType",
            id: "bb70dfa4-8ecb-46c2-ab44-029b28d169f4",
            direction: "child",
            multiplicity: "Many",
            required: false
        },
        acceptatievragen: {
            type: "RelationshipType",
            id: "845e7f5d-ac21-4a73-8ace-6a86791edce3",
            direction: "child",
            multiplicity: "Many",
            required: false
        },
        controleBeoordelingen: {
            type: "RelationshipType",
            id: "29bb066f-71e3-48b4-9f67-f31083ced5c2",
            direction: "child",
            multiplicity: "Many",
            required: false
        },
        risicoBeoordelingen: {
            type: "RelationshipType",
            id: "8be149ed-8563-4ac3-9500-fd31da45194c",
            direction: "child",
            multiplicity: "Many",
            required: false
        }
    });
    public readonly _props: RisicoAdviesDossierProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<RisicoAdviesDossier>(this, {
            _props: override,
            conclusie: computed,
            productbemiddlingsdossiers: computed,
            acceptatievragen: computed,
            controleBeoordelingen: computed,
            risicoBeoordelingen: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const contactpersoonFetchContext = getFetchTreeOfRelation(this._fetchContext, Dossier.PROPS.contactpersoon.id, Dossier.PROPS.contactpersoon.direction);
        const scopeFetchContext = getFetchTreeOfRelation(this._fetchContext, Dossier.PROPS.scope.id, Dossier.PROPS.scope.direction);
        const statusFetchContext = getFetchTreeOfRelation(this._fetchContext, Dossier.PROPS.status.id, Dossier.PROPS.status.direction);
        const huidigeStapFetchContext = getFetchTreeOfRelation(this._fetchContext, Dossier.PROPS.huidigeStap.id, Dossier.PROPS.huidigeStap.direction);
        const prijsFetchContext = getFetchTreeOfRelation(this._fetchContext, Dossier.PROPS.prijs.id, Dossier.PROPS.prijs.direction);
        const clientFetchContext = getFetchTreeOfRelation(this._fetchContext, Dossier.PROPS.client.id, Dossier.PROPS.client.direction);
        const productbemiddlingsdossiersFetchContext = getFetchTreeOfRelation(this._fetchContext, RisicoAdviesDossier.PROPS.productbemiddlingsdossiers.id, RisicoAdviesDossier.PROPS.productbemiddlingsdossiers.direction);
        const acceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, RisicoAdviesDossier.PROPS.acceptatievragen.id, RisicoAdviesDossier.PROPS.acceptatievragen.direction);
        const controleBeoordelingenFetchContext = getFetchTreeOfRelation(this._fetchContext, RisicoAdviesDossier.PROPS.controleBeoordelingen.id, RisicoAdviesDossier.PROPS.controleBeoordelingen.direction);
        const risicoBeoordelingenFetchContext = getFetchTreeOfRelation(this._fetchContext, RisicoAdviesDossier.PROPS.risicoBeoordelingen.id, RisicoAdviesDossier.PROPS.risicoBeoordelingen.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get contactpersoon() {
                try {
                    return getRelatedSingular(entity, contactpersoonFetchContext, Dossier.PROPS.contactpersoon.id, Dossier.PROPS.contactpersoon.direction, Dossier.PROPS.contactpersoon.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get scope() {
                try {
                    return getRelatedSingular(entity, scopeFetchContext, Dossier.PROPS.scope.id, Dossier.PROPS.scope.direction, Dossier.PROPS.scope.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get status() {
                try {
                    return getRelatedSingular(entity, statusFetchContext, Dossier.PROPS.status.id, Dossier.PROPS.status.direction, Dossier.PROPS.status.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get huidigeStap() {
                try {
                    return getRelatedSingular(entity, huidigeStapFetchContext, Dossier.PROPS.huidigeStap.id, Dossier.PROPS.huidigeStap.direction, Dossier.PROPS.huidigeStap.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get prijs() {
                try {
                    return getRelatedSingular(entity, prijsFetchContext, Dossier.PROPS.prijs.id, Dossier.PROPS.prijs.direction, Dossier.PROPS.prijs.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get client() {
                try {
                    return getRelatedSingular(entity, clientFetchContext, Dossier.PROPS.client.id, Dossier.PROPS.client.direction, Dossier.PROPS.client.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get omschrijving() {
                try {
                    return getFieldValue(metadataService, entity, Dossier.PROPS.omschrijving.id, Dossier.PROPS.omschrijving.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get rapport() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Dossier.PROPS.rapport.id, Dossier.PROPS.rapport.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get deelnemingen() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Dossier.PROPS.deelnemingen.id, Dossier.PROPS.deelnemingen.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get kredieten() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Dossier.PROPS.kredieten.id, Dossier.PROPS.kredieten.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get toeleveranciers() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Dossier.PROPS.toeleveranciers.id, Dossier.PROPS.toeleveranciers.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get voorraden() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Dossier.PROPS.voorraden.id, Dossier.PROPS.voorraden.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get gebouwenEnTerreinen() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Dossier.PROPS.gebouwenEnTerreinen.id, Dossier.PROPS.gebouwenEnTerreinen.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get vervoersmiddelen() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Dossier.PROPS.vervoersmiddelen.id, Dossier.PROPS.vervoersmiddelen.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get inventaris() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Dossier.PROPS.inventaris.id, Dossier.PROPS.inventaris.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get groepsvermogen() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Dossier.PROPS.groepsvermogen.id, Dossier.PROPS.groepsvermogen.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get machinesAndInstallaties() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Dossier.PROPS.machinesAndInstallaties.id, Dossier.PROPS.machinesAndInstallaties.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get rechtenAndVergunningen() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Dossier.PROPS.rechtenAndVergunningen.id, Dossier.PROPS.rechtenAndVergunningen.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get liquideMiddelen() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Dossier.PROPS.liquideMiddelen.id, Dossier.PROPS.liquideMiddelen.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get effecten() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Dossier.PROPS.effecten.id, Dossier.PROPS.effecten.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get productbemiddlingsdossiers() {
                try {
                    return getRelatedPlural(entity, productbemiddlingsdossiersFetchContext, RisicoAdviesDossier.PROPS.productbemiddlingsdossiers.id, RisicoAdviesDossier.PROPS.productbemiddlingsdossiers.direction, RisicoAdviesDossier.PROPS.productbemiddlingsdossiers.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatievragen() {
                try {
                    return getRelatedPlural(entity, acceptatievragenFetchContext, RisicoAdviesDossier.PROPS.acceptatievragen.id, RisicoAdviesDossier.PROPS.acceptatievragen.direction, RisicoAdviesDossier.PROPS.acceptatievragen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get controleBeoordelingen() {
                try {
                    return getRelatedPlural(entity, controleBeoordelingenFetchContext, RisicoAdviesDossier.PROPS.controleBeoordelingen.id, RisicoAdviesDossier.PROPS.controleBeoordelingen.direction, RisicoAdviesDossier.PROPS.controleBeoordelingen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get risicoBeoordelingen() {
                try {
                    return getRelatedPlural(entity, risicoBeoordelingenFetchContext, RisicoAdviesDossier.PROPS.risicoBeoordelingen.id, RisicoAdviesDossier.PROPS.risicoBeoordelingen.direction, RisicoAdviesDossier.PROPS.risicoBeoordelingen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get conclusie() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, RisicoAdviesDossier.PROPS.conclusie.id, RisicoAdviesDossier.PROPS.conclusie.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get conclusie(): string | null {
        return this._props.conclusie !== null ? TextType.getRawText(this._props.conclusie)! : null;
    }
    get productbemiddlingsdossiers(): ProductBemiddelingDossier[] | undefined {
        const productbemiddlingsdossiersFetchContext = getFetchTreeOfRelation(this._fetchContext, RisicoAdviesDossier.PROPS.productbemiddlingsdossiers.id, RisicoAdviesDossier.PROPS.productbemiddlingsdossiers.direction);
        if (productbemiddlingsdossiersFetchContext !== undefined) {
            return toTypedEntity(this._props.productbemiddlingsdossiers, this._metadataService, productbemiddlingsdossiersFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get acceptatievragen(): Acceptatiedeelvragen[] | undefined {
        const acceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, RisicoAdviesDossier.PROPS.acceptatievragen.id, RisicoAdviesDossier.PROPS.acceptatievragen.direction);
        if (acceptatievragenFetchContext !== undefined) {
            return toTypedEntity(this._props.acceptatievragen, this._metadataService, acceptatievragenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get controleBeoordelingen(): ControleBeoordeling[] | undefined {
        const controleBeoordelingenFetchContext = getFetchTreeOfRelation(this._fetchContext, RisicoAdviesDossier.PROPS.controleBeoordelingen.id, RisicoAdviesDossier.PROPS.controleBeoordelingen.direction);
        if (controleBeoordelingenFetchContext !== undefined) {
            return toTypedEntity(this._props.controleBeoordelingen, this._metadataService, controleBeoordelingenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get risicoBeoordelingen(): Risicobeoordeling[] | undefined {
        const risicoBeoordelingenFetchContext = getFetchTreeOfRelation(this._fetchContext, RisicoAdviesDossier.PROPS.risicoBeoordelingen.id, RisicoAdviesDossier.PROPS.risicoBeoordelingen.direction);
        if (risicoBeoordelingenFetchContext !== undefined) {
            return toTypedEntity(this._props.risicoBeoordelingen, this._metadataService, risicoBeoordelingenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class EntityField extends ObjEntity {
    public static ID = "695303b3-a6ee-4554-a2c0-90e63ce8fed0";
    public static PROPS = createProps({
        ...ObjEntity.PROPS,
        name: {
            type: "Field",
            id: "4014657b-36b7-4500-9875-15cf12206d9a",
            required: true
        },
        belongsTo: {
            type: "RelationshipType",
            id: "90d914dc-cee7-40c3-9417-65a1c6483b79",
            direction: "parent",
            multiplicity: "One",
            required: true
        }
    });
    public readonly _props: EntityFieldProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<EntityField>(this, {
            _props: override,
            name: computed,
            belongsTo: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const belongsToFetchContext = getFetchTreeOfRelation(this._fetchContext, EntityField.PROPS.belongsTo.id, EntityField.PROPS.belongsTo.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get belongsTo() {
                try {
                    return getRelatedSingular(entity, belongsToFetchContext, EntityField.PROPS.belongsTo.id, EntityField.PROPS.belongsTo.direction, EntityField.PROPS.belongsTo.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get name() {
                try {
                    return getFieldValue(metadataService, entity, EntityField.PROPS.name.id, EntityField.PROPS.name.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get name(): string {
        return TextType.getRawText(this._props.name)!;
    }
    get belongsTo(): EntityType | undefined {
        const belongsToFetchContext = getFetchTreeOfRelation(this._fetchContext, EntityField.PROPS.belongsTo.id, EntityField.PROPS.belongsTo.direction);
        if (belongsToFetchContext !== undefined) {
            return toTypedEntity(this._props.belongsTo, this._metadataService, belongsToFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class EntityType extends ObjEntity {
    public static ID = "bfa2dbf2-17ef-4d61-a331-e3a7805d5b59";
    public static PROPS = createProps({
        ...ObjEntity.PROPS,
        color: {
            type: "Field",
            id: "1c18e4c0-dce6-4b0c-a24d-104f4a90fe95",
            required: false
        },
        name: {
            type: "Field",
            id: "48308bf4-1ffe-4764-b5a2-5147c7fd979a",
            required: true
        },
        iconField: {
            type: "Field",
            id: "d022f207-0349-4a54-bfc9-c1340ccd8d5d",
            required: false
        },
        nameField: {
            type: "Field",
            id: "d2169611-56d5-474e-9734-0447c84cc671",
            required: false
        },
        extendedBy: {
            type: "RelationshipType",
            id: "3b54b0ae-21ac-4395-b622-6b006914aa35",
            direction: "child",
            multiplicity: "Many",
            required: false
        },
        has: {
            type: "RelationshipType",
            id: "90d914dc-cee7-40c3-9417-65a1c6483b79",
            direction: "child",
            multiplicity: "Many",
            required: false
        },
        extends: {
            type: "RelationshipType",
            id: "3b54b0ae-21ac-4395-b622-6b006914aa35",
            direction: "parent",
            multiplicity: "One",
            required: false
        },
        child: {
            type: "RelationshipType",
            id: "4435c618-e80a-4c3c-a7ae-d413a87c224c",
            direction: "parent",
            multiplicity: "Many",
            required: false
        },
        parent: {
            type: "RelationshipType",
            id: "1e5a7abf-b5e9-4db3-b8a5-e95afcf1c180",
            direction: "parent",
            multiplicity: "Many",
            required: false
        },
        acceptatiedeelvragenRisicoDefinitie: {
            type: "RelationshipType",
            id: "d369562b-5ce1-4e2e-a869-7a402b9d9251",
            direction: "parent",
            multiplicity: "One",
            required: false
        },
        acceptatiedeelvragenMaatregelDefinitie: {
            type: "RelationshipType",
            id: "b7684012-c1f5-4952-92fb-b8968d5dd8a6",
            direction: "parent",
            multiplicity: "One",
            required: false
        },
        acceptatiedeelvragenProductDefinitie: {
            type: "RelationshipType",
            id: "2715ca91-2af9-4635-a43c-6e0d5f30e283",
            direction: "parent",
            multiplicity: "One",
            required: false
        },
        acceptatiedeelvragenInventarisatieDefinitie: {
            type: "RelationshipType",
            id: "ac324f88-2cf8-4524-bc30-f7cec7cb6ec7",
            direction: "parent",
            multiplicity: "One",
            required: false
        },
        gerelateerdeAcceptatievragenDefinities: {
            type: "RelationshipType",
            id: "dbaa3e31-ebbd-4bf5-87fb-ba8f19be7d13",
            direction: "parent",
            multiplicity: "One",
            required: false
        },
        acceptiedeelvragenRelevanties: {
            type: "RelationshipType",
            id: "7b2ce394-4e48-4e38-9cf9-305ac6666142",
            direction: "parent",
            multiplicity: "Many",
            required: false
        },
        clausuleRelevantiesAcceptatiedeelvraag: {
            type: "RelationshipType",
            id: "47fcf915-386e-4fac-9efc-c2d34b9bfac9",
            direction: "parent",
            multiplicity: "Many",
            required: false
        },
        branches: {
            type: "RelationshipType",
            id: "f81a3cd6-9eef-4430-aa0a-492ef0f1b3c6",
            direction: "parent",
            multiplicity: "Many",
            required: false
        },
        risicoprofielen: {
            type: "RelationshipType",
            id: "87a183e6-1d1b-4e08-95fa-24afdd06ef8a",
            direction: "parent",
            multiplicity: "Many",
            required: false
        },
        businessCanvas: {
            type: "RelationshipType",
            id: "ff931078-acda-4c8f-82ec-b00e2c54450b",
            direction: "parent",
            multiplicity: "One",
            required: false
        }
    });
    public readonly _props: EntityTypeProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<EntityType>(this, {
            _props: override,
            color: computed,
            name: computed,
            iconField: computed,
            nameField: computed,
            extendedBy: computed,
            has: computed,
            extends: computed,
            child: computed,
            parent: computed,
            acceptatiedeelvragenRisicoDefinitie: computed,
            acceptatiedeelvragenMaatregelDefinitie: computed,
            acceptatiedeelvragenProductDefinitie: computed,
            acceptatiedeelvragenInventarisatieDefinitie: computed,
            gerelateerdeAcceptatievragenDefinities: computed,
            acceptiedeelvragenRelevanties: computed,
            clausuleRelevantiesAcceptatiedeelvraag: computed,
            branches: computed,
            risicoprofielen: computed,
            businessCanvas: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const extendedByFetchContext = getFetchTreeOfRelation(this._fetchContext, EntityType.PROPS.extendedBy.id, EntityType.PROPS.extendedBy.direction);
        const hasFetchContext = getFetchTreeOfRelation(this._fetchContext, EntityType.PROPS.has.id, EntityType.PROPS.has.direction);
        const extendsFetchContext = getFetchTreeOfRelation(this._fetchContext, EntityType.PROPS.extends.id, EntityType.PROPS.extends.direction);
        const childFetchContext = getFetchTreeOfRelation(this._fetchContext, EntityType.PROPS.child.id, EntityType.PROPS.child.direction);
        const parentFetchContext = getFetchTreeOfRelation(this._fetchContext, EntityType.PROPS.parent.id, EntityType.PROPS.parent.direction);
        const acceptatiedeelvragenRisicoDefinitieFetchContext = getFetchTreeOfRelation(this._fetchContext, EntityType.PROPS.acceptatiedeelvragenRisicoDefinitie.id, EntityType.PROPS.acceptatiedeelvragenRisicoDefinitie.direction);
        const acceptatiedeelvragenMaatregelDefinitieFetchContext = getFetchTreeOfRelation(this._fetchContext, EntityType.PROPS.acceptatiedeelvragenMaatregelDefinitie.id, EntityType.PROPS.acceptatiedeelvragenMaatregelDefinitie.direction);
        const acceptatiedeelvragenProductDefinitieFetchContext = getFetchTreeOfRelation(this._fetchContext, EntityType.PROPS.acceptatiedeelvragenProductDefinitie.id, EntityType.PROPS.acceptatiedeelvragenProductDefinitie.direction);
        const acceptatiedeelvragenInventarisatieDefinitieFetchContext = getFetchTreeOfRelation(this._fetchContext, EntityType.PROPS.acceptatiedeelvragenInventarisatieDefinitie.id, EntityType.PROPS.acceptatiedeelvragenInventarisatieDefinitie.direction);
        const gerelateerdeAcceptatievragenDefinitiesFetchContext = getFetchTreeOfRelation(this._fetchContext, EntityType.PROPS.gerelateerdeAcceptatievragenDefinities.id, EntityType.PROPS.gerelateerdeAcceptatievragenDefinities.direction);
        const acceptiedeelvragenRelevantiesFetchContext = getFetchTreeOfRelation(this._fetchContext, EntityType.PROPS.acceptiedeelvragenRelevanties.id, EntityType.PROPS.acceptiedeelvragenRelevanties.direction);
        const clausuleRelevantiesAcceptatiedeelvraagFetchContext = getFetchTreeOfRelation(this._fetchContext, EntityType.PROPS.clausuleRelevantiesAcceptatiedeelvraag.id, EntityType.PROPS.clausuleRelevantiesAcceptatiedeelvraag.direction);
        const branchesFetchContext = getFetchTreeOfRelation(this._fetchContext, EntityType.PROPS.branches.id, EntityType.PROPS.branches.direction);
        const risicoprofielenFetchContext = getFetchTreeOfRelation(this._fetchContext, EntityType.PROPS.risicoprofielen.id, EntityType.PROPS.risicoprofielen.direction);
        const businessCanvasFetchContext = getFetchTreeOfRelation(this._fetchContext, EntityType.PROPS.businessCanvas.id, EntityType.PROPS.businessCanvas.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get extendedBy() {
                try {
                    return getRelatedPlural(entity, extendedByFetchContext, EntityType.PROPS.extendedBy.id, EntityType.PROPS.extendedBy.direction, EntityType.PROPS.extendedBy.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get has() {
                try {
                    return getRelatedPlural(entity, hasFetchContext, EntityType.PROPS.has.id, EntityType.PROPS.has.direction, EntityType.PROPS.has.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get extends() {
                try {
                    return getRelatedSingular(entity, extendsFetchContext, EntityType.PROPS.extends.id, EntityType.PROPS.extends.direction, EntityType.PROPS.extends.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get child() {
                try {
                    return getRelatedPlural(entity, childFetchContext, EntityType.PROPS.child.id, EntityType.PROPS.child.direction, EntityType.PROPS.child.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get parent() {
                try {
                    return getRelatedPlural(entity, parentFetchContext, EntityType.PROPS.parent.id, EntityType.PROPS.parent.direction, EntityType.PROPS.parent.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatiedeelvragenRisicoDefinitie() {
                try {
                    return getRelatedSingular(entity, acceptatiedeelvragenRisicoDefinitieFetchContext, EntityType.PROPS.acceptatiedeelvragenRisicoDefinitie.id, EntityType.PROPS.acceptatiedeelvragenRisicoDefinitie.direction, EntityType.PROPS.acceptatiedeelvragenRisicoDefinitie.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatiedeelvragenMaatregelDefinitie() {
                try {
                    return getRelatedSingular(entity, acceptatiedeelvragenMaatregelDefinitieFetchContext, EntityType.PROPS.acceptatiedeelvragenMaatregelDefinitie.id, EntityType.PROPS.acceptatiedeelvragenMaatregelDefinitie.direction, EntityType.PROPS.acceptatiedeelvragenMaatregelDefinitie.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatiedeelvragenProductDefinitie() {
                try {
                    return getRelatedSingular(entity, acceptatiedeelvragenProductDefinitieFetchContext, EntityType.PROPS.acceptatiedeelvragenProductDefinitie.id, EntityType.PROPS.acceptatiedeelvragenProductDefinitie.direction, EntityType.PROPS.acceptatiedeelvragenProductDefinitie.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatiedeelvragenInventarisatieDefinitie() {
                try {
                    return getRelatedSingular(entity, acceptatiedeelvragenInventarisatieDefinitieFetchContext, EntityType.PROPS.acceptatiedeelvragenInventarisatieDefinitie.id, EntityType.PROPS.acceptatiedeelvragenInventarisatieDefinitie.direction, EntityType.PROPS.acceptatiedeelvragenInventarisatieDefinitie.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get gerelateerdeAcceptatievragenDefinities() {
                try {
                    return getRelatedSingular(entity, gerelateerdeAcceptatievragenDefinitiesFetchContext, EntityType.PROPS.gerelateerdeAcceptatievragenDefinities.id, EntityType.PROPS.gerelateerdeAcceptatievragenDefinities.direction, EntityType.PROPS.gerelateerdeAcceptatievragenDefinities.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptiedeelvragenRelevanties() {
                try {
                    return getRelatedPlural(entity, acceptiedeelvragenRelevantiesFetchContext, EntityType.PROPS.acceptiedeelvragenRelevanties.id, EntityType.PROPS.acceptiedeelvragenRelevanties.direction, EntityType.PROPS.acceptiedeelvragenRelevanties.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get clausuleRelevantiesAcceptatiedeelvraag() {
                try {
                    return getRelatedPlural(entity, clausuleRelevantiesAcceptatiedeelvraagFetchContext, EntityType.PROPS.clausuleRelevantiesAcceptatiedeelvraag.id, EntityType.PROPS.clausuleRelevantiesAcceptatiedeelvraag.direction, EntityType.PROPS.clausuleRelevantiesAcceptatiedeelvraag.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get branches() {
                try {
                    return getRelatedPlural(entity, branchesFetchContext, EntityType.PROPS.branches.id, EntityType.PROPS.branches.direction, EntityType.PROPS.branches.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get risicoprofielen() {
                try {
                    return getRelatedPlural(entity, risicoprofielenFetchContext, EntityType.PROPS.risicoprofielen.id, EntityType.PROPS.risicoprofielen.direction, EntityType.PROPS.risicoprofielen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get businessCanvas() {
                try {
                    return getRelatedSingular(entity, businessCanvasFetchContext, EntityType.PROPS.businessCanvas.id, EntityType.PROPS.businessCanvas.direction, EntityType.PROPS.businessCanvas.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get color() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, EntityType.PROPS.color.id, EntityType.PROPS.color.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get name() {
                try {
                    return getFieldValue(metadataService, entity, EntityType.PROPS.name.id, EntityType.PROPS.name.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get iconField() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, EntityType.PROPS.iconField.id, EntityType.PROPS.iconField.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get nameField() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, EntityType.PROPS.nameField.id, EntityType.PROPS.nameField.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get color(): ColorTypeObject | null {
        return this._props.color !== null ? (() => {
            const rawColor = ColorType.getRawColor(this._props.color)!;
            return { r: rawColor[0], g: rawColor[1], b: rawColor[2], a: rawColor[3] };
        })()! : null;
    }
    get name(): string {
        return TextType.getRawText(this._props.name)!;
    }
    get iconField(): string | null {
        return this._props.iconField !== null ? TextType.getRawText(this._props.iconField)! : null;
    }
    get nameField(): string | null {
        return this._props.nameField !== null ? TextType.getRawText(this._props.nameField)! : null;
    }
    get extendedBy(): EntityType[] | undefined {
        const extendedByFetchContext = getFetchTreeOfRelation(this._fetchContext, EntityType.PROPS.extendedBy.id, EntityType.PROPS.extendedBy.direction);
        if (extendedByFetchContext !== undefined) {
            return toTypedEntity(this._props.extendedBy, this._metadataService, extendedByFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get has(): EntityField[] | undefined {
        const hasFetchContext = getFetchTreeOfRelation(this._fetchContext, EntityType.PROPS.has.id, EntityType.PROPS.has.direction);
        if (hasFetchContext !== undefined) {
            return toTypedEntity(this._props.has, this._metadataService, hasFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get extends(): (EntityType | null) | undefined {
        const extendsFetchContext = getFetchTreeOfRelation(this._fetchContext, EntityType.PROPS.extends.id, EntityType.PROPS.extends.direction);
        if (extendsFetchContext !== undefined) {
            return toTypedEntity(this._props.extends, this._metadataService, extendsFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get child(): RelationshipType[] | undefined {
        const childFetchContext = getFetchTreeOfRelation(this._fetchContext, EntityType.PROPS.child.id, EntityType.PROPS.child.direction);
        if (childFetchContext !== undefined) {
            return toTypedEntity(this._props.child, this._metadataService, childFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get parent(): RelationshipType[] | undefined {
        const parentFetchContext = getFetchTreeOfRelation(this._fetchContext, EntityType.PROPS.parent.id, EntityType.PROPS.parent.direction);
        if (parentFetchContext !== undefined) {
            return toTypedEntity(this._props.parent, this._metadataService, parentFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get acceptatiedeelvragenRisicoDefinitie(): (AcceptatievragenDefinitie | null) | undefined {
        const acceptatiedeelvragenRisicoDefinitieFetchContext = getFetchTreeOfRelation(this._fetchContext, EntityType.PROPS.acceptatiedeelvragenRisicoDefinitie.id, EntityType.PROPS.acceptatiedeelvragenRisicoDefinitie.direction);
        if (acceptatiedeelvragenRisicoDefinitieFetchContext !== undefined) {
            return toTypedEntity(this._props.acceptatiedeelvragenRisicoDefinitie, this._metadataService, acceptatiedeelvragenRisicoDefinitieFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get acceptatiedeelvragenMaatregelDefinitie(): (AcceptatievragenDefinitie | null) | undefined {
        const acceptatiedeelvragenMaatregelDefinitieFetchContext = getFetchTreeOfRelation(this._fetchContext, EntityType.PROPS.acceptatiedeelvragenMaatregelDefinitie.id, EntityType.PROPS.acceptatiedeelvragenMaatregelDefinitie.direction);
        if (acceptatiedeelvragenMaatregelDefinitieFetchContext !== undefined) {
            return toTypedEntity(this._props.acceptatiedeelvragenMaatregelDefinitie, this._metadataService, acceptatiedeelvragenMaatregelDefinitieFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get acceptatiedeelvragenProductDefinitie(): (AcceptatievragenDefinitie | null) | undefined {
        const acceptatiedeelvragenProductDefinitieFetchContext = getFetchTreeOfRelation(this._fetchContext, EntityType.PROPS.acceptatiedeelvragenProductDefinitie.id, EntityType.PROPS.acceptatiedeelvragenProductDefinitie.direction);
        if (acceptatiedeelvragenProductDefinitieFetchContext !== undefined) {
            return toTypedEntity(this._props.acceptatiedeelvragenProductDefinitie, this._metadataService, acceptatiedeelvragenProductDefinitieFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get acceptatiedeelvragenInventarisatieDefinitie(): (AcceptatievragenDefinitie | null) | undefined {
        const acceptatiedeelvragenInventarisatieDefinitieFetchContext = getFetchTreeOfRelation(this._fetchContext, EntityType.PROPS.acceptatiedeelvragenInventarisatieDefinitie.id, EntityType.PROPS.acceptatiedeelvragenInventarisatieDefinitie.direction);
        if (acceptatiedeelvragenInventarisatieDefinitieFetchContext !== undefined) {
            return toTypedEntity(this._props.acceptatiedeelvragenInventarisatieDefinitie, this._metadataService, acceptatiedeelvragenInventarisatieDefinitieFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get gerelateerdeAcceptatievragenDefinities(): (AcceptatievragenDefinitie | null) | undefined {
        const gerelateerdeAcceptatievragenDefinitiesFetchContext = getFetchTreeOfRelation(this._fetchContext, EntityType.PROPS.gerelateerdeAcceptatievragenDefinities.id, EntityType.PROPS.gerelateerdeAcceptatievragenDefinities.direction);
        if (gerelateerdeAcceptatievragenDefinitiesFetchContext !== undefined) {
            return toTypedEntity(this._props.gerelateerdeAcceptatievragenDefinities, this._metadataService, gerelateerdeAcceptatievragenDefinitiesFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get acceptiedeelvragenRelevanties(): AcceptatiedeelvraagRelevantie[] | undefined {
        const acceptiedeelvragenRelevantiesFetchContext = getFetchTreeOfRelation(this._fetchContext, EntityType.PROPS.acceptiedeelvragenRelevanties.id, EntityType.PROPS.acceptiedeelvragenRelevanties.direction);
        if (acceptiedeelvragenRelevantiesFetchContext !== undefined) {
            return toTypedEntity(this._props.acceptiedeelvragenRelevanties, this._metadataService, acceptiedeelvragenRelevantiesFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get clausuleRelevantiesAcceptatiedeelvraag(): ClausuleRelevantieAcceptatiedeelvraag[] | undefined {
        const clausuleRelevantiesAcceptatiedeelvraagFetchContext = getFetchTreeOfRelation(this._fetchContext, EntityType.PROPS.clausuleRelevantiesAcceptatiedeelvraag.id, EntityType.PROPS.clausuleRelevantiesAcceptatiedeelvraag.direction);
        if (clausuleRelevantiesAcceptatiedeelvraagFetchContext !== undefined) {
            return toTypedEntity(this._props.clausuleRelevantiesAcceptatiedeelvraag, this._metadataService, clausuleRelevantiesAcceptatiedeelvraagFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get branches(): Branche[] | undefined {
        const branchesFetchContext = getFetchTreeOfRelation(this._fetchContext, EntityType.PROPS.branches.id, EntityType.PROPS.branches.direction);
        if (branchesFetchContext !== undefined) {
            return toTypedEntity(this._props.branches, this._metadataService, branchesFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get risicoprofielen(): Risicoprofiel[] | undefined {
        const risicoprofielenFetchContext = getFetchTreeOfRelation(this._fetchContext, EntityType.PROPS.risicoprofielen.id, EntityType.PROPS.risicoprofielen.direction);
        if (risicoprofielenFetchContext !== undefined) {
            return toTypedEntity(this._props.risicoprofielen, this._metadataService, risicoprofielenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get businessCanvas(): (BusinessCanvas | null) | undefined {
        const businessCanvasFetchContext = getFetchTreeOfRelation(this._fetchContext, EntityType.PROPS.businessCanvas.id, EntityType.PROPS.businessCanvas.direction);
        if (businessCanvasFetchContext !== undefined) {
            return toTypedEntity(this._props.businessCanvas, this._metadataService, businessCanvasFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class Goederen extends ObjEntity {
    public static ID = "34c7f7e6-5049-4674-af06-9de3971de4d9";
    public static PROPS = createProps({
        ...ObjEntity.PROPS,
        goederenType: {
            type: "RelationshipType",
            id: "e6fcddc2-b639-4e44-9bda-e557458197ab",
            direction: "child",
            multiplicity: "One",
            required: true
        },
        voorraden: {
            type: "RelationshipType",
            id: "bb063e00-2e12-4b1b-8927-ed906ed725b8",
            direction: "parent",
            multiplicity: "Many",
            required: false
        },
        risicobeoordelingen: {
            type: "RelationshipType",
            id: "00e34821-2c67-45a3-8c0f-2f83d69d7f9e",
            direction: "parent",
            multiplicity: "Many",
            required: false
        }
    });
    public readonly _props: GoederenProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Goederen>(this, {
            _props: override,
            goederenType: computed,
            voorraden: computed,
            risicobeoordelingen: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const goederenTypeFetchContext = getFetchTreeOfRelation(this._fetchContext, Goederen.PROPS.goederenType.id, Goederen.PROPS.goederenType.direction);
        const voorradenFetchContext = getFetchTreeOfRelation(this._fetchContext, Goederen.PROPS.voorraden.id, Goederen.PROPS.voorraden.direction);
        const risicobeoordelingenFetchContext = getFetchTreeOfRelation(this._fetchContext, Goederen.PROPS.risicobeoordelingen.id, Goederen.PROPS.risicobeoordelingen.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get goederenType() {
                try {
                    return getRelatedSingular(entity, goederenTypeFetchContext, Goederen.PROPS.goederenType.id, Goederen.PROPS.goederenType.direction, Goederen.PROPS.goederenType.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get voorraden() {
                try {
                    return getRelatedPlural(entity, voorradenFetchContext, Goederen.PROPS.voorraden.id, Goederen.PROPS.voorraden.direction, Goederen.PROPS.voorraden.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get risicobeoordelingen() {
                try {
                    return getRelatedPlural(entity, risicobeoordelingenFetchContext, Goederen.PROPS.risicobeoordelingen.id, Goederen.PROPS.risicobeoordelingen.direction, Goederen.PROPS.risicobeoordelingen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get goederenType(): VKRIGoederenType | undefined {
        const goederenTypeFetchContext = getFetchTreeOfRelation(this._fetchContext, Goederen.PROPS.goederenType.id, Goederen.PROPS.goederenType.direction);
        if (goederenTypeFetchContext !== undefined) {
            return toTypedEntity(this._props.goederenType, this._metadataService, goederenTypeFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get voorraden(): Voorraden[] | undefined {
        const voorradenFetchContext = getFetchTreeOfRelation(this._fetchContext, Goederen.PROPS.voorraden.id, Goederen.PROPS.voorraden.direction);
        if (voorradenFetchContext !== undefined) {
            return toTypedEntity(this._props.voorraden, this._metadataService, voorradenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get risicobeoordelingen(): GoederenRisicobeoordeling[] | undefined {
        const risicobeoordelingenFetchContext = getFetchTreeOfRelation(this._fetchContext, Goederen.PROPS.risicobeoordelingen.id, Goederen.PROPS.risicobeoordelingen.direction);
        if (risicobeoordelingenFetchContext !== undefined) {
            return toTypedEntity(this._props.risicobeoordelingen, this._metadataService, risicobeoordelingenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class GroupMember extends ObjEntity {
    public static ID = "c3f720d7-748c-4470-8f70-182d62728bab";
    public static PROPS = createProps({
        ...ObjEntity.PROPS,
        country: {
            type: "Field",
            id: "ab2dc113-f288-4322-a39c-487643225555",
            required: false
        },
        level: {
            type: "Field",
            id: "ba3f8b3d-2abe-45a0-ac7b-ac271018b17a",
            required: true
        },
        name: {
            type: "Field",
            id: "b30d12b8-3b6f-4733-b50b-05025d94a4fd",
            required: true
        },
        registeredNumber: {
            type: "Field",
            id: "963b389d-b5c5-4ecc-ab7d-78a3436124b7",
            required: false
        },
        status: {
            type: "Field",
            id: "a82fee00-16ba-4bc7-997f-fdf6c9f6026d",
            required: false
        },
        organization: {
            type: "RelationshipType",
            id: "336478b5-3493-4fe8-a547-c5d61ddc7aa2",
            direction: "parent",
            multiplicity: "One",
            required: true
        }
    });
    public readonly _props: GroupMemberProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<GroupMember>(this, {
            _props: override,
            country: computed,
            level: computed,
            name: computed,
            registeredNumber: computed,
            status: computed,
            organization: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, GroupMember.PROPS.organization.id, GroupMember.PROPS.organization.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, GroupMember.PROPS.organization.id, GroupMember.PROPS.organization.direction, GroupMember.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get country() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, GroupMember.PROPS.country.id, GroupMember.PROPS.country.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get level() {
                try {
                    return getFieldValue(metadataService, entity, GroupMember.PROPS.level.id, GroupMember.PROPS.level.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get name() {
                try {
                    return getFieldValue(metadataService, entity, GroupMember.PROPS.name.id, GroupMember.PROPS.name.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get registeredNumber() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, GroupMember.PROPS.registeredNumber.id, GroupMember.PROPS.registeredNumber.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get status() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, GroupMember.PROPS.status.id, GroupMember.PROPS.status.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get country(): string | null {
        return this._props.country !== null ? TextType.getRawText(this._props.country)! : null;
    }
    get level(): Decimal {
        return NumberType.getRawNumber(this._props.level)!;
    }
    get name(): string {
        return TextType.getRawText(this._props.name)!;
    }
    get registeredNumber(): string | null {
        return this._props.registeredNumber !== null ? TextType.getRawText(this._props.registeredNumber)! : null;
    }
    get status(): string | null {
        return this._props.status !== null ? TextType.getRawText(this._props.status)! : null;
    }
    get organization(): Organization | undefined {
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, GroupMember.PROPS.organization.id, GroupMember.PROPS.organization.direction);
        if (organizationFetchContext !== undefined) {
            return toTypedEntity(this._props.organization, this._metadataService, organizationFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export abstract class KVKRelation extends ObjEntity {
    public static ID = "9845ccff-9920-4b3e-bb96-51a5bdbbdce3";
    public static PROPS = createProps({
        ...ObjEntity.PROPS,
        name: {
            type: "Field",
            id: "9ca7c2fc-d432-46e6-8c24-178f5cddb84b",
            required: true
        },
        aandeelhouderVan: {
            type: "RelationshipType",
            id: "9c85b5a1-56f8-4501-9da5-f513bfd1d13f",
            direction: "parent",
            multiplicity: "Many",
            required: false
        },
        bestuurderVan: {
            type: "RelationshipType",
            id: "249282c5-547a-4649-ac63-87134d28dc0b",
            direction: "parent",
            multiplicity: "Many",
            required: false
        },
        commissarisVan: {
            type: "RelationshipType",
            id: "c0d88787-2085-48ce-8899-cfc1ef1b75f6",
            direction: "parent",
            multiplicity: "Many",
            required: false
        },
        gevolmachtigdeVan: {
            type: "RelationshipType",
            id: "8c680867-ff69-49ac-9d45-5250151cb6ae",
            direction: "parent",
            multiplicity: "Many",
            required: false
        }
    });
    public readonly _props: KVKRelationProps;
    constructor(protected metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<KVKRelation>(this, {
            _props: override,
            name: computed,
            aandeelhouderVan: computed,
            bestuurderVan: computed,
            commissarisVan: computed,
            gevolmachtigdeVan: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const aandeelhouderVanFetchContext = getFetchTreeOfRelation(this._fetchContext, KVKRelation.PROPS.aandeelhouderVan.id, KVKRelation.PROPS.aandeelhouderVan.direction);
        const bestuurderVanFetchContext = getFetchTreeOfRelation(this._fetchContext, KVKRelation.PROPS.bestuurderVan.id, KVKRelation.PROPS.bestuurderVan.direction);
        const commissarisVanFetchContext = getFetchTreeOfRelation(this._fetchContext, KVKRelation.PROPS.commissarisVan.id, KVKRelation.PROPS.commissarisVan.direction);
        const gevolmachtigdeVanFetchContext = getFetchTreeOfRelation(this._fetchContext, KVKRelation.PROPS.gevolmachtigdeVan.id, KVKRelation.PROPS.gevolmachtigdeVan.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get aandeelhouderVan() {
                try {
                    return getRelatedPlural(entity, aandeelhouderVanFetchContext, KVKRelation.PROPS.aandeelhouderVan.id, KVKRelation.PROPS.aandeelhouderVan.direction, KVKRelation.PROPS.aandeelhouderVan.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get bestuurderVan() {
                try {
                    return getRelatedPlural(entity, bestuurderVanFetchContext, KVKRelation.PROPS.bestuurderVan.id, KVKRelation.PROPS.bestuurderVan.direction, KVKRelation.PROPS.bestuurderVan.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get commissarisVan() {
                try {
                    return getRelatedPlural(entity, commissarisVanFetchContext, KVKRelation.PROPS.commissarisVan.id, KVKRelation.PROPS.commissarisVan.direction, KVKRelation.PROPS.commissarisVan.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get gevolmachtigdeVan() {
                try {
                    return getRelatedPlural(entity, gevolmachtigdeVanFetchContext, KVKRelation.PROPS.gevolmachtigdeVan.id, KVKRelation.PROPS.gevolmachtigdeVan.direction, KVKRelation.PROPS.gevolmachtigdeVan.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get name() {
                try {
                    return getFieldValue(metadataService, entity, KVKRelation.PROPS.name.id, KVKRelation.PROPS.name.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get name(): string {
        return TextType.getRawText(this._props.name)!;
    }
    get aandeelhouderVan(): Organization[] | undefined {
        const aandeelhouderVanFetchContext = getFetchTreeOfRelation(this._fetchContext, KVKRelation.PROPS.aandeelhouderVan.id, KVKRelation.PROPS.aandeelhouderVan.direction);
        if (aandeelhouderVanFetchContext !== undefined) {
            return toTypedEntity(this._props.aandeelhouderVan, this._metadataService, aandeelhouderVanFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get bestuurderVan(): Organization[] | undefined {
        const bestuurderVanFetchContext = getFetchTreeOfRelation(this._fetchContext, KVKRelation.PROPS.bestuurderVan.id, KVKRelation.PROPS.bestuurderVan.direction);
        if (bestuurderVanFetchContext !== undefined) {
            return toTypedEntity(this._props.bestuurderVan, this._metadataService, bestuurderVanFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get commissarisVan(): Organization[] | undefined {
        const commissarisVanFetchContext = getFetchTreeOfRelation(this._fetchContext, KVKRelation.PROPS.commissarisVan.id, KVKRelation.PROPS.commissarisVan.direction);
        if (commissarisVanFetchContext !== undefined) {
            return toTypedEntity(this._props.commissarisVan, this._metadataService, commissarisVanFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get gevolmachtigdeVan(): Organization[] | undefined {
        const gevolmachtigdeVanFetchContext = getFetchTreeOfRelation(this._fetchContext, KVKRelation.PROPS.gevolmachtigdeVan.id, KVKRelation.PROPS.gevolmachtigdeVan.direction);
        if (gevolmachtigdeVanFetchContext !== undefined) {
            return toTypedEntity(this._props.gevolmachtigdeVan, this._metadataService, gevolmachtigdeVanFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class Organization extends KVKRelation {
    public static ID = "0bad56f9-db49-44ac-ba9a-ca4cfa68e1b5";
    public static PROPS = createProps({
        ...KVKRelation.PROPS,
        email: {
            type: "Field",
            id: "14214924-7ca0-4267-a838-41e87308d1a9",
            required: true
        },
        telefoonnummer: {
            type: "Field",
            id: "cb53f79f-f2e2-4d78-97b9-23b69d8504f0",
            required: false
        },
        bezoekadresStraat: {
            type: "Field",
            id: "7b99bf61-574e-4be5-a22d-73039c4fda66",
            required: false
        },
        bezoekadresHuisnummer: {
            type: "Field",
            id: "2ee688bc-610e-4f2d-a33c-bd5fb2407771",
            required: false
        },
        bezoekadresHuisnummertoevoeging: {
            type: "Field",
            id: "775b1db6-3957-4d80-aa59-3f535115ca43",
            required: false
        },
        bezoekadresPostcode: {
            type: "Field",
            id: "5df86ee8-5d74-4249-8fb4-43303f6de823",
            required: false
        },
        bezoekadresPlaatsnaam: {
            type: "Field",
            id: "0ab67b0b-6878-4f07-beae-20c879955248",
            required: false
        },
        bezoekadresLand: {
            type: "Field",
            id: "5ef1d380-e4f9-4fde-8d7e-bfc48458a0d1",
            required: false
        },
        aantalWerkzamePersonen: {
            type: "Field",
            id: "b181e0cc-1e93-49dc-9a0c-9846d42813ae",
            required: true
        },
        website: {
            type: "Field",
            id: "d581d0aa-c6e6-46a7-9284-7cad50977f5b",
            required: false
        },
        kvkNummer: {
            type: "Field",
            id: "c90622a4-2775-4b40-bc17-d0c4b6115bdb",
            required: false
        },
        gestortKapital: {
            type: "Field",
            id: "f1f4fbe8-e5d7-4dd2-aa31-89746b5f57a6",
            required: false
        },
        startdatumOnderneming: {
            type: "Field",
            id: "0d4a3d9e-eb4e-46af-a1ee-ca28d312071c",
            required: false
        },
        objects: {
            type: "RelationshipType",
            id: "e4f66ada-39d8-4bb3-8150-33eb4ca8d2d7",
            direction: "child",
            multiplicity: "Many",
            required: false
        },
        primaireSBIActiviteit: {
            type: "RelationshipType",
            id: "40c7ca03-c9cd-4a6a-b284-fef45afd02f7",
            direction: "child",
            multiplicity: "One",
            required: true
        },
        sbiActiviteiten: {
            type: "RelationshipType",
            id: "673f8545-5456-4d2e-a585-d067a8e0f2ed",
            direction: "child",
            multiplicity: "Many",
            required: true
        },
        aandeelhouders: {
            type: "RelationshipType",
            id: "9c85b5a1-56f8-4501-9da5-f513bfd1d13f",
            direction: "child",
            multiplicity: "Many",
            required: false
        },
        bestuurders: {
            type: "RelationshipType",
            id: "249282c5-547a-4649-ac63-87134d28dc0b",
            direction: "child",
            multiplicity: "Many",
            required: false
        },
        commissarissen: {
            type: "RelationshipType",
            id: "c0d88787-2085-48ce-8899-cfc1ef1b75f6",
            direction: "child",
            multiplicity: "Many",
            required: false
        },
        gevolmachtigden: {
            type: "RelationshipType",
            id: "8c680867-ff69-49ac-9d45-5250151cb6ae",
            direction: "child",
            multiplicity: "Many",
            required: false
        },
        risicoAdressen: {
            type: "RelationshipType",
            id: "02f9f2bb-cb3f-429e-b8cd-6bcc86d9117b",
            direction: "child",
            multiplicity: "Many",
            required: false
        },
        concernrelatiesDochter: {
            type: "RelationshipType",
            id: "670868eb-79c1-48b0-9cf6-43a4ed00681e",
            direction: "child",
            multiplicity: "Many",
            required: false
        },
        concernrelatiesGroupMember: {
            type: "RelationshipType",
            id: "336478b5-3493-4fe8-a547-c5d61ddc7aa2",
            direction: "child",
            multiplicity: "Many",
            required: false
        },
        deponeringen: {
            type: "RelationshipType",
            id: "27f45c01-ebef-4a73-b7fc-4a1e411d86f4",
            direction: "child",
            multiplicity: "Many",
            required: false
        },
        client: {
            type: "RelationshipType",
            id: "1096f8a2-91f8-45a5-8ab6-6d8de865fd4a",
            direction: "parent",
            multiplicity: "One",
            required: false
        },
        concernrelatiesMoeder: {
            type: "RelationshipType",
            id: "103cfd7f-12aa-4def-bf07-385e61b85022",
            direction: "parent",
            multiplicity: "Many",
            required: false
        }
    });
    public readonly _props: OrganizationProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Organization>(this, {
            _props: override,
            email: computed,
            telefoonnummer: computed,
            bezoekadresStraat: computed,
            bezoekadresHuisnummer: computed,
            bezoekadresHuisnummertoevoeging: computed,
            bezoekadresPostcode: computed,
            bezoekadresPlaatsnaam: computed,
            bezoekadresLand: computed,
            aantalWerkzamePersonen: computed,
            website: computed,
            kvkNummer: computed,
            gestortKapital: computed,
            startdatumOnderneming: computed,
            objects: computed,
            primaireSBIActiviteit: computed,
            sbiActiviteiten: computed,
            aandeelhouders: computed,
            bestuurders: computed,
            commissarissen: computed,
            gevolmachtigden: computed,
            risicoAdressen: computed,
            concernrelatiesDochter: computed,
            concernrelatiesGroupMember: computed,
            deponeringen: computed,
            client: computed,
            concernrelatiesMoeder: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const aandeelhouderVanFetchContext = getFetchTreeOfRelation(this._fetchContext, KVKRelation.PROPS.aandeelhouderVan.id, KVKRelation.PROPS.aandeelhouderVan.direction);
        const bestuurderVanFetchContext = getFetchTreeOfRelation(this._fetchContext, KVKRelation.PROPS.bestuurderVan.id, KVKRelation.PROPS.bestuurderVan.direction);
        const commissarisVanFetchContext = getFetchTreeOfRelation(this._fetchContext, KVKRelation.PROPS.commissarisVan.id, KVKRelation.PROPS.commissarisVan.direction);
        const gevolmachtigdeVanFetchContext = getFetchTreeOfRelation(this._fetchContext, KVKRelation.PROPS.gevolmachtigdeVan.id, KVKRelation.PROPS.gevolmachtigdeVan.direction);
        const objectsFetchContext = getFetchTreeOfRelation(this._fetchContext, Organization.PROPS.objects.id, Organization.PROPS.objects.direction);
        const primaireSBIActiviteitFetchContext = getFetchTreeOfRelation(this._fetchContext, Organization.PROPS.primaireSBIActiviteit.id, Organization.PROPS.primaireSBIActiviteit.direction);
        const sbiActiviteitenFetchContext = getFetchTreeOfRelation(this._fetchContext, Organization.PROPS.sbiActiviteiten.id, Organization.PROPS.sbiActiviteiten.direction);
        const aandeelhoudersFetchContext = getFetchTreeOfRelation(this._fetchContext, Organization.PROPS.aandeelhouders.id, Organization.PROPS.aandeelhouders.direction);
        const bestuurdersFetchContext = getFetchTreeOfRelation(this._fetchContext, Organization.PROPS.bestuurders.id, Organization.PROPS.bestuurders.direction);
        const commissarissenFetchContext = getFetchTreeOfRelation(this._fetchContext, Organization.PROPS.commissarissen.id, Organization.PROPS.commissarissen.direction);
        const gevolmachtigdenFetchContext = getFetchTreeOfRelation(this._fetchContext, Organization.PROPS.gevolmachtigden.id, Organization.PROPS.gevolmachtigden.direction);
        const risicoAdressenFetchContext = getFetchTreeOfRelation(this._fetchContext, Organization.PROPS.risicoAdressen.id, Organization.PROPS.risicoAdressen.direction);
        const concernrelatiesDochterFetchContext = getFetchTreeOfRelation(this._fetchContext, Organization.PROPS.concernrelatiesDochter.id, Organization.PROPS.concernrelatiesDochter.direction);
        const concernrelatiesGroupMemberFetchContext = getFetchTreeOfRelation(this._fetchContext, Organization.PROPS.concernrelatiesGroupMember.id, Organization.PROPS.concernrelatiesGroupMember.direction);
        const deponeringenFetchContext = getFetchTreeOfRelation(this._fetchContext, Organization.PROPS.deponeringen.id, Organization.PROPS.deponeringen.direction);
        const clientFetchContext = getFetchTreeOfRelation(this._fetchContext, Organization.PROPS.client.id, Organization.PROPS.client.direction);
        const concernrelatiesMoederFetchContext = getFetchTreeOfRelation(this._fetchContext, Organization.PROPS.concernrelatiesMoeder.id, Organization.PROPS.concernrelatiesMoeder.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get aandeelhouderVan() {
                try {
                    return getRelatedPlural(entity, aandeelhouderVanFetchContext, KVKRelation.PROPS.aandeelhouderVan.id, KVKRelation.PROPS.aandeelhouderVan.direction, KVKRelation.PROPS.aandeelhouderVan.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get bestuurderVan() {
                try {
                    return getRelatedPlural(entity, bestuurderVanFetchContext, KVKRelation.PROPS.bestuurderVan.id, KVKRelation.PROPS.bestuurderVan.direction, KVKRelation.PROPS.bestuurderVan.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get commissarisVan() {
                try {
                    return getRelatedPlural(entity, commissarisVanFetchContext, KVKRelation.PROPS.commissarisVan.id, KVKRelation.PROPS.commissarisVan.direction, KVKRelation.PROPS.commissarisVan.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get gevolmachtigdeVan() {
                try {
                    return getRelatedPlural(entity, gevolmachtigdeVanFetchContext, KVKRelation.PROPS.gevolmachtigdeVan.id, KVKRelation.PROPS.gevolmachtigdeVan.direction, KVKRelation.PROPS.gevolmachtigdeVan.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get name() {
                try {
                    return getFieldValue(metadataService, entity, KVKRelation.PROPS.name.id, KVKRelation.PROPS.name.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objects() {
                try {
                    return getRelatedPlural(entity, objectsFetchContext, Organization.PROPS.objects.id, Organization.PROPS.objects.direction, Organization.PROPS.objects.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get primaireSBIActiviteit() {
                try {
                    return getRelatedSingular(entity, primaireSBIActiviteitFetchContext, Organization.PROPS.primaireSBIActiviteit.id, Organization.PROPS.primaireSBIActiviteit.direction, Organization.PROPS.primaireSBIActiviteit.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get sbiActiviteiten() {
                try {
                    return getRelatedPlural(entity, sbiActiviteitenFetchContext, Organization.PROPS.sbiActiviteiten.id, Organization.PROPS.sbiActiviteiten.direction, Organization.PROPS.sbiActiviteiten.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get aandeelhouders() {
                try {
                    return getRelatedPlural(entity, aandeelhoudersFetchContext, Organization.PROPS.aandeelhouders.id, Organization.PROPS.aandeelhouders.direction, Organization.PROPS.aandeelhouders.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get bestuurders() {
                try {
                    return getRelatedPlural(entity, bestuurdersFetchContext, Organization.PROPS.bestuurders.id, Organization.PROPS.bestuurders.direction, Organization.PROPS.bestuurders.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get commissarissen() {
                try {
                    return getRelatedPlural(entity, commissarissenFetchContext, Organization.PROPS.commissarissen.id, Organization.PROPS.commissarissen.direction, Organization.PROPS.commissarissen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get gevolmachtigden() {
                try {
                    return getRelatedPlural(entity, gevolmachtigdenFetchContext, Organization.PROPS.gevolmachtigden.id, Organization.PROPS.gevolmachtigden.direction, Organization.PROPS.gevolmachtigden.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get risicoAdressen() {
                try {
                    return getRelatedPlural(entity, risicoAdressenFetchContext, Organization.PROPS.risicoAdressen.id, Organization.PROPS.risicoAdressen.direction, Organization.PROPS.risicoAdressen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get concernrelatiesDochter() {
                try {
                    return getRelatedPlural(entity, concernrelatiesDochterFetchContext, Organization.PROPS.concernrelatiesDochter.id, Organization.PROPS.concernrelatiesDochter.direction, Organization.PROPS.concernrelatiesDochter.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get concernrelatiesGroupMember() {
                try {
                    return getRelatedPlural(entity, concernrelatiesGroupMemberFetchContext, Organization.PROPS.concernrelatiesGroupMember.id, Organization.PROPS.concernrelatiesGroupMember.direction, Organization.PROPS.concernrelatiesGroupMember.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get deponeringen() {
                try {
                    return getRelatedPlural(entity, deponeringenFetchContext, Organization.PROPS.deponeringen.id, Organization.PROPS.deponeringen.direction, Organization.PROPS.deponeringen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get client() {
                try {
                    return getRelatedSingular(entity, clientFetchContext, Organization.PROPS.client.id, Organization.PROPS.client.direction, Organization.PROPS.client.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get concernrelatiesMoeder() {
                try {
                    return getRelatedPlural(entity, concernrelatiesMoederFetchContext, Organization.PROPS.concernrelatiesMoeder.id, Organization.PROPS.concernrelatiesMoeder.direction, Organization.PROPS.concernrelatiesMoeder.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get email() {
                try {
                    return getFieldValue(metadataService, entity, Organization.PROPS.email.id, Organization.PROPS.email.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get telefoonnummer() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Organization.PROPS.telefoonnummer.id, Organization.PROPS.telefoonnummer.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get bezoekadresStraat() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Organization.PROPS.bezoekadresStraat.id, Organization.PROPS.bezoekadresStraat.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get bezoekadresHuisnummer() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Organization.PROPS.bezoekadresHuisnummer.id, Organization.PROPS.bezoekadresHuisnummer.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get bezoekadresHuisnummertoevoeging() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Organization.PROPS.bezoekadresHuisnummertoevoeging.id, Organization.PROPS.bezoekadresHuisnummertoevoeging.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get bezoekadresPostcode() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Organization.PROPS.bezoekadresPostcode.id, Organization.PROPS.bezoekadresPostcode.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get bezoekadresPlaatsnaam() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Organization.PROPS.bezoekadresPlaatsnaam.id, Organization.PROPS.bezoekadresPlaatsnaam.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get bezoekadresLand() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Organization.PROPS.bezoekadresLand.id, Organization.PROPS.bezoekadresLand.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get aantalWerkzamePersonen() {
                try {
                    return getFieldValue(metadataService, entity, Organization.PROPS.aantalWerkzamePersonen.id, Organization.PROPS.aantalWerkzamePersonen.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get website() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Organization.PROPS.website.id, Organization.PROPS.website.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get kvkNummer() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Organization.PROPS.kvkNummer.id, Organization.PROPS.kvkNummer.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get gestortKapital() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Organization.PROPS.gestortKapital.id, Organization.PROPS.gestortKapital.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get startdatumOnderneming() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Organization.PROPS.startdatumOnderneming.id, Organization.PROPS.startdatumOnderneming.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get email(): string {
        return EmailType.getRawEmailAddress(this._props.email)!;
    }
    get telefoonnummer(): string | null {
        return this._props.telefoonnummer !== null ? PhoneType.getRawPhoneNumber(this._props.telefoonnummer)! : null;
    }
    get bezoekadresStraat(): string | null {
        return this._props.bezoekadresStraat !== null ? TextType.getRawText(this._props.bezoekadresStraat)! : null;
    }
    get bezoekadresHuisnummer(): Decimal | null {
        return this._props.bezoekadresHuisnummer !== null ? NumberType.getRawNumber(this._props.bezoekadresHuisnummer)! : null;
    }
    get bezoekadresHuisnummertoevoeging(): string | null {
        return this._props.bezoekadresHuisnummertoevoeging !== null ? TextType.getRawText(this._props.bezoekadresHuisnummertoevoeging)! : null;
    }
    get bezoekadresPostcode(): string | null {
        return this._props.bezoekadresPostcode !== null ? TextType.getRawText(this._props.bezoekadresPostcode)! : null;
    }
    get bezoekadresPlaatsnaam(): string | null {
        return this._props.bezoekadresPlaatsnaam !== null ? TextType.getRawText(this._props.bezoekadresPlaatsnaam)! : null;
    }
    get bezoekadresLand(): string | null {
        return this._props.bezoekadresLand !== null ? TextType.getRawText(this._props.bezoekadresLand)! : null;
    }
    get aantalWerkzamePersonen(): Decimal {
        return NumberType.getRawNumber(this._props.aantalWerkzamePersonen)!;
    }
    get website(): string | null {
        return this._props.website !== null ? TextType.getRawText(this._props.website)! : null;
    }
    get kvkNummer(): Decimal | null {
        return this._props.kvkNummer !== null ? NumberType.getRawNumber(this._props.kvkNummer)! : null;
    }
    get gestortKapital(): Decimal | null {
        return this._props.gestortKapital !== null ? CurrencyType.getRawAmount(this._props.gestortKapital)! : null;
    }
    get startdatumOnderneming(): Date | null {
        return this._props.startdatumOnderneming !== null ? DateType.getRawDate(this._props.startdatumOnderneming)! : null;
    }
    get objects(): ObjectBP[] | undefined {
        const objectsFetchContext = getFetchTreeOfRelation(this._fetchContext, Organization.PROPS.objects.id, Organization.PROPS.objects.direction);
        if (objectsFetchContext !== undefined) {
            return toTypedEntity(this._props.objects, this._metadataService, objectsFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get primaireSBIActiviteit(): SBI | undefined {
        const primaireSBIActiviteitFetchContext = getFetchTreeOfRelation(this._fetchContext, Organization.PROPS.primaireSBIActiviteit.id, Organization.PROPS.primaireSBIActiviteit.direction);
        if (primaireSBIActiviteitFetchContext !== undefined) {
            return toTypedEntity(this._props.primaireSBIActiviteit, this._metadataService, primaireSBIActiviteitFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get sbiActiviteiten(): SBI[] | undefined {
        const sbiActiviteitenFetchContext = getFetchTreeOfRelation(this._fetchContext, Organization.PROPS.sbiActiviteiten.id, Organization.PROPS.sbiActiviteiten.direction);
        if (sbiActiviteitenFetchContext !== undefined) {
            return toTypedEntity(this._props.sbiActiviteiten, this._metadataService, sbiActiviteitenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get aandeelhouders(): KVKRelation[] | undefined {
        const aandeelhoudersFetchContext = getFetchTreeOfRelation(this._fetchContext, Organization.PROPS.aandeelhouders.id, Organization.PROPS.aandeelhouders.direction);
        if (aandeelhoudersFetchContext !== undefined) {
            return toTypedEntity(this._props.aandeelhouders, this._metadataService, aandeelhoudersFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get bestuurders(): KVKRelation[] | undefined {
        const bestuurdersFetchContext = getFetchTreeOfRelation(this._fetchContext, Organization.PROPS.bestuurders.id, Organization.PROPS.bestuurders.direction);
        if (bestuurdersFetchContext !== undefined) {
            return toTypedEntity(this._props.bestuurders, this._metadataService, bestuurdersFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get commissarissen(): KVKRelation[] | undefined {
        const commissarissenFetchContext = getFetchTreeOfRelation(this._fetchContext, Organization.PROPS.commissarissen.id, Organization.PROPS.commissarissen.direction);
        if (commissarissenFetchContext !== undefined) {
            return toTypedEntity(this._props.commissarissen, this._metadataService, commissarissenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get gevolmachtigden(): KVKRelation[] | undefined {
        const gevolmachtigdenFetchContext = getFetchTreeOfRelation(this._fetchContext, Organization.PROPS.gevolmachtigden.id, Organization.PROPS.gevolmachtigden.direction);
        if (gevolmachtigdenFetchContext !== undefined) {
            return toTypedEntity(this._props.gevolmachtigden, this._metadataService, gevolmachtigdenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get risicoAdressen(): RisicoAdres[] | undefined {
        const risicoAdressenFetchContext = getFetchTreeOfRelation(this._fetchContext, Organization.PROPS.risicoAdressen.id, Organization.PROPS.risicoAdressen.direction);
        if (risicoAdressenFetchContext !== undefined) {
            return toTypedEntity(this._props.risicoAdressen, this._metadataService, risicoAdressenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get concernrelatiesDochter(): Concernrelaties[] | undefined {
        const concernrelatiesDochterFetchContext = getFetchTreeOfRelation(this._fetchContext, Organization.PROPS.concernrelatiesDochter.id, Organization.PROPS.concernrelatiesDochter.direction);
        if (concernrelatiesDochterFetchContext !== undefined) {
            return toTypedEntity(this._props.concernrelatiesDochter, this._metadataService, concernrelatiesDochterFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get concernrelatiesGroupMember(): GroupMember[] | undefined {
        const concernrelatiesGroupMemberFetchContext = getFetchTreeOfRelation(this._fetchContext, Organization.PROPS.concernrelatiesGroupMember.id, Organization.PROPS.concernrelatiesGroupMember.direction);
        if (concernrelatiesGroupMemberFetchContext !== undefined) {
            return toTypedEntity(this._props.concernrelatiesGroupMember, this._metadataService, concernrelatiesGroupMemberFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get deponeringen(): Deponeringen[] | undefined {
        const deponeringenFetchContext = getFetchTreeOfRelation(this._fetchContext, Organization.PROPS.deponeringen.id, Organization.PROPS.deponeringen.direction);
        if (deponeringenFetchContext !== undefined) {
            return toTypedEntity(this._props.deponeringen, this._metadataService, deponeringenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get client(): (BusinessClient | null) | undefined {
        const clientFetchContext = getFetchTreeOfRelation(this._fetchContext, Organization.PROPS.client.id, Organization.PROPS.client.direction);
        if (clientFetchContext !== undefined) {
            return toTypedEntity(this._props.client, this._metadataService, clientFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get concernrelatiesMoeder(): Concernrelaties[] | undefined {
        const concernrelatiesMoederFetchContext = getFetchTreeOfRelation(this._fetchContext, Organization.PROPS.concernrelatiesMoeder.id, Organization.PROPS.concernrelatiesMoeder.direction);
        if (concernrelatiesMoederFetchContext !== undefined) {
            return toTypedEntity(this._props.concernrelatiesMoeder, this._metadataService, concernrelatiesMoederFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class Person extends KVKRelation {
    public static ID = "94a031ef-5957-4543-a443-00c259232f2f";
    public static PROPS = createProps({
        ...KVKRelation.PROPS,
        birthDate: {
            type: "Field",
            id: "a484e4ef-0f2d-409a-8f00-2bc1dd302a6b",
            required: false
        },
        voornamen: {
            type: "Field",
            id: "6e96b4a2-c763-4915-b2bc-41ecec26cf44",
            required: false
        },
        DGAs: {
            type: "RelationshipType",
            id: "79f9f36d-2311-4c27-86d8-61322c657c7d",
            direction: "parent",
            multiplicity: "Many",
            required: false
        },
        management: {
            type: "RelationshipType",
            id: "c4ad9dfe-633d-4577-9f05-91631e299a4d",
            direction: "parent",
            multiplicity: "Many",
            required: false
        },
        businessClient: {
            type: "RelationshipType",
            id: "0d640fa2-67be-45db-9291-47f7ab1b89dc",
            direction: "parent",
            multiplicity: "One",
            required: false
        },
        dossiers: {
            type: "RelationshipType",
            id: "69b64f6f-1634-4176-9396-51d476fccb11",
            direction: "parent",
            multiplicity: "Many",
            required: false
        }
    });
    public readonly _props: PersonProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Person>(this, {
            _props: override,
            birthDate: computed,
            voornamen: computed,
            DGAs: computed,
            management: computed,
            businessClient: computed,
            dossiers: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const aandeelhouderVanFetchContext = getFetchTreeOfRelation(this._fetchContext, KVKRelation.PROPS.aandeelhouderVan.id, KVKRelation.PROPS.aandeelhouderVan.direction);
        const bestuurderVanFetchContext = getFetchTreeOfRelation(this._fetchContext, KVKRelation.PROPS.bestuurderVan.id, KVKRelation.PROPS.bestuurderVan.direction);
        const commissarisVanFetchContext = getFetchTreeOfRelation(this._fetchContext, KVKRelation.PROPS.commissarisVan.id, KVKRelation.PROPS.commissarisVan.direction);
        const gevolmachtigdeVanFetchContext = getFetchTreeOfRelation(this._fetchContext, KVKRelation.PROPS.gevolmachtigdeVan.id, KVKRelation.PROPS.gevolmachtigdeVan.direction);
        const DGAsFetchContext = getFetchTreeOfRelation(this._fetchContext, Person.PROPS.DGAs.id, Person.PROPS.DGAs.direction);
        const managementFetchContext = getFetchTreeOfRelation(this._fetchContext, Person.PROPS.management.id, Person.PROPS.management.direction);
        const businessClientFetchContext = getFetchTreeOfRelation(this._fetchContext, Person.PROPS.businessClient.id, Person.PROPS.businessClient.direction);
        const dossiersFetchContext = getFetchTreeOfRelation(this._fetchContext, Person.PROPS.dossiers.id, Person.PROPS.dossiers.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get aandeelhouderVan() {
                try {
                    return getRelatedPlural(entity, aandeelhouderVanFetchContext, KVKRelation.PROPS.aandeelhouderVan.id, KVKRelation.PROPS.aandeelhouderVan.direction, KVKRelation.PROPS.aandeelhouderVan.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get bestuurderVan() {
                try {
                    return getRelatedPlural(entity, bestuurderVanFetchContext, KVKRelation.PROPS.bestuurderVan.id, KVKRelation.PROPS.bestuurderVan.direction, KVKRelation.PROPS.bestuurderVan.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get commissarisVan() {
                try {
                    return getRelatedPlural(entity, commissarisVanFetchContext, KVKRelation.PROPS.commissarisVan.id, KVKRelation.PROPS.commissarisVan.direction, KVKRelation.PROPS.commissarisVan.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get gevolmachtigdeVan() {
                try {
                    return getRelatedPlural(entity, gevolmachtigdeVanFetchContext, KVKRelation.PROPS.gevolmachtigdeVan.id, KVKRelation.PROPS.gevolmachtigdeVan.direction, KVKRelation.PROPS.gevolmachtigdeVan.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get name() {
                try {
                    return getFieldValue(metadataService, entity, KVKRelation.PROPS.name.id, KVKRelation.PROPS.name.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get DGAs() {
                try {
                    return getRelatedPlural(entity, DGAsFetchContext, Person.PROPS.DGAs.id, Person.PROPS.DGAs.direction, Person.PROPS.DGAs.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get management() {
                try {
                    return getRelatedPlural(entity, managementFetchContext, Person.PROPS.management.id, Person.PROPS.management.direction, Person.PROPS.management.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get businessClient() {
                try {
                    return getRelatedSingular(entity, businessClientFetchContext, Person.PROPS.businessClient.id, Person.PROPS.businessClient.direction, Person.PROPS.businessClient.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get dossiers() {
                try {
                    return getRelatedPlural(entity, dossiersFetchContext, Person.PROPS.dossiers.id, Person.PROPS.dossiers.direction, Person.PROPS.dossiers.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get birthDate() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Person.PROPS.birthDate.id, Person.PROPS.birthDate.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get voornamen() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Person.PROPS.voornamen.id, Person.PROPS.voornamen.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get birthDate(): Date | null {
        return this._props.birthDate !== null ? DateType.getRawDate(this._props.birthDate)! : null;
    }
    get voornamen(): string | null {
        return this._props.voornamen !== null ? TextType.getRawText(this._props.voornamen)! : null;
    }
    get DGAs(): DGA[] | undefined {
        const DGAsFetchContext = getFetchTreeOfRelation(this._fetchContext, Person.PROPS.DGAs.id, Person.PROPS.DGAs.direction);
        if (DGAsFetchContext !== undefined) {
            return toTypedEntity(this._props.DGAs, this._metadataService, DGAsFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get management(): Management[] | undefined {
        const managementFetchContext = getFetchTreeOfRelation(this._fetchContext, Person.PROPS.management.id, Person.PROPS.management.direction);
        if (managementFetchContext !== undefined) {
            return toTypedEntity(this._props.management, this._metadataService, managementFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get businessClient(): (BusinessClient | null) | undefined {
        const businessClientFetchContext = getFetchTreeOfRelation(this._fetchContext, Person.PROPS.businessClient.id, Person.PROPS.businessClient.direction);
        if (businessClientFetchContext !== undefined) {
            return toTypedEntity(this._props.businessClient, this._metadataService, businessClientFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get dossiers(): Dossier[] | undefined {
        const dossiersFetchContext = getFetchTreeOfRelation(this._fetchContext, Person.PROPS.dossiers.id, Person.PROPS.dossiers.direction);
        if (dossiersFetchContext !== undefined) {
            return toTypedEntity(this._props.dossiers, this._metadataService, dossiersFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export abstract class ObjectBP extends ObjEntity {
    public static ID = "50a0e4c6-7692-4e5e-af77-caad9c3af34e";
    public static PROPS = createProps({
        ...ObjEntity.PROPS,
        objectType: {
            type: "Field",
            id: "ca039104-72b6-4ebb-97a8-5c40dcb820b5",
            required: true
        },
        value: {
            type: "Field",
            id: "83655b4b-15b7-4a18-8747-1345074a123c",
            required: false
        },
        acceptatievragen: {
            type: "RelationshipType",
            id: "36ee8e41-1c11-413f-b1d3-102dd5483dbd",
            direction: "child",
            multiplicity: "Many",
            required: false
        },
        organization: {
            type: "RelationshipType",
            id: "e4f66ada-39d8-4bb3-8150-33eb4ca8d2d7",
            direction: "parent",
            multiplicity: "One",
            required: true
        }
    });
    public readonly _props: ObjectBPProps;
    constructor(protected metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<ObjectBP>(this, {
            _props: override,
            objectType: computed,
            value: computed,
            acceptatievragen: computed,
            organization: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const acceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatievragen() {
                try {
                    return getRelatedPlural(entity, acceptatievragenFetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction, ObjectBP.PROPS.acceptatievragen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction, ObjectBP.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectType() {
                try {
                    return getFieldValue(metadataService, entity, ObjectBP.PROPS.objectType.id, ObjectBP.PROPS.objectType.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ObjectBP.PROPS.value.id, ObjectBP.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get objectType(): string {
        return TextType.getRawText(this._props.objectType)!;
    }
    get value(): Decimal | null {
        return this._props.value !== null ? CurrencyType.getRawAmount(this._props.value)! : null;
    }
    get acceptatievragen(): Acceptatiedeelvragen[] | undefined {
        const acceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction);
        if (acceptatievragenFetchContext !== undefined) {
            return toTypedEntity(this._props.acceptatievragen, this._metadataService, acceptatievragenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get organization(): Organization | undefined {
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction);
        if (organizationFetchContext !== undefined) {
            return toTypedEntity(this._props.organization, this._metadataService, organizationFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class Aanhanger extends ObjectBP {
    public static ID = "5d8c257b-5aff-44e8-b43c-1f0473e99820";
    public static PROPS = createProps({
        ...ObjectBP.PROPS,
        merk: {
            type: "Field",
            id: "89ed83be-2711-4f0a-9c39-36778ac13a23",
            required: true
        },
        typeNaam: {
            type: "Field",
            id: "26ec9ec5-00a4-4541-a607-b58cdcda4a0d",
            required: false
        },
        bouwjaar: {
            type: "Field",
            id: "3109482d-48c6-41e1-9357-c2004919faaa",
            required: false
        }
    });
    public readonly _props: AanhangerProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Aanhanger>(this, {
            _props: override,
            merk: computed,
            typeNaam: computed,
            bouwjaar: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const acceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatievragen() {
                try {
                    return getRelatedPlural(entity, acceptatievragenFetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction, ObjectBP.PROPS.acceptatievragen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction, ObjectBP.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectType() {
                try {
                    return getFieldValue(metadataService, entity, ObjectBP.PROPS.objectType.id, ObjectBP.PROPS.objectType.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ObjectBP.PROPS.value.id, ObjectBP.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get merk() {
                try {
                    return getFieldValue(metadataService, entity, Aanhanger.PROPS.merk.id, Aanhanger.PROPS.merk.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get typeNaam() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Aanhanger.PROPS.typeNaam.id, Aanhanger.PROPS.typeNaam.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get bouwjaar() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Aanhanger.PROPS.bouwjaar.id, Aanhanger.PROPS.bouwjaar.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get merk(): string {
        return TextType.getRawText(this._props.merk)!;
    }
    get typeNaam(): string | null {
        return this._props.typeNaam !== null ? TextType.getRawText(this._props.typeNaam)! : null;
    }
    get bouwjaar(): Decimal | null {
        return this._props.bouwjaar !== null ? NumberType.getRawNumber(this._props.bouwjaar)! : null;
    }
}
export abstract class AbstractGebouwen extends ObjectBP {
    public static ID = "0d8a7bb2-ec8b-4f54-a4f4-eea1e0b49521";
    public static PROPS = createProps({
        ...ObjectBP.PROPS,
        bouwjaar: {
            type: "Field",
            id: "374f73bf-66cd-4e1c-9588-64c9011e4beb",
            required: false
        },
        risicoAdres: {
            type: "RelationshipType",
            id: "a33879fe-a537-4d33-86be-bb1a5dc58f14",
            direction: "child",
            multiplicity: "One",
            required: true
        }
    });
    public readonly _props: AbstractGebouwenProps;
    constructor(protected metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<AbstractGebouwen>(this, {
            _props: override,
            bouwjaar: computed,
            risicoAdres: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const acceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction);
        const risicoAdresFetchContext = getFetchTreeOfRelation(this._fetchContext, AbstractGebouwen.PROPS.risicoAdres.id, AbstractGebouwen.PROPS.risicoAdres.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatievragen() {
                try {
                    return getRelatedPlural(entity, acceptatievragenFetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction, ObjectBP.PROPS.acceptatievragen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction, ObjectBP.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectType() {
                try {
                    return getFieldValue(metadataService, entity, ObjectBP.PROPS.objectType.id, ObjectBP.PROPS.objectType.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ObjectBP.PROPS.value.id, ObjectBP.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get risicoAdres() {
                try {
                    return getRelatedSingular(entity, risicoAdresFetchContext, AbstractGebouwen.PROPS.risicoAdres.id, AbstractGebouwen.PROPS.risicoAdres.direction, AbstractGebouwen.PROPS.risicoAdres.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get bouwjaar() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, AbstractGebouwen.PROPS.bouwjaar.id, AbstractGebouwen.PROPS.bouwjaar.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get bouwjaar(): Date | null {
        return this._props.bouwjaar !== null ? DateType.getRawDate(this._props.bouwjaar)! : null;
    }
    get risicoAdres(): RisicoAdres | undefined {
        const risicoAdresFetchContext = getFetchTreeOfRelation(this._fetchContext, AbstractGebouwen.PROPS.risicoAdres.id, AbstractGebouwen.PROPS.risicoAdres.direction);
        if (risicoAdresFetchContext !== undefined) {
            return toTypedEntity(this._props.risicoAdres, this._metadataService, risicoAdresFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class Gebouwen extends AbstractGebouwen {
    public static ID = "fefbda0c-5a7d-4d64-b983-4e6befdca03d";
    public static PROPS = createProps({
        ...AbstractGebouwen.PROPS
    });
    public readonly _props: GebouwenProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Gebouwen>(this, {
            _props: override
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const acceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction);
        const risicoAdresFetchContext = getFetchTreeOfRelation(this._fetchContext, AbstractGebouwen.PROPS.risicoAdres.id, AbstractGebouwen.PROPS.risicoAdres.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatievragen() {
                try {
                    return getRelatedPlural(entity, acceptatievragenFetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction, ObjectBP.PROPS.acceptatievragen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction, ObjectBP.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectType() {
                try {
                    return getFieldValue(metadataService, entity, ObjectBP.PROPS.objectType.id, ObjectBP.PROPS.objectType.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ObjectBP.PROPS.value.id, ObjectBP.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get risicoAdres() {
                try {
                    return getRelatedSingular(entity, risicoAdresFetchContext, AbstractGebouwen.PROPS.risicoAdres.id, AbstractGebouwen.PROPS.risicoAdres.direction, AbstractGebouwen.PROPS.risicoAdres.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get bouwjaar() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, AbstractGebouwen.PROPS.bouwjaar.id, AbstractGebouwen.PROPS.bouwjaar.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
}
export class HuurdersInvesteringen extends AbstractGebouwen {
    public static ID = "c35ade5b-f6c0-473c-8d8f-e0da9e30793e";
    public static PROPS = createProps({
        ...AbstractGebouwen.PROPS
    });
    public readonly _props: HuurdersInvesteringenProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<HuurdersInvesteringen>(this, {
            _props: override
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const acceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction);
        const risicoAdresFetchContext = getFetchTreeOfRelation(this._fetchContext, AbstractGebouwen.PROPS.risicoAdres.id, AbstractGebouwen.PROPS.risicoAdres.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatievragen() {
                try {
                    return getRelatedPlural(entity, acceptatievragenFetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction, ObjectBP.PROPS.acceptatievragen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction, ObjectBP.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectType() {
                try {
                    return getFieldValue(metadataService, entity, ObjectBP.PROPS.objectType.id, ObjectBP.PROPS.objectType.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ObjectBP.PROPS.value.id, ObjectBP.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get risicoAdres() {
                try {
                    return getRelatedSingular(entity, risicoAdresFetchContext, AbstractGebouwen.PROPS.risicoAdres.id, AbstractGebouwen.PROPS.risicoAdres.direction, AbstractGebouwen.PROPS.risicoAdres.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get bouwjaar() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, AbstractGebouwen.PROPS.bouwjaar.id, AbstractGebouwen.PROPS.bouwjaar.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
}
export class VastgoedBeleggingen extends AbstractGebouwen {
    public static ID = "4942b372-176c-4862-b4c1-edcca945d4b8";
    public static PROPS = createProps({
        ...AbstractGebouwen.PROPS
    });
    public readonly _props: VastgoedBeleggingenProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<VastgoedBeleggingen>(this, {
            _props: override
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const acceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction);
        const risicoAdresFetchContext = getFetchTreeOfRelation(this._fetchContext, AbstractGebouwen.PROPS.risicoAdres.id, AbstractGebouwen.PROPS.risicoAdres.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatievragen() {
                try {
                    return getRelatedPlural(entity, acceptatievragenFetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction, ObjectBP.PROPS.acceptatievragen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction, ObjectBP.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectType() {
                try {
                    return getFieldValue(metadataService, entity, ObjectBP.PROPS.objectType.id, ObjectBP.PROPS.objectType.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ObjectBP.PROPS.value.id, ObjectBP.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get risicoAdres() {
                try {
                    return getRelatedSingular(entity, risicoAdresFetchContext, AbstractGebouwen.PROPS.risicoAdres.id, AbstractGebouwen.PROPS.risicoAdres.direction, AbstractGebouwen.PROPS.risicoAdres.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get bouwjaar() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, AbstractGebouwen.PROPS.bouwjaar.id, AbstractGebouwen.PROPS.bouwjaar.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
}
export abstract class AutomobielenMetBestuurder extends ObjectBP {
    public static ID = "7f431d8e-93e1-4cd1-aff4-2306f985aa50";
    public static PROPS = createProps({
        ...ObjectBP.PROPS,
        kenteken: {
            type: "Field",
            id: "dcffff6f-2954-4898-a33a-f80b9f4b3995",
            required: true
        },
        merk: {
            type: "Field",
            id: "700db39b-4795-42d5-91a2-96124e15ef62",
            required: true
        },
        typeNaam: {
            type: "Field",
            id: "402aba3a-1791-4b36-af36-ac602e8ac4ae",
            required: false
        },
        bouwjaar: {
            type: "Field",
            id: "bcc7e82c-5968-4fe8-8688-3ee8ddfc785e",
            required: false
        },
        dagwaarde: {
            type: "Field",
            id: "282b2c06-6ec0-4b73-8f8c-cceb7c00dbcd",
            required: false
        }
    });
    public readonly _props: AutomobielenMetBestuurderProps;
    constructor(protected metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<AutomobielenMetBestuurder>(this, {
            _props: override,
            kenteken: computed,
            merk: computed,
            typeNaam: computed,
            bouwjaar: computed,
            dagwaarde: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const acceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatievragen() {
                try {
                    return getRelatedPlural(entity, acceptatievragenFetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction, ObjectBP.PROPS.acceptatievragen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction, ObjectBP.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectType() {
                try {
                    return getFieldValue(metadataService, entity, ObjectBP.PROPS.objectType.id, ObjectBP.PROPS.objectType.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ObjectBP.PROPS.value.id, ObjectBP.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get kenteken() {
                try {
                    return getFieldValue(metadataService, entity, AutomobielenMetBestuurder.PROPS.kenteken.id, AutomobielenMetBestuurder.PROPS.kenteken.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get merk() {
                try {
                    return getFieldValue(metadataService, entity, AutomobielenMetBestuurder.PROPS.merk.id, AutomobielenMetBestuurder.PROPS.merk.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get typeNaam() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, AutomobielenMetBestuurder.PROPS.typeNaam.id, AutomobielenMetBestuurder.PROPS.typeNaam.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get bouwjaar() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, AutomobielenMetBestuurder.PROPS.bouwjaar.id, AutomobielenMetBestuurder.PROPS.bouwjaar.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get dagwaarde() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, AutomobielenMetBestuurder.PROPS.dagwaarde.id, AutomobielenMetBestuurder.PROPS.dagwaarde.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get kenteken(): string {
        return TextType.getRawText(this._props.kenteken)!;
    }
    get merk(): string {
        return TextType.getRawText(this._props.merk)!;
    }
    get typeNaam(): string | null {
        return this._props.typeNaam !== null ? TextType.getRawText(this._props.typeNaam)! : null;
    }
    get bouwjaar(): Decimal | null {
        return this._props.bouwjaar !== null ? NumberType.getRawNumber(this._props.bouwjaar)! : null;
    }
    get dagwaarde(): Decimal | null {
        return this._props.dagwaarde !== null ? CurrencyType.getRawAmount(this._props.dagwaarde)! : null;
    }
}
export class Auto extends AutomobielenMetBestuurder {
    public static ID = "7d79e994-e034-4a0e-87fd-26b7a630c36e";
    public static PROPS = createProps({
        ...AutomobielenMetBestuurder.PROPS
    });
    public readonly _props: AutoProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Auto>(this, {
            _props: override
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const acceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatievragen() {
                try {
                    return getRelatedPlural(entity, acceptatievragenFetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction, ObjectBP.PROPS.acceptatievragen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction, ObjectBP.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectType() {
                try {
                    return getFieldValue(metadataService, entity, ObjectBP.PROPS.objectType.id, ObjectBP.PROPS.objectType.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ObjectBP.PROPS.value.id, ObjectBP.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get kenteken() {
                try {
                    return getFieldValue(metadataService, entity, AutomobielenMetBestuurder.PROPS.kenteken.id, AutomobielenMetBestuurder.PROPS.kenteken.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get merk() {
                try {
                    return getFieldValue(metadataService, entity, AutomobielenMetBestuurder.PROPS.merk.id, AutomobielenMetBestuurder.PROPS.merk.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get typeNaam() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, AutomobielenMetBestuurder.PROPS.typeNaam.id, AutomobielenMetBestuurder.PROPS.typeNaam.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get bouwjaar() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, AutomobielenMetBestuurder.PROPS.bouwjaar.id, AutomobielenMetBestuurder.PROPS.bouwjaar.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get dagwaarde() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, AutomobielenMetBestuurder.PROPS.dagwaarde.id, AutomobielenMetBestuurder.PROPS.dagwaarde.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
}
export class Bromfiets extends AutomobielenMetBestuurder {
    public static ID = "1922f8ba-5ec1-4b85-9a6f-99296749f765";
    public static PROPS = createProps({
        ...AutomobielenMetBestuurder.PROPS
    });
    public readonly _props: BromfietsProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Bromfiets>(this, {
            _props: override
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const acceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatievragen() {
                try {
                    return getRelatedPlural(entity, acceptatievragenFetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction, ObjectBP.PROPS.acceptatievragen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction, ObjectBP.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectType() {
                try {
                    return getFieldValue(metadataService, entity, ObjectBP.PROPS.objectType.id, ObjectBP.PROPS.objectType.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ObjectBP.PROPS.value.id, ObjectBP.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get kenteken() {
                try {
                    return getFieldValue(metadataService, entity, AutomobielenMetBestuurder.PROPS.kenteken.id, AutomobielenMetBestuurder.PROPS.kenteken.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get merk() {
                try {
                    return getFieldValue(metadataService, entity, AutomobielenMetBestuurder.PROPS.merk.id, AutomobielenMetBestuurder.PROPS.merk.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get typeNaam() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, AutomobielenMetBestuurder.PROPS.typeNaam.id, AutomobielenMetBestuurder.PROPS.typeNaam.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get bouwjaar() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, AutomobielenMetBestuurder.PROPS.bouwjaar.id, AutomobielenMetBestuurder.PROPS.bouwjaar.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get dagwaarde() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, AutomobielenMetBestuurder.PROPS.dagwaarde.id, AutomobielenMetBestuurder.PROPS.dagwaarde.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
}
export class Bus extends AutomobielenMetBestuurder {
    public static ID = "8d5c1884-5fb5-4488-b4e1-56d2aadad497";
    public static PROPS = createProps({
        ...AutomobielenMetBestuurder.PROPS
    });
    public readonly _props: BusProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Bus>(this, {
            _props: override
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const acceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatievragen() {
                try {
                    return getRelatedPlural(entity, acceptatievragenFetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction, ObjectBP.PROPS.acceptatievragen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction, ObjectBP.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectType() {
                try {
                    return getFieldValue(metadataService, entity, ObjectBP.PROPS.objectType.id, ObjectBP.PROPS.objectType.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ObjectBP.PROPS.value.id, ObjectBP.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get kenteken() {
                try {
                    return getFieldValue(metadataService, entity, AutomobielenMetBestuurder.PROPS.kenteken.id, AutomobielenMetBestuurder.PROPS.kenteken.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get merk() {
                try {
                    return getFieldValue(metadataService, entity, AutomobielenMetBestuurder.PROPS.merk.id, AutomobielenMetBestuurder.PROPS.merk.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get typeNaam() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, AutomobielenMetBestuurder.PROPS.typeNaam.id, AutomobielenMetBestuurder.PROPS.typeNaam.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get bouwjaar() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, AutomobielenMetBestuurder.PROPS.bouwjaar.id, AutomobielenMetBestuurder.PROPS.bouwjaar.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get dagwaarde() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, AutomobielenMetBestuurder.PROPS.dagwaarde.id, AutomobielenMetBestuurder.PROPS.dagwaarde.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
}
export class Motor extends AutomobielenMetBestuurder {
    public static ID = "f287ae5c-698a-4042-b6a0-03449a74e3d8";
    public static PROPS = createProps({
        ...AutomobielenMetBestuurder.PROPS
    });
    public readonly _props: MotorProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Motor>(this, {
            _props: override
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const acceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatievragen() {
                try {
                    return getRelatedPlural(entity, acceptatievragenFetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction, ObjectBP.PROPS.acceptatievragen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction, ObjectBP.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectType() {
                try {
                    return getFieldValue(metadataService, entity, ObjectBP.PROPS.objectType.id, ObjectBP.PROPS.objectType.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ObjectBP.PROPS.value.id, ObjectBP.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get kenteken() {
                try {
                    return getFieldValue(metadataService, entity, AutomobielenMetBestuurder.PROPS.kenteken.id, AutomobielenMetBestuurder.PROPS.kenteken.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get merk() {
                try {
                    return getFieldValue(metadataService, entity, AutomobielenMetBestuurder.PROPS.merk.id, AutomobielenMetBestuurder.PROPS.merk.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get typeNaam() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, AutomobielenMetBestuurder.PROPS.typeNaam.id, AutomobielenMetBestuurder.PROPS.typeNaam.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get bouwjaar() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, AutomobielenMetBestuurder.PROPS.bouwjaar.id, AutomobielenMetBestuurder.PROPS.bouwjaar.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get dagwaarde() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, AutomobielenMetBestuurder.PROPS.dagwaarde.id, AutomobielenMetBestuurder.PROPS.dagwaarde.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
}
export class Tractor extends AutomobielenMetBestuurder {
    public static ID = "d8ebe159-e64a-406d-a804-248c4c8e5029";
    public static PROPS = createProps({
        ...AutomobielenMetBestuurder.PROPS
    });
    public readonly _props: TractorProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Tractor>(this, {
            _props: override
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const acceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatievragen() {
                try {
                    return getRelatedPlural(entity, acceptatievragenFetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction, ObjectBP.PROPS.acceptatievragen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction, ObjectBP.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectType() {
                try {
                    return getFieldValue(metadataService, entity, ObjectBP.PROPS.objectType.id, ObjectBP.PROPS.objectType.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ObjectBP.PROPS.value.id, ObjectBP.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get kenteken() {
                try {
                    return getFieldValue(metadataService, entity, AutomobielenMetBestuurder.PROPS.kenteken.id, AutomobielenMetBestuurder.PROPS.kenteken.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get merk() {
                try {
                    return getFieldValue(metadataService, entity, AutomobielenMetBestuurder.PROPS.merk.id, AutomobielenMetBestuurder.PROPS.merk.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get typeNaam() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, AutomobielenMetBestuurder.PROPS.typeNaam.id, AutomobielenMetBestuurder.PROPS.typeNaam.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get bouwjaar() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, AutomobielenMetBestuurder.PROPS.bouwjaar.id, AutomobielenMetBestuurder.PROPS.bouwjaar.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get dagwaarde() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, AutomobielenMetBestuurder.PROPS.dagwaarde.id, AutomobielenMetBestuurder.PROPS.dagwaarde.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
}
export class Vrachtwagen extends AutomobielenMetBestuurder {
    public static ID = "935667c7-f29f-4566-9e17-38e20d093daa";
    public static PROPS = createProps({
        ...AutomobielenMetBestuurder.PROPS
    });
    public readonly _props: VrachtwagenProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Vrachtwagen>(this, {
            _props: override
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const acceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatievragen() {
                try {
                    return getRelatedPlural(entity, acceptatievragenFetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction, ObjectBP.PROPS.acceptatievragen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction, ObjectBP.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectType() {
                try {
                    return getFieldValue(metadataService, entity, ObjectBP.PROPS.objectType.id, ObjectBP.PROPS.objectType.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ObjectBP.PROPS.value.id, ObjectBP.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get kenteken() {
                try {
                    return getFieldValue(metadataService, entity, AutomobielenMetBestuurder.PROPS.kenteken.id, AutomobielenMetBestuurder.PROPS.kenteken.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get merk() {
                try {
                    return getFieldValue(metadataService, entity, AutomobielenMetBestuurder.PROPS.merk.id, AutomobielenMetBestuurder.PROPS.merk.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get typeNaam() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, AutomobielenMetBestuurder.PROPS.typeNaam.id, AutomobielenMetBestuurder.PROPS.typeNaam.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get bouwjaar() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, AutomobielenMetBestuurder.PROPS.bouwjaar.id, AutomobielenMetBestuurder.PROPS.bouwjaar.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get dagwaarde() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, AutomobielenMetBestuurder.PROPS.dagwaarde.id, AutomobielenMetBestuurder.PROPS.dagwaarde.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
}
export class Bouwclaims extends ObjectBP {
    public static ID = "ea2e5eeb-cd7f-4591-b628-169ea9304fbb";
    public static PROPS = createProps({
        ...ObjectBP.PROPS,
        plaats: {
            type: "Field",
            id: "7bb631fe-7cee-4786-8f66-25beafdbe042",
            required: false
        },
        naam: {
            type: "Field",
            id: "11bcd415-a394-41dc-8e9d-38ffa1d3c297",
            required: false
        },
        perceelGrootte: {
            type: "Field",
            id: "03fd5931-d66c-486f-ba78-3f2d53a8c8f4",
            required: false
        }
    });
    public readonly _props: BouwclaimsProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Bouwclaims>(this, {
            _props: override,
            plaats: computed,
            naam: computed,
            perceelGrootte: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const acceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatievragen() {
                try {
                    return getRelatedPlural(entity, acceptatievragenFetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction, ObjectBP.PROPS.acceptatievragen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction, ObjectBP.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectType() {
                try {
                    return getFieldValue(metadataService, entity, ObjectBP.PROPS.objectType.id, ObjectBP.PROPS.objectType.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ObjectBP.PROPS.value.id, ObjectBP.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get plaats() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Bouwclaims.PROPS.plaats.id, Bouwclaims.PROPS.plaats.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get naam() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Bouwclaims.PROPS.naam.id, Bouwclaims.PROPS.naam.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get perceelGrootte() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Bouwclaims.PROPS.perceelGrootte.id, Bouwclaims.PROPS.perceelGrootte.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get plaats(): string | null {
        return this._props.plaats !== null ? TextType.getRawText(this._props.plaats)! : null;
    }
    get naam(): string | null {
        return this._props.naam !== null ? TextType.getRawText(this._props.naam)! : null;
    }
    get perceelGrootte(): Decimal | null {
        return this._props.perceelGrootte !== null ? NumberType.getRawNumber(this._props.perceelGrootte)! : null;
    }
}
export class Contracten extends ObjectBP {
    public static ID = "b84917dc-7611-42a5-9f75-cfd39fc3a745";
    public static PROPS = createProps({
        ...ObjectBP.PROPS,
        soortContract: {
            type: "Field",
            id: "27023d02-122b-415c-b062-c2fac7387bf6",
            required: false
        },
        naam: {
            type: "Field",
            id: "85beb152-d9fe-4516-bd15-b4ff369ccb77",
            required: false
        },
        partijen: {
            type: "Field",
            id: "129212aa-db91-496f-81c8-56cc4ed93ed1",
            required: false
        }
    });
    public readonly _props: ContractenProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Contracten>(this, {
            _props: override,
            soortContract: computed,
            naam: computed,
            partijen: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const acceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatievragen() {
                try {
                    return getRelatedPlural(entity, acceptatievragenFetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction, ObjectBP.PROPS.acceptatievragen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction, ObjectBP.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectType() {
                try {
                    return getFieldValue(metadataService, entity, ObjectBP.PROPS.objectType.id, ObjectBP.PROPS.objectType.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ObjectBP.PROPS.value.id, ObjectBP.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get soortContract() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Contracten.PROPS.soortContract.id, Contracten.PROPS.soortContract.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get naam() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Contracten.PROPS.naam.id, Contracten.PROPS.naam.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get partijen() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Contracten.PROPS.partijen.id, Contracten.PROPS.partijen.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get soortContract(): string | null {
        return this._props.soortContract !== null ? TextType.getRawText(this._props.soortContract)! : null;
    }
    get naam(): string | null {
        return this._props.naam !== null ? TextType.getRawText(this._props.naam)! : null;
    }
    get partijen(): string | null {
        return this._props.partijen !== null ? TextType.getRawText(this._props.partijen)! : null;
    }
}
export class Deelnemingen extends ObjectBP {
    public static ID = "84be3868-688d-4912-bd5b-4cf0f471b67f";
    public static PROPS = createProps({
        ...ObjectBP.PROPS,
        naam: {
            type: "Field",
            id: "d406f24a-96c3-47a1-8d3c-adfd76a27ce0",
            required: true
        },
        plaats: {
            type: "Field",
            id: "a9eb1996-f076-4b89-922d-9e51e9751419",
            required: false
        }
    });
    public readonly _props: DeelnemingenProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Deelnemingen>(this, {
            _props: override,
            naam: computed,
            plaats: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const acceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatievragen() {
                try {
                    return getRelatedPlural(entity, acceptatievragenFetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction, ObjectBP.PROPS.acceptatievragen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction, ObjectBP.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectType() {
                try {
                    return getFieldValue(metadataService, entity, ObjectBP.PROPS.objectType.id, ObjectBP.PROPS.objectType.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ObjectBP.PROPS.value.id, ObjectBP.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get naam() {
                try {
                    return getFieldValue(metadataService, entity, Deelnemingen.PROPS.naam.id, Deelnemingen.PROPS.naam.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get plaats() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Deelnemingen.PROPS.plaats.id, Deelnemingen.PROPS.plaats.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get naam(): string {
        return TextType.getRawText(this._props.naam)!;
    }
    get plaats(): string | null {
        return this._props.plaats !== null ? TextType.getRawText(this._props.plaats)! : null;
    }
}
export class DGA extends ObjectBP {
    public static ID = "81362de6-3898-4ec4-9a6b-9b44607acace";
    public static PROPS = createProps({
        ...ObjectBP.PROPS,
        persoon: {
            type: "RelationshipType",
            id: "79f9f36d-2311-4c27-86d8-61322c657c7d",
            direction: "child",
            multiplicity: "One",
            required: true
        }
    });
    public readonly _props: DGAProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<DGA>(this, {
            _props: override,
            persoon: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const acceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction);
        const persoonFetchContext = getFetchTreeOfRelation(this._fetchContext, DGA.PROPS.persoon.id, DGA.PROPS.persoon.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatievragen() {
                try {
                    return getRelatedPlural(entity, acceptatievragenFetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction, ObjectBP.PROPS.acceptatievragen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction, ObjectBP.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectType() {
                try {
                    return getFieldValue(metadataService, entity, ObjectBP.PROPS.objectType.id, ObjectBP.PROPS.objectType.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ObjectBP.PROPS.value.id, ObjectBP.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get persoon() {
                try {
                    return getRelatedSingular(entity, persoonFetchContext, DGA.PROPS.persoon.id, DGA.PROPS.persoon.direction, DGA.PROPS.persoon.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get persoon(): Person | undefined {
        const persoonFetchContext = getFetchTreeOfRelation(this._fetchContext, DGA.PROPS.persoon.id, DGA.PROPS.persoon.direction);
        if (persoonFetchContext !== undefined) {
            return toTypedEntity(this._props.persoon, this._metadataService, persoonFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class Distributiekanalen extends ObjectBP {
    public static ID = "3f85c2b3-c903-451f-8f92-32336a77fe76";
    public static PROPS = createProps({
        ...ObjectBP.PROPS
    });
    public readonly _props: DistributiekanalenProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Distributiekanalen>(this, {
            _props: override
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const acceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatievragen() {
                try {
                    return getRelatedPlural(entity, acceptatievragenFetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction, ObjectBP.PROPS.acceptatievragen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction, ObjectBP.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectType() {
                try {
                    return getFieldValue(metadataService, entity, ObjectBP.PROPS.objectType.id, ObjectBP.PROPS.objectType.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ObjectBP.PROPS.value.id, ObjectBP.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
}
export class Effecten extends ObjectBP {
    public static ID = "bbe9dce1-9a41-48f9-9c9f-b40204b64a09";
    public static PROPS = createProps({
        ...ObjectBP.PROPS
    });
    public readonly _props: EffectenProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Effecten>(this, {
            _props: override
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const acceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatievragen() {
                try {
                    return getRelatedPlural(entity, acceptatievragenFetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction, ObjectBP.PROPS.acceptatievragen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction, ObjectBP.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectType() {
                try {
                    return getFieldValue(metadataService, entity, ObjectBP.PROPS.objectType.id, ObjectBP.PROPS.objectType.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ObjectBP.PROPS.value.id, ObjectBP.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
}
export class Electronica extends ObjectBP {
    public static ID = "59983b78-1dcf-401b-b281-5984dd298c29";
    public static PROPS = createProps({
        ...ObjectBP.PROPS,
        soortApparatuur: {
            type: "RelationshipType",
            id: "b7b00e0f-130d-4f66-ab44-2d276a06f860",
            direction: "child",
            multiplicity: "One",
            required: true
        }
    });
    public readonly _props: ElectronicaProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Electronica>(this, {
            _props: override,
            soortApparatuur: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const acceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction);
        const soortApparatuurFetchContext = getFetchTreeOfRelation(this._fetchContext, Electronica.PROPS.soortApparatuur.id, Electronica.PROPS.soortApparatuur.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatievragen() {
                try {
                    return getRelatedPlural(entity, acceptatievragenFetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction, ObjectBP.PROPS.acceptatievragen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction, ObjectBP.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectType() {
                try {
                    return getFieldValue(metadataService, entity, ObjectBP.PROPS.objectType.id, ObjectBP.PROPS.objectType.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ObjectBP.PROPS.value.id, ObjectBP.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get soortApparatuur() {
                try {
                    return getRelatedSingular(entity, soortApparatuurFetchContext, Electronica.PROPS.soortApparatuur.id, Electronica.PROPS.soortApparatuur.direction, Electronica.PROPS.soortApparatuur.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get soortApparatuur(): SoortElectronischApparatuur | undefined {
        const soortApparatuurFetchContext = getFetchTreeOfRelation(this._fetchContext, Electronica.PROPS.soortApparatuur.id, Electronica.PROPS.soortApparatuur.direction);
        if (soortApparatuurFetchContext !== undefined) {
            return toTypedEntity(this._props.soortApparatuur, this._metadataService, soortApparatuurFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class Fiets extends ObjectBP {
    public static ID = "e6f88712-cb34-4448-b63e-20688b549820";
    public static PROPS = createProps({
        ...ObjectBP.PROPS,
        merk: {
            type: "Field",
            id: "74c8e1f7-c78b-4337-affd-f2d6bd4bb009",
            required: true
        },
        typeNaam: {
            type: "Field",
            id: "9b9efb9f-47a0-4d7b-aa92-c7a70ac1c224",
            required: false
        },
        soortFiets: {
            type: "RelationshipType",
            id: "2bcaca2f-6df9-4745-9d50-049d2c29adaa",
            direction: "child",
            multiplicity: "One",
            required: false
        }
    });
    public readonly _props: FietsProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Fiets>(this, {
            _props: override,
            merk: computed,
            typeNaam: computed,
            soortFiets: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const acceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction);
        const soortFietsFetchContext = getFetchTreeOfRelation(this._fetchContext, Fiets.PROPS.soortFiets.id, Fiets.PROPS.soortFiets.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatievragen() {
                try {
                    return getRelatedPlural(entity, acceptatievragenFetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction, ObjectBP.PROPS.acceptatievragen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction, ObjectBP.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectType() {
                try {
                    return getFieldValue(metadataService, entity, ObjectBP.PROPS.objectType.id, ObjectBP.PROPS.objectType.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ObjectBP.PROPS.value.id, ObjectBP.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get soortFiets() {
                try {
                    return getRelatedSingular(entity, soortFietsFetchContext, Fiets.PROPS.soortFiets.id, Fiets.PROPS.soortFiets.direction, Fiets.PROPS.soortFiets.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get merk() {
                try {
                    return getFieldValue(metadataService, entity, Fiets.PROPS.merk.id, Fiets.PROPS.merk.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get typeNaam() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Fiets.PROPS.typeNaam.id, Fiets.PROPS.typeNaam.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get merk(): string {
        return TextType.getRawText(this._props.merk)!;
    }
    get typeNaam(): string | null {
        return this._props.typeNaam !== null ? TextType.getRawText(this._props.typeNaam)! : null;
    }
    get soortFiets(): (SoortFiets | null) | undefined {
        const soortFietsFetchContext = getFetchTreeOfRelation(this._fetchContext, Fiets.PROPS.soortFiets.id, Fiets.PROPS.soortFiets.direction);
        if (soortFietsFetchContext !== undefined) {
            return toTypedEntity(this._props.soortFiets, this._metadataService, soortFietsFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class Groepsvermogen extends ObjectBP {
    public static ID = "ad940bfe-41ae-403b-bb01-1da8f8fd037c";
    public static PROPS = createProps({
        ...ObjectBP.PROPS
    });
    public readonly _props: GroepsvermogenProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Groepsvermogen>(this, {
            _props: override
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const acceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatievragen() {
                try {
                    return getRelatedPlural(entity, acceptatievragenFetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction, ObjectBP.PROPS.acceptatievragen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction, ObjectBP.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectType() {
                try {
                    return getFieldValue(metadataService, entity, ObjectBP.PROPS.objectType.id, ObjectBP.PROPS.objectType.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ObjectBP.PROPS.value.id, ObjectBP.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
}
export class Inventaris extends ObjectBP {
    public static ID = "636684e9-4c26-4b82-b0e4-ebf8cc637a14";
    public static PROPS = createProps({
        ...ObjectBP.PROPS,
        omschrijving: {
            type: "Field",
            id: "80b92831-2b25-46c6-8d2e-370ead697d44",
            required: true
        }
    });
    public readonly _props: InventarisProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Inventaris>(this, {
            _props: override,
            omschrijving: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const acceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatievragen() {
                try {
                    return getRelatedPlural(entity, acceptatievragenFetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction, ObjectBP.PROPS.acceptatievragen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction, ObjectBP.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectType() {
                try {
                    return getFieldValue(metadataService, entity, ObjectBP.PROPS.objectType.id, ObjectBP.PROPS.objectType.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ObjectBP.PROPS.value.id, ObjectBP.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get omschrijving() {
                try {
                    return getFieldValue(metadataService, entity, Inventaris.PROPS.omschrijving.id, Inventaris.PROPS.omschrijving.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get omschrijving(): string {
        return TextType.getRawText(this._props.omschrijving)!;
    }
}
export class Kasmiddelen extends ObjectBP {
    public static ID = "4cbcf3ff-0b65-4ca6-b9d2-a9c4982f109c";
    public static PROPS = createProps({
        ...ObjectBP.PROPS
    });
    public readonly _props: KasmiddelenProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Kasmiddelen>(this, {
            _props: override
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const acceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatievragen() {
                try {
                    return getRelatedPlural(entity, acceptatievragenFetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction, ObjectBP.PROPS.acceptatievragen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction, ObjectBP.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectType() {
                try {
                    return getFieldValue(metadataService, entity, ObjectBP.PROPS.objectType.id, ObjectBP.PROPS.objectType.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ObjectBP.PROPS.value.id, ObjectBP.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
}
export class Kernprocessen extends ObjectBP {
    public static ID = "3ae6561f-b119-40ab-9c78-92913d258466";
    public static PROPS = createProps({
        ...ObjectBP.PROPS,
        kernprocessenMasterdata: {
            type: "RelationshipType",
            id: "dbf200fc-b40d-4b20-a355-824bf48a957f",
            direction: "child",
            multiplicity: "One",
            required: true
        }
    });
    public readonly _props: KernprocessenProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Kernprocessen>(this, {
            _props: override,
            kernprocessenMasterdata: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const acceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction);
        const kernprocessenMasterdataFetchContext = getFetchTreeOfRelation(this._fetchContext, Kernprocessen.PROPS.kernprocessenMasterdata.id, Kernprocessen.PROPS.kernprocessenMasterdata.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatievragen() {
                try {
                    return getRelatedPlural(entity, acceptatievragenFetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction, ObjectBP.PROPS.acceptatievragen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction, ObjectBP.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectType() {
                try {
                    return getFieldValue(metadataService, entity, ObjectBP.PROPS.objectType.id, ObjectBP.PROPS.objectType.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ObjectBP.PROPS.value.id, ObjectBP.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get kernprocessenMasterdata() {
                try {
                    return getRelatedSingular(entity, kernprocessenMasterdataFetchContext, Kernprocessen.PROPS.kernprocessenMasterdata.id, Kernprocessen.PROPS.kernprocessenMasterdata.direction, Kernprocessen.PROPS.kernprocessenMasterdata.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get kernprocessenMasterdata(): KernprocessenMasterdata | undefined {
        const kernprocessenMasterdataFetchContext = getFetchTreeOfRelation(this._fetchContext, Kernprocessen.PROPS.kernprocessenMasterdata.id, Kernprocessen.PROPS.kernprocessenMasterdata.direction);
        if (kernprocessenMasterdataFetchContext !== undefined) {
            return toTypedEntity(this._props.kernprocessenMasterdata, this._metadataService, kernprocessenMasterdataFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class Klanten extends ObjectBP {
    public static ID = "df625609-c11f-4ea3-a94d-9804930f45ac";
    public static PROPS = createProps({
        ...ObjectBP.PROPS,
        afnemers: {
            type: "Field",
            id: "a51739b6-7d5d-45c1-b105-e1ded4cdaa9d",
            required: true
        }
    });
    public readonly _props: KlantenProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Klanten>(this, {
            _props: override,
            afnemers: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const acceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatievragen() {
                try {
                    return getRelatedPlural(entity, acceptatievragenFetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction, ObjectBP.PROPS.acceptatievragen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction, ObjectBP.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectType() {
                try {
                    return getFieldValue(metadataService, entity, ObjectBP.PROPS.objectType.id, ObjectBP.PROPS.objectType.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ObjectBP.PROPS.value.id, ObjectBP.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get afnemers() {
                try {
                    return getFieldValue(metadataService, entity, Klanten.PROPS.afnemers.id, Klanten.PROPS.afnemers.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get afnemers(): string {
        return TextType.getRawText(this._props.afnemers)!;
    }
}
export class Kredieten extends ObjectBP {
    public static ID = "9d61354a-4f03-4b9f-bc86-3154a561a5ae";
    public static PROPS = createProps({
        ...ObjectBP.PROPS,
        aanbieder: {
            type: "Field",
            id: "2524b4c6-a95b-48c7-8b04-559e1382ecef",
            required: false
        },
        hoofdSom: {
            type: "Field",
            id: "93814862-1d84-43ad-b821-640131080102",
            required: false
        },
        rentePercentage: {
            type: "Field",
            id: "e76150e2-474e-405d-bbd6-4cc18363140b",
            required: false
        }
    });
    public readonly _props: KredietenProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Kredieten>(this, {
            _props: override,
            aanbieder: computed,
            hoofdSom: computed,
            rentePercentage: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const acceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatievragen() {
                try {
                    return getRelatedPlural(entity, acceptatievragenFetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction, ObjectBP.PROPS.acceptatievragen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction, ObjectBP.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectType() {
                try {
                    return getFieldValue(metadataService, entity, ObjectBP.PROPS.objectType.id, ObjectBP.PROPS.objectType.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ObjectBP.PROPS.value.id, ObjectBP.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get aanbieder() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Kredieten.PROPS.aanbieder.id, Kredieten.PROPS.aanbieder.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get hoofdSom() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Kredieten.PROPS.hoofdSom.id, Kredieten.PROPS.hoofdSom.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get rentePercentage() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Kredieten.PROPS.rentePercentage.id, Kredieten.PROPS.rentePercentage.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get aanbieder(): string | null {
        return this._props.aanbieder !== null ? TextType.getRawText(this._props.aanbieder)! : null;
    }
    get hoofdSom(): string | null {
        return this._props.hoofdSom !== null ? TextType.getRawText(this._props.hoofdSom)! : null;
    }
    get rentePercentage(): Decimal | null {
        return this._props.rentePercentage !== null ? PercentageType.getRawPercentage(this._props.rentePercentage)! : null;
    }
}
export class Leveranciers extends ObjectBP {
    public static ID = "c852a9d5-b5be-46fb-8ab5-18b98017f502";
    public static PROPS = createProps({
        ...ObjectBP.PROPS,
        welkeLeveranciers: {
            type: "Field",
            id: "22d49122-45a3-49d0-abbf-a02ee14a71d5",
            required: true
        }
    });
    public readonly _props: LeveranciersProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Leveranciers>(this, {
            _props: override,
            welkeLeveranciers: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const acceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatievragen() {
                try {
                    return getRelatedPlural(entity, acceptatievragenFetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction, ObjectBP.PROPS.acceptatievragen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction, ObjectBP.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectType() {
                try {
                    return getFieldValue(metadataService, entity, ObjectBP.PROPS.objectType.id, ObjectBP.PROPS.objectType.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ObjectBP.PROPS.value.id, ObjectBP.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get welkeLeveranciers() {
                try {
                    return getFieldValue(metadataService, entity, Leveranciers.PROPS.welkeLeveranciers.id, Leveranciers.PROPS.welkeLeveranciers.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get welkeLeveranciers(): string {
        return TextType.getRawText(this._props.welkeLeveranciers)!;
    }
}
export class Management extends ObjectBP {
    public static ID = "80d7a5f4-7bbe-4a10-ada9-90afc4ff2c09";
    public static PROPS = createProps({
        ...ObjectBP.PROPS,
        bestuurder: {
            type: "RelationshipType",
            id: "c4ad9dfe-633d-4577-9f05-91631e299a4d",
            direction: "child",
            multiplicity: "One",
            required: true
        }
    });
    public readonly _props: ManagementProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Management>(this, {
            _props: override,
            bestuurder: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const acceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction);
        const bestuurderFetchContext = getFetchTreeOfRelation(this._fetchContext, Management.PROPS.bestuurder.id, Management.PROPS.bestuurder.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatievragen() {
                try {
                    return getRelatedPlural(entity, acceptatievragenFetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction, ObjectBP.PROPS.acceptatievragen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction, ObjectBP.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectType() {
                try {
                    return getFieldValue(metadataService, entity, ObjectBP.PROPS.objectType.id, ObjectBP.PROPS.objectType.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ObjectBP.PROPS.value.id, ObjectBP.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get bestuurder() {
                try {
                    return getRelatedSingular(entity, bestuurderFetchContext, Management.PROPS.bestuurder.id, Management.PROPS.bestuurder.direction, Management.PROPS.bestuurder.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get bestuurder(): Person | undefined {
        const bestuurderFetchContext = getFetchTreeOfRelation(this._fetchContext, Management.PROPS.bestuurder.id, Management.PROPS.bestuurder.direction);
        if (bestuurderFetchContext !== undefined) {
            return toTypedEntity(this._props.bestuurder, this._metadataService, bestuurderFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class OndersteunendeProcessen extends ObjectBP {
    public static ID = "278b3599-eb7e-414d-9dd3-bf1ddc2d157d";
    public static PROPS = createProps({
        ...ObjectBP.PROPS,
        ondersteunendeProcessenMasterdata: {
            type: "RelationshipType",
            id: "1bf2b0ae-581b-4abe-9c6c-9a09ce1fbd0a",
            direction: "child",
            multiplicity: "One",
            required: true
        }
    });
    public readonly _props: OndersteunendeProcessenProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<OndersteunendeProcessen>(this, {
            _props: override,
            ondersteunendeProcessenMasterdata: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const acceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction);
        const ondersteunendeProcessenMasterdataFetchContext = getFetchTreeOfRelation(this._fetchContext, OndersteunendeProcessen.PROPS.ondersteunendeProcessenMasterdata.id, OndersteunendeProcessen.PROPS.ondersteunendeProcessenMasterdata.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatievragen() {
                try {
                    return getRelatedPlural(entity, acceptatievragenFetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction, ObjectBP.PROPS.acceptatievragen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction, ObjectBP.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectType() {
                try {
                    return getFieldValue(metadataService, entity, ObjectBP.PROPS.objectType.id, ObjectBP.PROPS.objectType.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ObjectBP.PROPS.value.id, ObjectBP.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get ondersteunendeProcessenMasterdata() {
                try {
                    return getRelatedSingular(entity, ondersteunendeProcessenMasterdataFetchContext, OndersteunendeProcessen.PROPS.ondersteunendeProcessenMasterdata.id, OndersteunendeProcessen.PROPS.ondersteunendeProcessenMasterdata.direction, OndersteunendeProcessen.PROPS.ondersteunendeProcessenMasterdata.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get ondersteunendeProcessenMasterdata(): OndersteunendeProcessenMasterdata | undefined {
        const ondersteunendeProcessenMasterdataFetchContext = getFetchTreeOfRelation(this._fetchContext, OndersteunendeProcessen.PROPS.ondersteunendeProcessenMasterdata.id, OndersteunendeProcessen.PROPS.ondersteunendeProcessenMasterdata.direction);
        if (ondersteunendeProcessenMasterdataFetchContext !== undefined) {
            return toTypedEntity(this._props.ondersteunendeProcessenMasterdata, this._metadataService, ondersteunendeProcessenMasterdataFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class Perceel extends ObjectBP {
    public static ID = "203a2a3a-1592-405b-b2b5-7329e5606695";
    public static PROPS = createProps({
        ...ObjectBP.PROPS,
        naam: {
            type: "Field",
            id: "1ccde838-6a57-4c96-a694-38ec0c10c89a",
            required: false
        },
        plaats: {
            type: "Field",
            id: "d0c348fa-ef05-400a-bc42-8344eb380646",
            required: true
        },
        perceelGrootte: {
            type: "Field",
            id: "31e9c3d4-74b2-4dd1-9804-833a65af024a",
            required: true
        },
        postcode: {
            type: "Field",
            id: "3a614fe6-c947-455c-8729-493895b36f1d",
            required: true
        }
    });
    public readonly _props: PerceelProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Perceel>(this, {
            _props: override,
            naam: computed,
            plaats: computed,
            perceelGrootte: computed,
            postcode: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const acceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatievragen() {
                try {
                    return getRelatedPlural(entity, acceptatievragenFetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction, ObjectBP.PROPS.acceptatievragen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction, ObjectBP.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectType() {
                try {
                    return getFieldValue(metadataService, entity, ObjectBP.PROPS.objectType.id, ObjectBP.PROPS.objectType.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ObjectBP.PROPS.value.id, ObjectBP.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get naam() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Perceel.PROPS.naam.id, Perceel.PROPS.naam.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get plaats() {
                try {
                    return getFieldValue(metadataService, entity, Perceel.PROPS.plaats.id, Perceel.PROPS.plaats.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get perceelGrootte() {
                try {
                    return getFieldValue(metadataService, entity, Perceel.PROPS.perceelGrootte.id, Perceel.PROPS.perceelGrootte.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get postcode() {
                try {
                    return getFieldValue(metadataService, entity, Perceel.PROPS.postcode.id, Perceel.PROPS.postcode.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get naam(): string | null {
        return this._props.naam !== null ? TextType.getRawText(this._props.naam)! : null;
    }
    get plaats(): string {
        return TextType.getRawText(this._props.plaats)!;
    }
    get perceelGrootte(): string {
        return TextType.getRawText(this._props.perceelGrootte)!;
    }
    get postcode(): string {
        return TextType.getRawText(this._props.postcode)!;
    }
}
export class Personeel extends ObjectBP {
    public static ID = "0e18e7fd-3d21-4d35-b4e4-804d7d367051";
    public static PROPS = createProps({
        ...ObjectBP.PROPS,
        werkzamePersonen: {
            type: "Field",
            id: "668460b9-973d-4fc0-bc32-691eabc34cf1",
            required: true
        },
        aantalOperoepkrachten: {
            type: "Field",
            id: "b0331c6f-3147-49ca-a3a3-6c03bb0960b9",
            required: false
        }
    });
    public readonly _props: PersoneelProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Personeel>(this, {
            _props: override,
            werkzamePersonen: computed,
            aantalOperoepkrachten: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const acceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatievragen() {
                try {
                    return getRelatedPlural(entity, acceptatievragenFetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction, ObjectBP.PROPS.acceptatievragen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction, ObjectBP.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectType() {
                try {
                    return getFieldValue(metadataService, entity, ObjectBP.PROPS.objectType.id, ObjectBP.PROPS.objectType.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ObjectBP.PROPS.value.id, ObjectBP.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get werkzamePersonen() {
                try {
                    return getFieldValue(metadataService, entity, Personeel.PROPS.werkzamePersonen.id, Personeel.PROPS.werkzamePersonen.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get aantalOperoepkrachten() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Personeel.PROPS.aantalOperoepkrachten.id, Personeel.PROPS.aantalOperoepkrachten.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get werkzamePersonen(): Decimal {
        return NumberType.getRawNumber(this._props.werkzamePersonen)!;
    }
    get aantalOperoepkrachten(): Decimal | null {
        return this._props.aantalOperoepkrachten !== null ? NumberType.getRawNumber(this._props.aantalOperoepkrachten)! : null;
    }
}
export class Propositie extends ObjectBP {
    public static ID = "08c59ad0-8097-46cd-825e-ad4e75aec0be";
    public static PROPS = createProps({
        ...ObjectBP.PROPS,
        omschrijving: {
            type: "Field",
            id: "7a6f9277-a224-45b5-b54b-e260737bbae4",
            required: false
        }
    });
    public readonly _props: PropositieProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Propositie>(this, {
            _props: override,
            omschrijving: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const acceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatievragen() {
                try {
                    return getRelatedPlural(entity, acceptatievragenFetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction, ObjectBP.PROPS.acceptatievragen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction, ObjectBP.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectType() {
                try {
                    return getFieldValue(metadataService, entity, ObjectBP.PROPS.objectType.id, ObjectBP.PROPS.objectType.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ObjectBP.PROPS.value.id, ObjectBP.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get omschrijving() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Propositie.PROPS.omschrijving.id, Propositie.PROPS.omschrijving.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get omschrijving(): string | null {
        return this._props.omschrijving !== null ? TextType.getRawText(this._props.omschrijving)! : null;
    }
}
export class Relatiebeheer extends ObjectBP {
    public static ID = "93411090-0a0c-40a2-91e0-d52339dbf2fb";
    public static PROPS = createProps({
        ...ObjectBP.PROPS,
        onderhoud: {
            type: "Field",
            id: "a0ce729a-9e1b-4ad3-9c51-3d9efa6830ff",
            required: true
        }
    });
    public readonly _props: RelatiebeheerProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Relatiebeheer>(this, {
            _props: override,
            onderhoud: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const acceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatievragen() {
                try {
                    return getRelatedPlural(entity, acceptatievragenFetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction, ObjectBP.PROPS.acceptatievragen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction, ObjectBP.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectType() {
                try {
                    return getFieldValue(metadataService, entity, ObjectBP.PROPS.objectType.id, ObjectBP.PROPS.objectType.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ObjectBP.PROPS.value.id, ObjectBP.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get onderhoud() {
                try {
                    return getFieldValue(metadataService, entity, Relatiebeheer.PROPS.onderhoud.id, Relatiebeheer.PROPS.onderhoud.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get onderhoud(): string {
        return TextType.getRawText(this._props.onderhoud)!;
    }
}
export class Schepen extends ObjectBP {
    public static ID = "181066ee-36c5-489d-9812-49ecca1a3b5d";
    public static PROPS = createProps({
        ...ObjectBP.PROPS,
        naamBoot: {
            type: "Field",
            id: "38c049eb-6306-41db-a781-41f1f157ebb6",
            required: true
        },
        soortBoot: {
            type: "Field",
            id: "ffc4910c-4d12-44b6-ab39-2efff30f4aeb",
            required: true
        },
        merk: {
            type: "Field",
            id: "61414720-965f-4964-8522-e99c25ebe628",
            required: true
        },
        typeNaam: {
            type: "Field",
            id: "2bc05de9-009f-4e0b-a4a0-f5a301642e85",
            required: false
        },
        bouwjaar: {
            type: "Field",
            id: "95ca4e87-6416-4a8f-8150-04172c2dc6ba",
            required: false
        }
    });
    public readonly _props: SchepenProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Schepen>(this, {
            _props: override,
            naamBoot: computed,
            soortBoot: computed,
            merk: computed,
            typeNaam: computed,
            bouwjaar: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const acceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatievragen() {
                try {
                    return getRelatedPlural(entity, acceptatievragenFetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction, ObjectBP.PROPS.acceptatievragen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction, ObjectBP.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectType() {
                try {
                    return getFieldValue(metadataService, entity, ObjectBP.PROPS.objectType.id, ObjectBP.PROPS.objectType.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ObjectBP.PROPS.value.id, ObjectBP.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get naamBoot() {
                try {
                    return getFieldValue(metadataService, entity, Schepen.PROPS.naamBoot.id, Schepen.PROPS.naamBoot.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get soortBoot() {
                try {
                    return getFieldValue(metadataService, entity, Schepen.PROPS.soortBoot.id, Schepen.PROPS.soortBoot.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get merk() {
                try {
                    return getFieldValue(metadataService, entity, Schepen.PROPS.merk.id, Schepen.PROPS.merk.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get typeNaam() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Schepen.PROPS.typeNaam.id, Schepen.PROPS.typeNaam.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get bouwjaar() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Schepen.PROPS.bouwjaar.id, Schepen.PROPS.bouwjaar.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get naamBoot(): string {
        return TextType.getRawText(this._props.naamBoot)!;
    }
    get soortBoot(): string {
        return TextType.getRawText(this._props.soortBoot)!;
    }
    get merk(): string {
        return TextType.getRawText(this._props.merk)!;
    }
    get typeNaam(): string | null {
        return this._props.typeNaam !== null ? TextType.getRawText(this._props.typeNaam)! : null;
    }
    get bouwjaar(): Decimal | null {
        return this._props.bouwjaar !== null ? NumberType.getRawNumber(this._props.bouwjaar)! : null;
    }
}
export class TegoedenOpBankgirorekeningen extends ObjectBP {
    public static ID = "ac33b4e9-7e3c-42c4-af6f-77cbb83867bf";
    public static PROPS = createProps({
        ...ObjectBP.PROPS,
        naamBank: {
            type: "Field",
            id: "e22a9c1d-c6dd-4f96-888a-5e3f0b84764e",
            required: false
        }
    });
    public readonly _props: TegoedenOpBankgirorekeningenProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<TegoedenOpBankgirorekeningen>(this, {
            _props: override,
            naamBank: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const acceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatievragen() {
                try {
                    return getRelatedPlural(entity, acceptatievragenFetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction, ObjectBP.PROPS.acceptatievragen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction, ObjectBP.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectType() {
                try {
                    return getFieldValue(metadataService, entity, ObjectBP.PROPS.objectType.id, ObjectBP.PROPS.objectType.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ObjectBP.PROPS.value.id, ObjectBP.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get naamBank() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, TegoedenOpBankgirorekeningen.PROPS.naamBank.id, TegoedenOpBankgirorekeningen.PROPS.naamBank.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get naamBank(): string | null {
        return this._props.naamBank !== null ? TextType.getRawText(this._props.naamBank)! : null;
    }
}
export class Vliegtuigen extends ObjectBP {
    public static ID = "975a7e23-ca18-4dff-8424-e6fdbe25ecad";
    public static PROPS = createProps({
        ...ObjectBP.PROPS,
        merk: {
            type: "Field",
            id: "5f4b3b09-84b1-49b7-894d-581446d5a05f",
            required: true
        },
        typeNaam: {
            type: "Field",
            id: "f3f9304d-247f-42ba-a82d-6c604dc0bd87",
            required: false
        },
        bouwjaar: {
            type: "Field",
            id: "f091c894-a046-461b-8f65-e8f0b8d44ce6",
            required: false
        },
        dagwaarde: {
            type: "Field",
            id: "f587d15a-8c59-431c-ad06-ef5471d069d7",
            required: false
        },
        gebruik: {
            type: "Field",
            id: "6c060d3b-3cac-4bf0-99e0-1af5ee0ea137",
            required: true
        }
    });
    public readonly _props: VliegtuigenProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Vliegtuigen>(this, {
            _props: override,
            merk: computed,
            typeNaam: computed,
            bouwjaar: computed,
            dagwaarde: computed,
            gebruik: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const acceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatievragen() {
                try {
                    return getRelatedPlural(entity, acceptatievragenFetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction, ObjectBP.PROPS.acceptatievragen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction, ObjectBP.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectType() {
                try {
                    return getFieldValue(metadataService, entity, ObjectBP.PROPS.objectType.id, ObjectBP.PROPS.objectType.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ObjectBP.PROPS.value.id, ObjectBP.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get merk() {
                try {
                    return getFieldValue(metadataService, entity, Vliegtuigen.PROPS.merk.id, Vliegtuigen.PROPS.merk.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get typeNaam() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Vliegtuigen.PROPS.typeNaam.id, Vliegtuigen.PROPS.typeNaam.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get bouwjaar() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Vliegtuigen.PROPS.bouwjaar.id, Vliegtuigen.PROPS.bouwjaar.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get dagwaarde() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Vliegtuigen.PROPS.dagwaarde.id, Vliegtuigen.PROPS.dagwaarde.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get gebruik() {
                try {
                    return getFieldValue(metadataService, entity, Vliegtuigen.PROPS.gebruik.id, Vliegtuigen.PROPS.gebruik.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get merk(): string {
        return TextType.getRawText(this._props.merk)!;
    }
    get typeNaam(): string | null {
        return this._props.typeNaam !== null ? TextType.getRawText(this._props.typeNaam)! : null;
    }
    get bouwjaar(): Decimal | null {
        return this._props.bouwjaar !== null ? NumberType.getRawNumber(this._props.bouwjaar)! : null;
    }
    get dagwaarde(): Decimal | null {
        return this._props.dagwaarde !== null ? CurrencyType.getRawAmount(this._props.dagwaarde)! : null;
    }
    get gebruik(): string {
        return TextType.getRawText(this._props.gebruik)!;
    }
}
export class VoorraadEmballage extends ObjectBP {
    public static ID = "71435d78-16ec-44cd-b932-2f27a7d3ae3b";
    public static PROPS = createProps({
        ...ObjectBP.PROPS,
        soortEmbalage: {
            type: "Field",
            id: "06229734-1939-4c7a-8449-61b12155fd62",
            required: false
        },
        aantalEmballage: {
            type: "Field",
            id: "a24786da-c01e-47a2-8eaf-7a768a6749f4",
            required: false
        }
    });
    public readonly _props: VoorraadEmballageProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<VoorraadEmballage>(this, {
            _props: override,
            soortEmbalage: computed,
            aantalEmballage: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const acceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatievragen() {
                try {
                    return getRelatedPlural(entity, acceptatievragenFetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction, ObjectBP.PROPS.acceptatievragen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction, ObjectBP.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectType() {
                try {
                    return getFieldValue(metadataService, entity, ObjectBP.PROPS.objectType.id, ObjectBP.PROPS.objectType.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ObjectBP.PROPS.value.id, ObjectBP.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get soortEmbalage() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, VoorraadEmballage.PROPS.soortEmbalage.id, VoorraadEmballage.PROPS.soortEmbalage.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get aantalEmballage() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, VoorraadEmballage.PROPS.aantalEmballage.id, VoorraadEmballage.PROPS.aantalEmballage.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get soortEmbalage(): string | null {
        return this._props.soortEmbalage !== null ? TextType.getRawText(this._props.soortEmbalage)! : null;
    }
    get aantalEmballage(): Decimal | null {
        return this._props.aantalEmballage !== null ? NumberType.getRawNumber(this._props.aantalEmballage)! : null;
    }
}
export class VoorraadLevendeHave extends ObjectBP {
    public static ID = "61b6c79b-ab81-45d4-b108-0b5238200194";
    public static PROPS = createProps({
        ...ObjectBP.PROPS,
        aantalLevendeHave: {
            type: "Field",
            id: "95246651-ff4e-407b-86ad-fb1fef0f353b",
            required: false
        },
        levendeHave: {
            type: "RelationshipType",
            id: "9e29f9d4-a348-4b82-8e92-a5c108cd0efe",
            direction: "child",
            multiplicity: "Many",
            required: true
        }
    });
    public readonly _props: VoorraadLevendeHaveProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<VoorraadLevendeHave>(this, {
            _props: override,
            aantalLevendeHave: computed,
            levendeHave: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const acceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction);
        const levendeHaveFetchContext = getFetchTreeOfRelation(this._fetchContext, VoorraadLevendeHave.PROPS.levendeHave.id, VoorraadLevendeHave.PROPS.levendeHave.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatievragen() {
                try {
                    return getRelatedPlural(entity, acceptatievragenFetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction, ObjectBP.PROPS.acceptatievragen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction, ObjectBP.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectType() {
                try {
                    return getFieldValue(metadataService, entity, ObjectBP.PROPS.objectType.id, ObjectBP.PROPS.objectType.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ObjectBP.PROPS.value.id, ObjectBP.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get levendeHave() {
                try {
                    return getRelatedPlural(entity, levendeHaveFetchContext, VoorraadLevendeHave.PROPS.levendeHave.id, VoorraadLevendeHave.PROPS.levendeHave.direction, VoorraadLevendeHave.PROPS.levendeHave.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get aantalLevendeHave() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, VoorraadLevendeHave.PROPS.aantalLevendeHave.id, VoorraadLevendeHave.PROPS.aantalLevendeHave.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get aantalLevendeHave(): Decimal | null {
        return this._props.aantalLevendeHave !== null ? NumberType.getRawNumber(this._props.aantalLevendeHave)! : null;
    }
    get levendeHave(): SoortenLevendeHave[] | undefined {
        const levendeHaveFetchContext = getFetchTreeOfRelation(this._fetchContext, VoorraadLevendeHave.PROPS.levendeHave.id, VoorraadLevendeHave.PROPS.levendeHave.direction);
        if (levendeHaveFetchContext !== undefined) {
            return toTypedEntity(this._props.levendeHave, this._metadataService, levendeHaveFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class VoorraadMeerjarenPlantopstand extends ObjectBP {
    public static ID = "34c25ef8-1830-4273-85d7-a32330beaab8";
    public static PROPS = createProps({
        ...ObjectBP.PROPS,
        perceelGrootte: {
            type: "Field",
            id: "a6d48ca3-a99e-4e3d-aa31-c42709e80563",
            required: false
        },
        oogstproducten: {
            type: "RelationshipType",
            id: "6e7bc55a-4858-4158-a50d-4137bbfef1de",
            direction: "child",
            multiplicity: "Many",
            required: true
        }
    });
    public readonly _props: VoorraadMeerjarenPlantopstandProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<VoorraadMeerjarenPlantopstand>(this, {
            _props: override,
            perceelGrootte: computed,
            oogstproducten: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const acceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction);
        const oogstproductenFetchContext = getFetchTreeOfRelation(this._fetchContext, VoorraadMeerjarenPlantopstand.PROPS.oogstproducten.id, VoorraadMeerjarenPlantopstand.PROPS.oogstproducten.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatievragen() {
                try {
                    return getRelatedPlural(entity, acceptatievragenFetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction, ObjectBP.PROPS.acceptatievragen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction, ObjectBP.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectType() {
                try {
                    return getFieldValue(metadataService, entity, ObjectBP.PROPS.objectType.id, ObjectBP.PROPS.objectType.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ObjectBP.PROPS.value.id, ObjectBP.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get oogstproducten() {
                try {
                    return getRelatedPlural(entity, oogstproductenFetchContext, VoorraadMeerjarenPlantopstand.PROPS.oogstproducten.id, VoorraadMeerjarenPlantopstand.PROPS.oogstproducten.direction, VoorraadMeerjarenPlantopstand.PROPS.oogstproducten.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get perceelGrootte() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, VoorraadMeerjarenPlantopstand.PROPS.perceelGrootte.id, VoorraadMeerjarenPlantopstand.PROPS.perceelGrootte.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get perceelGrootte(): Decimal | null {
        return this._props.perceelGrootte !== null ? NumberType.getRawNumber(this._props.perceelGrootte)! : null;
    }
    get oogstproducten(): SoortenOogstproducten[] | undefined {
        const oogstproductenFetchContext = getFetchTreeOfRelation(this._fetchContext, VoorraadMeerjarenPlantopstand.PROPS.oogstproducten.id, VoorraadMeerjarenPlantopstand.PROPS.oogstproducten.direction);
        if (oogstproductenFetchContext !== undefined) {
            return toTypedEntity(this._props.oogstproducten, this._metadataService, oogstproductenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class VoorraadVastgoed extends ObjectBP {
    public static ID = "cb95f32a-b275-410e-9b6e-d7e9e2fb1bb8";
    public static PROPS = createProps({
        ...ObjectBP.PROPS,
        soortVastgoedbelegging: {
            type: "Field",
            id: "b0527b4e-7252-4195-bb22-8df36fcd6008",
            required: false
        },
        aantalVastgoedobjecten: {
            type: "Field",
            id: "0e48caf3-abc9-4924-9fd1-7216c73eafab",
            required: false
        }
    });
    public readonly _props: VoorraadVastgoedProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<VoorraadVastgoed>(this, {
            _props: override,
            soortVastgoedbelegging: computed,
            aantalVastgoedobjecten: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const acceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatievragen() {
                try {
                    return getRelatedPlural(entity, acceptatievragenFetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction, ObjectBP.PROPS.acceptatievragen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction, ObjectBP.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectType() {
                try {
                    return getFieldValue(metadataService, entity, ObjectBP.PROPS.objectType.id, ObjectBP.PROPS.objectType.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ObjectBP.PROPS.value.id, ObjectBP.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get soortVastgoedbelegging() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, VoorraadVastgoed.PROPS.soortVastgoedbelegging.id, VoorraadVastgoed.PROPS.soortVastgoedbelegging.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get aantalVastgoedobjecten() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, VoorraadVastgoed.PROPS.aantalVastgoedobjecten.id, VoorraadVastgoed.PROPS.aantalVastgoedobjecten.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get soortVastgoedbelegging(): string | null {
        return this._props.soortVastgoedbelegging !== null ? TextType.getRawText(this._props.soortVastgoedbelegging)! : null;
    }
    get aantalVastgoedobjecten(): Decimal | null {
        return this._props.aantalVastgoedobjecten !== null ? NumberType.getRawNumber(this._props.aantalVastgoedobjecten)! : null;
    }
}
export abstract class Voorraden extends ObjectBP {
    public static ID = "6338877d-909b-45d7-bcd9-d8f9a08f6eef";
    public static PROPS = createProps({
        ...ObjectBP.PROPS,
        goederen: {
            type: "RelationshipType",
            id: "bb063e00-2e12-4b1b-8927-ed906ed725b8",
            direction: "child",
            multiplicity: "Many",
            required: true
        }
    });
    public readonly _props: VoorradenProps;
    constructor(protected metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Voorraden>(this, {
            _props: override,
            goederen: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const acceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction);
        const goederenFetchContext = getFetchTreeOfRelation(this._fetchContext, Voorraden.PROPS.goederen.id, Voorraden.PROPS.goederen.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatievragen() {
                try {
                    return getRelatedPlural(entity, acceptatievragenFetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction, ObjectBP.PROPS.acceptatievragen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction, ObjectBP.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectType() {
                try {
                    return getFieldValue(metadataService, entity, ObjectBP.PROPS.objectType.id, ObjectBP.PROPS.objectType.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ObjectBP.PROPS.value.id, ObjectBP.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get goederen() {
                try {
                    return getRelatedPlural(entity, goederenFetchContext, Voorraden.PROPS.goederen.id, Voorraden.PROPS.goederen.direction, Voorraden.PROPS.goederen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get goederen(): Goederen[] | undefined {
        const goederenFetchContext = getFetchTreeOfRelation(this._fetchContext, Voorraden.PROPS.goederen.id, Voorraden.PROPS.goederen.direction);
        if (goederenFetchContext !== undefined) {
            return toTypedEntity(this._props.goederen, this._metadataService, goederenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class Werkmaterieel extends ObjectBP {
    public static ID = "355dd1b8-627d-4952-859b-f7eedc0801e5";
    public static PROPS = createProps({
        ...ObjectBP.PROPS,
        merk: {
            type: "Field",
            id: "72cd1b7e-7a82-4cea-b4d4-422437115474",
            required: false
        },
        bouwjaar: {
            type: "Field",
            id: "d381183d-ebd9-46ee-94d5-140e2a9325ba",
            required: false
        },
        gebruik: {
            type: "RelationshipType",
            id: "ddb42641-4a49-48b3-8893-7516045a5797",
            direction: "child",
            multiplicity: "Many",
            required: true
        }
    });
    public readonly _props: WerkmaterieelProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Werkmaterieel>(this, {
            _props: override,
            merk: computed,
            bouwjaar: computed,
            gebruik: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const acceptatievragenFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction);
        const organizationFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction);
        const gebruikFetchContext = getFetchTreeOfRelation(this._fetchContext, Werkmaterieel.PROPS.gebruik.id, Werkmaterieel.PROPS.gebruik.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get acceptatievragen() {
                try {
                    return getRelatedPlural(entity, acceptatievragenFetchContext, ObjectBP.PROPS.acceptatievragen.id, ObjectBP.PROPS.acceptatievragen.direction, ObjectBP.PROPS.acceptatievragen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organization() {
                try {
                    return getRelatedSingular(entity, organizationFetchContext, ObjectBP.PROPS.organization.id, ObjectBP.PROPS.organization.direction, ObjectBP.PROPS.organization.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectType() {
                try {
                    return getFieldValue(metadataService, entity, ObjectBP.PROPS.objectType.id, ObjectBP.PROPS.objectType.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get value() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, ObjectBP.PROPS.value.id, ObjectBP.PROPS.value.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get gebruik() {
                try {
                    return getRelatedPlural(entity, gebruikFetchContext, Werkmaterieel.PROPS.gebruik.id, Werkmaterieel.PROPS.gebruik.direction, Werkmaterieel.PROPS.gebruik.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get merk() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Werkmaterieel.PROPS.merk.id, Werkmaterieel.PROPS.merk.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get bouwjaar() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Werkmaterieel.PROPS.bouwjaar.id, Werkmaterieel.PROPS.bouwjaar.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get merk(): string | null {
        return this._props.merk !== null ? TextType.getRawText(this._props.merk)! : null;
    }
    get bouwjaar(): Date | null {
        return this._props.bouwjaar !== null ? DateType.getRawDate(this._props.bouwjaar)! : null;
    }
    get gebruik(): GebruikWerkmaterieel[] | undefined {
        const gebruikFetchContext = getFetchTreeOfRelation(this._fetchContext, Werkmaterieel.PROPS.gebruik.id, Werkmaterieel.PROPS.gebruik.direction);
        if (gebruikFetchContext !== undefined) {
            return toTypedEntity(this._props.gebruik, this._metadataService, gebruikFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class Offerte extends ObjEntity {
    public static ID = "8ac1ba12-eabb-4a25-a7bd-e5d5a831a9aa";
    public static PROPS = createProps({
        ...ObjEntity.PROPS,
        verzekerdbedrag: {
            type: "Field",
            id: "347ac5aa-185f-4bf4-b39f-8fbe29d635d2",
            required: false
        },
        eigenRisico: {
            type: "Field",
            id: "e4308e9f-13f6-4c70-9fea-d3c32d866015",
            required: false
        },
        premie: {
            type: "Field",
            id: "0fceb8a1-445f-4569-88c3-a268f6c7f0ea",
            required: true
        },
        toelichting: {
            type: "Field",
            id: "68c95e68-7989-48b3-a480-d5ba58c2e344",
            required: false
        },
        productvoorwaarden: {
            type: "Field",
            id: "406b0a68-0109-4279-9e3d-69f44082b9b1",
            required: false
        },
        clausules: {
            type: "Field",
            id: "228384b9-c8fa-4434-bae6-03f6199a2c0f",
            required: false
        },
        branche: {
            type: "RelationshipType",
            id: "e79f5e2b-2a03-4c7f-919c-f942149d0db0",
            direction: "child",
            multiplicity: "One",
            required: true
        },
        dekkingsentiteiten: {
            type: "RelationshipType",
            id: "cb53fb3a-d5d4-4fdd-8b38-2c8da6a41963",
            direction: "child",
            multiplicity: "Many",
            required: false
        },
        betaaltermijn: {
            type: "RelationshipType",
            id: "84ea679a-267a-4bbb-a4cb-de69a9971b74",
            direction: "child",
            multiplicity: "One",
            required: true
        },
        aanbieder: {
            type: "RelationshipType",
            id: "2f411354-77e6-41ab-bc59-5e21a0d123b6",
            direction: "child",
            multiplicity: "One",
            required: true
        },
        dossier: {
            type: "RelationshipType",
            id: "f08ee548-ea48-46f8-88a4-392b900866c2",
            direction: "parent",
            multiplicity: "One",
            required: true
        },
        offerteBeoordeling: {
            type: "RelationshipType",
            id: "b7545164-2848-4847-851e-80373a5a5da3",
            direction: "parent",
            multiplicity: "One",
            required: false
        }
    });
    public readonly _props: OfferteProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Offerte>(this, {
            _props: override,
            verzekerdbedrag: computed,
            eigenRisico: computed,
            premie: computed,
            toelichting: computed,
            productvoorwaarden: computed,
            clausules: computed,
            branche: computed,
            dekkingsentiteiten: computed,
            betaaltermijn: computed,
            aanbieder: computed,
            dossier: computed,
            offerteBeoordeling: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const brancheFetchContext = getFetchTreeOfRelation(this._fetchContext, Offerte.PROPS.branche.id, Offerte.PROPS.branche.direction);
        const dekkingsentiteitenFetchContext = getFetchTreeOfRelation(this._fetchContext, Offerte.PROPS.dekkingsentiteiten.id, Offerte.PROPS.dekkingsentiteiten.direction);
        const betaaltermijnFetchContext = getFetchTreeOfRelation(this._fetchContext, Offerte.PROPS.betaaltermijn.id, Offerte.PROPS.betaaltermijn.direction);
        const aanbiederFetchContext = getFetchTreeOfRelation(this._fetchContext, Offerte.PROPS.aanbieder.id, Offerte.PROPS.aanbieder.direction);
        const dossierFetchContext = getFetchTreeOfRelation(this._fetchContext, Offerte.PROPS.dossier.id, Offerte.PROPS.dossier.direction);
        const offerteBeoordelingFetchContext = getFetchTreeOfRelation(this._fetchContext, Offerte.PROPS.offerteBeoordeling.id, Offerte.PROPS.offerteBeoordeling.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get branche() {
                try {
                    return getRelatedSingular(entity, brancheFetchContext, Offerte.PROPS.branche.id, Offerte.PROPS.branche.direction, Offerte.PROPS.branche.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get dekkingsentiteiten() {
                try {
                    return getRelatedPlural(entity, dekkingsentiteitenFetchContext, Offerte.PROPS.dekkingsentiteiten.id, Offerte.PROPS.dekkingsentiteiten.direction, Offerte.PROPS.dekkingsentiteiten.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get betaaltermijn() {
                try {
                    return getRelatedSingular(entity, betaaltermijnFetchContext, Offerte.PROPS.betaaltermijn.id, Offerte.PROPS.betaaltermijn.direction, Offerte.PROPS.betaaltermijn.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get aanbieder() {
                try {
                    return getRelatedSingular(entity, aanbiederFetchContext, Offerte.PROPS.aanbieder.id, Offerte.PROPS.aanbieder.direction, Offerte.PROPS.aanbieder.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get dossier() {
                try {
                    return getRelatedSingular(entity, dossierFetchContext, Offerte.PROPS.dossier.id, Offerte.PROPS.dossier.direction, Offerte.PROPS.dossier.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get offerteBeoordeling() {
                try {
                    return getRelatedSingular(entity, offerteBeoordelingFetchContext, Offerte.PROPS.offerteBeoordeling.id, Offerte.PROPS.offerteBeoordeling.direction, Offerte.PROPS.offerteBeoordeling.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get verzekerdbedrag() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Offerte.PROPS.verzekerdbedrag.id, Offerte.PROPS.verzekerdbedrag.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get eigenRisico() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Offerte.PROPS.eigenRisico.id, Offerte.PROPS.eigenRisico.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get premie() {
                try {
                    return getFieldValue(metadataService, entity, Offerte.PROPS.premie.id, Offerte.PROPS.premie.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get toelichting() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Offerte.PROPS.toelichting.id, Offerte.PROPS.toelichting.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get productvoorwaarden() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Offerte.PROPS.productvoorwaarden.id, Offerte.PROPS.productvoorwaarden.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get clausules() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Offerte.PROPS.clausules.id, Offerte.PROPS.clausules.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get verzekerdbedrag(): Decimal | null {
        return this._props.verzekerdbedrag !== null ? CurrencyType.getRawAmount(this._props.verzekerdbedrag)! : null;
    }
    get eigenRisico(): string | null {
        return this._props.eigenRisico !== null ? TextType.getRawText(this._props.eigenRisico)! : null;
    }
    get premie(): Decimal {
        return CurrencyType.getRawAmount(this._props.premie)!;
    }
    get toelichting(): string | null {
        return this._props.toelichting !== null ? TextType.getRawText(this._props.toelichting)! : null;
    }
    get productvoorwaarden(): string | null {
        return this._props.productvoorwaarden !== null ? TextType.getRawText(this._props.productvoorwaarden)! : null;
    }
    get clausules(): string | null {
        return this._props.clausules !== null ? TextType.getRawText(this._props.clausules)! : null;
    }
    get branche(): Branche | undefined {
        const brancheFetchContext = getFetchTreeOfRelation(this._fetchContext, Offerte.PROPS.branche.id, Offerte.PROPS.branche.direction);
        if (brancheFetchContext !== undefined) {
            return toTypedEntity(this._props.branche, this._metadataService, brancheFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get dekkingsentiteiten(): DekkingsEntiteit[] | undefined {
        const dekkingsentiteitenFetchContext = getFetchTreeOfRelation(this._fetchContext, Offerte.PROPS.dekkingsentiteiten.id, Offerte.PROPS.dekkingsentiteiten.direction);
        if (dekkingsentiteitenFetchContext !== undefined) {
            return toTypedEntity(this._props.dekkingsentiteiten, this._metadataService, dekkingsentiteitenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get betaaltermijn(): Betaaltermijn | undefined {
        const betaaltermijnFetchContext = getFetchTreeOfRelation(this._fetchContext, Offerte.PROPS.betaaltermijn.id, Offerte.PROPS.betaaltermijn.direction);
        if (betaaltermijnFetchContext !== undefined) {
            return toTypedEntity(this._props.betaaltermijn, this._metadataService, betaaltermijnFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get aanbieder(): Maatschappij | undefined {
        const aanbiederFetchContext = getFetchTreeOfRelation(this._fetchContext, Offerte.PROPS.aanbieder.id, Offerte.PROPS.aanbieder.direction);
        if (aanbiederFetchContext !== undefined) {
            return toTypedEntity(this._props.aanbieder, this._metadataService, aanbiederFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get dossier(): ProductBemiddelingDossier | undefined {
        const dossierFetchContext = getFetchTreeOfRelation(this._fetchContext, Offerte.PROPS.dossier.id, Offerte.PROPS.dossier.direction);
        if (dossierFetchContext !== undefined) {
            return toTypedEntity(this._props.dossier, this._metadataService, dossierFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get offerteBeoordeling(): (OfferteBeoordeling | null) | undefined {
        const offerteBeoordelingFetchContext = getFetchTreeOfRelation(this._fetchContext, Offerte.PROPS.offerteBeoordeling.id, Offerte.PROPS.offerteBeoordeling.direction);
        if (offerteBeoordelingFetchContext !== undefined) {
            return toTypedEntity(this._props.offerteBeoordeling, this._metadataService, offerteBeoordelingFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class OfferteBeoordeling extends ObjEntity {
    public static ID = "f91c0ad8-34a4-4937-95e4-87e5550c74eb";
    public static PROPS = createProps({
        ...ObjEntity.PROPS,
        toelichting: {
            type: "Field",
            id: "b34da7d7-9abc-488c-8427-bdb816bf5356",
            required: false
        },
        besteOfferte: {
            type: "RelationshipType",
            id: "b7545164-2848-4847-851e-80373a5a5da3",
            direction: "child",
            multiplicity: "One",
            required: true
        },
        branche: {
            type: "RelationshipType",
            id: "a4105de0-133e-4561-ad0b-554f6dcda96a",
            direction: "child",
            multiplicity: "One",
            required: true
        },
        dossier: {
            type: "RelationshipType",
            id: "11a3dbbe-6cf0-4502-a1e5-1051a2e909c3",
            direction: "parent",
            multiplicity: "One",
            required: true
        }
    });
    public readonly _props: OfferteBeoordelingProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<OfferteBeoordeling>(this, {
            _props: override,
            toelichting: computed,
            besteOfferte: computed,
            branche: computed,
            dossier: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const besteOfferteFetchContext = getFetchTreeOfRelation(this._fetchContext, OfferteBeoordeling.PROPS.besteOfferte.id, OfferteBeoordeling.PROPS.besteOfferte.direction);
        const brancheFetchContext = getFetchTreeOfRelation(this._fetchContext, OfferteBeoordeling.PROPS.branche.id, OfferteBeoordeling.PROPS.branche.direction);
        const dossierFetchContext = getFetchTreeOfRelation(this._fetchContext, OfferteBeoordeling.PROPS.dossier.id, OfferteBeoordeling.PROPS.dossier.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get besteOfferte() {
                try {
                    return getRelatedSingular(entity, besteOfferteFetchContext, OfferteBeoordeling.PROPS.besteOfferte.id, OfferteBeoordeling.PROPS.besteOfferte.direction, OfferteBeoordeling.PROPS.besteOfferte.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get branche() {
                try {
                    return getRelatedSingular(entity, brancheFetchContext, OfferteBeoordeling.PROPS.branche.id, OfferteBeoordeling.PROPS.branche.direction, OfferteBeoordeling.PROPS.branche.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get dossier() {
                try {
                    return getRelatedSingular(entity, dossierFetchContext, OfferteBeoordeling.PROPS.dossier.id, OfferteBeoordeling.PROPS.dossier.direction, OfferteBeoordeling.PROPS.dossier.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get toelichting() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, OfferteBeoordeling.PROPS.toelichting.id, OfferteBeoordeling.PROPS.toelichting.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get toelichting(): string | null {
        return this._props.toelichting !== null ? TextType.getRawText(this._props.toelichting)! : null;
    }
    get besteOfferte(): Offerte | undefined {
        const besteOfferteFetchContext = getFetchTreeOfRelation(this._fetchContext, OfferteBeoordeling.PROPS.besteOfferte.id, OfferteBeoordeling.PROPS.besteOfferte.direction);
        if (besteOfferteFetchContext !== undefined) {
            return toTypedEntity(this._props.besteOfferte, this._metadataService, besteOfferteFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get branche(): Branche | undefined {
        const brancheFetchContext = getFetchTreeOfRelation(this._fetchContext, OfferteBeoordeling.PROPS.branche.id, OfferteBeoordeling.PROPS.branche.direction);
        if (brancheFetchContext !== undefined) {
            return toTypedEntity(this._props.branche, this._metadataService, brancheFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get dossier(): ProductBemiddelingDossier | undefined {
        const dossierFetchContext = getFetchTreeOfRelation(this._fetchContext, OfferteBeoordeling.PROPS.dossier.id, OfferteBeoordeling.PROPS.dossier.direction);
        if (dossierFetchContext !== undefined) {
            return toTypedEntity(this._props.dossier, this._metadataService, dossierFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export abstract class Pack extends ObjEntity {
    public static ID = "74f57c82-5197-4fd7-89bb-feec48bda1ad";
    public static PROPS = createProps({
        ...ObjEntity.PROPS,
        name: {
            type: "Field",
            id: "e661bc9e-6d68-4aad-8db7-cbf695c701bb",
            required: true
        },
        entities: {
            type: "RelationshipType",
            id: "8f42b5db-6e5c-4775-9a06-e647d4d45771",
            direction: "parent",
            multiplicity: "Many",
            required: false
        },
        usersThatCanRead: {
            type: "RelationshipType",
            id: "812c4b51-d85a-44d6-93af-d77968f2c2ce",
            direction: "parent",
            multiplicity: "Many",
            required: false
        }
    });
    public readonly _props: PackProps;
    constructor(protected metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Pack>(this, {
            _props: override,
            name: computed,
            entities: computed,
            usersThatCanRead: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const entitiesFetchContext = getFetchTreeOfRelation(this._fetchContext, Pack.PROPS.entities.id, Pack.PROPS.entities.direction);
        const usersThatCanReadFetchContext = getFetchTreeOfRelation(this._fetchContext, Pack.PROPS.usersThatCanRead.id, Pack.PROPS.usersThatCanRead.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get entities() {
                try {
                    return getRelatedPlural(entity, entitiesFetchContext, Pack.PROPS.entities.id, Pack.PROPS.entities.direction, Pack.PROPS.entities.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get usersThatCanRead() {
                try {
                    return getRelatedPlural(entity, usersThatCanReadFetchContext, Pack.PROPS.usersThatCanRead.id, Pack.PROPS.usersThatCanRead.direction, Pack.PROPS.usersThatCanRead.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get name() {
                try {
                    return getFieldValue(metadataService, entity, Pack.PROPS.name.id, Pack.PROPS.name.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get name(): string {
        return TextType.getRawText(this._props.name)!;
    }
    get entities(): ObjEntity[] | undefined {
        const entitiesFetchContext = getFetchTreeOfRelation(this._fetchContext, Pack.PROPS.entities.id, Pack.PROPS.entities.direction);
        if (entitiesFetchContext !== undefined) {
            return toTypedEntity(this._props.entities, this._metadataService, entitiesFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get usersThatCanRead(): User[] | undefined {
        const usersThatCanReadFetchContext = getFetchTreeOfRelation(this._fetchContext, Pack.PROPS.usersThatCanRead.id, Pack.PROPS.usersThatCanRead.direction);
        if (usersThatCanReadFetchContext !== undefined) {
            return toTypedEntity(this._props.usersThatCanRead, this._metadataService, usersThatCanReadFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class EnvironmentPack extends Pack {
    public static ID = "aa0e28ac-3e04-49b6-8b2f-8f73b77f4ac0";
    public static PROPS = createProps({
        ...Pack.PROPS
    });
    public readonly _props: EnvironmentPackProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<EnvironmentPack>(this, {
            _props: override
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const entitiesFetchContext = getFetchTreeOfRelation(this._fetchContext, Pack.PROPS.entities.id, Pack.PROPS.entities.direction);
        const usersThatCanReadFetchContext = getFetchTreeOfRelation(this._fetchContext, Pack.PROPS.usersThatCanRead.id, Pack.PROPS.usersThatCanRead.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get entities() {
                try {
                    return getRelatedPlural(entity, entitiesFetchContext, Pack.PROPS.entities.id, Pack.PROPS.entities.direction, Pack.PROPS.entities.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get usersThatCanRead() {
                try {
                    return getRelatedPlural(entity, usersThatCanReadFetchContext, Pack.PROPS.usersThatCanRead.id, Pack.PROPS.usersThatCanRead.direction, Pack.PROPS.usersThatCanRead.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get name() {
                try {
                    return getFieldValue(metadataService, entity, Pack.PROPS.name.id, Pack.PROPS.name.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
}
export class ModulePack extends Pack {
    public static ID = "7a7730aa-0306-476d-a994-ecd07516ec44";
    public static PROPS = createProps({
        ...Pack.PROPS
    });
    public readonly _props: ModulePackProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<ModulePack>(this, {
            _props: override
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const entitiesFetchContext = getFetchTreeOfRelation(this._fetchContext, Pack.PROPS.entities.id, Pack.PROPS.entities.direction);
        const usersThatCanReadFetchContext = getFetchTreeOfRelation(this._fetchContext, Pack.PROPS.usersThatCanRead.id, Pack.PROPS.usersThatCanRead.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get entities() {
                try {
                    return getRelatedPlural(entity, entitiesFetchContext, Pack.PROPS.entities.id, Pack.PROPS.entities.direction, Pack.PROPS.entities.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get usersThatCanRead() {
                try {
                    return getRelatedPlural(entity, usersThatCanReadFetchContext, Pack.PROPS.usersThatCanRead.id, Pack.PROPS.usersThatCanRead.direction, Pack.PROPS.usersThatCanRead.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get name() {
                try {
                    return getFieldValue(metadataService, entity, Pack.PROPS.name.id, Pack.PROPS.name.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
}
export class SystemPack extends Pack {
    public static ID = "585f1f50-79c1-4137-ae75-424e591efdf4";
    public static PROPS = createProps({
        ...Pack.PROPS
    });
    public readonly _props: SystemPackProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<SystemPack>(this, {
            _props: override
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const entitiesFetchContext = getFetchTreeOfRelation(this._fetchContext, Pack.PROPS.entities.id, Pack.PROPS.entities.direction);
        const usersThatCanReadFetchContext = getFetchTreeOfRelation(this._fetchContext, Pack.PROPS.usersThatCanRead.id, Pack.PROPS.usersThatCanRead.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get entities() {
                try {
                    return getRelatedPlural(entity, entitiesFetchContext, Pack.PROPS.entities.id, Pack.PROPS.entities.direction, Pack.PROPS.entities.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get usersThatCanRead() {
                try {
                    return getRelatedPlural(entity, usersThatCanReadFetchContext, Pack.PROPS.usersThatCanRead.id, Pack.PROPS.usersThatCanRead.direction, Pack.PROPS.usersThatCanRead.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get name() {
                try {
                    return getFieldValue(metadataService, entity, Pack.PROPS.name.id, Pack.PROPS.name.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
}
export class Pakket extends ObjEntity {
    public static ID = "0b4568a4-d8cd-4101-8ffd-b645dfa993ce";
    public static PROPS = createProps({
        ...ObjEntity.PROPS,
        polissen: {
            type: "RelationshipType",
            id: "93151867-c180-49db-a4df-b9e97cd40655",
            direction: "child",
            multiplicity: "Many",
            required: false
        }
    });
    public readonly _props: PakketProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Pakket>(this, {
            _props: override,
            polissen: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const polissenFetchContext = getFetchTreeOfRelation(this._fetchContext, Pakket.PROPS.polissen.id, Pakket.PROPS.polissen.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get polissen() {
                try {
                    return getRelatedPlural(entity, polissenFetchContext, Pakket.PROPS.polissen.id, Pakket.PROPS.polissen.direction, Pakket.PROPS.polissen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get polissen(): Polis[] | undefined {
        const polissenFetchContext = getFetchTreeOfRelation(this._fetchContext, Pakket.PROPS.polissen.id, Pakket.PROPS.polissen.direction);
        if (polissenFetchContext !== undefined) {
            return toTypedEntity(this._props.polissen, this._metadataService, polissenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class Polis extends ObjEntity {
    public static ID = "34c80a83-3655-4caa-9936-9fc91af001a3";
    public static PROPS = createProps({
        ...ObjEntity.PROPS,
        registratienummer: {
            type: "Field",
            id: "a7ea2527-f9f0-4bf2-9229-dff67cdee22b",
            required: true
        },
        product: {
            type: "Field",
            id: "e008ab65-e394-4e4d-a50d-4e24f92ad582",
            required: true
        },
        maatschappij: {
            type: "Field",
            id: "98feb8b8-fb4e-4632-a12a-36f6b190e432",
            required: true
        },
        maandbedrag: {
            type: "Field",
            id: "a37c21a5-5593-47c5-bb49-2de50b718f8b",
            required: true
        },
        omschrijving: {
            type: "Field",
            id: "e0cac6b0-7439-488a-8845-3674fcd2fb4c",
            required: false
        },
        client: {
            type: "RelationshipType",
            id: "d1b6a84f-5eec-4438-830c-94cb1706d3f4",
            direction: "parent",
            multiplicity: "One",
            required: true
        },
        pakket: {
            type: "RelationshipType",
            id: "93151867-c180-49db-a4df-b9e97cd40655",
            direction: "parent",
            multiplicity: "One",
            required: false
        },
        branche: {
            type: "RelationshipType",
            id: "349d1ffc-a286-4d13-a6cd-225b282acb1e",
            direction: "parent",
            multiplicity: "One",
            required: true
        }
    });
    public readonly _props: PolisProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Polis>(this, {
            _props: override,
            registratienummer: computed,
            product: computed,
            maatschappij: computed,
            maandbedrag: computed,
            omschrijving: computed,
            client: computed,
            pakket: computed,
            branche: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const clientFetchContext = getFetchTreeOfRelation(this._fetchContext, Polis.PROPS.client.id, Polis.PROPS.client.direction);
        const pakketFetchContext = getFetchTreeOfRelation(this._fetchContext, Polis.PROPS.pakket.id, Polis.PROPS.pakket.direction);
        const brancheFetchContext = getFetchTreeOfRelation(this._fetchContext, Polis.PROPS.branche.id, Polis.PROPS.branche.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get client() {
                try {
                    return getRelatedSingular(entity, clientFetchContext, Polis.PROPS.client.id, Polis.PROPS.client.direction, Polis.PROPS.client.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get pakket() {
                try {
                    return getRelatedSingular(entity, pakketFetchContext, Polis.PROPS.pakket.id, Polis.PROPS.pakket.direction, Polis.PROPS.pakket.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get branche() {
                try {
                    return getRelatedSingular(entity, brancheFetchContext, Polis.PROPS.branche.id, Polis.PROPS.branche.direction, Polis.PROPS.branche.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get registratienummer() {
                try {
                    return getFieldValue(metadataService, entity, Polis.PROPS.registratienummer.id, Polis.PROPS.registratienummer.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get product() {
                try {
                    return getFieldValue(metadataService, entity, Polis.PROPS.product.id, Polis.PROPS.product.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get maatschappij() {
                try {
                    return getFieldValue(metadataService, entity, Polis.PROPS.maatschappij.id, Polis.PROPS.maatschappij.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get maandbedrag() {
                try {
                    return getFieldValue(metadataService, entity, Polis.PROPS.maandbedrag.id, Polis.PROPS.maandbedrag.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get omschrijving() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Polis.PROPS.omschrijving.id, Polis.PROPS.omschrijving.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get registratienummer(): string {
        return TextType.getRawText(this._props.registratienummer)!;
    }
    get product(): string {
        return TextType.getRawText(this._props.product)!;
    }
    get maatschappij(): string {
        return TextType.getRawText(this._props.maatschappij)!;
    }
    get maandbedrag(): Decimal {
        return CurrencyType.getRawAmount(this._props.maandbedrag)!;
    }
    get omschrijving(): string | null {
        return this._props.omschrijving !== null ? TextType.getRawText(this._props.omschrijving)! : null;
    }
    get client(): BusinessClient | undefined {
        const clientFetchContext = getFetchTreeOfRelation(this._fetchContext, Polis.PROPS.client.id, Polis.PROPS.client.direction);
        if (clientFetchContext !== undefined) {
            return toTypedEntity(this._props.client, this._metadataService, clientFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get pakket(): (Pakket | null) | undefined {
        const pakketFetchContext = getFetchTreeOfRelation(this._fetchContext, Polis.PROPS.pakket.id, Polis.PROPS.pakket.direction);
        if (pakketFetchContext !== undefined) {
            return toTypedEntity(this._props.pakket, this._metadataService, pakketFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get branche(): Branche | undefined {
        const brancheFetchContext = getFetchTreeOfRelation(this._fetchContext, Polis.PROPS.branche.id, Polis.PROPS.branche.direction);
        if (brancheFetchContext !== undefined) {
            return toTypedEntity(this._props.branche, this._metadataService, brancheFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export abstract class PrijsAfspraak extends ObjEntity {
    public static ID = "a0577e46-e0b0-4c1d-af61-19c5fd087e66";
    public static PROPS = createProps({
        ...ObjEntity.PROPS,
        dossiers: {
            type: "RelationshipType",
            id: "fa6534e6-3c87-4522-b79a-167936c798b9",
            direction: "parent",
            multiplicity: "Many",
            required: false
        }
    });
    public readonly _props: PrijsAfspraakProps;
    constructor(protected metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<PrijsAfspraak>(this, {
            _props: override,
            dossiers: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const dossiersFetchContext = getFetchTreeOfRelation(this._fetchContext, PrijsAfspraak.PROPS.dossiers.id, PrijsAfspraak.PROPS.dossiers.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get dossiers() {
                try {
                    return getRelatedPlural(entity, dossiersFetchContext, PrijsAfspraak.PROPS.dossiers.id, PrijsAfspraak.PROPS.dossiers.direction, PrijsAfspraak.PROPS.dossiers.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get dossiers(): Dossier[] | undefined {
        const dossiersFetchContext = getFetchTreeOfRelation(this._fetchContext, PrijsAfspraak.PROPS.dossiers.id, PrijsAfspraak.PROPS.dossiers.direction);
        if (dossiersFetchContext !== undefined) {
            return toTypedEntity(this._props.dossiers, this._metadataService, dossiersFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class Nacalculatie extends PrijsAfspraak {
    public static ID = "067bf5e0-1b2d-4e93-94c3-35b9dffbab62";
    public static PROPS = createProps({
        ...PrijsAfspraak.PROPS,
        bedragPerUur: {
            type: "Field",
            id: "31fcdb89-f5a7-46d5-8153-104f95845550",
            required: true
        },
        geschatAantalUur: {
            type: "Field",
            id: "bb233dff-1792-4cad-abc7-9f3c2f8d58ac",
            required: true
        }
    });
    public readonly _props: NacalculatieProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Nacalculatie>(this, {
            _props: override,
            bedragPerUur: computed,
            geschatAantalUur: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const dossiersFetchContext = getFetchTreeOfRelation(this._fetchContext, PrijsAfspraak.PROPS.dossiers.id, PrijsAfspraak.PROPS.dossiers.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get dossiers() {
                try {
                    return getRelatedPlural(entity, dossiersFetchContext, PrijsAfspraak.PROPS.dossiers.id, PrijsAfspraak.PROPS.dossiers.direction, PrijsAfspraak.PROPS.dossiers.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get bedragPerUur() {
                try {
                    return getFieldValue(metadataService, entity, Nacalculatie.PROPS.bedragPerUur.id, Nacalculatie.PROPS.bedragPerUur.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get geschatAantalUur() {
                try {
                    return getFieldValue(metadataService, entity, Nacalculatie.PROPS.geschatAantalUur.id, Nacalculatie.PROPS.geschatAantalUur.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get bedragPerUur(): Decimal {
        return CurrencyType.getRawAmount(this._props.bedragPerUur)!;
    }
    get geschatAantalUur(): Decimal {
        return NumberType.getRawNumber(this._props.geschatAantalUur)!;
    }
}
export class NietFactureerbaar extends PrijsAfspraak {
    public static ID = "11a6c324-2904-4b87-8168-f3db3648fa43";
    public static PROPS = createProps({
        ...PrijsAfspraak.PROPS,
        reden: {
            type: "Field",
            id: "e5e01fd7-5f22-4dc5-b976-e10c38fadc91",
            required: true
        }
    });
    public readonly _props: NietFactureerbaarProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<NietFactureerbaar>(this, {
            _props: override,
            reden: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const dossiersFetchContext = getFetchTreeOfRelation(this._fetchContext, PrijsAfspraak.PROPS.dossiers.id, PrijsAfspraak.PROPS.dossiers.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get dossiers() {
                try {
                    return getRelatedPlural(entity, dossiersFetchContext, PrijsAfspraak.PROPS.dossiers.id, PrijsAfspraak.PROPS.dossiers.direction, PrijsAfspraak.PROPS.dossiers.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get reden() {
                try {
                    return getFieldValue(metadataService, entity, NietFactureerbaar.PROPS.reden.id, NietFactureerbaar.PROPS.reden.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get reden(): string {
        return TextType.getRawText(this._props.reden)!;
    }
}
export class VastePrijs extends PrijsAfspraak {
    public static ID = "a3acbc6e-b86f-4391-9797-5c5b64e8f569";
    public static PROPS = createProps({
        ...PrijsAfspraak.PROPS,
        bedrag: {
            type: "Field",
            id: "b10f44d3-d73b-4609-b646-5b4e049bdf91",
            required: true
        }
    });
    public readonly _props: VastePrijsProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<VastePrijs>(this, {
            _props: override,
            bedrag: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const dossiersFetchContext = getFetchTreeOfRelation(this._fetchContext, PrijsAfspraak.PROPS.dossiers.id, PrijsAfspraak.PROPS.dossiers.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get dossiers() {
                try {
                    return getRelatedPlural(entity, dossiersFetchContext, PrijsAfspraak.PROPS.dossiers.id, PrijsAfspraak.PROPS.dossiers.direction, PrijsAfspraak.PROPS.dossiers.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get bedrag() {
                try {
                    return getFieldValue(metadataService, entity, VastePrijs.PROPS.bedrag.id, VastePrijs.PROPS.bedrag.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get bedrag(): Decimal {
        return CurrencyType.getRawAmount(this._props.bedrag)!;
    }
}
export class RelationshipType extends ObjEntity {
    public static ID = "0265b31e-e9ea-4962-952e-c752b295a6f9";
    public static PROPS = createProps({
        ...ObjEntity.PROPS,
        name: {
            type: "Field",
            id: "93efdf65-0909-4822-ac78-e615ba7eeaf7",
            required: true
        },
        reverseName: {
            type: "Field",
            id: "92661536-9aa9-4b51-998a-b143087d6450",
            required: true
        },
        parent: {
            type: "RelationshipType",
            id: "4435c618-e80a-4c3c-a7ae-d413a87c224c",
            direction: "child",
            multiplicity: "One",
            required: true
        },
        child: {
            type: "RelationshipType",
            id: "1e5a7abf-b5e9-4db3-b8a5-e95afcf1c180",
            direction: "child",
            multiplicity: "One",
            required: true
        }
    });
    public readonly _props: RelationshipTypeProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<RelationshipType>(this, {
            _props: override,
            name: computed,
            reverseName: computed,
            parent: computed,
            child: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const parentFetchContext = getFetchTreeOfRelation(this._fetchContext, RelationshipType.PROPS.parent.id, RelationshipType.PROPS.parent.direction);
        const childFetchContext = getFetchTreeOfRelation(this._fetchContext, RelationshipType.PROPS.child.id, RelationshipType.PROPS.child.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get parent() {
                try {
                    return getRelatedSingular(entity, parentFetchContext, RelationshipType.PROPS.parent.id, RelationshipType.PROPS.parent.direction, RelationshipType.PROPS.parent.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get child() {
                try {
                    return getRelatedSingular(entity, childFetchContext, RelationshipType.PROPS.child.id, RelationshipType.PROPS.child.direction, RelationshipType.PROPS.child.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get name() {
                try {
                    return getFieldValue(metadataService, entity, RelationshipType.PROPS.name.id, RelationshipType.PROPS.name.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get reverseName() {
                try {
                    return getFieldValue(metadataService, entity, RelationshipType.PROPS.reverseName.id, RelationshipType.PROPS.reverseName.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get name(): string {
        return TextType.getRawText(this._props.name)!;
    }
    get reverseName(): string {
        return TextType.getRawText(this._props.reverseName)!;
    }
    get parent(): EntityType | undefined {
        const parentFetchContext = getFetchTreeOfRelation(this._fetchContext, RelationshipType.PROPS.parent.id, RelationshipType.PROPS.parent.direction);
        if (parentFetchContext !== undefined) {
            return toTypedEntity(this._props.parent, this._metadataService, parentFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get child(): EntityType | undefined {
        const childFetchContext = getFetchTreeOfRelation(this._fetchContext, RelationshipType.PROPS.child.id, RelationshipType.PROPS.child.direction);
        if (childFetchContext !== undefined) {
            return toTypedEntity(this._props.child, this._metadataService, childFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class RisicoAdres extends ObjEntity {
    public static ID = "4a83b11d-0782-45eb-9311-05ef65fa3b7b";
    public static PROPS = createProps({
        ...ObjEntity.PROPS,
        adres: {
            type: "Field",
            id: "2716120e-e52d-4fde-90e5-ea234df3fbc7",
            required: true
        },
        plaats: {
            type: "Field",
            id: "7691b191-121b-464e-b963-a3c2689ece2b",
            required: true
        },
        postcode: {
            type: "Field",
            id: "9f6d15ac-4ed0-405e-9ad1-36399a109e97",
            required: true
        },
        impactSchade: {
            type: "RelationshipType",
            id: "58754998-c308-4c42-b1f4-8fa5843b7cb1",
            direction: "child",
            multiplicity: "One",
            required: false
        },
        brandontwikkelsnelheid: {
            type: "RelationshipType",
            id: "e04599de-7c0e-4287-9353-ff558b26d995",
            direction: "child",
            multiplicity: "One",
            required: false
        },
        kenmerkenVerblijf: {
            type: "RelationshipType",
            id: "0619f180-9035-44ed-b60d-ff5d88680494",
            direction: "child",
            multiplicity: "One",
            required: false
        },
        kenmerkenGebruik: {
            type: "RelationshipType",
            id: "b69bd698-b4ba-4bb1-91c3-2ba703ac94e8",
            direction: "child",
            multiplicity: "One",
            required: false
        },
        ruimtes: {
            type: "RelationshipType",
            id: "5ccbafe8-ba0f-49a7-af2b-1a250cf46acc",
            direction: "child",
            multiplicity: "Many",
            required: false
        },
        gebouwen: {
            type: "RelationshipType",
            id: "a33879fe-a537-4d33-86be-bb1a5dc58f14",
            direction: "parent",
            multiplicity: "Many",
            required: false
        },
        beoordelingen: {
            type: "RelationshipType",
            id: "b787fe77-90cc-4b77-ab77-76b11a2d8558",
            direction: "parent",
            multiplicity: "Many",
            required: false
        },
        organisatie: {
            type: "RelationshipType",
            id: "02f9f2bb-cb3f-429e-b8cd-6bcc86d9117b",
            direction: "parent",
            multiplicity: "One",
            required: true
        }
    });
    public readonly _props: RisicoAdresProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<RisicoAdres>(this, {
            _props: override,
            adres: computed,
            plaats: computed,
            postcode: computed,
            impactSchade: computed,
            brandontwikkelsnelheid: computed,
            kenmerkenVerblijf: computed,
            kenmerkenGebruik: computed,
            ruimtes: computed,
            gebouwen: computed,
            beoordelingen: computed,
            organisatie: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const impactSchadeFetchContext = getFetchTreeOfRelation(this._fetchContext, RisicoAdres.PROPS.impactSchade.id, RisicoAdres.PROPS.impactSchade.direction);
        const brandontwikkelsnelheidFetchContext = getFetchTreeOfRelation(this._fetchContext, RisicoAdres.PROPS.brandontwikkelsnelheid.id, RisicoAdres.PROPS.brandontwikkelsnelheid.direction);
        const kenmerkenVerblijfFetchContext = getFetchTreeOfRelation(this._fetchContext, RisicoAdres.PROPS.kenmerkenVerblijf.id, RisicoAdres.PROPS.kenmerkenVerblijf.direction);
        const kenmerkenGebruikFetchContext = getFetchTreeOfRelation(this._fetchContext, RisicoAdres.PROPS.kenmerkenGebruik.id, RisicoAdres.PROPS.kenmerkenGebruik.direction);
        const ruimtesFetchContext = getFetchTreeOfRelation(this._fetchContext, RisicoAdres.PROPS.ruimtes.id, RisicoAdres.PROPS.ruimtes.direction);
        const gebouwenFetchContext = getFetchTreeOfRelation(this._fetchContext, RisicoAdres.PROPS.gebouwen.id, RisicoAdres.PROPS.gebouwen.direction);
        const beoordelingenFetchContext = getFetchTreeOfRelation(this._fetchContext, RisicoAdres.PROPS.beoordelingen.id, RisicoAdres.PROPS.beoordelingen.direction);
        const organisatieFetchContext = getFetchTreeOfRelation(this._fetchContext, RisicoAdres.PROPS.organisatie.id, RisicoAdres.PROPS.organisatie.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get impactSchade() {
                try {
                    return getRelatedSingular(entity, impactSchadeFetchContext, RisicoAdres.PROPS.impactSchade.id, RisicoAdres.PROPS.impactSchade.direction, RisicoAdres.PROPS.impactSchade.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get brandontwikkelsnelheid() {
                try {
                    return getRelatedSingular(entity, brandontwikkelsnelheidFetchContext, RisicoAdres.PROPS.brandontwikkelsnelheid.id, RisicoAdres.PROPS.brandontwikkelsnelheid.direction, RisicoAdres.PROPS.brandontwikkelsnelheid.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get kenmerkenVerblijf() {
                try {
                    return getRelatedSingular(entity, kenmerkenVerblijfFetchContext, RisicoAdres.PROPS.kenmerkenVerblijf.id, RisicoAdres.PROPS.kenmerkenVerblijf.direction, RisicoAdres.PROPS.kenmerkenVerblijf.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get kenmerkenGebruik() {
                try {
                    return getRelatedSingular(entity, kenmerkenGebruikFetchContext, RisicoAdres.PROPS.kenmerkenGebruik.id, RisicoAdres.PROPS.kenmerkenGebruik.direction, RisicoAdres.PROPS.kenmerkenGebruik.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get ruimtes() {
                try {
                    return getRelatedPlural(entity, ruimtesFetchContext, RisicoAdres.PROPS.ruimtes.id, RisicoAdres.PROPS.ruimtes.direction, RisicoAdres.PROPS.ruimtes.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get gebouwen() {
                try {
                    return getRelatedPlural(entity, gebouwenFetchContext, RisicoAdres.PROPS.gebouwen.id, RisicoAdres.PROPS.gebouwen.direction, RisicoAdres.PROPS.gebouwen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get beoordelingen() {
                try {
                    return getRelatedPlural(entity, beoordelingenFetchContext, RisicoAdres.PROPS.beoordelingen.id, RisicoAdres.PROPS.beoordelingen.direction, RisicoAdres.PROPS.beoordelingen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get organisatie() {
                try {
                    return getRelatedSingular(entity, organisatieFetchContext, RisicoAdres.PROPS.organisatie.id, RisicoAdres.PROPS.organisatie.direction, RisicoAdres.PROPS.organisatie.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get adres() {
                try {
                    return getFieldValue(metadataService, entity, RisicoAdres.PROPS.adres.id, RisicoAdres.PROPS.adres.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get plaats() {
                try {
                    return getFieldValue(metadataService, entity, RisicoAdres.PROPS.plaats.id, RisicoAdres.PROPS.plaats.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get postcode() {
                try {
                    return getFieldValue(metadataService, entity, RisicoAdres.PROPS.postcode.id, RisicoAdres.PROPS.postcode.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get adres(): string {
        return TextType.getRawText(this._props.adres)!;
    }
    get plaats(): string {
        return TextType.getRawText(this._props.plaats)!;
    }
    get postcode(): string {
        return TextType.getRawText(this._props.postcode)!;
    }
    get impactSchade(): (IBBImpactSchade | null) | undefined {
        const impactSchadeFetchContext = getFetchTreeOfRelation(this._fetchContext, RisicoAdres.PROPS.impactSchade.id, RisicoAdres.PROPS.impactSchade.direction);
        if (impactSchadeFetchContext !== undefined) {
            return toTypedEntity(this._props.impactSchade, this._metadataService, impactSchadeFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get brandontwikkelsnelheid(): (IBBBrandontwikkelsnelheid | null) | undefined {
        const brandontwikkelsnelheidFetchContext = getFetchTreeOfRelation(this._fetchContext, RisicoAdres.PROPS.brandontwikkelsnelheid.id, RisicoAdres.PROPS.brandontwikkelsnelheid.direction);
        if (brandontwikkelsnelheidFetchContext !== undefined) {
            return toTypedEntity(this._props.brandontwikkelsnelheid, this._metadataService, brandontwikkelsnelheidFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get kenmerkenVerblijf(): (IBBGebruikskarakteristiekGebouw1 | null) | undefined {
        const kenmerkenVerblijfFetchContext = getFetchTreeOfRelation(this._fetchContext, RisicoAdres.PROPS.kenmerkenVerblijf.id, RisicoAdres.PROPS.kenmerkenVerblijf.direction);
        if (kenmerkenVerblijfFetchContext !== undefined) {
            return toTypedEntity(this._props.kenmerkenVerblijf, this._metadataService, kenmerkenVerblijfFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get kenmerkenGebruik(): (IBBGebruikskarakteristiekGebouw2 | null) | undefined {
        const kenmerkenGebruikFetchContext = getFetchTreeOfRelation(this._fetchContext, RisicoAdres.PROPS.kenmerkenGebruik.id, RisicoAdres.PROPS.kenmerkenGebruik.direction);
        if (kenmerkenGebruikFetchContext !== undefined) {
            return toTypedEntity(this._props.kenmerkenGebruik, this._metadataService, kenmerkenGebruikFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get ruimtes(): IBBGebruikskarakteristiekPersoonlijkRuimtes[] | undefined {
        const ruimtesFetchContext = getFetchTreeOfRelation(this._fetchContext, RisicoAdres.PROPS.ruimtes.id, RisicoAdres.PROPS.ruimtes.direction);
        if (ruimtesFetchContext !== undefined) {
            return toTypedEntity(this._props.ruimtes, this._metadataService, ruimtesFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get gebouwen(): AbstractGebouwen[] | undefined {
        const gebouwenFetchContext = getFetchTreeOfRelation(this._fetchContext, RisicoAdres.PROPS.gebouwen.id, RisicoAdres.PROPS.gebouwen.direction);
        if (gebouwenFetchContext !== undefined) {
            return toTypedEntity(this._props.gebouwen, this._metadataService, gebouwenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get beoordelingen(): RisicoAdresRisicobeoordeling[] | undefined {
        const beoordelingenFetchContext = getFetchTreeOfRelation(this._fetchContext, RisicoAdres.PROPS.beoordelingen.id, RisicoAdres.PROPS.beoordelingen.direction);
        if (beoordelingenFetchContext !== undefined) {
            return toTypedEntity(this._props.beoordelingen, this._metadataService, beoordelingenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get organisatie(): Organization | undefined {
        const organisatieFetchContext = getFetchTreeOfRelation(this._fetchContext, RisicoAdres.PROPS.organisatie.id, RisicoAdres.PROPS.organisatie.direction);
        if (organisatieFetchContext !== undefined) {
            return toTypedEntity(this._props.organisatie, this._metadataService, organisatieFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export abstract class Risicobeoordeling extends ObjEntity {
    public static ID = "5eac98cf-4ef2-4496-a7b7-f46c5c0366ec";
    public static PROPS = createProps({
        ...ObjEntity.PROPS,
        toelichtingRisicoklasse: {
            type: "Field",
            id: "f1300957-b492-4a65-a0dc-69ca8a689ad9",
            required: false
        },
        toelichtingRisicostrategie: {
            type: "Field",
            id: "0126bce7-db1a-482b-8d0f-ba583bddee55",
            required: false
        },
        risicoType: {
            type: "RelationshipType",
            id: "5e742014-a993-46d3-8b15-526c2c0b125d",
            direction: "child",
            multiplicity: "One",
            required: true
        },
        automatischeInschattingRisicoklasse: {
            type: "RelationshipType",
            id: "977077af-f599-4e84-80ba-ae39535baa85",
            direction: "child",
            multiplicity: "One",
            required: true
        },
        brutoRisicoklasse: {
            type: "RelationshipType",
            id: "9f32ad5d-90c7-480c-9b4d-b86f90b2732e",
            direction: "child",
            multiplicity: "One",
            required: false
        },
        nettoRisicoklasse: {
            type: "RelationshipType",
            id: "9308aeb4-e851-4483-9080-b19533524889",
            direction: "child",
            multiplicity: "One",
            required: false
        },
        risicostrategie: {
            type: "RelationshipType",
            id: "12313fef-1cac-42eb-af90-85d54fb0e9ff",
            direction: "child",
            multiplicity: "One",
            required: false
        },
        dossier: {
            type: "RelationshipType",
            id: "8be149ed-8563-4ac3-9500-fd31da45194c",
            direction: "parent",
            multiplicity: "One",
            required: true
        }
    });
    public readonly _props: RisicobeoordelingProps;
    constructor(protected metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Risicobeoordeling>(this, {
            _props: override,
            toelichtingRisicoklasse: computed,
            toelichtingRisicostrategie: computed,
            risicoType: computed,
            automatischeInschattingRisicoklasse: computed,
            brutoRisicoklasse: computed,
            nettoRisicoklasse: computed,
            risicostrategie: computed,
            dossier: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const risicoTypeFetchContext = getFetchTreeOfRelation(this._fetchContext, Risicobeoordeling.PROPS.risicoType.id, Risicobeoordeling.PROPS.risicoType.direction);
        const automatischeInschattingRisicoklasseFetchContext = getFetchTreeOfRelation(this._fetchContext, Risicobeoordeling.PROPS.automatischeInschattingRisicoklasse.id, Risicobeoordeling.PROPS.automatischeInschattingRisicoklasse.direction);
        const brutoRisicoklasseFetchContext = getFetchTreeOfRelation(this._fetchContext, Risicobeoordeling.PROPS.brutoRisicoklasse.id, Risicobeoordeling.PROPS.brutoRisicoklasse.direction);
        const nettoRisicoklasseFetchContext = getFetchTreeOfRelation(this._fetchContext, Risicobeoordeling.PROPS.nettoRisicoklasse.id, Risicobeoordeling.PROPS.nettoRisicoklasse.direction);
        const risicostrategieFetchContext = getFetchTreeOfRelation(this._fetchContext, Risicobeoordeling.PROPS.risicostrategie.id, Risicobeoordeling.PROPS.risicostrategie.direction);
        const dossierFetchContext = getFetchTreeOfRelation(this._fetchContext, Risicobeoordeling.PROPS.dossier.id, Risicobeoordeling.PROPS.dossier.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get risicoType() {
                try {
                    return getRelatedSingular(entity, risicoTypeFetchContext, Risicobeoordeling.PROPS.risicoType.id, Risicobeoordeling.PROPS.risicoType.direction, Risicobeoordeling.PROPS.risicoType.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get automatischeInschattingRisicoklasse() {
                try {
                    return getRelatedSingular(entity, automatischeInschattingRisicoklasseFetchContext, Risicobeoordeling.PROPS.automatischeInschattingRisicoklasse.id, Risicobeoordeling.PROPS.automatischeInschattingRisicoklasse.direction, Risicobeoordeling.PROPS.automatischeInschattingRisicoklasse.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get brutoRisicoklasse() {
                try {
                    return getRelatedSingular(entity, brutoRisicoklasseFetchContext, Risicobeoordeling.PROPS.brutoRisicoklasse.id, Risicobeoordeling.PROPS.brutoRisicoklasse.direction, Risicobeoordeling.PROPS.brutoRisicoklasse.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get nettoRisicoklasse() {
                try {
                    return getRelatedSingular(entity, nettoRisicoklasseFetchContext, Risicobeoordeling.PROPS.nettoRisicoklasse.id, Risicobeoordeling.PROPS.nettoRisicoklasse.direction, Risicobeoordeling.PROPS.nettoRisicoklasse.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get risicostrategie() {
                try {
                    return getRelatedSingular(entity, risicostrategieFetchContext, Risicobeoordeling.PROPS.risicostrategie.id, Risicobeoordeling.PROPS.risicostrategie.direction, Risicobeoordeling.PROPS.risicostrategie.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get dossier() {
                try {
                    return getRelatedSingular(entity, dossierFetchContext, Risicobeoordeling.PROPS.dossier.id, Risicobeoordeling.PROPS.dossier.direction, Risicobeoordeling.PROPS.dossier.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get toelichtingRisicoklasse() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Risicobeoordeling.PROPS.toelichtingRisicoklasse.id, Risicobeoordeling.PROPS.toelichtingRisicoklasse.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get toelichtingRisicostrategie() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Risicobeoordeling.PROPS.toelichtingRisicostrategie.id, Risicobeoordeling.PROPS.toelichtingRisicostrategie.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get toelichtingRisicoklasse(): string | null {
        return this._props.toelichtingRisicoklasse !== null ? TextType.getRawText(this._props.toelichtingRisicoklasse)! : null;
    }
    get toelichtingRisicostrategie(): string | null {
        return this._props.toelichtingRisicostrategie !== null ? TextType.getRawText(this._props.toelichtingRisicostrategie)! : null;
    }
    get risicoType(): Risicoprofiel | undefined {
        const risicoTypeFetchContext = getFetchTreeOfRelation(this._fetchContext, Risicobeoordeling.PROPS.risicoType.id, Risicobeoordeling.PROPS.risicoType.direction);
        if (risicoTypeFetchContext !== undefined) {
            return toTypedEntity(this._props.risicoType, this._metadataService, risicoTypeFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get automatischeInschattingRisicoklasse(): Risicoklasse | undefined {
        const automatischeInschattingRisicoklasseFetchContext = getFetchTreeOfRelation(this._fetchContext, Risicobeoordeling.PROPS.automatischeInschattingRisicoklasse.id, Risicobeoordeling.PROPS.automatischeInschattingRisicoklasse.direction);
        if (automatischeInschattingRisicoklasseFetchContext !== undefined) {
            return toTypedEntity(this._props.automatischeInschattingRisicoklasse, this._metadataService, automatischeInschattingRisicoklasseFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get brutoRisicoklasse(): (Risicoklasse | null) | undefined {
        const brutoRisicoklasseFetchContext = getFetchTreeOfRelation(this._fetchContext, Risicobeoordeling.PROPS.brutoRisicoklasse.id, Risicobeoordeling.PROPS.brutoRisicoklasse.direction);
        if (brutoRisicoklasseFetchContext !== undefined) {
            return toTypedEntity(this._props.brutoRisicoklasse, this._metadataService, brutoRisicoklasseFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get nettoRisicoklasse(): (Risicoklasse | null) | undefined {
        const nettoRisicoklasseFetchContext = getFetchTreeOfRelation(this._fetchContext, Risicobeoordeling.PROPS.nettoRisicoklasse.id, Risicobeoordeling.PROPS.nettoRisicoklasse.direction);
        if (nettoRisicoklasseFetchContext !== undefined) {
            return toTypedEntity(this._props.nettoRisicoklasse, this._metadataService, nettoRisicoklasseFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get risicostrategie(): (Risicostrategie | null) | undefined {
        const risicostrategieFetchContext = getFetchTreeOfRelation(this._fetchContext, Risicobeoordeling.PROPS.risicostrategie.id, Risicobeoordeling.PROPS.risicostrategie.direction);
        if (risicostrategieFetchContext !== undefined) {
            return toTypedEntity(this._props.risicostrategie, this._metadataService, risicostrategieFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get dossier(): RisicoAdviesDossier | undefined {
        const dossierFetchContext = getFetchTreeOfRelation(this._fetchContext, Risicobeoordeling.PROPS.dossier.id, Risicobeoordeling.PROPS.dossier.direction);
        if (dossierFetchContext !== undefined) {
            return toTypedEntity(this._props.dossier, this._metadataService, dossierFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class GoederenRisicobeoordeling extends Risicobeoordeling {
    public static ID = "666df96e-a8ba-4427-a357-873e5ec41e04";
    public static PROPS = createProps({
        ...Risicobeoordeling.PROPS,
        goederen: {
            type: "RelationshipType",
            id: "00e34821-2c67-45a3-8c0f-2f83d69d7f9e",
            direction: "child",
            multiplicity: "One",
            required: true
        }
    });
    public readonly _props: GoederenRisicobeoordelingProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<GoederenRisicobeoordeling>(this, {
            _props: override,
            goederen: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const risicoTypeFetchContext = getFetchTreeOfRelation(this._fetchContext, Risicobeoordeling.PROPS.risicoType.id, Risicobeoordeling.PROPS.risicoType.direction);
        const automatischeInschattingRisicoklasseFetchContext = getFetchTreeOfRelation(this._fetchContext, Risicobeoordeling.PROPS.automatischeInschattingRisicoklasse.id, Risicobeoordeling.PROPS.automatischeInschattingRisicoklasse.direction);
        const brutoRisicoklasseFetchContext = getFetchTreeOfRelation(this._fetchContext, Risicobeoordeling.PROPS.brutoRisicoklasse.id, Risicobeoordeling.PROPS.brutoRisicoklasse.direction);
        const nettoRisicoklasseFetchContext = getFetchTreeOfRelation(this._fetchContext, Risicobeoordeling.PROPS.nettoRisicoklasse.id, Risicobeoordeling.PROPS.nettoRisicoklasse.direction);
        const risicostrategieFetchContext = getFetchTreeOfRelation(this._fetchContext, Risicobeoordeling.PROPS.risicostrategie.id, Risicobeoordeling.PROPS.risicostrategie.direction);
        const dossierFetchContext = getFetchTreeOfRelation(this._fetchContext, Risicobeoordeling.PROPS.dossier.id, Risicobeoordeling.PROPS.dossier.direction);
        const goederenFetchContext = getFetchTreeOfRelation(this._fetchContext, GoederenRisicobeoordeling.PROPS.goederen.id, GoederenRisicobeoordeling.PROPS.goederen.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get risicoType() {
                try {
                    return getRelatedSingular(entity, risicoTypeFetchContext, Risicobeoordeling.PROPS.risicoType.id, Risicobeoordeling.PROPS.risicoType.direction, Risicobeoordeling.PROPS.risicoType.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get automatischeInschattingRisicoklasse() {
                try {
                    return getRelatedSingular(entity, automatischeInschattingRisicoklasseFetchContext, Risicobeoordeling.PROPS.automatischeInschattingRisicoklasse.id, Risicobeoordeling.PROPS.automatischeInschattingRisicoklasse.direction, Risicobeoordeling.PROPS.automatischeInschattingRisicoklasse.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get brutoRisicoklasse() {
                try {
                    return getRelatedSingular(entity, brutoRisicoklasseFetchContext, Risicobeoordeling.PROPS.brutoRisicoklasse.id, Risicobeoordeling.PROPS.brutoRisicoklasse.direction, Risicobeoordeling.PROPS.brutoRisicoklasse.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get nettoRisicoklasse() {
                try {
                    return getRelatedSingular(entity, nettoRisicoklasseFetchContext, Risicobeoordeling.PROPS.nettoRisicoklasse.id, Risicobeoordeling.PROPS.nettoRisicoklasse.direction, Risicobeoordeling.PROPS.nettoRisicoklasse.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get risicostrategie() {
                try {
                    return getRelatedSingular(entity, risicostrategieFetchContext, Risicobeoordeling.PROPS.risicostrategie.id, Risicobeoordeling.PROPS.risicostrategie.direction, Risicobeoordeling.PROPS.risicostrategie.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get dossier() {
                try {
                    return getRelatedSingular(entity, dossierFetchContext, Risicobeoordeling.PROPS.dossier.id, Risicobeoordeling.PROPS.dossier.direction, Risicobeoordeling.PROPS.dossier.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get toelichtingRisicoklasse() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Risicobeoordeling.PROPS.toelichtingRisicoklasse.id, Risicobeoordeling.PROPS.toelichtingRisicoklasse.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get toelichtingRisicostrategie() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Risicobeoordeling.PROPS.toelichtingRisicostrategie.id, Risicobeoordeling.PROPS.toelichtingRisicostrategie.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get goederen() {
                try {
                    return getRelatedSingular(entity, goederenFetchContext, GoederenRisicobeoordeling.PROPS.goederen.id, GoederenRisicobeoordeling.PROPS.goederen.direction, GoederenRisicobeoordeling.PROPS.goederen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get goederen(): Goederen | undefined {
        const goederenFetchContext = getFetchTreeOfRelation(this._fetchContext, GoederenRisicobeoordeling.PROPS.goederen.id, GoederenRisicobeoordeling.PROPS.goederen.direction);
        if (goederenFetchContext !== undefined) {
            return toTypedEntity(this._props.goederen, this._metadataService, goederenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class RisicoAdresRisicobeoordeling extends Risicobeoordeling {
    public static ID = "4c842ea0-8c37-40e5-ae07-2d9df0f4801d";
    public static PROPS = createProps({
        ...Risicobeoordeling.PROPS,
        ibbRisicoklasse: {
            type: "RelationshipType",
            id: "0e4f5ed0-325c-4a41-b6a6-23fb12c6add4",
            direction: "child",
            multiplicity: "One",
            required: true
        },
        risicoAdres: {
            type: "RelationshipType",
            id: "b787fe77-90cc-4b77-ab77-76b11a2d8558",
            direction: "child",
            multiplicity: "One",
            required: true
        }
    });
    public readonly _props: RisicoAdresRisicobeoordelingProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<RisicoAdresRisicobeoordeling>(this, {
            _props: override,
            ibbRisicoklasse: computed,
            risicoAdres: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const risicoTypeFetchContext = getFetchTreeOfRelation(this._fetchContext, Risicobeoordeling.PROPS.risicoType.id, Risicobeoordeling.PROPS.risicoType.direction);
        const automatischeInschattingRisicoklasseFetchContext = getFetchTreeOfRelation(this._fetchContext, Risicobeoordeling.PROPS.automatischeInschattingRisicoklasse.id, Risicobeoordeling.PROPS.automatischeInschattingRisicoklasse.direction);
        const brutoRisicoklasseFetchContext = getFetchTreeOfRelation(this._fetchContext, Risicobeoordeling.PROPS.brutoRisicoklasse.id, Risicobeoordeling.PROPS.brutoRisicoklasse.direction);
        const nettoRisicoklasseFetchContext = getFetchTreeOfRelation(this._fetchContext, Risicobeoordeling.PROPS.nettoRisicoklasse.id, Risicobeoordeling.PROPS.nettoRisicoklasse.direction);
        const risicostrategieFetchContext = getFetchTreeOfRelation(this._fetchContext, Risicobeoordeling.PROPS.risicostrategie.id, Risicobeoordeling.PROPS.risicostrategie.direction);
        const dossierFetchContext = getFetchTreeOfRelation(this._fetchContext, Risicobeoordeling.PROPS.dossier.id, Risicobeoordeling.PROPS.dossier.direction);
        const ibbRisicoklasseFetchContext = getFetchTreeOfRelation(this._fetchContext, RisicoAdresRisicobeoordeling.PROPS.ibbRisicoklasse.id, RisicoAdresRisicobeoordeling.PROPS.ibbRisicoklasse.direction);
        const risicoAdresFetchContext = getFetchTreeOfRelation(this._fetchContext, RisicoAdresRisicobeoordeling.PROPS.risicoAdres.id, RisicoAdresRisicobeoordeling.PROPS.risicoAdres.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get risicoType() {
                try {
                    return getRelatedSingular(entity, risicoTypeFetchContext, Risicobeoordeling.PROPS.risicoType.id, Risicobeoordeling.PROPS.risicoType.direction, Risicobeoordeling.PROPS.risicoType.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get automatischeInschattingRisicoklasse() {
                try {
                    return getRelatedSingular(entity, automatischeInschattingRisicoklasseFetchContext, Risicobeoordeling.PROPS.automatischeInschattingRisicoklasse.id, Risicobeoordeling.PROPS.automatischeInschattingRisicoklasse.direction, Risicobeoordeling.PROPS.automatischeInschattingRisicoklasse.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get brutoRisicoklasse() {
                try {
                    return getRelatedSingular(entity, brutoRisicoklasseFetchContext, Risicobeoordeling.PROPS.brutoRisicoklasse.id, Risicobeoordeling.PROPS.brutoRisicoklasse.direction, Risicobeoordeling.PROPS.brutoRisicoklasse.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get nettoRisicoklasse() {
                try {
                    return getRelatedSingular(entity, nettoRisicoklasseFetchContext, Risicobeoordeling.PROPS.nettoRisicoklasse.id, Risicobeoordeling.PROPS.nettoRisicoklasse.direction, Risicobeoordeling.PROPS.nettoRisicoklasse.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get risicostrategie() {
                try {
                    return getRelatedSingular(entity, risicostrategieFetchContext, Risicobeoordeling.PROPS.risicostrategie.id, Risicobeoordeling.PROPS.risicostrategie.direction, Risicobeoordeling.PROPS.risicostrategie.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get dossier() {
                try {
                    return getRelatedSingular(entity, dossierFetchContext, Risicobeoordeling.PROPS.dossier.id, Risicobeoordeling.PROPS.dossier.direction, Risicobeoordeling.PROPS.dossier.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get toelichtingRisicoklasse() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Risicobeoordeling.PROPS.toelichtingRisicoklasse.id, Risicobeoordeling.PROPS.toelichtingRisicoklasse.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get toelichtingRisicostrategie() {
                try {
                    const possiblyUndefinedValue = getFieldValue(metadataService, entity, Risicobeoordeling.PROPS.toelichtingRisicostrategie.id, Risicobeoordeling.PROPS.toelichtingRisicostrategie.required);
                    return possiblyUndefinedValue !== undefined ? possiblyUndefinedValue : null;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get ibbRisicoklasse() {
                try {
                    return getRelatedSingular(entity, ibbRisicoklasseFetchContext, RisicoAdresRisicobeoordeling.PROPS.ibbRisicoklasse.id, RisicoAdresRisicobeoordeling.PROPS.ibbRisicoklasse.direction, RisicoAdresRisicobeoordeling.PROPS.ibbRisicoklasse.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get risicoAdres() {
                try {
                    return getRelatedSingular(entity, risicoAdresFetchContext, RisicoAdresRisicobeoordeling.PROPS.risicoAdres.id, RisicoAdresRisicobeoordeling.PROPS.risicoAdres.direction, RisicoAdresRisicobeoordeling.PROPS.risicoAdres.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get ibbRisicoklasse(): RisicoklasseIBB | undefined {
        const ibbRisicoklasseFetchContext = getFetchTreeOfRelation(this._fetchContext, RisicoAdresRisicobeoordeling.PROPS.ibbRisicoklasse.id, RisicoAdresRisicobeoordeling.PROPS.ibbRisicoklasse.direction);
        if (ibbRisicoklasseFetchContext !== undefined) {
            return toTypedEntity(this._props.ibbRisicoklasse, this._metadataService, ibbRisicoklasseFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get risicoAdres(): RisicoAdres | undefined {
        const risicoAdresFetchContext = getFetchTreeOfRelation(this._fetchContext, RisicoAdresRisicobeoordeling.PROPS.risicoAdres.id, RisicoAdresRisicobeoordeling.PROPS.risicoAdres.direction);
        if (risicoAdresFetchContext !== undefined) {
            return toTypedEntity(this._props.risicoAdres, this._metadataService, risicoAdresFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class Risicocategorie extends ObjEntity {
    public static ID = "0d6c1994-53f8-4d4f-8c85-7f2056d76954";
    public static PROPS = createProps({
        ...ObjEntity.PROPS,
        risicoprofielen: {
            type: "RelationshipType",
            id: "1398437e-6027-4597-8063-ef1d4ddf25ac",
            direction: "child",
            multiplicity: "Many",
            required: false
        }
    });
    public readonly _props: RisicocategorieProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Risicocategorie>(this, {
            _props: override,
            risicoprofielen: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const risicoprofielenFetchContext = getFetchTreeOfRelation(this._fetchContext, Risicocategorie.PROPS.risicoprofielen.id, Risicocategorie.PROPS.risicoprofielen.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get risicoprofielen() {
                try {
                    return getRelatedPlural(entity, risicoprofielenFetchContext, Risicocategorie.PROPS.risicoprofielen.id, Risicocategorie.PROPS.risicoprofielen.direction, Risicocategorie.PROPS.risicoprofielen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get risicoprofielen(): Risicoprofiel[] | undefined {
        const risicoprofielenFetchContext = getFetchTreeOfRelation(this._fetchContext, Risicocategorie.PROPS.risicoprofielen.id, Risicocategorie.PROPS.risicoprofielen.direction);
        if (risicoprofielenFetchContext !== undefined) {
            return toTypedEntity(this._props.risicoprofielen, this._metadataService, risicoprofielenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class Risicoprofiel extends ObjEntity {
    public static ID = "606b1da6-d171-4055-b711-6ffd071aec5a";
    public static PROPS = createProps({
        ...ObjEntity.PROPS,
        naam: {
            type: "Field",
            id: "16ab1d90-5fee-4325-8501-aeac1d6440a7",
            required: true
        },
        omschrijving: {
            type: "Field",
            id: "a0488920-6ae9-4c36-964e-1d20b6c707cb",
            required: true
        },
        dekkingen: {
            type: "RelationshipType",
            id: "95e5d8ac-1a8c-4a68-9617-8c8d58dd3154",
            direction: "child",
            multiplicity: "Many",
            required: false
        },
        objectBPTypes: {
            type: "RelationshipType",
            id: "87a183e6-1d1b-4e08-95fa-24afdd06ef8a",
            direction: "child",
            multiplicity: "Many",
            required: false
        },
        controleBeoordelingen: {
            type: "RelationshipType",
            id: "4e709117-1010-4985-a251-f45eedbf55f6",
            direction: "parent",
            multiplicity: "Many",
            required: false
        },
        categorie: {
            type: "RelationshipType",
            id: "1398437e-6027-4597-8063-ef1d4ddf25ac",
            direction: "parent",
            multiplicity: "One",
            required: true
        },
        beoordelingen: {
            type: "RelationshipType",
            id: "5e742014-a993-46d3-8b15-526c2c0b125d",
            direction: "parent",
            multiplicity: "Many",
            required: false
        }
    });
    public readonly _props: RisicoprofielProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Risicoprofiel>(this, {
            _props: override,
            naam: computed,
            omschrijving: computed,
            dekkingen: computed,
            objectBPTypes: computed,
            controleBeoordelingen: computed,
            categorie: computed,
            beoordelingen: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const dekkingenFetchContext = getFetchTreeOfRelation(this._fetchContext, Risicoprofiel.PROPS.dekkingen.id, Risicoprofiel.PROPS.dekkingen.direction);
        const objectBPTypesFetchContext = getFetchTreeOfRelation(this._fetchContext, Risicoprofiel.PROPS.objectBPTypes.id, Risicoprofiel.PROPS.objectBPTypes.direction);
        const controleBeoordelingenFetchContext = getFetchTreeOfRelation(this._fetchContext, Risicoprofiel.PROPS.controleBeoordelingen.id, Risicoprofiel.PROPS.controleBeoordelingen.direction);
        const categorieFetchContext = getFetchTreeOfRelation(this._fetchContext, Risicoprofiel.PROPS.categorie.id, Risicoprofiel.PROPS.categorie.direction);
        const beoordelingenFetchContext = getFetchTreeOfRelation(this._fetchContext, Risicoprofiel.PROPS.beoordelingen.id, Risicoprofiel.PROPS.beoordelingen.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get dekkingen() {
                try {
                    return getRelatedPlural(entity, dekkingenFetchContext, Risicoprofiel.PROPS.dekkingen.id, Risicoprofiel.PROPS.dekkingen.direction, Risicoprofiel.PROPS.dekkingen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get objectBPTypes() {
                try {
                    return getRelatedPlural(entity, objectBPTypesFetchContext, Risicoprofiel.PROPS.objectBPTypes.id, Risicoprofiel.PROPS.objectBPTypes.direction, Risicoprofiel.PROPS.objectBPTypes.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get controleBeoordelingen() {
                try {
                    return getRelatedPlural(entity, controleBeoordelingenFetchContext, Risicoprofiel.PROPS.controleBeoordelingen.id, Risicoprofiel.PROPS.controleBeoordelingen.direction, Risicoprofiel.PROPS.controleBeoordelingen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get categorie() {
                try {
                    return getRelatedSingular(entity, categorieFetchContext, Risicoprofiel.PROPS.categorie.id, Risicoprofiel.PROPS.categorie.direction, Risicoprofiel.PROPS.categorie.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get beoordelingen() {
                try {
                    return getRelatedPlural(entity, beoordelingenFetchContext, Risicoprofiel.PROPS.beoordelingen.id, Risicoprofiel.PROPS.beoordelingen.direction, Risicoprofiel.PROPS.beoordelingen.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get naam() {
                try {
                    return getFieldValue(metadataService, entity, Risicoprofiel.PROPS.naam.id, Risicoprofiel.PROPS.naam.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get omschrijving() {
                try {
                    return getFieldValue(metadataService, entity, Risicoprofiel.PROPS.omschrijving.id, Risicoprofiel.PROPS.omschrijving.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get naam(): string {
        return TextType.getRawText(this._props.naam)!;
    }
    get omschrijving(): string {
        return TextType.getRawText(this._props.omschrijving)!;
    }
    get dekkingen(): DekkingsType[] | undefined {
        const dekkingenFetchContext = getFetchTreeOfRelation(this._fetchContext, Risicoprofiel.PROPS.dekkingen.id, Risicoprofiel.PROPS.dekkingen.direction);
        if (dekkingenFetchContext !== undefined) {
            return toTypedEntity(this._props.dekkingen, this._metadataService, dekkingenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get objectBPTypes(): EntityType[] | undefined {
        const objectBPTypesFetchContext = getFetchTreeOfRelation(this._fetchContext, Risicoprofiel.PROPS.objectBPTypes.id, Risicoprofiel.PROPS.objectBPTypes.direction);
        if (objectBPTypesFetchContext !== undefined) {
            return toTypedEntity(this._props.objectBPTypes, this._metadataService, objectBPTypesFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get controleBeoordelingen(): ControleBeoordeling[] | undefined {
        const controleBeoordelingenFetchContext = getFetchTreeOfRelation(this._fetchContext, Risicoprofiel.PROPS.controleBeoordelingen.id, Risicoprofiel.PROPS.controleBeoordelingen.direction);
        if (controleBeoordelingenFetchContext !== undefined) {
            return toTypedEntity(this._props.controleBeoordelingen, this._metadataService, controleBeoordelingenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get categorie(): Risicocategorie | undefined {
        const categorieFetchContext = getFetchTreeOfRelation(this._fetchContext, Risicoprofiel.PROPS.categorie.id, Risicoprofiel.PROPS.categorie.direction);
        if (categorieFetchContext !== undefined) {
            return toTypedEntity(this._props.categorie, this._metadataService, categorieFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get beoordelingen(): Risicobeoordeling[] | undefined {
        const beoordelingenFetchContext = getFetchTreeOfRelation(this._fetchContext, Risicoprofiel.PROPS.beoordelingen.id, Risicoprofiel.PROPS.beoordelingen.direction);
        if (beoordelingenFetchContext !== undefined) {
            return toTypedEntity(this._props.beoordelingen, this._metadataService, beoordelingenFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export abstract class Trigger extends ObjEntity {
    public static ID = "f046388c-6739-4780-993c-480e1d07ce6d";
    public static PROPS = createProps({
        ...ObjEntity.PROPS,
        automations: {
            type: "RelationshipType",
            id: "a7b219ab-dd2f-4fb7-bac3-9721583ac145",
            direction: "parent",
            multiplicity: "Many",
            required: false
        }
    });
    public readonly _props: TriggerProps;
    constructor(protected metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<Trigger>(this, {
            _props: override,
            automations: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const automationsFetchContext = getFetchTreeOfRelation(this._fetchContext, Trigger.PROPS.automations.id, Trigger.PROPS.automations.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get automations() {
                try {
                    return getRelatedPlural(entity, automationsFetchContext, Trigger.PROPS.automations.id, Trigger.PROPS.automations.direction, Trigger.PROPS.automations.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get automations(): Automation[] | undefined {
        const automationsFetchContext = getFetchTreeOfRelation(this._fetchContext, Trigger.PROPS.automations.id, Trigger.PROPS.automations.direction);
        if (automationsFetchContext !== undefined) {
            return toTypedEntity(this._props.automations, this._metadataService, automationsFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class User extends ObjEntity {
    public static ID = "cbd033ab-0330-41e1-8638-8c27ddd596d8";
    public static PROPS = createProps({
        ...ObjEntity.PROPS,
        ssoId: {
            type: "Field",
            id: "b714a332-2a4b-4b2e-8bde-2426afaed043",
            required: true
        },
        canRead: {
            type: "RelationshipType",
            id: "812c4b51-d85a-44d6-93af-d77968f2c2ce",
            direction: "child",
            multiplicity: "Many",
            required: false
        }
    });
    public readonly _props: UserProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<User>(this, {
            _props: override,
            ssoId: computed,
            canRead: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const canReadFetchContext = getFetchTreeOfRelation(this._fetchContext, User.PROPS.canRead.id, User.PROPS.canRead.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get canRead() {
                try {
                    return getRelatedPlural(entity, canReadFetchContext, User.PROPS.canRead.id, User.PROPS.canRead.direction, User.PROPS.canRead.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get ssoId() {
                try {
                    return getFieldValue(metadataService, entity, User.PROPS.ssoId.id, User.PROPS.ssoId.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get ssoId(): string {
        return TextType.getRawText(this._props.ssoId)!;
    }
    get canRead(): Pack[] | undefined {
        const canReadFetchContext = getFetchTreeOfRelation(this._fetchContext, User.PROPS.canRead.id, User.PROPS.canRead.direction);
        if (canReadFetchContext !== undefined) {
            return toTypedEntity(this._props.canRead, this._metadataService, canReadFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
export class VKRIMaatregelRelevantie extends ObjEntity {
    public static ID = "d6cffe81-8618-4cbf-a31c-094d506f8286";
    public static PROPS = createProps({
        ...ObjEntity.PROPS,
        risicoklasse: {
            type: "RelationshipType",
            id: "821c9d69-918d-482d-9123-840ee867028c",
            direction: "child",
            multiplicity: "One",
            required: true
        },
        maatregelsets: {
            type: "RelationshipType",
            id: "526728f9-20a1-4ef4-a765-f439faeb4f7e",
            direction: "child",
            multiplicity: "Many",
            required: true
        }
    });
    public readonly _props: VKRIMaatregelRelevantieProps;
    constructor(metadataService: MetadataService, entity: Entity, fetchContext: ModelFetchContext, modelMemoizer: ModelMemoizer) {
        super(metadataService, entity, fetchContext, modelMemoizer);
        makeObservable<VKRIMaatregelRelevantie>(this, {
            _props: override,
            risicoklasse: computed,
            maatregelsets: computed
        });
        const packFetchContext = getFetchTreeOfRelation(this._fetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction);
        const risicoklasseFetchContext = getFetchTreeOfRelation(this._fetchContext, VKRIMaatregelRelevantie.PROPS.risicoklasse.id, VKRIMaatregelRelevantie.PROPS.risicoklasse.direction);
        const maatregelsetsFetchContext = getFetchTreeOfRelation(this._fetchContext, VKRIMaatregelRelevantie.PROPS.maatregelsets.id, VKRIMaatregelRelevantie.PROPS.maatregelsets.direction);
        this._props = observable({
            get pack() {
                try {
                    return getRelatedSingular(entity, packFetchContext, ObjEntity.PROPS.pack.id, ObjEntity.PROPS.pack.direction, ObjEntity.PROPS.pack.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get id() {
                try {
                    return getFieldValue(metadataService, entity, ObjEntity.PROPS.id.id, ObjEntity.PROPS.id.required)!;
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get risicoklasse() {
                try {
                    return getRelatedSingular(entity, risicoklasseFetchContext, VKRIMaatregelRelevantie.PROPS.risicoklasse.id, VKRIMaatregelRelevantie.PROPS.risicoklasse.direction, VKRIMaatregelRelevantie.PROPS.risicoklasse.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            },
            get maatregelsets() {
                try {
                    return getRelatedPlural(entity, maatregelsetsFetchContext, VKRIMaatregelRelevantie.PROPS.maatregelsets.id, VKRIMaatregelRelevantie.PROPS.maatregelsets.direction, VKRIMaatregelRelevantie.PROPS.maatregelsets.required);
                }
                catch (error) {
                    console.warn(error);
                    return undefined!;
                }
            }
        });
    }
    get risicoklasse(): Risicoklasse | undefined {
        const risicoklasseFetchContext = getFetchTreeOfRelation(this._fetchContext, VKRIMaatregelRelevantie.PROPS.risicoklasse.id, VKRIMaatregelRelevantie.PROPS.risicoklasse.direction);
        if (risicoklasseFetchContext !== undefined) {
            return toTypedEntity(this._props.risicoklasse, this._metadataService, risicoklasseFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
    get maatregelsets(): VKRIMaatregelset[] | undefined {
        const maatregelsetsFetchContext = getFetchTreeOfRelation(this._fetchContext, VKRIMaatregelRelevantie.PROPS.maatregelsets.id, VKRIMaatregelRelevantie.PROPS.maatregelsets.direction);
        if (maatregelsetsFetchContext !== undefined) {
            return toTypedEntity(this._props.maatregelsets, this._metadataService, maatregelsetsFetchContext, this._modelMemoizer);
        }
        return undefined;
    }
}
QueryFactory.MODEL_DICTIONARY[ObjEntity.ID] = ObjEntity;
QueryFactory.MODEL_DICTIONARY[EntityType.ID] = EntityType;
QueryFactory.INSTANTIABLE_DICTIONARY[EntityType.ID] = EntityType;
QueryFactory.MODEL_DICTIONARY[EntityField.ID] = EntityField;
QueryFactory.INSTANTIABLE_DICTIONARY[EntityField.ID] = EntityField;
QueryFactory.MODEL_DICTIONARY[RelationshipType.ID] = RelationshipType;
QueryFactory.INSTANTIABLE_DICTIONARY[RelationshipType.ID] = RelationshipType;
QueryFactory.MODEL_DICTIONARY[User.ID] = User;
QueryFactory.INSTANTIABLE_DICTIONARY[User.ID] = User;
QueryFactory.MODEL_DICTIONARY[Distributiekanalen.ID] = Distributiekanalen;
QueryFactory.INSTANTIABLE_DICTIONARY[Distributiekanalen.ID] = Distributiekanalen;
QueryFactory.MODEL_DICTIONARY[Klanten.ID] = Klanten;
QueryFactory.INSTANTIABLE_DICTIONARY[Klanten.ID] = Klanten;
QueryFactory.MODEL_DICTIONARY[Electronica.ID] = Electronica;
QueryFactory.INSTANTIABLE_DICTIONARY[Electronica.ID] = Electronica;
QueryFactory.MODEL_DICTIONARY[Inventaris.ID] = Inventaris;
QueryFactory.INSTANTIABLE_DICTIONARY[Inventaris.ID] = Inventaris;
QueryFactory.MODEL_DICTIONARY[Aanhanger.ID] = Aanhanger;
QueryFactory.INSTANTIABLE_DICTIONARY[Aanhanger.ID] = Aanhanger;
QueryFactory.MODEL_DICTIONARY[TegoedenOpBankgirorekeningen.ID] = TegoedenOpBankgirorekeningen;
QueryFactory.INSTANTIABLE_DICTIONARY[TegoedenOpBankgirorekeningen.ID] = TegoedenOpBankgirorekeningen;
QueryFactory.MODEL_DICTIONARY[AutomobielenMetBestuurder.ID] = AutomobielenMetBestuurder;
QueryFactory.MODEL_DICTIONARY[Vliegtuigen.ID] = Vliegtuigen;
QueryFactory.INSTANTIABLE_DICTIONARY[Vliegtuigen.ID] = Vliegtuigen;
QueryFactory.MODEL_DICTIONARY[Auto.ID] = Auto;
QueryFactory.INSTANTIABLE_DICTIONARY[Auto.ID] = Auto;
QueryFactory.MODEL_DICTIONARY[Bouwclaims.ID] = Bouwclaims;
QueryFactory.INSTANTIABLE_DICTIONARY[Bouwclaims.ID] = Bouwclaims;
QueryFactory.MODEL_DICTIONARY[AbstractGebouwen.ID] = AbstractGebouwen;
QueryFactory.MODEL_DICTIONARY[HuurdersInvesteringen.ID] = HuurdersInvesteringen;
QueryFactory.INSTANTIABLE_DICTIONARY[HuurdersInvesteringen.ID] = HuurdersInvesteringen;
QueryFactory.MODEL_DICTIONARY[VastgoedBeleggingen.ID] = VastgoedBeleggingen;
QueryFactory.INSTANTIABLE_DICTIONARY[VastgoedBeleggingen.ID] = VastgoedBeleggingen;
QueryFactory.MODEL_DICTIONARY[Contracten.ID] = Contracten;
QueryFactory.INSTANTIABLE_DICTIONARY[Contracten.ID] = Contracten;
QueryFactory.MODEL_DICTIONARY[Werkmaterieel.ID] = Werkmaterieel;
QueryFactory.INSTANTIABLE_DICTIONARY[Werkmaterieel.ID] = Werkmaterieel;
QueryFactory.MODEL_DICTIONARY[Bus.ID] = Bus;
QueryFactory.INSTANTIABLE_DICTIONARY[Bus.ID] = Bus;
QueryFactory.MODEL_DICTIONARY[Effecten.ID] = Effecten;
QueryFactory.INSTANTIABLE_DICTIONARY[Effecten.ID] = Effecten;
QueryFactory.MODEL_DICTIONARY[Gebouwen.ID] = Gebouwen;
QueryFactory.INSTANTIABLE_DICTIONARY[Gebouwen.ID] = Gebouwen;
QueryFactory.MODEL_DICTIONARY[Vrachtwagen.ID] = Vrachtwagen;
QueryFactory.INSTANTIABLE_DICTIONARY[Vrachtwagen.ID] = Vrachtwagen;
QueryFactory.MODEL_DICTIONARY[Motor.ID] = Motor;
QueryFactory.INSTANTIABLE_DICTIONARY[Motor.ID] = Motor;
QueryFactory.MODEL_DICTIONARY[Schepen.ID] = Schepen;
QueryFactory.INSTANTIABLE_DICTIONARY[Schepen.ID] = Schepen;
QueryFactory.MODEL_DICTIONARY[Kasmiddelen.ID] = Kasmiddelen;
QueryFactory.INSTANTIABLE_DICTIONARY[Kasmiddelen.ID] = Kasmiddelen;
QueryFactory.MODEL_DICTIONARY[Bromfiets.ID] = Bromfiets;
QueryFactory.INSTANTIABLE_DICTIONARY[Bromfiets.ID] = Bromfiets;
QueryFactory.MODEL_DICTIONARY[Fiets.ID] = Fiets;
QueryFactory.INSTANTIABLE_DICTIONARY[Fiets.ID] = Fiets;
QueryFactory.MODEL_DICTIONARY[Tractor.ID] = Tractor;
QueryFactory.INSTANTIABLE_DICTIONARY[Tractor.ID] = Tractor;
QueryFactory.MODEL_DICTIONARY[Perceel.ID] = Perceel;
QueryFactory.INSTANTIABLE_DICTIONARY[Perceel.ID] = Perceel;
QueryFactory.MODEL_DICTIONARY[Groepsvermogen.ID] = Groepsvermogen;
QueryFactory.INSTANTIABLE_DICTIONARY[Groepsvermogen.ID] = Groepsvermogen;
QueryFactory.MODEL_DICTIONARY[SoortElectronischApparatuur.ID] = SoortElectronischApparatuur;
QueryFactory.INSTANTIABLE_DICTIONARY[SoortElectronischApparatuur.ID] = SoortElectronischApparatuur;
QueryFactory.MODEL_DICTIONARY[SoortFiets.ID] = SoortFiets;
QueryFactory.INSTANTIABLE_DICTIONARY[SoortFiets.ID] = SoortFiets;
QueryFactory.MODEL_DICTIONARY[GebruikWerkmaterieel.ID] = GebruikWerkmaterieel;
QueryFactory.INSTANTIABLE_DICTIONARY[GebruikWerkmaterieel.ID] = GebruikWerkmaterieel;
QueryFactory.MODEL_DICTIONARY[Deelnemingen.ID] = Deelnemingen;
QueryFactory.INSTANTIABLE_DICTIONARY[Deelnemingen.ID] = Deelnemingen;
QueryFactory.MODEL_DICTIONARY[DGA.ID] = DGA;
QueryFactory.INSTANTIABLE_DICTIONARY[DGA.ID] = DGA;
QueryFactory.MODEL_DICTIONARY[Management.ID] = Management;
QueryFactory.INSTANTIABLE_DICTIONARY[Management.ID] = Management;
QueryFactory.MODEL_DICTIONARY[Kredieten.ID] = Kredieten;
QueryFactory.INSTANTIABLE_DICTIONARY[Kredieten.ID] = Kredieten;
QueryFactory.MODEL_DICTIONARY[Leveranciers.ID] = Leveranciers;
QueryFactory.INSTANTIABLE_DICTIONARY[Leveranciers.ID] = Leveranciers;
QueryFactory.MODEL_DICTIONARY[Personeel.ID] = Personeel;
QueryFactory.INSTANTIABLE_DICTIONARY[Personeel.ID] = Personeel;
QueryFactory.MODEL_DICTIONARY[VoorraadMeerjarenPlantopstand.ID] = VoorraadMeerjarenPlantopstand;
QueryFactory.INSTANTIABLE_DICTIONARY[VoorraadMeerjarenPlantopstand.ID] = VoorraadMeerjarenPlantopstand;
QueryFactory.MODEL_DICTIONARY[Voorraden.ID] = Voorraden;
QueryFactory.MODEL_DICTIONARY[VoorraadEmballage.ID] = VoorraadEmballage;
QueryFactory.INSTANTIABLE_DICTIONARY[VoorraadEmballage.ID] = VoorraadEmballage;
QueryFactory.MODEL_DICTIONARY[OndersteunendeProcessen.ID] = OndersteunendeProcessen;
QueryFactory.INSTANTIABLE_DICTIONARY[OndersteunendeProcessen.ID] = OndersteunendeProcessen;
QueryFactory.MODEL_DICTIONARY[Kernprocessen.ID] = Kernprocessen;
QueryFactory.INSTANTIABLE_DICTIONARY[Kernprocessen.ID] = Kernprocessen;
QueryFactory.MODEL_DICTIONARY[VoorraadLevendeHave.ID] = VoorraadLevendeHave;
QueryFactory.INSTANTIABLE_DICTIONARY[VoorraadLevendeHave.ID] = VoorraadLevendeHave;
QueryFactory.MODEL_DICTIONARY[VoorraadVastgoed.ID] = VoorraadVastgoed;
QueryFactory.INSTANTIABLE_DICTIONARY[VoorraadVastgoed.ID] = VoorraadVastgoed;
QueryFactory.MODEL_DICTIONARY[Goederen.ID] = Goederen;
QueryFactory.INSTANTIABLE_DICTIONARY[Goederen.ID] = Goederen;
QueryFactory.MODEL_DICTIONARY[SoortenOogstproducten.ID] = SoortenOogstproducten;
QueryFactory.INSTANTIABLE_DICTIONARY[SoortenOogstproducten.ID] = SoortenOogstproducten;
QueryFactory.MODEL_DICTIONARY[OndersteunendeProcessenMasterdata.ID] = OndersteunendeProcessenMasterdata;
QueryFactory.INSTANTIABLE_DICTIONARY[OndersteunendeProcessenMasterdata.ID] = OndersteunendeProcessenMasterdata;
QueryFactory.MODEL_DICTIONARY[KernprocessenMasterdata.ID] = KernprocessenMasterdata;
QueryFactory.INSTANTIABLE_DICTIONARY[KernprocessenMasterdata.ID] = KernprocessenMasterdata;
QueryFactory.MODEL_DICTIONARY[SoortenLevendeHave.ID] = SoortenLevendeHave;
QueryFactory.INSTANTIABLE_DICTIONARY[SoortenLevendeHave.ID] = SoortenLevendeHave;
QueryFactory.MODEL_DICTIONARY[Propositie.ID] = Propositie;
QueryFactory.INSTANTIABLE_DICTIONARY[Propositie.ID] = Propositie;
QueryFactory.MODEL_DICTIONARY[Relatiebeheer.ID] = Relatiebeheer;
QueryFactory.INSTANTIABLE_DICTIONARY[Relatiebeheer.ID] = Relatiebeheer;
QueryFactory.MODEL_DICTIONARY[Offerte.ID] = Offerte;
QueryFactory.INSTANTIABLE_DICTIONARY[Offerte.ID] = Offerte;
QueryFactory.MODEL_DICTIONARY[OfferteBeoordeling.ID] = OfferteBeoordeling;
QueryFactory.INSTANTIABLE_DICTIONARY[OfferteBeoordeling.ID] = OfferteBeoordeling;
QueryFactory.MODEL_DICTIONARY[Betaaltermijn.ID] = Betaaltermijn;
QueryFactory.INSTANTIABLE_DICTIONARY[Betaaltermijn.ID] = Betaaltermijn;
QueryFactory.MODEL_DICTIONARY[Maatschappij.ID] = Maatschappij;
QueryFactory.INSTANTIABLE_DICTIONARY[Maatschappij.ID] = Maatschappij;
QueryFactory.MODEL_DICTIONARY[ProductBemiddelingDossier.ID] = ProductBemiddelingDossier;
QueryFactory.INSTANTIABLE_DICTIONARY[ProductBemiddelingDossier.ID] = ProductBemiddelingDossier;
QueryFactory.MODEL_DICTIONARY[Acceptatiedeelvragen.ID] = Acceptatiedeelvragen;
QueryFactory.MODEL_DICTIONARY[AcceptatievragenDefinitie.ID] = AcceptatievragenDefinitie;
QueryFactory.INSTANTIABLE_DICTIONARY[AcceptatievragenDefinitie.ID] = AcceptatievragenDefinitie;
QueryFactory.MODEL_DICTIONARY[AcceptatiedeelvraagRelevantie.ID] = AcceptatiedeelvraagRelevantie;
QueryFactory.INSTANTIABLE_DICTIONARY[AcceptatiedeelvraagRelevantie.ID] = AcceptatiedeelvraagRelevantie;
QueryFactory.MODEL_DICTIONARY[BrancheBeoordeling.ID] = BrancheBeoordeling;
QueryFactory.INSTANTIABLE_DICTIONARY[BrancheBeoordeling.ID] = BrancheBeoordeling;
QueryFactory.MODEL_DICTIONARY[Branche.ID] = Branche;
QueryFactory.INSTANTIABLE_DICTIONARY[Branche.ID] = Branche;
QueryFactory.MODEL_DICTIONARY[DekkingsEntiteit.ID] = DekkingsEntiteit;
QueryFactory.INSTANTIABLE_DICTIONARY[DekkingsEntiteit.ID] = DekkingsEntiteit;
QueryFactory.MODEL_DICTIONARY[DekkingsType.ID] = DekkingsType;
QueryFactory.INSTANTIABLE_DICTIONARY[DekkingsType.ID] = DekkingsType;
QueryFactory.MODEL_DICTIONARY[ClausuleBeoordeling.ID] = ClausuleBeoordeling;
QueryFactory.INSTANTIABLE_DICTIONARY[ClausuleBeoordeling.ID] = ClausuleBeoordeling;
QueryFactory.MODEL_DICTIONARY[Clausule.ID] = Clausule;
QueryFactory.INSTANTIABLE_DICTIONARY[Clausule.ID] = Clausule;
QueryFactory.MODEL_DICTIONARY[ClausuleRelevantie.ID] = ClausuleRelevantie;
QueryFactory.MODEL_DICTIONARY[ClausuleRelevantieAcceptatiedeelvraag.ID] = ClausuleRelevantieAcceptatiedeelvraag;
QueryFactory.INSTANTIABLE_DICTIONARY[ClausuleRelevantieAcceptatiedeelvraag.ID] = ClausuleRelevantieAcceptatiedeelvraag;
QueryFactory.MODEL_DICTIONARY[ClausuleRelevantieBranche.ID] = ClausuleRelevantieBranche;
QueryFactory.INSTANTIABLE_DICTIONARY[ClausuleRelevantieBranche.ID] = ClausuleRelevantieBranche;
QueryFactory.MODEL_DICTIONARY[ClausuleRelevantieRBH.ID] = ClausuleRelevantieRBH;
QueryFactory.INSTANTIABLE_DICTIONARY[ClausuleRelevantieRBH.ID] = ClausuleRelevantieRBH;
QueryFactory.MODEL_DICTIONARY[ControleBeoordeling.ID] = ControleBeoordeling;
QueryFactory.MODEL_DICTIONARY[Beoordelingsklasse.ID] = Beoordelingsklasse;
QueryFactory.INSTANTIABLE_DICTIONARY[Beoordelingsklasse.ID] = Beoordelingsklasse;
QueryFactory.MODEL_DICTIONARY[Actiepunt.ID] = Actiepunt;
QueryFactory.INSTANTIABLE_DICTIONARY[Actiepunt.ID] = Actiepunt;
QueryFactory.MODEL_DICTIONARY[MaatregelBeoordeling.ID] = MaatregelBeoordeling;
QueryFactory.INSTANTIABLE_DICTIONARY[MaatregelBeoordeling.ID] = MaatregelBeoordeling;
QueryFactory.MODEL_DICTIONARY[MaatregelCategorie.ID] = MaatregelCategorie;
QueryFactory.MODEL_DICTIONARY[MaatregelCategorieVKRI.ID] = MaatregelCategorieVKRI;
QueryFactory.INSTANTIABLE_DICTIONARY[MaatregelCategorieVKRI.ID] = MaatregelCategorieVKRI;
QueryFactory.MODEL_DICTIONARY[MaatregelSubcategorie.ID] = MaatregelSubcategorie;
QueryFactory.MODEL_DICTIONARY[Maatregel.ID] = Maatregel;
QueryFactory.MODEL_DICTIONARY[MaatregelIBB.ID] = MaatregelIBB;
QueryFactory.INSTANTIABLE_DICTIONARY[MaatregelIBB.ID] = MaatregelIBB;
QueryFactory.MODEL_DICTIONARY[MaatregelVKRI.ID] = MaatregelVKRI;
QueryFactory.INSTANTIABLE_DICTIONARY[MaatregelVKRI.ID] = MaatregelVKRI;
QueryFactory.MODEL_DICTIONARY[VKRIMaatregelRelevantie.ID] = VKRIMaatregelRelevantie;
QueryFactory.INSTANTIABLE_DICTIONARY[VKRIMaatregelRelevantie.ID] = VKRIMaatregelRelevantie;
QueryFactory.MODEL_DICTIONARY[VKRIMaatregelset.ID] = VKRIMaatregelset;
QueryFactory.INSTANTIABLE_DICTIONARY[VKRIMaatregelset.ID] = VKRIMaatregelset;
QueryFactory.MODEL_DICTIONARY[RisicoAdviesDossier.ID] = RisicoAdviesDossier;
QueryFactory.INSTANTIABLE_DICTIONARY[RisicoAdviesDossier.ID] = RisicoAdviesDossier;
QueryFactory.MODEL_DICTIONARY[Risicocategorie.ID] = Risicocategorie;
QueryFactory.INSTANTIABLE_DICTIONARY[Risicocategorie.ID] = Risicocategorie;
QueryFactory.MODEL_DICTIONARY[Risicoprofiel.ID] = Risicoprofiel;
QueryFactory.INSTANTIABLE_DICTIONARY[Risicoprofiel.ID] = Risicoprofiel;
QueryFactory.MODEL_DICTIONARY[Risicobeoordeling.ID] = Risicobeoordeling;
QueryFactory.MODEL_DICTIONARY[RisicoAdresRisicobeoordeling.ID] = RisicoAdresRisicobeoordeling;
QueryFactory.INSTANTIABLE_DICTIONARY[RisicoAdresRisicobeoordeling.ID] = RisicoAdresRisicobeoordeling;
QueryFactory.MODEL_DICTIONARY[GoederenRisicobeoordeling.ID] = GoederenRisicobeoordeling;
QueryFactory.INSTANTIABLE_DICTIONARY[GoederenRisicobeoordeling.ID] = GoederenRisicobeoordeling;
QueryFactory.MODEL_DICTIONARY[Risicoklasse.ID] = Risicoklasse;
QueryFactory.INSTANTIABLE_DICTIONARY[Risicoklasse.ID] = Risicoklasse;
QueryFactory.MODEL_DICTIONARY[RisicoklasseIBB.ID] = RisicoklasseIBB;
QueryFactory.INSTANTIABLE_DICTIONARY[RisicoklasseIBB.ID] = RisicoklasseIBB;
QueryFactory.MODEL_DICTIONARY[VKRIGoederenType.ID] = VKRIGoederenType;
QueryFactory.INSTANTIABLE_DICTIONARY[VKRIGoederenType.ID] = VKRIGoederenType;
QueryFactory.MODEL_DICTIONARY[Risicostrategie.ID] = Risicostrategie;
QueryFactory.INSTANTIABLE_DICTIONARY[Risicostrategie.ID] = Risicostrategie;
QueryFactory.MODEL_DICTIONARY[Trigger.ID] = Trigger;
QueryFactory.MODEL_DICTIONARY[Automation.ID] = Automation;
QueryFactory.INSTANTIABLE_DICTIONARY[Automation.ID] = Automation;
QueryFactory.MODEL_DICTIONARY[AutomationResult.ID] = AutomationResult;
QueryFactory.INSTANTIABLE_DICTIONARY[AutomationResult.ID] = AutomationResult;
QueryFactory.MODEL_DICTIONARY[Pack.ID] = Pack;
QueryFactory.MODEL_DICTIONARY[EnvironmentPack.ID] = EnvironmentPack;
QueryFactory.INSTANTIABLE_DICTIONARY[EnvironmentPack.ID] = EnvironmentPack;
QueryFactory.MODEL_DICTIONARY[ModulePack.ID] = ModulePack;
QueryFactory.INSTANTIABLE_DICTIONARY[ModulePack.ID] = ModulePack;
QueryFactory.MODEL_DICTIONARY[SystemPack.ID] = SystemPack;
QueryFactory.INSTANTIABLE_DICTIONARY[SystemPack.ID] = SystemPack;
QueryFactory.MODEL_DICTIONARY[SBI.ID] = SBI;
QueryFactory.INSTANTIABLE_DICTIONARY[SBI.ID] = SBI;
QueryFactory.MODEL_DICTIONARY[RBH.ID] = RBH;
QueryFactory.INSTANTIABLE_DICTIONARY[RBH.ID] = RBH;
QueryFactory.MODEL_DICTIONARY[RisicoAdres.ID] = RisicoAdres;
QueryFactory.INSTANTIABLE_DICTIONARY[RisicoAdres.ID] = RisicoAdres;
QueryFactory.MODEL_DICTIONARY[IBBImpactSchade.ID] = IBBImpactSchade;
QueryFactory.INSTANTIABLE_DICTIONARY[IBBImpactSchade.ID] = IBBImpactSchade;
QueryFactory.MODEL_DICTIONARY[IBBBrandontwikkelsnelheid.ID] = IBBBrandontwikkelsnelheid;
QueryFactory.INSTANTIABLE_DICTIONARY[IBBBrandontwikkelsnelheid.ID] = IBBBrandontwikkelsnelheid;
QueryFactory.MODEL_DICTIONARY[IBBGebruikskarakteristiekGebouw1.ID] = IBBGebruikskarakteristiekGebouw1;
QueryFactory.INSTANTIABLE_DICTIONARY[IBBGebruikskarakteristiekGebouw1.ID] = IBBGebruikskarakteristiekGebouw1;
QueryFactory.MODEL_DICTIONARY[IBBGebruikskarakteristiekGebouw2.ID] = IBBGebruikskarakteristiekGebouw2;
QueryFactory.INSTANTIABLE_DICTIONARY[IBBGebruikskarakteristiekGebouw2.ID] = IBBGebruikskarakteristiekGebouw2;
QueryFactory.MODEL_DICTIONARY[IBBGebruikskarakteristiekPersoonlijkRuimtes.ID] = IBBGebruikskarakteristiekPersoonlijkRuimtes;
QueryFactory.INSTANTIABLE_DICTIONARY[IBBGebruikskarakteristiekPersoonlijkRuimtes.ID] = IBBGebruikskarakteristiekPersoonlijkRuimtes;
QueryFactory.MODEL_DICTIONARY[BusinessCanvas.ID] = BusinessCanvas;
QueryFactory.INSTANTIABLE_DICTIONARY[BusinessCanvas.ID] = BusinessCanvas;
QueryFactory.MODEL_DICTIONARY[ObjectBP.ID] = ObjectBP;
QueryFactory.MODEL_DICTIONARY[BusinessClient.ID] = BusinessClient;
QueryFactory.INSTANTIABLE_DICTIONARY[BusinessClient.ID] = BusinessClient;
QueryFactory.MODEL_DICTIONARY[KVKRelation.ID] = KVKRelation;
QueryFactory.MODEL_DICTIONARY[Organization.ID] = Organization;
QueryFactory.INSTANTIABLE_DICTIONARY[Organization.ID] = Organization;
QueryFactory.MODEL_DICTIONARY[Concernrelaties.ID] = Concernrelaties;
QueryFactory.INSTANTIABLE_DICTIONARY[Concernrelaties.ID] = Concernrelaties;
QueryFactory.MODEL_DICTIONARY[GroupMember.ID] = GroupMember;
QueryFactory.INSTANTIABLE_DICTIONARY[GroupMember.ID] = GroupMember;
QueryFactory.MODEL_DICTIONARY[Person.ID] = Person;
QueryFactory.INSTANTIABLE_DICTIONARY[Person.ID] = Person;
QueryFactory.MODEL_DICTIONARY[Dossier.ID] = Dossier;
QueryFactory.MODEL_DICTIONARY[Scope.ID] = Scope;
QueryFactory.INSTANTIABLE_DICTIONARY[Scope.ID] = Scope;
QueryFactory.MODEL_DICTIONARY[AdviesStappen.ID] = AdviesStappen;
QueryFactory.INSTANTIABLE_DICTIONARY[AdviesStappen.ID] = AdviesStappen;
QueryFactory.MODEL_DICTIONARY[DossierStatus.ID] = DossierStatus;
QueryFactory.INSTANTIABLE_DICTIONARY[DossierStatus.ID] = DossierStatus;
QueryFactory.MODEL_DICTIONARY[PrijsAfspraak.ID] = PrijsAfspraak;
QueryFactory.MODEL_DICTIONARY[Nacalculatie.ID] = Nacalculatie;
QueryFactory.INSTANTIABLE_DICTIONARY[Nacalculatie.ID] = Nacalculatie;
QueryFactory.MODEL_DICTIONARY[NietFactureerbaar.ID] = NietFactureerbaar;
QueryFactory.INSTANTIABLE_DICTIONARY[NietFactureerbaar.ID] = NietFactureerbaar;
QueryFactory.MODEL_DICTIONARY[VastePrijs.ID] = VastePrijs;
QueryFactory.INSTANTIABLE_DICTIONARY[VastePrijs.ID] = VastePrijs;
QueryFactory.MODEL_DICTIONARY[Datastore.ID] = Datastore;
QueryFactory.INSTANTIABLE_DICTIONARY[Datastore.ID] = Datastore;
QueryFactory.MODEL_DICTIONARY[Polis.ID] = Polis;
QueryFactory.INSTANTIABLE_DICTIONARY[Polis.ID] = Polis;
QueryFactory.MODEL_DICTIONARY[Pakket.ID] = Pakket;
QueryFactory.INSTANTIABLE_DICTIONARY[Pakket.ID] = Pakket;
QueryFactory.MODEL_DICTIONARY[Deponeringen.ID] = Deponeringen;
QueryFactory.INSTANTIABLE_DICTIONARY[Deponeringen.ID] = Deponeringen;
QueryFactory.MODEL_DICTIONARY[Balans.ID] = Balans;
QueryFactory.INSTANTIABLE_DICTIONARY[Balans.ID] = Balans;
QueryFactory.MODEL_DICTIONARY[VerliesEnWinstrekening.ID] = VerliesEnWinstrekening;
QueryFactory.INSTANTIABLE_DICTIONARY[VerliesEnWinstrekening.ID] = VerliesEnWinstrekening;
QueryFactory.MODEL_DICTIONARY[Kengetallen.ID] = Kengetallen;
QueryFactory.INSTANTIABLE_DICTIONARY[Kengetallen.ID] = Kengetallen;