import { LinearProgress } from '@material-ui/core';
import { CSSProperties, default as React, FC, useEffect } from 'react';

const DELAY = 1000;

interface LazyComponentLoaderProps
{
	className?: string
}

export const LazyComponentLoader: FC<LazyComponentLoaderProps> =
	(props) =>
	{
		const [appear, setAppear] = React.useState(false);
		const timerRef = React.useRef<NodeJS.Timeout>();

		useEffect(() =>
		{
			timerRef.current = setTimeout(() =>
			{
				setAppear(true);
			}, DELAY);

			return () => clearTimeout(timerRef.current!);
		}, []);

		const style: CSSProperties = {};

		if (!appear)
			style.opacity = 0;

		return <LinearProgress
			color="secondary"
			{...props}
			style={style}
		/>;
	};