import { Card, CardContent, CardHeader, List, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { FC, useMemo } from 'react';
import { EntityType } from '../../../../../../__generated__/ygg/model/modelClasses';
import { getName } from '../../../../risico-analyse/data/RiscoBeoordelingItem';
import { getCanvasItemBranches } from '../../../../verzekeringen/data/getCanvastItemBranches';
import { RisicoAnalyseSelection } from '../../../DossierRoot';
import { BrancheBeoordelingItem } from './BrancheBeoordelingItem';

const useStyles = makeStyles((theme: Theme) =>
	({
		header: {
			padding: theme.spacing(1),
			background: theme.palette.secondary.lightest,
			color: theme.palette.secondary.lightestContrastText,
		},
		content: {
			display: 'flex',
			flexDirection: 'column',
			rowGap: theme.spacing(1),
		},
		list: {
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing(2),
		},
	}),
);

interface RisicoAnalysePanelProps
{
	objectTypeDictionary: Record<string, EntityType>
	selectedItem: RisicoAnalyseSelection['item']
}

export const RisicoAnalysePanel: FC<RisicoAnalysePanelProps> =
	({
		objectTypeDictionary,
		selectedItem: {risicobeoordelingItem, canvasItem},
	}) =>
	{
		const classes = useStyles();
		
		const branches = useMemo(() => getCanvasItemBranches(risicobeoordelingItem.beoordeling.risicoType!, canvasItem, objectTypeDictionary), [canvasItem, objectTypeDictionary, risicobeoordelingItem.beoordeling]);

		return <Card>
			<CardHeader
				className={classes.header}
				title={<Typography variant="h6">
					{getName(risicobeoordelingItem)}
				</Typography>}/>
			<CardContent className={classes.content}>
				<List className={classes.list}>
					{
						branches.map((branche, index) =>
							<BrancheBeoordelingItem
								key={index}
								branche={branche}
							/>
						)
					}
					{
						branches.length === 0 &&
							<Typography>Geen relevante verzekeringen gevonden</Typography>
					}
				</List>
			</CardContent>
		</Card>;
	};