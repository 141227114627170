import {
	createStyles,
	IconButton as MUIIconButton,
	IconButtonProps as MUIIconButtonProps,
	makeStyles,
	Theme,
	Tooltip
} from '@material-ui/core';
import { ExtendButtonBase } from '@material-ui/core/ButtonBase';
import { IconButtonTypeMap } from '@material-ui/core/IconButton/IconButton';
import capitalize from '@material-ui/core/utils/capitalize';
import clsx from 'clsx';
import React from 'react';
import { ButtonLoader } from './ButtonLoader';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		loading: {
			'&$disabled': {
				color: theme.palette.action.active,
			},
		},
		loadingInherit: {
			'&$disabled': {
				color: `inherit`,
			},
		},
		loadingPrimary: {
			'&$disabled': {
				color: theme.palette.primary.main,
			},
		},
		loadingSecondary: {
			'&$disabled': {
				color: theme.palette.secondary.main,
			},
		},
		disabled: {},
	}),
);

interface ExtraProps
{
	loading?: boolean
	title?: React.ReactNode
}

export type IconButtonProps<C extends React.ElementType> = Omit<MUIIconButtonProps<C, {component?: C}>, 'title'> & ExtraProps

export const IconButton = React.forwardRef<any, IconButtonProps<any>>(
	({
		 color = 'default',
		 size = 'medium',
		 className,
		 disabled = false,
		 loading = false,
		 title,
		 children,
		 ...other
	 }, ref) =>
	{
		const classes = useStyles();

		const button = <MUIIconButton ref={ref} color={color} size={size} className={clsx(
			{
				[classes.loading]: loading,
				[(classes as any)[`loading${capitalize(color)}`]]: loading && color !== 'default',
			},
			title ? undefined : className,
		)} classes={{disabled: classes.disabled}} disabled={loading || disabled} {...other}>
			<ButtonLoader loading={loading} size={size}>
				{children}
			</ButtonLoader>
		</MUIIconButton>;

		if (title)
		{
			return <Tooltip title={title}>
					<span className={className}>
						{button}
					</span>
			</Tooltip>;
		}
		else
		{
			return button;
		}
	}) as ExtendButtonBase<IconButtonTypeMap<ExtraProps>>;