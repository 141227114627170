import { Box, ListItem, Radio } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC, useContext } from 'react';
import { Branche, BrancheBeoordeling } from '../../../../../../__generated__/ygg/model/modelClasses';
import { useComputed } from '../../../../../../mobx/useComputed';
import { ValueView } from '../../../../../../value/view/ValueView';
import {
	ControlebeoordelingRadio,
	ValueOptions
} from '../../../../risico-analyse/components/controlebeoordeling/ControlebeoordelingRadio';
import { DossierContextRef } from '../../../data/DossierContext';

const useStyles = makeStyles((theme: Theme) =>
	({
		root: {
			padding: 0,
			display: 'flex',
		},
		container: {
			padding: theme.spacing(1),
			display: 'flex',
			flexGrow: 1,
			background: theme.palette.background.semiLight2,
			borderRadius: theme.shape.borderRadius,
		},
		input: {
			pointerEvents: 'none',
		},
	}),
);

interface BrancheBeoordelingItemProps
{
	branche: Branche
}

export const BrancheBeoordelingItem: FC<BrancheBeoordelingItemProps> =
	({
		branche,
	}) =>
	{
		const classes = useStyles();
		
		const dossier = useContext(DossierContextRef);

		const beoordeling = useComputed<BrancheBeoordeling | undefined>(() =>
		{
			return dossier.controleBeoordelingen!
				.filter((beoordeling): beoordeling is BrancheBeoordeling => beoordeling instanceof BrancheBeoordeling)
				.find(beoordeling =>
					beoordeling.branche!.id === branche.id
				);
		}, [branche.id, dossier]);

		const value = useComputed<ValueOptions | undefined>(() =>
		{
			return beoordeling?.beoordelingsklasse!.id as ValueOptions | undefined;
		}, [beoordeling]);

		return <ListItem
			className={classes.root}
		>
			{
				value ?
				<ControlebeoordelingRadio
					className={classes.input}
					value={value}
					checked
				/>
					:
					<Radio
						className={classes.input}
						checked={false}
					/>
			}
			<Box
				className={classes.container}
			>
				<ValueView color="textSecondary" value={branche.entity} />
			</Box>
		</ListItem>;
	};