import { Box, Card, CardActionArea, CardHeader, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ChevronRight } from '@material-ui/icons';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { BusinessCanvas, Dossier } from '../../../../../__generated__/ygg/model/modelClasses';
import { useComputed } from '../../../../../mobx/useComputed';
import { ACCEPTATIE_STEP, ADVIES_STEP, UITVRAAG_STEP } from '../../../../product/dossier/data/DossierStep';
import {
	BEDRIJFSPROFIEL_STEP, INVENTARISATIEVRAGEN_STEP,
	ORGANISATIE_STEP,
	RAPPORTAGE_STEP,
	RISICO_ANALYSE_STEP,
	VERZEKERINGEN_STEP
} from '../../data/DossierStep';
import { BedrijfsprofielCardContent } from './BedrijfsprofielCardContent';

const useStyles = makeStyles((theme: Theme) =>
	({
		root: {
			display: 'flex',
			flexDirection: 'column',
			flexBasis: '50%',
			background: theme.palette.background.semiLight,
		},
		header: {
			background: theme.palette.primary.main,
			color: theme.palette.primary.contrastText,
		},
		headerDisabled: {
			background: '#dcdcdc',
			color: '#ababab',
		},
		titleContainer: {
			display: 'flex',
		},
		title: {
			textTransform: 'uppercase',
			fontWeight: theme.typography.fontWeightMedium,
		},
		icon: {
			marginInlineStart: 'auto',
		},
		help: {
			marginInlineStart: theme.spacing(1),
			cursor: 'pointer',
		},
		content: {
			flexGrow: 1,
		},
	}),
);

interface BedrijfsprofielCardProps
{
	dossier: Dossier
	categories: BusinessCanvas[]
	selectedItem?: BusinessCanvas
	onSelectItem: (businessCanvasItem: BusinessCanvas) => void
	onPositionChange: (position: number) => void
}

export const BedrijfsprofielCard: FC<BedrijfsprofielCardProps> =
	({
		dossier,

		...other
	}) =>
	{
		const classes = useStyles();

		const disabled = useComputed(() =>
		{
			switch (dossier.huidigeStap!.id)
			{
				case RAPPORTAGE_STEP:
				case VERZEKERINGEN_STEP:
				case RISICO_ANALYSE_STEP:
				case INVENTARISATIEVRAGEN_STEP:
				case BEDRIJFSPROFIEL_STEP:
				case ADVIES_STEP:
				case UITVRAAG_STEP:
				case ACCEPTATIE_STEP:
					return false;
				case ORGANISATIE_STEP:
					return true;
				default:
					return true;
			}
		}, [dossier]);

		return <Card className={classes.root}>
			<CardActionArea
				component={Link}
				to={`./bedrijfsprofiel?d=${dossier.id}`}
				disabled={disabled}
			>
			<CardHeader
				className={disabled ? classes.headerDisabled : classes.header}
				disableTypography
				title={
					<Box className={classes.titleContainer}>
						<Typography
							className={classes.title}
						>
							Bedrijfsprofiel
						</Typography>
						<ChevronRight
							className={classes.icon}
						/>
					</Box>
				}
			/>
			</CardActionArea>
			{
				!disabled &&
				<>
					<BedrijfsprofielCardContent
						className={classes.content}
						{...other}
					/>
				</>
			}
		</Card>;
	};