import { Dialog as MUIDialog, DialogContentText, DialogTitle, ThemeProvider } from '@material-ui/core';
import { DialogProps as MUIDialogProps } from '@material-ui/core/Dialog/Dialog';
import React, { FC, useCallback, useContext } from 'react';
import { theme } from '../../Theme';
import { DialogActionContextRef } from './DialogActionContext';
import { DialogContent } from './DialogContent';
import { DialogContextRef } from './DialogContext';

export type DialogCloseReason = 'backdropClick' | 'escapeKeyDown' | 'buttonClick';

export interface DialogProps extends Omit<MUIDialogProps, 'title' | 'onClose' | 'children'>
{
	title: React.ReactNode
	text?: string
	onClose: (reason: DialogCloseReason) => void
	classes?: MUIDialogProps['classes'] & Partial<{title: string}>
}

export const Dialog: FC<DialogProps> =
	({
		title,
		text,
		onClose,
		children,
		classes: {title: titleClassName, ...classes} = {},
		...other
	}) =>
	{
		const isInDialog = useContext(DialogContextRef);

		const handleClose = useCallback((event?: {}, reason: DialogCloseReason = 'buttonClick') =>
		{
			onClose(reason);
		}, [onClose]);

		return <ThemeProvider theme={theme}>
			<MUIDialog
				onClose={handleClose}
				disableScrollLock
				hideBackdrop={isInDialog}
				classes={classes}
				{...other}
				aria-labelledby="alert-dialog-title"
				aria-describedby={text !== undefined ? 'alert-dialog-description' : undefined}
			>
				<DialogContextRef.Provider value={true}>
					<DialogActionContextRef.Provider value={handleClose}>
						{
							title != null &&
							<DialogTitle
								id="alert-dialog-title"
								disableTypography={typeof title !== 'string'}
								className={titleClassName}
							>
								{title}
							</DialogTitle>
						}
						{
							text
								?
								<DialogContent>
									<DialogContentText id="alert-dialog-description">
										{text}
									</DialogContentText>
								</DialogContent>
								:
								children
						}
					</DialogActionContextRef.Provider>
				</DialogContextRef.Provider>
			</MUIDialog>
		</ThemeProvider>;
	};