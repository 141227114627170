import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography} from "@material-ui/core";
import {WarningOutlined, CloseOutlined} from "@material-ui/icons";
import React, {FC} from "react";
import {theme} from "../../../Theme";
import {makeStyles} from "@material-ui/core/styles";

interface ConfirmDialogProps {
    title: string,
    content: string,
    open: boolean,
    confirmText?: string,
    cancelText?: string,
    onConfirm: () => void,
    onCancel: () => void,
    onClose: () => void
}

const useStyles = makeStyles((theme) => ({
    paper: {
        minHeight: 0,
        border: `3px solid ${theme.palette.warning.main}`
    }
}))

export const ConfirmDialog: FC<ConfirmDialogProps> = (
    {
        title,
        content,
        open,
        confirmText = 'Bevestigen',
        cancelText = 'Annuleren',
        onConfirm,
        onCancel,
        onClose
    }
) => {

    const classes = useStyles();

    return <Dialog
        classes={{paper: classes.paper}}
        onClose={onClose}
        open={open}
    >
        <DialogTitle>
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <div style={{
                    display: "flex",
                    alignItems: "flex-end"
                }}>
                    <WarningOutlined style={{color: theme.palette.warning.main, marginRight: "10px"}} fontSize={'large'}/> {title}
                </div>
                <IconButton onClick={onClose}>
                    <CloseOutlined />
                </IconButton>
            </div>
        </DialogTitle>

        <DialogContent>
            <Typography
                paragraph
            >
                {content}
            </Typography>
        </DialogContent>

        <DialogActions>
            <Button onClick={onCancel} style={{border: `1px solid ${theme.palette.warning.main}`, color: theme.palette.warning.main}} variant="outlined"
                    autoFocus>{cancelText}</Button>
            <Button onClick={onConfirm} style={{color: theme.palette.primary.contrastText, backgroundColor: theme.palette.warning.main}}>{confirmText}</Button>
        </DialogActions>

    </Dialog>
}
