import { Risicoklasse } from '../../../../__generated__/ygg/model/modelClasses';

export function getAverageScore(risicoklasses: Risicoklasse[]): number
{
	const sum = risicoklasses.reduce((result, current) =>
	{
		return result + parseInt(current.value!);
	}, 0);

	if (risicoklasses.length === 0)
		return 0;
	else
		return sum / risicoklasses.length;
}