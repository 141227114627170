import { IllegalStateException } from '@intentic/yggdrasil-rotr';
import React, { FC, useContext } from 'react';
import { OrganizationCard } from '../../../../bedrijfsprofiel/components/panel/organization/OrganizationCard';
import { ClientContextRef } from '../../../../bedrijfsprofiel/data/ClientContext';
import { OrganizationSelection } from '../../../DossierRoot';
import { BalanceList } from './BalanceList';
import { PolicyList } from './PolicyList';

interface OrganizationPanelProps
{
	selectedItem: OrganizationSelection['item']
}

export const OrganizationPanel: FC<OrganizationPanelProps> =
	({
		selectedItem,
	}) =>
	{
		const client = useContext(ClientContextRef);

		switch (selectedItem)
		{
			case 'Details':
			case 'Risico-Adressen':
				return <OrganizationCard
					organization={client.organization!}
				/>;
			case 'Financiën':
				return <BalanceList/>;
			case 'Polissen':
				return <PolicyList/>;
			default:
				throw new IllegalStateException(`Unknown item type ${selectedItem}`)
		}
	};