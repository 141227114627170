import { List } from '@material-ui/core';
import { Apps, BarChart, BookmarkBorder, Business, DonutSmall, ListAltOutlined, VpnKey } from '@material-ui/icons';
import React, {FC, useContext, useState} from 'react';
import { DossierDrawerProps } from '../DossierDrawerList';
import { RisicoAdviesDossierAppDrawerItem } from './RisicoAdviesDossierAppDrawerItem';
import {ConfirmDialog} from "../../../generic/Dialog/Confirm/ConfirmDialog";
import {DossierContextRef} from "../../data/DossierContext";
import {BASIS_SCOPE_ID} from "../../../pages/risico/dossier/DossierRoot";

export const RisicoAdviesDossierDrawerList: FC<DossierDrawerProps> =
	({
		selectedButton,
	}) =>
	{
		const dossier = useContext(DossierContextRef);

		const [open, toggleConfirmDialog] = useState(false)
		const [actions, setConfirmDialogActions] = useState({
			onConfirm: () => {},
			onCancel: () => {}
		})

		const onClose = () => {
			toggleConfirmDialog(false);
			setConfirmDialogActions({
				onConfirm: () => {},
				onCancel: () => {},
			})
		}

		return <>
			<ConfirmDialog
				onConfirm={actions.onConfirm}
				onCancel={actions.onCancel}
				onClose={onClose}
				open={open}
				title={'Waarschuwing'}
				content={'U wil graag een stap terug gaan in het werkprogramma. Alle data van de stappen die u terug gaat, gaat verloren. Weet u zeker dat u door wil gaan?'}
			/>
			<List style={{padding: '0'}}>
				<RisicoAdviesDossierAppDrawerItem
					item='dossier'
					name="Dossier"
					selected={selectedButton === 'dossier'}
					Icon={BookmarkBorder}
				/>
				<RisicoAdviesDossierAppDrawerItem
					item='organisatie/'
					name="Organisatie"
					setConfirmDialogActions={setConfirmDialogActions}
					toggleConfirmDialog={toggleConfirmDialog}
					selected={selectedButton === 'organisatie'}
					Icon={Business}
				/>
				<RisicoAdviesDossierAppDrawerItem
					item='bedrijfsprofiel'
					name="Bedrijfsprofiel"
					setConfirmDialogActions={setConfirmDialogActions}
					toggleConfirmDialog={toggleConfirmDialog}
					selected={selectedButton === 'bedrijfsprofiel'}
					Icon={Apps}
				/>
				{
					dossier?.scope!.id === BASIS_SCOPE_ID && <RisicoAdviesDossierAppDrawerItem
						item='inventarisatievragen'
						name="Inventarisatievragen"
						setConfirmDialogActions={setConfirmDialogActions}
						toggleConfirmDialog={toggleConfirmDialog}
						selected={selectedButton === 'inventarisatievragen'}
						Icon={ListAltOutlined}
					/>
				}

				{
					dossier?.scope!.id === BASIS_SCOPE_ID && <RisicoAdviesDossierAppDrawerItem
						item='risico-analyse'
						name="Risico-analyse"
						setConfirmDialogActions={setConfirmDialogActions}
						toggleConfirmDialog={toggleConfirmDialog}
						selected={selectedButton === 'risico-analyse'}
						Icon={BarChart}
					/>
				}
				<RisicoAdviesDossierAppDrawerItem
					item='verzekeringen'
					name="Verzekeringen"
					setConfirmDialogActions={setConfirmDialogActions}
					toggleConfirmDialog={toggleConfirmDialog}
					selected={selectedButton === 'verzekeringen'}
					Icon={VpnKey}
				/>
				<RisicoAdviesDossierAppDrawerItem
					item='rapportage'
					name="Rapportage"
					setConfirmDialogActions={setConfirmDialogActions}
					toggleConfirmDialog={toggleConfirmDialog}
					selected={selectedButton === 'rapportage'}
					Icon={DonutSmall}
				/>
			</List>
		</>;
	};