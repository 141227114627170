import {List} from '@material-ui/core';
import {
	Apps,
	BookmarkBorder,
	Business,
	Create,
	DonutSmall,
	ListAltOutlined,
	QuestionAnswer,
} from '@material-ui/icons';
import React, {FC, useState} from 'react';
import { DossierDrawerProps } from '../DossierDrawerList';
import { ProductBemiddelingDossierAppDrawerItem } from './ProductBemiddelingDossierAppDrawerItem';
import {ConfirmDialog} from "../../../generic/Dialog/Confirm/ConfirmDialog";

export const ProductBemiddelingDossierDrawerList: FC<DossierDrawerProps> =
	({
		selectedButton,
	}) =>
	{
		const [open, toggleConfirmDialog] = useState(false)
		const [actions, setConfirmDialogActions] = useState({
			onConfirm: () => {},
			onCancel: () => {}
		})

        const onClose = () => {
            toggleConfirmDialog(false);
            setConfirmDialogActions({
                onConfirm: () => {},
                onCancel: () => {},
            })
        }

		return <>
			<ConfirmDialog
				onConfirm={actions.onConfirm}
				onCancel={actions.onCancel}
				onClose={onClose}
				open={open}
				title={'Waarschuwing'}
				content={'U wil graag een stap terug gaan in het werkprogramma. Alle data van de stappen die u terug gaat, gaat verloren. Weet u zeker dat u door wil gaan?'}
			/>
			<List style={{padding: '0'}}>
				<ProductBemiddelingDossierAppDrawerItem
					item='dossier'
					name="Dossier"
					selected={selectedButton === 'dossier'}
					Icon={BookmarkBorder}
				/>
				<ProductBemiddelingDossierAppDrawerItem
					item='organisatie/'
					name="Organisatie"
					toggleConfirmDialog={toggleConfirmDialog}
					setConfirmDialogActions={setConfirmDialogActions}
					selected={selectedButton === 'organisatie'}
					Icon={Business}
				/>
				<ProductBemiddelingDossierAppDrawerItem
					item='bedrijfsprofiel'
					name="Bedrijfsprofiel"
					toggleConfirmDialog={toggleConfirmDialog}
					setConfirmDialogActions={setConfirmDialogActions}
					selected={selectedButton === 'bedrijfsprofiel'}
					Icon={Apps}
				/>
				<ProductBemiddelingDossierAppDrawerItem
					item='acceptatie'
					name="Acceptatie"
					toggleConfirmDialog={toggleConfirmDialog}
					setConfirmDialogActions={setConfirmDialogActions}
					selected={selectedButton === 'acceptatie'}
					Icon={Create}
				/>
				<ProductBemiddelingDossierAppDrawerItem
					item='uitvraag'
					name="Uitvraag"
					toggleConfirmDialog={toggleConfirmDialog}
					setConfirmDialogActions={setConfirmDialogActions}
					selected={selectedButton === 'uitvraag'}
					Icon={ListAltOutlined}
				/>
				<ProductBemiddelingDossierAppDrawerItem
					item='advies'
					name="Advies"
					toggleConfirmDialog={toggleConfirmDialog}
					setConfirmDialogActions={setConfirmDialogActions}
					selected={selectedButton === 'advies'}
					Icon={QuestionAnswer}
				/>
				<ProductBemiddelingDossierAppDrawerItem
					item='rapportage'
					name="Rapportage"
					toggleConfirmDialog={toggleConfirmDialog}
					setConfirmDialogActions={setConfirmDialogActions}
					selected={selectedButton === 'rapportage'}
					Icon={DonutSmall}
				/>
			</List>
		</>;
	};