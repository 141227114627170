import { LinearProgress } from '@material-ui/core';
import { default as React, FC } from 'react';

interface InstantComponentLoaderProps
{
	className?: string
}

export const InstantComponentLoader: FC<InstantComponentLoaderProps> = (props) =>
{
	return <LinearProgress
		color="secondary"
		{...props}
	/>;
};