import { Divider, Drawer, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { ChevronLeft, ChevronRight, ExitToApp, Help } from '@material-ui/icons';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import clsx from 'clsx';
import React, { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useRouteMatch } from "react-router-dom";
import { IconButton } from "../generic/Button/IconButton";
import { RootContextRef } from '../RootContext';
import { useAppDrawerItemStyles } from './AppDrawerItem';
import { RequireDossier } from "./data/DossierContext";
import { DossierDrawerList } from './dossier/DossierDrawerList';
import { SimpleAppDrawerItem } from "./SimpleAppDrawerItem";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
	({
		drawer: {
			width: drawerWidth,
			flexShrink: 0,
			whiteSpace: 'nowrap',
		},
		paper: {
			background: theme.palette.background.semiLight,
		},
		drawerOpen: {
			width: drawerWidth,
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
		},
		drawerClose: {
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			overflowX: 'hidden',
			width: theme.spacing(7) + 1,
			'& $toolbar': {
				justifyContent: 'flex-end',
			},
			'& $logo': {
				display: 'none',
			}
		},
		toolbar: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			padding: theme.spacing(0, 0.5),
			...theme.mixins.toolbar,
		},
		divider: {
			marginTop: 'auto',
		},
		logo: {
			height: theme.spacing(4),
			paddingLeft: theme.spacing(1.5),
		},
		button: {
			color: theme.palette.primary.darkest,
		},
		list: {
			padding: '0',
		},
	}),
);

export const AppDrawer: FC =
	() =>
	{
		const classes = useStyles();
		const theme = useTheme();
		
		const {authenticationService} = useContext(RootContextRef);

		const appDrawerItemClasses = useAppDrawerItemStyles();

		const listItemClasses = useMemo(() => ({
			root: appDrawerItemClasses.root,
			selected: appDrawerItemClasses.selected,
			focusVisible: appDrawerItemClasses.focusVisible
		}), [appDrawerItemClasses.focusVisible, appDrawerItemClasses.root, appDrawerItemClasses.selected]);

		const [open, setOpen] = useState(false);

		const toggleDrawer = useCallback(() =>
		{
			setOpen(open => !open);
		}, []);

		const selectedButton = useRouteMatch<{ slug: string}>('/:slug*')?.params.slug ?? '';
		
		const handleLogout = useCallback(() => 
		{
			authenticationService.logout();
		}, [authenticationService]);

		return <Drawer
			variant="permanent"
			className={clsx(classes.drawer, {
				[classes.drawerOpen]: open,
				[classes.drawerClose]: !open,
			})}
			classes={{
				paper: clsx(classes.paper, {
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open,
				}),
			}}
		>
			<div className={classes.toolbar}>
				<img src="/CProfile_Mono_Black.png" alt="C-Profile" className={classes.logo}/>
				<IconButton className={classes.button} onClick={toggleDrawer}>
					{theme.direction === 'rtl' === open ? <ChevronRight/> : <ChevronLeft/>}
				</IconButton>
			</div>
			<Divider/>
			<RequireDossier>
				<List className={classes.list}>
					<SimpleAppDrawerItem
						item='organisatie/home'
						name="Home"
						selected={selectedButton === 'organisatie/home'}
						Icon={ViewHeadlineIcon}
					/>
					<DossierDrawerList/>
				</List>
				<Divider className={classes.divider}/>
				<List>
					<SimpleAppDrawerItem
						item='help'
						name="Help"
						selected={selectedButton === 'help'}
						Icon={Help}
					/>
					<ListItem
						classes={listItemClasses}
						button
						onClick={handleLogout}
					>
						<ListItemIcon className={appDrawerItemClasses.icon}><ExitToApp/></ListItemIcon>
						<ListItemText classes={{primary: appDrawerItemClasses.text}} primary="Uitloggen"/>
					</ListItem>
				</List>
			</RequireDossier>
		</Drawer>;
	};