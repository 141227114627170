import { CardContent, List } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC } from 'react';
import { BusinessCanvas } from '../../../../../__generated__/ygg/model/modelClasses';
import { BusinessCanvasItem } from './BusinessCanvasItem';

const useStyles = makeStyles((theme: Theme) =>
	({
		list: {
			display: 'grid',
			columnGap: theme.spacing(6),
			rowGap: theme.spacing(2),
			gridTemplateColumns: '1fr 1fr',
		},
	}),
);

interface BedrijfsprofielCardContentProps
{
	categories: BusinessCanvas[]
	selectedItem?: BusinessCanvas
	onSelectItem: (businessCanvasItem: BusinessCanvas) => void
	onPositionChange: (position: number) => void
	className?: string
}

export const BedrijfsprofielCardContent: FC<BedrijfsprofielCardContentProps> =
	({
		categories,
		className,
		...other
	}) =>
	{
		const classes = useStyles();

		return <CardContent className={className}>
			<List className={classes.list}>
				{
					categories.map((businessCanvasItem, index) =>
						<BusinessCanvasItem
							key={index}
							businessCanvasItem={businessCanvasItem}
							{...other}
						/>
					)
				}
			</List>
		</CardContent>;
	};