import { SvgIconComponent } from '@material-ui/icons';
import React, { FC, useContext, useMemo } from 'react';
import { AppDrawerItem } from './AppDrawerItem';
import { ClientIDContextRef } from './data/DossierContext';

interface SimpleAppDrawerItemProps
{
	item: string
	name: string
	selected: boolean
	Icon: SvgIconComponent
}

export const SimpleAppDrawerItem: FC<SimpleAppDrawerItemProps> =
	({
		item,
		...other
	}) =>
	{
		const clientId = useContext(ClientIDContextRef);

		const to = useMemo(() =>
		{
			switch (item)
			{
				case 'organisatie':
				case 'organisatie/home':
					return `/${item}?c=${clientId}`;
				default:
					return `/${item}`;
			}
		}, [clientId, item]);

		return <AppDrawerItem
			to={to}
			{...other}
		/>;
	};