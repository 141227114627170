import { MockGraphqlWebSocketService } from '@intentic/yggdrasil-rotr';
import React, { FC, useContext, useEffect, useState } from 'react';
import { RootContextRef } from './RootContext';

export const ReportWebSocketContextProvider: FC = ({children}) =>
{
	const {authenticationService, apiService} = useContext(RootContextRef);

	const [webSocketService] = useState(() => new MockGraphqlWebSocketService());

	useEffect(() =>
	{
		webSocketService.connect();

		return () => webSocketService.disconnect();
	}, [webSocketService]);
	
	return <RootContextRef.Provider
		value={{
			authenticationService,
			apiService,
			webSocketService,
		}}
	>
		{
			children
		}
	</RootContextRef.Provider>;
};