import { DataObject, Entity } from '@intentic/yggdrasil-rotr';
import { Box } from '@material-ui/core';
import clsx from 'clsx';
import React, { FC } from 'react';
import { ValueView, ValueViewProps } from '../../value/view/ValueView';

export interface QueryDSLValueViewProps extends Omit<ValueViewProps, 'value'>
{
	value: Array<Entity> | Entity | DataObject | Array<DataObject> | null | undefined
	containerClassName?: string
}

export const QueryDSLValueView: FC<QueryDSLValueViewProps> =
	({
		value,
		containerClassName,
		className,
		...other
	}) =>
	{
		if (value == null)
			return null;
		else if (Array.isArray(value))
			return <Box className={containerClassName}>
				{
					value.map((v, index) =>
						<Box
							key={index}
						>
							<ValueView
								value={v}
								className={className}
								{...other}
							/>
						</Box>
					)
				}
			</Box>;
		else
			return <ValueView
				className={clsx(containerClassName, className)}
				value={value}
				{...other}
			/>;
	};