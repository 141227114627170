import {
	Button as MUIButton,
	ButtonProps as MUIButtonProps,
	ButtonTypeMap,
	createStyles,
	makeStyles,
	Theme,
	Tooltip
} from '@material-ui/core';
import { ExtendButtonBase } from '@material-ui/core/ButtonBase';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import capitalize from '@material-ui/core/utils/capitalize';
import clsx from 'clsx';
import React from 'react';
import { ButtonLoader } from './ButtonLoader';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		loadingText: {
			'&$disabled': {
				color: theme.palette.text.primary,
			},
		},
		loadingTextInherit: {
			'&$disabled': {
				color: `inherit`,
			},
		},
		loadingTextPrimary: {
			'&$disabled': {
				color: theme.palette.primary.main,
			},
		},
		loadingTextSecondary: {
			'&$disabled': {
				color: theme.palette.secondary.main,
			},
		},
		loadingOutlined: {
			'&$disabled': {
				border: `1px solid ${theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)'}`,
				color: theme.palette.text.primary,
			},
		},
		loadingOutlinedInherit: {
			'&$disabled': {
				borderColor: 'currentColor',
				color: `inherit`,
			},
		},
		loadingOutlinedPrimary: {
			'&$disabled': {
				border: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
				color: theme.palette.primary.main,
			},
		},
		loadingOutlinedSecondary: {
			'&$disabled': {
				border: `1px solid ${alpha(theme.palette.secondary.main, 0.5)}`,
				color: theme.palette.secondary.main,
			},
		},
		loadingContained: {
			'&$disabled': {
				backgroundColor: theme.palette.grey[300],
				color: theme.palette.getContrastText(theme.palette.grey[300]),
				boxShadow: theme.shadows[2],
			},
		},
		loadingContainedInherit: {
			'&$disabled': {
				backgroundColor: theme.palette.grey[300],
				color: `inherit`,
			},
		},
		loadingContainedPrimary: {
			'&$disabled': {
				backgroundColor: theme.palette.primary.main,
				color: theme.palette.primary.contrastText,
			},
		},
		loadingContainedSecondary: {
			'&$disabled': {
				backgroundColor: theme.palette.secondary.main,
				color: theme.palette.secondary.contrastText,
			},
		},
		disabled: {},
	}),
);

interface ExtraProps
{
	loading?: boolean
	title?: React.ReactNode
}

export type ButtonProps<C extends React.ElementType> = Omit<MUIButtonProps<C, {component?: C}>, 'title'> & ExtraProps

export const Button = React.forwardRef<any, ButtonProps<any>>(
	({
		 variant = 'text',
		 color = 'default',
		 size = 'medium',
		 className,
		 disabled = false,
		 loading = false,
		 title,
		 children,
		 ...other
	 }, ref) =>
	{
		const classes = useStyles();

		const button = <MUIButton ref={ref} variant={variant} color={color} size={size} className={clsx(
			{
				[(classes as any)[`loading${capitalize(variant)}`]]: loading,
				[(classes as any)[`loading${capitalize(variant)}${capitalize(color)}`]]: loading && color !== 'default',
			},
			title ? undefined : className,
		)} classes={{disabled: classes.disabled}} disabled={loading || disabled} {...other}>
			<ButtonLoader loading={loading} size={size}>
				{children}
			</ButtonLoader>
		</MUIButton>;

		if (title)
		{
			return <Tooltip title={title}>
					<span className={className}>
						{button}
					</span>
			</Tooltip>;
		}
		else
		{
			return button;
		}
	}) as ExtendButtonBase<ButtonTypeMap<ExtraProps>>;