import {
	Card,
	CardContent,
	CardHeader,
	List,
	ListItem,
	ListItemText,
	makeStyles,
	Theme,
	Typography
} from '@material-ui/core';
import React, { FC, useMemo } from 'react';
import { ValueView } from '../../../../../../value/view/ValueView';
import { ObjectBPTypeComparator } from '../../../../bedrijfsprofiel/data/ObjectBPType';
import { BedrijfsprofielSelection } from '../../../DossierRoot';
import { ObjectBPTypeItem } from './ObjectBPTypeItem';

const useStyles = makeStyles((theme: Theme) =>
	({
		header: {
			padding: theme.spacing(1),
			background: theme.palette.secondary.lightest,
			color: theme.palette.secondary.lightestContrastText,
		},
		content: {
			display: 'flex',
			flexDirection: 'column',
			rowGap: theme.spacing(1),
		},
		list: {
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing(2),
		},
		empty: {
			display: 'none',
			'&:only-child':{
				display: 'initial',
			},
		},
	}),
);

interface BedrijfsprofielPanelProps
{
	selectedItem: BedrijfsprofielSelection['item']
}

export const BedrijfsprofielPanel: FC<BedrijfsprofielPanelProps> =
	({
		selectedItem: canvasItem,
	}) =>
	{
		const classes = useStyles();

		const objectTypes = canvasItem.objectTypes!;

		const orderedObjectTypes = useMemo(() =>
		{
			return objectTypes.slice().sort(ObjectBPTypeComparator);
		}, [objectTypes]);

		return <Card>
			<CardHeader
				className={classes.header}
				title={<ValueView
					variant="h6"
					value={canvasItem.entity}
				/>}
			/>
			<CardContent className={classes.content}>
				<List className={classes.list}>
					{
						orderedObjectTypes.map((objectType, index) =>
							<ObjectBPTypeItem
								key={index}
								objectBPType={objectType}
							/>
						)
					}
					<ListItem
						className={classes.empty}
						disableGutters
					>
						<ListItemText disableTypography primary={<Typography variant="caption">Geen relevante bedrijfsprofiel objecten gevonden</Typography>}/>
					</ListItem>
				</List>
			</CardContent>
		</Card>;
	};