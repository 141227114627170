import * as React from 'react';
import { FC, useContext, useState } from 'react';
import { RootContextRef } from './RootContext';
import { useAsyncEffect } from './util/hooks/useAsyncEffect';
import { InstantComponentLoader } from './util/InstantComponentLoader';

export const RequireAuthentication: FC =
    ({
         children,
     }) =>
    {
        const {authenticationService} = useContext(RootContextRef);

        const [authenticated, setAuthenticated] = useState(() => authenticationService.authenticated);

        useAsyncEffect(() =>
            ({
                promise: authenticationService.requireAuthentication(),
                then: () => setAuthenticated(true),
            }), [authenticationService]);

        if (authenticated)
            return <>{children}</>
        else
            return <InstantComponentLoader/>;
    };