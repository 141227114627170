import { DataObject } from '@intentic/yggdrasil-rotr';
import { Box, Card, CardContent, CardHeader } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC, useContext, useMemo } from 'react';
import { Organization } from '../../../../../../__generated__/ygg/model/modelClasses';
import { ValueView } from '../../../../../../value/view/ValueView';
import { YggdrasilContextRef } from '../../../../../../YggdrasilContext';
import { PropPair } from '../../object-bp/specialized/template/ObjectBPCardTemplate';
import { ObjectBPCardTemplatePropertyRow } from '../../object-bp/specialized/template/ObjectBPCardTemplatePropertyRow';

const useStyles = makeStyles((theme: Theme) =>
	({
		header: {
			padding: theme.spacing(1),
			background: theme.palette.secondary.lightest,
			color: theme.palette.secondary.lightestContrastText,
		},
		content: {
			display: 'flex',
			flexDirection: 'column',
			rowGap: theme.spacing(4),
		},
		section: {
			display: 'flex',
			flexDirection: 'column',
			rowGap: theme.spacing(1),
		},
		total: {
			fontWeight: theme.typography.fontWeightBold,
		}
	}),
);

interface OrganizationCardProps
{
	organization: Organization
}

export const OrganizationCard: FC<OrganizationCardProps> =
	({
		organization
	}) =>
	{
		const classes = useStyles();

		const {textType} = useContext(YggdrasilContextRef);
		
		const addresses = useMemo<DataObject[]>(() => 
		{
			return organization.risicoAdressen!.map(address =>
			{
				return textType.construct(`${address.adres}, ${address.plaats}`, textType.constructSpecification(true));
			});
		}, [organization.risicoAdressen, textType]);

		const props = useMemo<PropPair[]>(() =>
		{
			return [
				{
					prop: Organization.PROPS.primaireSBIActiviteit,
					value: organization._props.primaireSBIActiviteit,
				},
				{
					prop: 'Personen',
					value: [
						...organization._props.aandeelhouders!,
						...organization._props.bestuurders!,
						...organization._props.commissarissen!,
						...organization._props.gevolmachtigden!,
					],
				},
				{
					prop: Organization.PROPS.aantalWerkzamePersonen,
					value: organization._props.aantalWerkzamePersonen,
				},
				{
					prop: Organization.PROPS.risicoAdressen,
					value: addresses,
				},
			];
		}, [addresses, organization._props.aandeelhouders, organization._props.aantalWerkzamePersonen, organization._props.bestuurders, organization._props.commissarissen, organization._props.gevolmachtigden, organization._props.primaireSBIActiviteit]);

		return <Card>
			<CardHeader
				className={classes.header}
				disableTypography
				title={<ValueView
					variant="h6"
					value={organization.entity}
				/>}/>
			<CardContent className={classes.content}>
				<Box className={classes.section}>
					{
						props.map((propPair, index) =>
							<ObjectBPCardTemplatePropertyRow
								key={index}
								prop={propPair.prop}
								value={propPair.value}
							/>
						)
					}
				</Box>
			</CardContent>
		</Card>;
	};