import { Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import React, { FC } from 'react';
import { Button } from '../../generic/Button/Button';

const useStyles = makeStyles((theme: Theme) =>
	({
		errorIcon: {
			marginRight: theme.spacing(1),
			marginBottom: -3,
		},
		container: {
			margin: theme.spacing(2),
		},
		button: {
			marginTop: theme.spacing(2),
		},
	}),
);

const chunkFailedMessage = /Loading chunk [\d]+ failed/;

const ErrorBoundary: FC =
	({
		children
	}) =>
	{
		const classes = useStyles();

		return <SentryErrorBoundary
			fallback={({
				error,
				resetError,
			}) =>
			{
				if (error?.message && chunkFailedMessage.test(error.message))
				{
					return <div className={classes.container}>
						<Typography color="error" component="p" variant="h5">
							<ErrorIcon className={classes.errorIcon}/>
							Applicatie niet kunnen laden
						</Typography>
						<Typography component="p">
							{error?.message ?? ''}
						</Typography>
						<Typography component="p">
							Je gebruikt een verouderde versie van de applicatie. Ververs de pagina om de nieuwste versie
							binnen te halen.
						</Typography>
						<div className={classes.button}>
							<Button variant="contained" color="primary" onClick={() => window.location.reload(true)}>Pagina
								verversen</Button>
						</div>
					</div>
				}
				else
				{
					return <div className={classes.container}>
						<Typography color="error" component="p" variant="h5">
							<ErrorIcon className={classes.errorIcon}/>
							Er is iets misgegaan
						</Typography>
						<Typography component="p">
							{error?.message ?? error?.toString() ?? ''}
						</Typography>
						<div className={classes.button}>
							<Button variant="contained" color="primary" onClick={resetError}>Opnieuw proberen</Button>
						</div>
					</div>;
				}
			}}
		>
			{children}
		</SentryErrorBoundary>
	}

export default ErrorBoundary;