import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';
import {
	QBrancheBeoordeling,
	QBusinessClient,
	QControleBeoordeling,
	QDekkingsEntiteit,
	QDekkingsType,
	QGoederen,
	QGoederenRisicobeoordeling,
	QOrganization,
	QRisicoAdresRisicobeoordeling,
	QRisicoAdviesDossier,
	QRisicobeoordeling,
	QRisicoprofiel
} from '../../../../__generated__/ygg/dsl/queryClasses';
import { RisicoAdviesDossier } from '../../../../__generated__/ygg/model/modelClasses';
import { useDataSingle } from '../../../../query/useDataSingle';
import { LazyComponentLoader } from '../../../../util/LazyComponentLoader';
import { useQueryParams } from '../../../../util/router/useQueryParams';
import { ClientContextRef } from '../../bedrijfsprofiel/data/ClientContext';

const useStyles = makeStyles(() =>
	({
		loader: {
			flexGrow: 1,
		},
		main: {
			alignSelf: 'center',
			marginInline: 'auto',
		},
	}),
);

export const DossierContextRef = React.createContext<RisicoAdviesDossier>(undefined!);

export const RequireDossier: FC =
	({
		children
	}) =>
	{
		const classes = useStyles();

		const dossierID = useQueryParams().get('d');

		const dossier = useDataSingle(queryFactory =>
		{
			if (dossierID !== null)
			{
				return queryFactory.selectFrom(QRisicoAdviesDossier.risicoAdviesDossier)
					.join(QRisicoAdviesDossier.risicoAdviesDossier.scope)
					.join(QRisicoAdviesDossier.risicoAdviesDossier.huidigeStap)
					.join(QRisicoAdviesDossier.risicoAdviesDossier.risicoBeoordelingen, QRisicobeoordeling.risicobeoordeling)
					.cast(QRisicobeoordeling.risicobeoordeling, QRisicoAdresRisicobeoordeling.risicoAdresRisicobeoordeling)
					.join(QRisicoAdresRisicobeoordeling.risicoAdresRisicobeoordeling.risicoAdres)
					.join(QRisicoAdresRisicobeoordeling.risicoAdresRisicobeoordeling.ibbRisicoklasse)
					.cast(QRisicobeoordeling.risicobeoordeling, QGoederenRisicobeoordeling.goederenRisicobeoordeling)
					.join(QGoederenRisicobeoordeling.goederenRisicobeoordeling.goederen, QGoederen.goederen)
					.join(QGoederen.goederen.goederenType)
					.join(QRisicobeoordeling.risicobeoordeling.risicoType, QRisicoprofiel.risicoprofiel)
					.join(QRisicoprofiel.risicoprofiel.objectBPTypes)
					.join(QRisicoprofiel.risicoprofiel.dekkingen, QDekkingsType.dekkingsType)
					.join(QDekkingsType.dekkingsType.dekkingsentiteiten, QDekkingsEntiteit.dekkingsEntiteit)
					.join(QDekkingsEntiteit.dekkingsEntiteit.branches)
					.join(QRisicoprofiel.risicoprofiel.objectBPTypes)
					.join(QRisicobeoordeling.risicobeoordeling.automatischeInschattingRisicoklasse)
					.join(QRisicobeoordeling.risicobeoordeling.brutoRisicoklasse)
					.join(QRisicoAdviesDossier.risicoAdviesDossier.controleBeoordelingen, QControleBeoordeling.controleBeoordeling)
					.join(QControleBeoordeling.controleBeoordeling.risicoprofielen)
					.join(QControleBeoordeling.controleBeoordeling.beoordelingsklasse)
					.cast(QControleBeoordeling.controleBeoordeling, QBrancheBeoordeling.brancheBeoordeling)
					.join(QBrancheBeoordeling.brancheBeoordeling.branche)
					.join(QBrancheBeoordeling.brancheBeoordeling.missendeDekkingen)
					.join(QBrancheBeoordeling.brancheBeoordeling.aanwezigeDekkingen)
					.join(QRisicoAdviesDossier.risicoAdviesDossier.client, QBusinessClient.businessClient)
					.join(QBusinessClient.businessClient.organization, QOrganization.organization)
					.join(QOrganization.organization.objects)
					.join(QOrganization.organization.primaireSBIActiviteit)
					.join(QOrganization.organization.aandeelhouders)
					.join(QOrganization.organization.bestuurders)
					.join(QOrganization.organization.commissarissen)
					.join(QOrganization.organization.gevolmachtigden)
					.join(QOrganization.organization.risicoAdressen)
					.join(QBusinessClient.businessClient.polissen)
					.for(dossierID)
			}
		}, [dossierID]);

		if (dossier === undefined && dossierID !== null)
			return <LazyComponentLoader className={classes.loader}/>
		else if (dossier == null)
			return <Typography className={classes.main} color="error">Dossier niet op kunnen halen</Typography>
		else
		{
			return <DossierContextRef.Provider key={dossierID} value={dossier}>
				<ClientContextRef.Provider value={dossier.client!}>
					{children}
				</ClientContextRef.Provider>
			</DossierContextRef.Provider>
		}
	}