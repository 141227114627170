import { Risicobeoordeling } from '../../../../__generated__/ygg/model/modelClasses';
import { getName as getRisicobeoordelingName, } from './RiscoBeoordeling';

export interface RisicobeoordelingItem
{
	id: number
	beoordeling: Risicobeoordeling
}

export function getName(item: RisicobeoordelingItem): string
{
	const {beoordeling} = item;

	return `R${item.id} ${getRisicobeoordelingName(beoordeling)}`;
}