import { Box, Typography } from "@material-ui/core";
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ChevronRight } from '@material-ui/icons';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React, { FC, useCallback, useContext } from "react";
import { Link } from 'react-router-dom';
import { BusinessCanvas, EntityType, Risicoklasse } from '../../../../../__generated__/ygg/model/modelClasses';
import { Button } from "../../../../../generic/Button/Button";
import { useComputed } from '../../../../../mobx/useComputed';
import { CanvasItemBeoordelingItem } from '../../data/CanvasItemBeoordelingItem';
import { DossierContextRef } from '../../data/DossierContext';
import {
	INVENTARISATIEVRAGEN_STEP,
	RAPPORTAGE_STEP,
	RISICO_ANALYSE_STEP,
	VERZEKERINGEN_STEP
} from '../../data/DossierStep';
import { RisicoAnalyseSelection } from '../../DossierRoot';
import { CanvasTabs } from './CanvasTabs';

const useStyles = makeStyles((theme: Theme) =>
	({
		root: {
			display: 'flex',
			flexDirection: 'column',
		},
		topContainer: {
			display: 'flex',
			justifyContent: 'flex-end',
			gap: theme.spacing(2),
		},
		textSection: {
			display: 'flex',
			flexBasis: `calc(50% - ${theme.spacing(1)}px)`,
			textAlign: 'end',
			justifyContent: 'flex-end',
		},
		text: {
			color: theme.palette.primary.dark,
		},
		tabs: {
			flexGrow: 1,
			minWidth: 0,
		},
		buttonContainer: {
			flexBasis: `calc(50% - ${theme.spacing(1)}px)`,
		},
		button: {
			width: '100%',
			padding: theme.spacing(1, 2),
			...theme.typography.body1,
		},
		icon: {
			marginInlineStart: 'auto',
		},
		textLong: {
			alignSelf: 'end',
		},
		checkCircle: {
			alignItems: 'end',
			paddingRight: `${theme.spacing(2)}px`
		},
		colorPrimary: {
			color: '#33CC99',
		},
		colorDisabled: {
			color: 'grey',
		},
		textRows: {
			display: 'flex',
			flexDirection: 'column',
			paddingLeft: '10px',
		},
		spacer: {
			paddingTop: '20px',
			paddingBottom: '20px',
		},
	}),
);

interface RisicoAnalyseSectionProps
{
	categories: BusinessCanvas[]
	objectTypeDictionary: Record<string, EntityType>
	risicoklasses: Risicoklasse[]
	selectedItem?: RisicoAnalyseSelection
	onSelectItem: (item: RisicoAnalyseSelection | undefined) => void
	onPositionChange: (position: number) => void
}

export const RisicoAnalyseSection: FC<RisicoAnalyseSectionProps> =
	({
		categories,
		objectTypeDictionary,
		risicoklasses,
		selectedItem,
		onSelectItem,
		...other
	}) =>
	{
		const classes = useStyles();

		const dossier = useContext(DossierContextRef);

		const handleSelectRisicobeoordelingItem = useCallback((item: CanvasItemBeoordelingItem | undefined) =>
		{
			if (item === undefined)
				onSelectItem(undefined);
			else
				onSelectItem({type: 'risico analyse', item});
		}, [onSelectItem]);

		const disabled = useComputed(() =>
		{
			switch (dossier.huidigeStap!.id)
			{
				case RAPPORTAGE_STEP:
				case VERZEKERINGEN_STEP:
				case RISICO_ANALYSE_STEP:
					return false;
				case INVENTARISATIEVRAGEN_STEP:
				default:
					return true;
			}
		}, [dossier]);

		return <Box className={classes.root}>
			<Box className={classes.topContainer}>
				<Box className={classes.textSection}>
					<Box className={classes.checkCircle}>
						<CheckCircleIcon className={disabled ? classes.colorDisabled : classes.colorPrimary}/>
					</Box>
					<Box className={classes.textRows}>
						<Typography className={classes.text}>
							<li>Heeft u alle risico's beoordeeld en maatregelen geëvalueerd?</li>
						</Typography>
						<Typography variant="caption">
							Beoordeel uw risicoprofiel en evalueer de effectiviteit van <br/>aanwezige beheersingsmaatregelen.
						</Typography>
					</Box>
				</Box>
				<Box className={classes.buttonContainer}>
					<Button
						disabled={disabled}
						variant="contained"
						color="primary"
						className={classes.button}
						component={Link}
						to={`./risico-analyse?d=${dossier.id}`}
					>
						Risico-analyse
						<ChevronRight
							className={classes.icon}
						/>
					</Button>
				</Box>
			</Box>
			{
				!disabled &&
				<>
					<Box className={classes.spacer}>
						<Typography
							variant="caption"
							className={classes.textLong}
						>
							Selecteer een risico om de aanbevolen verzekeringen te bekijken. Als u de risico-analyse voltooit,
							zullen alle verzekeringen zichtbaar worden.
						</Typography>
					</Box>
					<CanvasTabs
						className={classes.tabs}
						businessCanvas={categories}
						objectTypeDictionary={objectTypeDictionary}
						risicoklasses={risicoklasses}
						onSelectItem={handleSelectRisicobeoordelingItem}
						selectedItem={selectedItem?.type === 'risico analyse' ? selectedItem.item : undefined}
						{...other}
					/>
				</>
			}
		</Box>;
	}