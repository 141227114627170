import { EntityType } from '@intentic/yggdrasil-rotr';
import { useContext } from 'react';
import { useComputed } from '../../../mobx/useComputed';
import { YggdrasilContextRef } from '../../../YggdrasilContext';

/**
 * @param idConstants must remain same length and must consist of constant values only
 */
export function useEntityTypes(...idConstants:string[]): EntityType[]
{
	const {metadataService} = useContext(YggdrasilContextRef);

	return useComputed(() =>
	{
		return idConstants.map(id => metadataService.getById(id));
		// eslint-disable-next-line
	}, [metadataService]);
}