import React, { FC, useCallback } from 'react';
import {
	BusinessCanvas,
	EntityType,
	Risicobeoordeling,
	Risicoklasse
} from '../../../../../__generated__/ygg/model/modelClasses';
import { useComputed } from '../../../../../mobx/useComputed';
import {
	BUSINESS_CANVAS_KANALEN_ID,
	BUSINESS_CANVAS_KLANTEN_ID,
	BUSINESS_CANVAS_MIDDELEN_ID,
	BUSINESS_CANVAS_PARTNERS_ID,
	BUSINESS_CANVAS_PROCESSEN_ID,
	BUSINESS_CANVAS_PROPOSITIE_ID,
	BUSINESS_CANVAS_RELATIE_ID
} from '../../../bedrijfsprofiel/data/BusinessCanvas';
import { getAverageScore } from '../../../rapportage/data/Risicoklasse';
import {
	RISICO_KLASSE_HIGH_ID,
	RISICO_KLASSE_LOW_ID,
	RISICO_KLASSE_MIDDLE_ID,
	RISICO_KLASSE_VERY_HIGH_ID
} from '../../data/RisicoKlasseIds';
import { getRisicoprofielenIDs } from '../../data/useRisicoprofielenIDs';
import { GraphContainer, GraphContainerProps } from './GraphContainer';

interface BusinessCanvasGraphContainerProps extends Omit<GraphContainerProps<BusinessCanvas>, 'summary' | 'actualScore' | 'referenceScore'>
{
	objectTypeDictionary: Record<string, EntityType>
	allRisicoBeoordelingen: Risicobeoordeling[]
	risicoklasses: Risicoklasse[]
	referenceScore?: (item: Risicobeoordeling) => Risicoklasse
	actualScore?: (item: Risicobeoordeling) => Risicoklasse | undefined | null
	activatedItem?: Set<string>
}

const defaultReferenceScore = (beoordeling: Risicobeoordeling) => beoordeling.automatischeInschattingRisicoklasse!
const defaultActualScore = (beoordeling: Risicobeoordeling) => beoordeling.brutoRisicoklasse

export const BusinessCanvasGraphContainer: FC<BusinessCanvasGraphContainerProps> =
	({
		objectTypeDictionary,
		allRisicoBeoordelingen,
		risicoklasses,
		items,
		referenceScore = defaultReferenceScore,
		actualScore = defaultActualScore,
		...other
	}) =>
	{
		const categoryAverageScores = useComputed(() =>
		{
			return items.reduce<Record<string, Risicoklasse>>((result, category) =>
			{
				const risicoprofielIds = getRisicoprofielenIDs(category, objectTypeDictionary);

				const risicoKlasses = allRisicoBeoordelingen
					.filter(risicoBeoordeling => risicoprofielIds.has(risicoBeoordeling.risicoType!.id))
					.map(risicobeoordeling => referenceScore(risicobeoordeling));

				switch (Math.ceil(getAverageScore(risicoKlasses)))
				{
					case 0:
					case 1:
						result[category.id] = risicoklasses.find(risicoklasse => risicoklasse.id === RISICO_KLASSE_LOW_ID)!;
						break;
					case 2:
						result[category.id] = risicoklasses.find(risicoklasse => risicoklasse.id === RISICO_KLASSE_MIDDLE_ID)!;
						break;
					case 3:
						result[category.id] = risicoklasses.find(risicoklasse => risicoklasse.id === RISICO_KLASSE_HIGH_ID)!;
						break;
					case 4:
						result[category.id] = risicoklasses.find(risicoklasse => risicoklasse.id === RISICO_KLASSE_VERY_HIGH_ID)!;
						break;
				}

				return result;
			}, {});
		}, [allRisicoBeoordelingen, items, objectTypeDictionary, referenceScore, risicoklasses]);

		const categoryActualAverageScores = useComputed(() =>
		{
			return items.reduce<Record<string, Risicoklasse>>((result, category) =>
			{
				const risicoprofielIds = getRisicoprofielenIDs(category, objectTypeDictionary);

				const risicoKlasses = allRisicoBeoordelingen
					.filter(risicoBeoordeling => risicoprofielIds.has(risicoBeoordeling.risicoType!.id))
					.map(risicobeoordeling => actualScore(risicobeoordeling) ?? referenceScore(risicobeoordeling));

				switch (Math.ceil(getAverageScore(risicoKlasses)))
				{
					case 0:
					case 1:
						result[category.id] = risicoklasses.find(risicoklasse => risicoklasse.id === RISICO_KLASSE_LOW_ID)!;
						break;
					case 2:
						result[category.id] = risicoklasses.find(risicoklasse => risicoklasse.id === RISICO_KLASSE_MIDDLE_ID)!;
						break;
					case 3:
						result[category.id] = risicoklasses.find(risicoklasse => risicoklasse.id === RISICO_KLASSE_HIGH_ID)!;
						break;
					case 4:
						result[category.id] = risicoklasses.find(risicoklasse => risicoklasse.id === RISICO_KLASSE_VERY_HIGH_ID)!;
						break;
				}

				return result;
			}, {});
		}, [actualScore, allRisicoBeoordelingen, items, objectTypeDictionary, referenceScore, risicoklasses]);

		const graphReferenceScore = useCallback((item: BusinessCanvas) =>
		{
			return categoryAverageScores[item.id];
		}, [categoryAverageScores]);

		const graphActualScore = useCallback((item: BusinessCanvas) =>
		{
			return categoryActualAverageScores[item.id];
		}, [categoryActualAverageScores]);

		const summary = useCallback((item: BusinessCanvas) =>
		{
			switch (item.id)
			{
				case BUSINESS_CANVAS_PARTNERS_ID:
					return 'PRT';
				case BUSINESS_CANVAS_PROCESSEN_ID:
					return 'PRC';
				case BUSINESS_CANVAS_PROPOSITIE_ID:
					return 'PRP';
				case BUSINESS_CANVAS_MIDDELEN_ID:
					return 'MDL';
				case BUSINESS_CANVAS_RELATIE_ID:
					return 'RLT';
				case BUSINESS_CANVAS_KANALEN_ID:
					return 'KNL';
				case BUSINESS_CANVAS_KLANTEN_ID:
					return 'KLT';
				default:
					return item.name.substr(0, 3);
			}
		}, []);

		return <GraphContainer
			items={items}
			summary={summary}
			referenceScore={graphReferenceScore}
			actualScore={graphActualScore}
			{...other}
		/>;
	};