import { Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC, useContext, useState } from 'react';
import { BusinessCanvas, EntityType, Risicoklasse } from '../../../../../__generated__/ygg/model/modelClasses';
import { useComputed } from '../../../../../mobx/useComputed';
import { BusinessCanvasGraphContainer } from '../../../risico-analyse/components/graph/BusinessCanvasGraphContainer';
import { DossierContextRef } from '../../data/DossierContext';

const useStyles = makeStyles((theme: Theme) =>
	({
		root: {
			display: 'flex',
			flexDirection: 'column',
		},
		title: {
			paddingBlockStart: theme.spacing(2),
		},
	})
);

interface AggregateCanvasItemProps
{
	businessCanvasItems: BusinessCanvas[]
	objectTypeDictionary: Record<string, EntityType>
	risicoklasses: Risicoklasse[]
}

export const AggregateCanvasItem: FC<AggregateCanvasItemProps> =
	({
		businessCanvasItems,
		objectTypeDictionary,
		risicoklasses,
	}) =>
	{
		const classes = useStyles();

		const dossier = useContext(DossierContextRef);

		const [selectedCanvasItem, setSelectedCanvasItem] = useState<BusinessCanvas | undefined>();

		const allRisicoBeoordelingen = useComputed(() => dossier.risicoBeoordelingen!, [dossier]);

		return <Box
			className={classes.root}
		>
			<BusinessCanvasGraphContainer
				objectTypeDictionary={objectTypeDictionary}
				allRisicoBeoordelingen={allRisicoBeoordelingen}
				risicoklasses={risicoklasses}
				items={businessCanvasItems}
				selectedItem={selectedCanvasItem}
				onSelectItem={setSelectedCanvasItem}
			/>
		</Box>;
	};