import { EntityType } from '../../../../__generated__/ygg/model/modelClasses';

export const OBJECT_BP_TYPE_MANAGEMENT_ID = '558d3b03-f6be-419a-bdfb-7f423ca8b2b4'
export const OBJECT_BP_TYPE_PERSONEEL_ID = 'ea81aa51-5ea7-4433-a45a-76f716c09d4d'
export const OBJECT_BP_TYPE_REGISTER_DEELNEMINGEN_ID = 'a31e8dc5-3440-4880-8dee-db5778625bfa'
export const OBJECT_BP_TYPE_REGISTER_KREDIETEN_ID = '7c4ad9f0-8820-4eec-ae84-c848c6e3b87e'
export const OBJECT_BP_TYPE_TOELEVERANCIERS_ID = '879a482f-8037-4499-aaab-0abbf9c217b8'

export const OBJECT_BP_TYPE_KERNPROCESSEN_ID = '8ceb53a8-58c5-4762-a42d-e6968d74d11d'
export const OBJECT_BP_TYPE_ONDERSTEUNENDE_PROCESSEN_ID = '7384e211-1f87-45bc-917b-5d4e4d8d0220'
export const OBJECT_BP_TYPE_VOORRAAD_ID = '80e1022e-16c3-45ab-ba0a-361460a15fdc'

export const OBJECT_BP_TYPE_PROPOSITIE_ID = '9ea8feb4-f6d9-4cf8-a860-bffc95650312'

export const OBJECT_BP_TYPE_GEBOUWEN_EN_TERREINEN_ID = '97ed92da-8a23-4cff-8bd9-0092b7da3893'
export const OBJECT_BP_TYPE_VERVOERSMIDDELEN_ID = '437bae9d-3b1d-457b-b6d8-3bd2297ef624'
export const OBJECT_BP_TYPE_INVENTARIS_ID = 'ee3de665-b807-405f-af30-9d898e7ecb6c'
export const OBJECT_BP_TYPE_REGISTER_GROEPSVERMOGEN_ID = '05b5d357-e7e6-4c75-a7c4-d34556e157cf'
export const OBJECT_BP_TYPE_MACHINES_EN_INSTALLATIES_ID = 'd678580e-30d7-4cf7-bc67-7a9be83bfcf6'
export const OBJECT_BP_TYPE_RECHTEN_EN_VERGUNNINGEN_ID = '8bd39c7a-629e-4077-b14c-c768a026d626'
export const OBJECT_BP_TYPE_LIQUIDE_MIDDELEN_ID = '456c0f0c-7481-458a-854e-ca5a390a269f'
export const OBJECT_BP_TYPE_EFFECTEN_ID = 'df8b43df-3802-49b4-b1e7-15920b3ae074'

export const OBJECT_BP_TYPE_RELATIEBEHEER_ID = '315b19e0-26e3-4455-9357-d6a2ed8e12a4'

export const OBJECT_BP_TYPE_DISTRIBUTIEKANALEN_ID = '59e6d624-03fa-44b3-b451-56c56426705b'

export const OBJECT_BP_TYPE_REGISTER_KLANTEN_ID = '6bffa59e-ed45-4f67-9a2c-e78affde50c8'



export const OBJECT_BP_SPECIALIZED_TYPE_REGISTER_DGA_ID = '6fdd5425-5c6e-4e2a-9c93-6787f2f6a177'
export const OBJECT_BP_SPECIALIZED_TYPE_REGISTER_MANAGEMENT_ID = '1cc76f42-6eb6-485b-82fd-2bf271505277'
export const OBJECT_BP_SPECIALIZED_TYPE_REGISTER_LEVERANCIERS_ID = '4f9b2df2-66c1-4cff-a745-3bd91744e07b'
export const OBJECT_BP_SPECIALIZED_TYPE_REGISTER_PERSONEEL_ID = '320db2bb-f380-44fc-ae70-cdd3b8ba595e'
export const OBJECT_BP_SPECIALIZED_TYPE_REGISTER_VOORRAAD_MEERJAREN_PLANTOPSTAND_ID = 'da82ea10-ebe8-446b-9383-af36d46f9f36'
export const OBJECT_BP_SPECIALIZED_TYPE_REGISTER_VOORRADEN_ID = '4a43df68-1468-496f-b69e-c1d4cfa75598'
export const OBJECT_BP_SPECIALIZED_TYPE_REGISTER_VOORRAAD_EMBALLAGE_ID = 'caba7ab2-c276-46eb-88cb-15620cafb15f'
export const OBJECT_BP_SPECIALIZED_TYPE_REGISTER_VOORRAAD_LEVENDE_HAVE_ID = '3361d3c6-53d3-4220-80ec-51161363d240'
export const OBJECT_BP_SPECIALIZED_TYPE_REGISTER_VOORRAAD_VASTGOED_ID = '6d5be726-7985-40cd-a342-953846f36c80'
export const OBJECT_BP_SPECIALIZED_TYPE_REGISTER_ELECTRONICA_ID = '8123562d-5317-4235-b8e0-b56c80f66fbd'
export const OBJECT_BP_SPECIALIZED_TYPE_REGISTER_INVENTARIS_ID = '636684e9-4c26-4b82-b0e4-ebf8cc637a14'
export const OBJECT_BP_SPECIALIZED_TYPE_REGISTER_AANHANGER_ID = '7b3c4675-59b7-433c-8776-e4956805ff04'
export const OBJECT_BP_SPECIALIZED_TYPE_TEGOEDEN_OP_BANKGIROREKENINGEN_ID = '49f8737a-ee65-4c91-8138-11197483ca8d'
export const OBJECT_BP_SPECIALIZED_TYPE_REGISTER_VLIEGTUIGEN_ID = '5ff9af57-270c-4ccd-be9f-fb5713615a7f'
export const OBJECT_BP_SPECIALIZED_TYPE_REGISTER_AUTO_ID = 'fb124796-4d59-4e56-b83c-141f29519f8b'
export const OBJECT_BP_SPECIALIZED_TYPE_REGISTER_BOUWCLAIMS_ID = '7e14ad69-ba53-4128-ae77-d19aeacccc18'
export const OBJECT_BP_SPECIALIZED_TYPE_HUURDERS_INVESTERINGEN_ID = 'b5af1b5c-cd0a-428b-b70a-23c66688613f'
export const OBJECT_BP_SPECIALIZED_TYPE_VASTGOED_BELEGGINGEN_ID = 'e12cb757-d6b7-4ccd-926a-367b96223251'
export const OBJECT_BP_SPECIALIZED_TYPE_REGISTER_CONTRACTEN_ID = 'fce775a8-f866-431d-bc4c-8815e8e8f44c'
export const OBJECT_BP_SPECIALIZED_TYPE_REGISTER_WERKMATERIEEL_ID = 'b7261bf0-237e-48dd-b42c-7468fbaac31b';
export const OBJECT_BP_SPECIALIZED_TYPE_REGISTER_BUS_ID = '383aba04-7fdf-49cf-8ae8-52ac279f2127'
export const OBJECT_BP_SPECIALIZED_TYPE_REGISTER_GEBOUWEN_ID = 'ddce6e97-b981-42f6-ad57-c9d5e5f92ea3'
export const OBJECT_BP_SPECIALIZED_TYPE_REGISTER_VRACHTWAGEN_ID = '6a7dafd3-1c1b-4abd-a174-7c0034fe6d4b'
export const OBJECT_BP_SPECIALIZED_TYPE_REGISTER_MOTOR_ID = 'c376ed89-b7b3-4189-9e49-d92d53df9655'
export const OBJECT_BP_SPECIALIZED_TYPE_REGISTER_SCHEPEN_ID = 'e8a112fb-1edc-453b-9f19-653c1bae9a1a'
export const OBJECT_BP_SPECIALIZED_TYPE_KASMIDDELEN_ID = 'c9ccf0b1-dc34-44cd-a204-ff8c7e98ba04'
export const OBJECT_BP_SPECIALIZED_TYPE_REGISTER_BROMFIETS_ID = 'a1e91b76-9ba1-4a1c-a106-508f61a70db7'
export const OBJECT_BP_SPECIALIZED_TYPE_REGISTER_FIETS_ID = 'b347e5ba-a796-4d77-acaf-da84c6ca4424'
export const OBJECT_BP_SPECIALIZED_TYPE_REGISTER_TRACTOR_ID = 'f397b3b0-7d48-425c-a299-e75897afbf4c'
export const OBJECT_BP_SPECIALIZED_TYPE_REGISTER_PERCEEL_ID = '4ddab087-3fb4-4057-857c-774953b503cc'


const orderMapping: Record<string, number> =
	{
		[OBJECT_BP_TYPE_MANAGEMENT_ID]: 0,
		[OBJECT_BP_TYPE_PERSONEEL_ID]: 1,
		[OBJECT_BP_TYPE_REGISTER_DEELNEMINGEN_ID]: 2,
		[OBJECT_BP_TYPE_REGISTER_KREDIETEN_ID]: 3,
		[OBJECT_BP_TYPE_TOELEVERANCIERS_ID]: 4,

		[OBJECT_BP_TYPE_KERNPROCESSEN_ID]: 0,
		[OBJECT_BP_TYPE_ONDERSTEUNENDE_PROCESSEN_ID]: 1,
		[OBJECT_BP_TYPE_VOORRAAD_ID]: 2,

		[OBJECT_BP_TYPE_PROPOSITIE_ID]: 0,

		[OBJECT_BP_TYPE_GEBOUWEN_EN_TERREINEN_ID]: 0,
		[OBJECT_BP_TYPE_VERVOERSMIDDELEN_ID]: 1,
		[OBJECT_BP_TYPE_INVENTARIS_ID]: 2,
		[OBJECT_BP_TYPE_REGISTER_GROEPSVERMOGEN_ID]: 3,
		[OBJECT_BP_TYPE_MACHINES_EN_INSTALLATIES_ID]: 4,
		[OBJECT_BP_TYPE_RECHTEN_EN_VERGUNNINGEN_ID]: 5,
		[OBJECT_BP_TYPE_LIQUIDE_MIDDELEN_ID]: 6,
		[OBJECT_BP_TYPE_EFFECTEN_ID]: 7,

		[OBJECT_BP_TYPE_RELATIEBEHEER_ID]: 0,

		[OBJECT_BP_TYPE_DISTRIBUTIEKANALEN_ID]: 0,

		[OBJECT_BP_TYPE_REGISTER_KLANTEN_ID]: 0,
	}

export function ObjectBPTypeComparator(obpt1: EntityType, obpt2: EntityType): number
{
	const order1 = orderMapping[obpt1.id] ?? 100;
	const order2 = orderMapping[obpt2.id] ?? 100;

	return order1 - order2;
}