import React, { FC, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { ProductBemiddelingDossierDrawerList } from './product/ProductBemiddelingDossierDrawerList';
import { RisicoAdviesDossierDrawerList } from './risico/RisicoAdviesDossierDrawerList';

export interface DossierDrawerProps
{
	selectedButton: string
}

export const DossierDrawerList: FC =
	() =>
	{
		const match = useRouteMatch<{ type?: string, slug?: string }>({path: '/:type/:slug'});

		const selectedButton = useMemo(() => match?.params.slug ?? '', [match]);
		
		switch (match?.params.type ?? '')
		{
			case 'risico':
				return <RisicoAdviesDossierDrawerList selectedButton={selectedButton}/>
			case 'product':
				return <ProductBemiddelingDossierDrawerList selectedButton={selectedButton}/>
			default:
				return null;	
		}
	};