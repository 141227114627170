import { Typography } from '@material-ui/core';
import React from 'react';
import { DataObjectViewProps } from './DataObjectView';

export const DefaultView = React.forwardRef<any, DataObjectViewProps>(
	({
		dataObject,
		variant,
		...other
	}, ref) =>
	{
		return <Typography variant={variant ?? 'body1'} ref={ref} {...other}>{dataObject.toString()}</Typography>;
	});