import { Box, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC, useContext } from 'react';
import { useComputed } from '../../../../../mobx/useComputed';
import { ValueView } from '../../../../../value/view/ValueView';
import { DossierContextRef } from '../../data/DossierContext';
import {
	INVENTARISATIEVRAGEN_STEP,
	RAPPORTAGE_STEP,
	RISICO_ANALYSE_STEP,
	VERZEKERINGEN_STEP
} from '../../data/DossierStep';
import { ProgressIndicator } from './ProgressIndicator';

const useStyles = makeStyles((theme: Theme) =>
	({
		root: {
			display: 'flex',
			gap: theme.spacing(2),
			justifyContent: 'space-between',
		},
		text: {
			display: 'flex',
			flexDirection: 'column',
		},
	}),
);

export const Introduction: FC =
	() =>
	{
		const classes = useStyles();

		const dossier = useContext(DossierContextRef);
		
		const value = useComputed(() =>
		{
			switch (dossier.huidigeStap!.id)
			{
				case RAPPORTAGE_STEP:
					return 1.00;
				case VERZEKERINGEN_STEP:
					return 0.80;
				case RISICO_ANALYSE_STEP:
					return 0.55;
				case INVENTARISATIEVRAGEN_STEP:
					return 0.40;
				default:
					return 0.05;
			}
		}, [dossier]);

		return <Box className={classes.root}>
			<Box className={classes.text}>
				<Typography variant="h5" color="primary">
					<ValueView variant="inherit" value={dossier.client!.organization!.entity}/>
					{' '}
					<ValueView variant="inherit" value={dossier.entity}/>
				</Typography>
				<Typography paragraph>
					Hier ziet u een overzicht en de voortgang van het actuele risico-analyse dossier.
				</Typography>
				<Typography paragraph>
					Zorg ervoor dat u elke stap volledig voltooit, aangezien de volgende stap afhangt van de gegevens.
				</Typography>
			</Box>
			<ProgressIndicator value={value}/>
		</Box>
	};