import { Box, Paper } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { PropsWithChildren } from 'react';

const useStyles = makeStyles<Theme, { position: number | undefined }>((theme: Theme) =>
	({
		rootArrow: ({position}) => ({
			background: theme.palette.background.dark,
			flex: `360px 0 0`,
			position: 'relative',
			'&:after': {
				content: '""',
				position: 'absolute',
				left: theme.spacing(-1.5),
				top: position ? position - theme.spacing(2) : theme.spacing(-4),
				borderStyle: 'solid',
				borderWidth: theme.spacing(2, 1.5, 2, 0),
				borderColor: 'transparent',
				borderRightColor: theme.palette.background.dark,
				display: 'block',
				width: 0,
				zIndex: 0,
			},
		}),
		rootFixed: {
			position: 'fixed',
			bottom: 0,
			top: 0,
			width: 360,
		},
		rootContainer: {
			color: theme.palette.background.paper,
			display: 'flex',
			flexDirection: 'column',
			height: '100%',
			overflowY: 'auto',
			WebkitOverflowScrolling: 'touch',
		},
	}),
);

interface GenericSidePanelProps<T>
{
	selectedItem?: T
	position: number
	className?: string
}

export const GenericSidePanel = <T, >
	({
		selectedItem,
		position,
		className,
		children,
	}: PropsWithChildren<GenericSidePanelProps<T>>) =>
	{
		const classes = useStyles({position: selectedItem ? position : undefined});

		const rootClassName = clsx(classes.rootArrow, className);

		return <Paper
			square
			className={rootClassName}
		>
			<Box className={classes.rootFixed}>
				<Box className={classes.rootContainer}>
					{
						children
					}
				</Box>
			</Box>
		</Paper>;
	};