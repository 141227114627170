import { ListItem } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { ChevronRight } from '@material-ui/icons';
import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { BusinessCanvas } from '../../../../../__generated__/ygg/model/modelClasses';
import { ValueView } from '../../../../../value/view/ValueView';

const useStyles = makeStyles((theme: Theme) =>
	({
		root: {
			borderBottom: `1px solid ${theme.palette.text.primary}`,
			'&$focusVisible': {
				color: theme.palette.primary.dark,
				fontWeight: theme.typography.fontWeightBold,
				boxShadow: theme.shadows[8],
				marginInline: theme.spacing(-1),
				paddingInline: theme.spacing(1),
				width: `calc(100% + ${theme.spacing(2)}px)`,
				borderBottom: 0,
			},
			'&$selected, &$selected:hover': {
				color: theme.palette.primary.dark,
				fontWeight: theme.typography.fontWeightBold,
				boxShadow: theme.shadows[8],
				marginInline: theme.spacing(-1),
				paddingInline: theme.spacing(1),
				width: `calc(100% + ${theme.spacing(2)}px)`,
				borderBottom: 0,
			},
		},
		focusVisible: {},
		selected: {},
		chevron: {
			marginInlineStart: 'auto',
		},
	}),
);

interface BusinessCanvasItemProps
{
	businessCanvasItem: BusinessCanvas
	selectedItem?: BusinessCanvas
	onSelectItem: (businessCanvasItem: BusinessCanvas) => void
	onPositionChange: (position: number) => void
}

export const BusinessCanvasItem: FC<BusinessCanvasItemProps> =
	({
		businessCanvasItem,
		selectedItem,
		onSelectItem,
		onPositionChange,
	}) =>
	{
		const classes = useStyles();

		const listItemClasses = useMemo(() => ({
			root: classes.root,
			selected: classes.selected,
			focusVisible: classes.focusVisible
		}), [classes.focusVisible, classes.root, classes.selected]);

		const element = useRef<HTMLDivElement>(null);

		useEffect(() =>
		{
			if (businessCanvasItem.id === selectedItem?.id)
			{
				const resizeListener = () =>
				{
					const rect = element.current!.getBoundingClientRect();
					onPositionChange(rect.top + (rect.height / 2) + window.pageYOffset);
				}

				resizeListener();

				window.addEventListener('resize', resizeListener);

				return () => window.removeEventListener('resize', resizeListener);
			}
		}, [businessCanvasItem.id, onPositionChange, selectedItem]);
		
		const handleClick = useCallback(() => 
		{
			onSelectItem(businessCanvasItem);
		}, [businessCanvasItem, onSelectItem]);

		return <ListItem
			ref={element}
			disableGutters
			button
			selected={businessCanvasItem.id === selectedItem?.id}
			onClick={handleClick}
			classes={listItemClasses}
		>
			<ValueView color="textSecondary" value={businessCanvasItem.entity} />
			<ChevronRight className={classes.chevron}/>
		</ListItem>;
	};