import { Box } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, { FC, useCallback, useContext, useMemo } from 'react';
import { BusinessCanvas, EntityType } from '../../../../../__generated__/ygg/model/modelClasses';
import { GraphContainer } from '../../../risico-analyse/components/graph/GraphContainer';
import { RisicobeoordelingItem } from '../../../risico-analyse/data/RiscoBeoordelingItem';
import { useRisicoprofielenIDs } from '../../../risico-analyse/data/useRisicoprofielenIDs';
import { CanvasItemBeoordelingItem } from '../../data/CanvasItemBeoordelingItem';
import { DossierContextRef } from '../../data/DossierContext';

const useStyles = makeStyles((theme: Theme) =>
	({
		root: {
			display: 'flex',
			flexDirection: 'column',
		},
		title: {
			paddingBlockStart: theme.spacing(2),
		},
	})
);

interface CanvasItemProps
{
	businessCanvasItem: BusinessCanvas
	objectTypeDictionary: Record<string, EntityType>
	selectedItem?: CanvasItemBeoordelingItem
	onSelectItem: (item: CanvasItemBeoordelingItem | undefined) => void
}

export const CanvasItem: FC<CanvasItemProps> =
	({
		businessCanvasItem,
		objectTypeDictionary,
		selectedItem,
		onSelectItem,
	}) =>
	{
		const classes = useStyles();

		const dossier = useContext(DossierContextRef);

		const risicoprofielIds = useRisicoprofielenIDs(businessCanvasItem, objectTypeDictionary);

		const items = useMemo<RisicobeoordelingItem[]>(() =>
		{
			return dossier.risicoBeoordelingen!.filter(beoordeling => risicoprofielIds.has(beoordeling.risicoType!.id))
				.sort((b1, b2) => b1.risicoType!.naam.localeCompare(b2.risicoType!.naam))
				.map((beoordeling, index) => ({id: index + 1, beoordeling}));
		}, [dossier, risicoprofielIds]);

		const handleSelectRisicobeoordelingItem = useCallback((item: RisicobeoordelingItem | undefined) =>
		{
			if (item === undefined)
				onSelectItem(undefined);
			else
				onSelectItem({risicobeoordelingItem: item, canvasItem: businessCanvasItem})
		}, [businessCanvasItem, onSelectItem]);

		return <Box
			className={classes.root}
		>
			<GraphContainer
				items={items}
				selectedItem={selectedItem?.risicobeoordelingItem}
				onSelectItem={handleSelectRisicobeoordelingItem}
			/>
		</Box>;
	};