import { AbstractObjEntity, AbstractQueryDSLQuery, Query, QueryFactory } from '@intentic/yggdrasil-rotr';
import { DependencyList, useCallback, useContext, useMemo } from 'react';
import { useObservable } from '../generic/useObservable';
import { YggdrasilContextRef } from '../YggdrasilContext';

export function useDataSingle<T extends AbstractObjEntity>(
    query: (queryFactory: QueryFactory) => AbstractQueryDSLQuery<T, Query> | undefined,
    inputs: DependencyList
): T | null | undefined
{
    const context = useContext(YggdrasilContextRef);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const queryCallback = useCallback(query, inputs);

    const observable = useMemo(() =>
    {
        const query = queryCallback(new QueryFactory(context));

        if (query !== undefined)
            return query.publishOne();
    }, [context, queryCallback]);

    const catchError = useCallback((error) =>
    {
        console.error(error);
        return null;
    }, []);

    return useObservable(observable, catchError);
}